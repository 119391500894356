import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//import './Exam.css';
//import './Home.css';

export class Examsheet extends React.Component {
	constructor(props) {
		super(props);
		var dataSet = [
			{
				q:
					'1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore?',
				options: [
					' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
					' Praesent ut odio aliquam, imperdiet velit vel, egestas metus.',
					' Sed dapibus tellus sed sapien auctor vestibulum.',
					' Vivamus et tellus sit amet massa egestas convallis.',
				],
				correct: 1,
				choice: -1,
			},
			{
				q:
					'2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore?',
				options: [
					' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
					' Praesent ut odio aliquam, imperdiet velit vel, egestas metus.',
					' Seda dapibus tellus sed sapien auctor vestibulum.',
					' Vivamus et tellus sit amet massa egestas convallis.',
				],
				correct: 3,
				choice: -1,
			},
			{
				q:
					'3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore?',
				options: [
					' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
					' Praesent ut odio aliquam, imperdiet velit vel, egestas metus.',
					' Seda dapibus tellus sed sapien auctor vestibulum.',
					' Vivamus et tellus sit amet massa egestas convallis.',
				],
				correct: 3,
				choice: -1,
			},
		];
		this.state = {
			dataSet: dataSet,
			correct: 0,
			incorrect: 0,
		};
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		this.badClick();
		this.goodClick();
	}
	badClick() {
		this.setState({ correct: this.state.incorrect + 1 });
	}
	goodClick() {
		this.setState({ incorrect: this.state.correct + 1 });
	}

	render() {
		var questions = [];
		for (let i = 0; i < this.state.dataSet.length; i++) {
			questions.push(
				<Question
					actions={this.handleClick}
					question={this.state.dataSet[i].q}
					options={this.state.dataSet[i].options}
					index={i}
					ans={this.state.dataSet[i].correct}
				/>
			);
		}
		return <form>{questions}</form>;
	}
}

export class Question extends React.Component {
	constructor(props) {
		super(props);
		this.onValueChange = this.onValueChange.bind(this);

		this.state = {
			question: props.question,
			options: props.options,
			index: props.i,
			ans: props.correct,
		};
	}

	onValueChange(event) {
		this.setState({
			selectedOption: event.target.value,
		});
	}

	render() {
		return (
			<form>
				<p>{this.state.question}</p>
				<ul>
					<li>
						<label>
							<input
								type="radio"
								value={this.state.options[0]}
								checked={
									this.state.selectedOption === this.state.options[0]
								}
								onChange={this.onValueChange}
								onClick={this.props.actions}
							/>
							{this.state.options[0]}
						</label>
					</li>
					<li>
						<label>
							<input
								type="radio"
								value={this.state.options[1]}
								checked={
									this.state.selectedOption === this.state.options[1]
								}
								onChange={this.onValueChange}
								onClick={this.props.actions}
							/>
							{this.state.options[1]}
						</label>
					</li>
					<li>
						<label>
							<input
								type="radio"
								value={this.state.options[2]}
								checked={
									this.state.selectedOption === this.state.options[2]
								}
								onChange={this.onValueChange}
								onClick={this.props.actions}
							/>
							{this.state.options[2]}
						</label>
					</li>
					<li>
						<label>
							<input
								type="radio"
								value={this.state.options[3]}
								checked={
									this.state.selectedOption === this.state.options[3]
								}
								onChange={this.onValueChange}
								onClick={this.props.actions}
							/>
							{this.state.options[3]}
						</label>
					</li>
				</ul>
			</form>
		);
	}
}
