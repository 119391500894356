/**
 * Renders each row of the scheduled interview table in the parent component
 * InterviewSchedule.js
 * 
 */
import React, { Component } from 'react'
import video_sign from '../../assets/hiring_requirement/video.png';
import close_sign from '../../assets/hiring_requirement/bin.png';
import UserContext from '../../User/User';
import { Link } from 'react-router-dom';
import firebase from "firebase";
import DeleteConfirmationModalInterview from './DeleteConfirmationModalInterview';


export class ActionButtons extends Component {

    constructor(props) {
        super(props);
        this.state = {
            interviewDetails: {},
            addModalShow: false,
        }

        this.handleOnDelete = this.handleOnDelete.bind(this);
    }

    async componentDidMount() {



        this.setState({
            interviewDetails: this.props.interviewDetails,

        })


    }



    /**
     * Gets triggereg when delete button is pressed in the modal
     * DeleteConfirmationModal
     */
    handleOnDelete() {
        this.props.handleOnDelete(this.state.interviewDetails.meetingId)
    }

    render() {
        let interviewTime = "unconfirmed";
        let start_time = this.state.interviewDetails.start_time
        if(start_time!= undefined){     
            interviewTime = new Date(start_time.seconds * 1000);
        }
       
        let addModalClose = () => this.setState({addModalShow:false});

        return (
            <React.Fragment>
                <td>
                    {this.state.interviewDetails.name}
                </td>
                <td>
                    New York
                </td>
                {/* <td>
                    102032
                </td> */}
                <td>
                {interviewTime.toString()}
                </td>
                <td>
                    <div style={{ display: "flex" }}>
                        <div>

                            <Link to={`/interview/${this.state.interviewDetails.meetingId}`} ><img src={video_sign} alt="edit requirement" /></Link>

                        </div>
                        <div style={{marginLeft: '20px'}}>
                        <img src={close_sign} alt = "delete interview" onClick={()=>this.setState({addModalShow:true})}/>
                            <DeleteConfirmationModalInterview handleOnDelete={this.handleOnDelete} show = {this.state.addModalShow} onHide={addModalClose}> </DeleteConfirmationModalInterview>
                        </div>
                    </div>
                </td>
            </React.Fragment>

        )


    }
}

export default ActionButtons
