import React from 'react';
import styles from './ViewCandidates.module.scss';
import Dashboard from '../../components/Dashboard';
import Candidate from '../../components/Candidate';
import firebase from 'firebase';
import UserContext from '../../User/User';
import moodle_client from "moodle-client";
import axios from 'axios';

class ViewCandidates extends React.Component {
	constructor() {
		super();

		this.state = {
			candidates: [], //Phase-out (pull candidates from moodle)
			moodleCandidates: [],
			savedCands: [],
			fullStack: 0,
			cybersecurity: 0,
			saved: 0,
			unsaved: 0,
			angular: 0,
			java: 0,
			javaScript: 0,
			python: 0,
			react: 0,
			amazonEC2: 0,
			aws: 0,
			mongoDB: 0,
			MSazure: 0,
			stripe: 0,
			open: true,
			openLanguage: true,
			openTechnical: true,
			savedCands: [],
		};

		this.handleCheck = this.handleCheck.bind(this);
		this.displayMoodleCandidates = this.displayMoodleCandidates.bind(this);
		this.checkList = this.checkList.bind(this);
	}
	static contextType = UserContext;


	displayMoodleCandidates() {
		//console.log("HELLOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO")
		const { moodleCandidates } = this.state;
		console.log(moodleCandidates)
		const { savedCands } = this.state;
		var candidateList = [];
		var i;

		for (i = 0; i < moodleCandidates.length; i++) {
			let name;
			let saved = [];
			let title;
			let id;
			let languages = [];
			let technical = [];
			let experience = [];
			let education = [];
			let mentor = [];
			let skillsAndProgress = [];
			let profileUrl;
			let initial;
			let email;
			let grades =[];
			let skills=[];

			try {
				//get necessery data from moodle candidates
				saved = savedCands;
				name = moodleCandidates[i].first + ' ' + moodleCandidates[i].last;
				email = moodleCandidates[i].email;
				id = moodleCandidates[i].candidateID;
				grades = moodleCandidates[i].grades;
				skills=moodleCandidates[i].skills;
				
					languages = moodleCandidates[i].languages;
					technical = moodleCandidates[i].technical;
	
					experience = moodleCandidates[i].experience;
					education = moodleCandidates[i].education;

					mentor = moodleCandidates[i].mentor;
					if(languages==undefined){
						languages = [];
					}
					if(technical==undefined){
						technical = [];
					}
					if(experience==undefined){
						experience = [];
					}
					if(education==undefined){
						education = [];
					}
					if(mentor==undefined){
						mentor = [];
					}
				

				profileUrl = moodleCandidates[i].profileUrl;
				if (saved.includes(id)) {
					initial = 'fa fa-star favorite gold move-again';
				} else {
					initial = 'fa fa-star favorite gray move-again';
				}
				if (moodleCandidates[i].course === 'FullStack') {
					title = 'Full-Stack Developer';
				} else {
					title = 'Cybersecurity';
				}
				if (!this.checkList(title, languages, technical)) {
					continue;
				}
				if(this.state.saved && saved.includes(id) == false){
					continue;
				}
				if(this.state.unsaved && saved.includes(id) == true){
					continue;
				}
			} catch (err) {
				continue;
			}
			candidateList.push(
				<Candidate
					key={i}
					name={name}
					email={email}
					title={title}
					languages={languages}
					technical={technical}
					id={id}
					experience={experience}
					education={education}
					mentor={mentor}
					grades={grades}
					profileUrl={profileUrl}
					initialFavorite={initial}
					skills={skills}
				/>
			);
		}

		return candidateList;
	}


	checkList(title, languages, technical) {
		let checks = [];
		const {
			fullStack,
			cybersecurity,
			saved,
			unsaved,
			angular,
			java,
			javaScript,
			python,
			react,
			amazonEC2,
			aws,
			mongoDB,
			MSazure,
			stripe,
		} = this.state;
		if (fullStack != cybersecurity) {
			if (fullStack & (title !== 'Full-Stack Developer')) {
				return false;
			} else if (cybersecurity & (title !== 'Cybersecurity')) {
				return false;
			}
		}

		if (angular) {
			if (!languages.includes('Angular.JS')) {
				return false;
			}
		}
		if (java) {
			if (!languages.includes('Java')) {
				return false;
			}
		}
		if (javaScript) {
			if (!languages.includes('JavaScript')) {
				return false;
			}
		}
		if (python) {
			if (!languages.includes('Python')) {
				return false;
			}
		}
		if (react) {
			if (!languages.includes('React.JS')) {
				return false;
			}
		}

		if (amazonEC2) {
			if (!technical.includes('Amazon EC2')) {
				return false;
			}
		}
		if (aws) {
			if (!technical.includes('AWS')) {
				return false;
			}
		}
		if (mongoDB) {
			if (!technical.includes('MongoDB')) {
				return false;
			}
		}
		if (MSazure) {
			if (!technical.includes('MS Azure')) {
				return false;
			}
		}
		if (stripe) {
			if (!technical.includes('Stripe API')) {
				return false;
			}
		}

		return true;
	}

	componentDidMount() {
		const context = this.context;
		
		this.setState({
			candidates: context.candidates, //Phase-out (pull candidates from moodle)
			savedCands: context.savedCands,
			moodleCandidates:context.moodleCandidates,
		});

		let course = 3;
		let id = 10036;
		let grades =[];

		/*
		if(course == 2){
			let data;
			axios.get(`/webservice/rest/server.php?wstoken=01f04ab2f0eacb95cb7909261d5f7fb0&wsfunction=gradereport_user_get_grade_items&userid=${id}&courseid=2&moodlewsrestformat=json`)
			.then((response) => {
				data = response["data"];
				const user = data.usergrades[0].userfullname;
				const assignments = data.usergrades[0].gradeitems;
				let reactTotal = 0;
				let javaTotal = 0;
				let gradesTotal=0;

				let reactAssignments = 0;
				let javaAssignments = 0;
				let completedAssignments = 0;
				let i;
				
				for (i = 0; i < assignments.length; i++) {
					const grade =assignments[i].graderaw;
					
					if(assignments[i].itemtype==="course" ){
						continue;
					}
					if(grade!=null){
						gradesTotal = gradesTotal + grade;
						completedAssignments ++;

						if(assignments[i].itemname.includes("React")){
							
							reactTotal = reactTotal + grade;
							reactAssignments ++;
						}
						else if(assignments[i].itemname.includes("Data Structures")){
							javaTotal = javaTotal + grade;
							javaAssignments ++;
						}

					}
				
				}
				let totalScore = 0;
				if(completedAssignments!=0){
					totalScore=gradesTotal/completedAssignments;
				}
				let reactScore=0;
				if(reactAssignments!=0){
					reactScore=reactTotal/reactAssignments;
				}
				let javaScore=0;
				if(javaAssignments!=0){
					javaScore=javaTotal/javaAssignments;
				}
				
				grades.push(totalScore);
				grades.push(reactScore);
				grades.push(javaScore);
				console.log("Total Grade: " + totalScore);
				console.log("react: " + reactScore);
				console.log("Java: " + javaScore);

			}).catch(err => {
			
			})
		}else if(course==3){
			let data;
			axios.get(`/webservice/rest/server.php?wstoken=01f04ab2f0eacb95cb7909261d5f7fb0&wsfunction=gradereport_user_get_grade_items&userid=${id}&courseid=3&moodlewsrestformat=json`)
			.then((response) => {
				data = response["data"];
				const user = data.usergrades[0].userfullname;
				const assignments = data.usergrades[0].gradeitems;

				let total1 = 0;
				let assignments1 =0;
				let total2 = 0;
				let assignments2 =0;
				let total3 = 0;
				let assignments3 =0;
				let total4 = 0;
				let assignments4 =0;
				let total5 = 0;
				let assignments5 =0;

				let gradesTotal=0;
				let completedAssignments = 0;
				let i;
				
				for (i = 0; i < assignments.length; i++) {
					const grade =assignments[i].graderaw;
					
					if(assignments[i].itemtype==="course" ){
						continue;
					}
					if(grade!=null){
						gradesTotal = gradesTotal + grade;
						completedAssignments ++;
						
						if(assignments[i].itemname.includes("Quiz 1")){//Threats & Attack Vectors
							
							total1 = total1 + grade;
							assignments1 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 2")){
							total1 = total1 + grade;
							assignments1 ++;
						}
						else if(assignments[i].itemname.includes("Lab 1")){
							total1 = total1 + grade;
							assignments1 ++;
						}
						else if(assignments[i].itemname.includes("Email Security")){
							total1 = total1 + grade;
							assignments1 ++;
						}
						else if(assignments[i].itemname.includes("Exam 1")){
							total1 = total1 + grade;
							assignments1 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 3")){//Infrastructure Design
							total2 = total2 + grade;
							assignments2 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 4")){
							total2 = total2 + grade;
							assignments2 ++;
						}
						else if(assignments[i].itemname.includes("Exam 2")){
							total2 = total2 + grade;
							assignments2 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 5")){//Secure Implementation
							total3 = total3 + grade;
							assignments3 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 6")){
							total3 = total3 + grade;
							assignments3 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 9")){
							
							total3 = total3 + grade;
							assignments3 ++;
						}
						else if(assignments[i].itemname.includes("Exam 3")){
							total3 = total3 + grade;
							assignments3 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 7")){//Cyber Incident Response
							total4 = total4 + grade;
							assignments4 ++;
						}
						else if(assignments[i].itemname.includes("Lab 7")){
							total4 = total4 + grade;
							assignments4 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 8")){
							total4 = total4 + grade;
							assignments4 ++;
						}
						else if(assignments[i].itemname.includes("Lab 8")){
							total4 = total4 + grade;
							assignments4 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 10")){
							total4 = total4 + grade;
							assignments4 ++;
						}
						else if(assignments[i].itemname.includes("Exam 4")){
							total4 = total4 + grade;
							assignments4 ++;
						}
						else if(assignments[i].itemname.includes("Exam 5")){
							total4 = total4 + grade;
							assignments4 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 11")){//Governance, Risk & Compliance
							total5 = total5 + grade;
							assignments5 ++;
						}
						else if(assignments[i].itemname.includes("Quiz 12")){
							total5 = total5 + grade;
							assignments5 ++;
						}
						else if(assignments[i].itemname.includes("Exam 6")){
							total5 = total5 + grade;
							assignments5 ++;
						}

					}
				
				}
				let totalScore = 0;
				if(completedAssignments!=0){
					totalScore=gradesTotal/completedAssignments;
				}
				let total1Score=0;
				if(assignments1!=0){
					total1Score=total1/assignments1;
				}
				let total2Score=0;
				if(assignments2!=0){
					total2Score=total2/assignments2;
				}
				let total3Score = 0;
				if(assignments3!=0){
					total3Score=total3/assignments3;
				}
				let total4Score=0;
				if(assignments3!=0){
					total4Score=total4/assignments4;
				}
				let total5Score=0;
				if(assignments5!=0){
					total5Score=total5/assignments5;
				}
				
				grades.push(totalScore);
				grades.push(total1Score);
				grades.push(total2Score);
				grades.push(total3Score);
				grades.push(total4Score);
				grades.push(total5Score);

				

				console.log("Total Grade: " + totalScore);
				console.log("Threats & Attack Vectors: " + total1Score);
				console.log("Infrastructure Design: " + total2Score);
				console.log("Secure Implementation: " + total3Score);
				console.log("Cyber Incident Response: " + total4Score);
				console.log("Governance, Risk & Compliance: " + total5Score);
			}).catch(err => {
			
			})
		}
*/
	}

	handleCheck(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		if (this.state[name] == 0) {
			this.setState({
				[name]: 1,
			});
		} else {
			this.setState({
				[name]: 0,
			});
		}
	}

	handleButtonClick = () => {
		this.setState((state) => {
			return {
				open: !state.open,
				filterAnimation: true,
			};
		});
	};

	handleLanguageButtonClick = () => {
		this.setState((state) => {
			return {
				openLanguage: !state.openLanguage,
			};
		});
	};

	handleTechnicalButtonClick = () => {
		this.setState((state) => {
			return {
				openTechnical: !state.openTechnical,
			};
		});
	};
	render() {
		//User data
		let { userID, logoutUser, setUser, loading } = this.context;

		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="View_Candidate" />

						{/*right expands when dashboard is closed*/}
						<div class="right">
							<div class="topBar">
								<div class="pageName">
									<h1>Candidates</h1>
								</div>
							</div>

							{/*code here*/}
							<div className={styles.inner}>
								{/*Candidate list starts here*/}
							<div className={styles.candidates_list}>
								<div className={styles.blockHeader}>
									<h1>Manage Your Candidates</h1>
								</div>
								{this.displayMoodleCandidates()}
							</div>

							{/*Candidate Filter starts here*/}
							<div className={styles.candidate_filter}>
								<div className={styles.filterContainer}>
									<div className={styles.blockHeader}>
										<h1>Filter</h1>
										<h2>Specify desired Skills for each position</h2>
									</div>
									<div className={styles.checkList}>
									<div className={styles.dropdownCheckList}>
										<span className={styles.candidate_filter_elements}>
											Filters
										</span>
										<span role="button" className={styles.typeButton}>
											<a role="button">
												<i
													onClick={this.handleButtonClick}
													className={
														this.state.open
															? 'fa fa-chevron-up'
															: 'fa fa-chevron-down'
													}
													aria-hidden="true"
												> </i>
											</a>
										</span>
									</div>

									{this.state.open && (
										<div
											className={
												this.state.open
													? styles.dropdownListOpen
													: styles.dropdownListClose
											}
										>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="fullStack"
													checked={this.state.fullStack == 1}
													onChange={this.handleCheck}
												/>
												Full-Stack
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="cybersecurity"
													checked={this.state.cybersecurity == 1}
													onChange={this.handleCheck}
												/>
												Cybersecurity
											</div><div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="saved"
													checked={this.state.saved == 1}
													onChange={this.handleCheck}
												/>
												Saved
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="unsaved"
													checked={this.state.unsaved == 1}
													onChange={this.handleCheck}
												/>
												Unsaved
											</div>
										</div>
									)}
									{/* <br></br>
									<div className={styles.dropdownCheckList}>
										<span className={styles.candidate_filter_elements}>
											Languages &#38;
										</span>
										<br></br>
										<span className={styles.candidate_filter_elements}>
											Frameworks
										</span>
										<span role="button" className="type-button">
											<i
												onClick={this.handleLanguageButtonClick}
												className={
													this.state.openLanguage
														? 'fa fa-chevron-up'
														: 'fa fa-chevron-down'
												}
												aria-hidden="true"
											></i>
										</span>
									</div>
									{this.state.openLanguage && (
										<div
											className={
												this.state.openLanguage
													? 'dropdown-list-open'
													: 'dropdown-list-close'
											}
										>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="angular"
													checked={this.state.angular == 1}
													onChange={this.handleCheck}
												/>
												Angular.JS
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="java"
													checked={this.state.java == 1}
													onChange={this.handleCheck}
												/>
												Java
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="javaScript"
													checked={this.state.javaScript == 1}
													onChange={this.handleCheck}
												/>
												JavaScript
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="python"
													checked={this.state.python == 1}
													onChange={this.handleCheck}
												/>
												Python
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="react"
													checked={this.state.react == 1}
													onChange={this.handleCheck}
												/>
												React
											</div>
										</div>
									)}

									<br></br>
									<div className={styles.dropdownCheckList}>
										<span className={styles.candidate_filter_elements}>
											Competency
										</span>
										<span role="button" className="type-button">
											<i
												onClick={this.handleTechnicalButtonClick}
												className={
													this.state.openTechnical
														? 'fa fa-chevron-up'
														: 'fa fa-chevron-down'
												}
												aria-hidden="true"
											></i>
										</span>
									</div>
									{this.state.openTechnical && (
										<div
											className={
												this.state.openTechnical
													? 'dropdown-list-open'
													: 'dropdown-list-close'
											}
										>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="amazonEC2"
													checked={this.state.amazonEC2 == 1}
													onChange={this.handleCheck}
												/>
												Amazon EC2
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="aws"
													checked={this.state.aws == 1}
													onChange={this.handleCheck}
												/>
												AWS
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="mongoDB"
													checked={this.state.mongoDB == 1}
													onChange={this.handleCheck}
												/>
												MongoDB
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="MSazure"
													checked={this.state.MSazure == 1}
													onChange={this.handleCheck}
												/>
												MS Azure
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="stripe"
													checked={this.state.stripe == 1}
													onChange={this.handleCheck}
												/>
												Stripe API
											</div>
										</div>
									)} */}
									</div>
								</div>
							</div>
							{/*Candidate Filter ends here*/}
							</div>

						</div>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default ViewCandidates;
