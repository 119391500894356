import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import styles from'./About.module.scss';
import cubeimg from '../../assets/icons/cube-icon.png';
import graphimg from '../../assets/icons/graph-icon2.png';
import lectureimg from '../../assets/icons/lecture-icon.png';
import peerimg from '../../assets/icons/peer-icon.png';
import trainimg from '../../assets/icons/train-icon.png';
import pairimg from '../../assets/icons/pair-icon.png';
import cognizant from '../../assets/employed/cognizant.png';
import verizon from '../../assets/employed/verizon.png';
import capitalone from '../../assets/employed/capitalone.png';
import wayfair from '../../assets/employed/wayfair.png';
import computer from '../../assets/icons/computer-icon.png';
import ai from '../../assets/icons/ai-icon.png';
import team from '../../assets/icons/team-icon.png';
import bookmark from '../../assets/icons/bookmark-icon.png';
import newsletter from '../../assets/icons/newsletter-icon.png';
import certificate from '../../assets/icons/certificate-icon.png';
import students from '../../assets/people/students.png';
import employers from '../../assets/people/employers.png';
import universities from '../../assets/people/universities.png';
import firebase from 'firebase';

function About() {

	//Tracking Analytics
	useEffect(() => {
		let analytics = firebase.analytics();
		console.log('Visited About Us')
		analytics.logEvent('visited_about_us')
	}, []); 

	return (
		<React.Fragment>
			<section className={styles.heroAbout}>
				<div className={styles.container}>
					<div className={styles.fade1}>
						<h1>About Us</h1>
					</div>
					<div className={styles.fade2}>
						<p>
							Our mission is to accelerate the careers of recent Full-Stack and Cybersecurity
							graduates by including Fortune 1000 leaders into our industry tailored, 
							high impact training program with the goal of facilitating continual career advancement. <br/> <br/>
							At Minos Labs, we believe in our core values of D.A.T.A
						</p>
					</div>
				</div>
			</section>

			<section className={styles.pros}>
				<div className={styles.col}>
					<div className={styles.container}>
						<h1>Diversity</h1>
						<p>
							Different perspectives foster a collaborative learning
							environment
						</p>
					</div>
				</div>

				<div className={styles.col}>
					<div className={styles.container}>
						<h1>Adaptability</h1>
						<p>
							Continually improving, to ensure we're prepared for the
							world of tomorrow
						</p>
					</div>
				</div>

				<div className={styles.col}>
					<div className={styles.container}>
						<h1>Transparency</h1>
						<p>Honesty and openness throughout the hiring process</p>
					</div>
				</div>
				
				<div className={styles.col}>
					<div className={styles.container}>
						<h1>Accountability</h1>
						<p>
							Deliver what is promised for our associates and clients
							alike
						</p>
					</div>
				</div>
			</section>

			<section className={styles.process}>
				<section className={styles.colContainer}>
					<div className={styles.left}>
						<div className={styles.container}>
							<div className={styles.sticky}>
								<h1>Our Approach</h1>
								<br></br>
								<p>A snapshot of your time at Minos Labs </p>
							</div>
						</div>
					</div>
					<div className={styles.right}>
						<div className={styles.container}>
							<div className={styles.row}>
								<div className={styles.image}>
									<img src={cubeimg} />
								</div>
								<div className={styles.text}>
									<h1>Daily Training Modules</h1>
									<p>
										Enhance your tech skills through daily training
										modules, assessments, and practice
									</p>
								</div>
							</div>
							<div className={styles.line}></div>
							<div className={styles.row}>
								<div className={styles.image}>
									<img src={lectureimg} />
								</div>
								<div className={styles.text}>
									<h1>Professional Lectures</h1>
									<p>
										Learn from industry professionals during lecture
										and twice weekly recitation sessions
									</p>
								</div>
							</div>
							<div className={styles.line}></div>
							<div className={styles.row}>
								<div className={styles.image}>
									<img src={trainimg} />
								</div>
								<div className={styles.text}>
									<h1>Train with coaches</h1>
									<p>
										Work with executive coaches and hiring managers to
										develop your professional skills and ace your
										interviews
									</p>
								</div>
							</div>
							<div className={styles.line}></div>
							<div className={styles.row}>
								<div className={styles.image}>
									<img src={peerimg} />
								</div>
								<div className={styles.text}>
									<h1>peer collaboration</h1>
									<p>
										Collaborate with your peers on real world projects
										and build your development portfolio
									</p>
								</div>
							</div>
							<div className={styles.line}></div>
							<div className={styles.row}>
								<div className={styles.image}>
									<img src={pairimg} />
								</div>
								<div className={styles.text}>
									<h1>paired with advisors</h1>
									<p>
										Connect, learn, and ask questions with advisors
										through weekly webinar sessions
									</p>
								</div>
							</div>
							<div className={styles.line}></div>
							<div className={styles.row}>
								<div className={styles.image}>
									<img src={graphimg} />
								</div>
								<div className={styles.text}>
									<h1>day one</h1>
									<p>
										Accelerate your career and enter the workforce
										with relevant, in-demand skills in your field!
										<text>
											lorum ipsum lorum ipsum lorum ipsum lorum ipsum{' '}
										</text>
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</section>

			<section className={styles.employed}>
				<div className={styles.eContainer}>
					<strong>Organizations that have employed our graduates</strong>
					<img src={cognizant} />
					<img src={verizon} />
					<img src={capitalone} />
					<img src={wayfair} />
				</div>
			</section>
			<section className={styles.ourCurriculum}>
					<div className={styles.containerMain}>
						<h1>Our Curriculum</h1>
						<p>
							Our goal is to equip Minos Labs Associates with industry
							knowledge and expertise needed to succeed
						</p>
					</div>

					<div className={styles.benefitsRow}>
						<div className={styles.benefitsCol1}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={computer} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>FULL-STACK DEVELOPMENT</h3>
									<p>
										Enhance your skills and be able to work across a
										full stack with our fully digital sessions, led by
										industry professionals
									</p>
								</div>
							</div>
						</div>

						<div className={styles.benefitsCol2}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={ai} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>CYBERSECURITY</h3>
									<p>
										Gain exposure to industry leading technologies and
										frameworks while simultaneously applying what you
										learn to real world projects and scenarios
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className={styles.benefitsRow}>
						<div className={styles.benefitsCol1}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={bookmark} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>REAL WORLD SIMULATION</h3>
									<p>
										Apply the knowledge you gain, using industry
										leading API’s in a full scale, Agile team project
									</p>
								</div>
							</div>
						</div>

						<div className={styles.benefitsCol2}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={newsletter} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>CAREER COACHING</h3>
									<p>
										Perfect your resume and interview skills with our
										in house team of executive coaches who work
										everyday as hiring managers for Fortune 1000
										companies
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className={styles.benefitsRow}>
						<div className={styles.benefitsCol1}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={team} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>ADVISORY NETWORK</h3>
									<p>
										Gain access to, and hear from C-Level executives
										across multiple industries and build connections
										that will serve you for a lifetime
									</p>
								</div>
							</div>
						</div>

						<div className={styles.benefitsCol2}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={certificate} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>DIGITAL PROGRAM</h3>
									<p>
										Enter the workforce, prepared for remote work with
										our industry tailored process, designed to help
										you accelerate your career as efficiently as
										possible
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			{/* <section className={styles.workforce}>
				<h1> Work Force Development</h1>
				<div className={styles.col}>
					<div className={styles.container}>
						<h1>TARGET POPULATIONS</h1>
						<p>
							Due to the increased demand for Cybersecurity and
							Full-Stack environments in the United States, we look to
							promote education and growth in the space. Supporting the
							diversity in the workforce is of great importance and we
							aim to accerate the process by focusing on the target
							demographics below:
						</p>
						<p style={{ marginBottom: '-18px' }}>Military Veterans</p>
						<p style={{ marginBottom: '-18px' }}>Imigrants/Refugees</p>
						<p style={{ marginBottom: '-18px' }}>
							Public Assistance Recipients
						</p>
						<p style={{ marginBottom: '-18px' }}>
							Disadvantaged Minorities
						</p>
						<p style={{ marginBottom: '-18px' }}>Women in STEM</p>
					</div>
				</div>
				<div className={styles.col}>
					<div className={styles.container}>
						<h1>TRAINING</h1>
						<p>
							Our Career Acceleration Program is led by industry subject
							matter experts that provide guidance to recent college
							graduates with the slots always reserved for
							under-reperesented groups to support workforce education
							development.
						</p>
						<p>
							Our Workforce Education Development Program is focused on
							technology education for individuals with little to no
							technical experience. We aim to empower individuals that
							seek to succeed within technology and launch a new career.
						</p>
					</div>
				</div>
				<div className={styles.col}>
					<div className={styles.container}>
						<h1>NONPROFIT SUPPORT</h1>
						<p>
							Partnering with Non-Profit 501(c)(3) organizations, Family
							Offices, and Personal Donors has supported the misson to
							provide under-represented groups with quality training,
							mentorship, and education. This has enabled us to provide
							an online learning environment, educational material, and
							executive coaching at no cost to these individuals.
						</p>
						<p>
							Minos Labs’ objective is to enable those in need to grow in
							their careers and develop their skills within our
							increasingly technology driven world.
						</p>
					</div>
				</div>
			</section> */}

			<section className={styles.apply}>
				<div className={styles.container}>
					<h1>Accelerate your career</h1>
					<Link to="/exam-start">
						<button>Apply Now</button>
					</Link>
				</div>
			</section>
		</React.Fragment>
	);
}

export default About;
