import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TwilioVideo from './TwilioVideo';
import Notes from './Notes'
import StudentProfile from './studentProfile';
import { NotesProvider } from './notesContext';
import PagenNotFound from '../pages/PageNotFound';
import UserContext from "../User/User";
import CorporateUserProfile from './CorporateUserProfile';
const axios = require('axios');
class Interview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            interviewId: this.props.match.params.interviewId,
            meetingNotes: "",
            meetingInfo: {},
            noIdFoundError: false,
            userType: "",
        }
        this.onMeetingNotesChange = this.onMeetingNotesChange.bind(this)
    }
    static contextType = UserContext;
    async componentDidMount() {
        //Fixes the header to the top when on this component
        var headerDom = document.getElementById("myHeader")
        headerDom.style.position = "absolute"

        try {
            const meetingDetails = await axios.get(`https://us-central1-minos-labs-57c35.cloudfunctions.net/interviewApp/getInterviewDetail/?id=${this.state.interviewId}`)
            console.log(meetingDetails.data.interview)

            const newMeetingObj = {
                candidateID: meetingDetails.data.interview.candidateID,
                corporateUserID: meetingDetails.data.interview.corporateUserID,
                flag: meetingDetails.data.interview.flag
            }

            this.setState({
                meetingInfo: newMeetingObj
            })

        } catch (e) {
            this.setState({
                noIdFoundError: true
            })
        }

        let { loginType } = this.context;
        this.setState({
            userType: loginType
        })

    }
    
    //Undos the above action when leaving the component
    componentWillUnmount() {
        var headerDom = document.getElementById("myHeader")
        headerDom.style.position = "fixed"
    }


    /**
     * This function sets the Note content to the state from child component
     * Notes.js to be used in the NoteContext
     * @param {meeting Note content from Notes.js} notesContent
     */
    onMeetingNotesChange(notesContent) {
        this.setState({
            meetingNotes: notesContent
        })
    }
    render() {
        return (
            <div>
                {
                    this.state.noIdFoundError ? <PagenNotFound></PagenNotFound> : <React.Fragment>

                        <section class="hero-university2"></section>

                        <div className="interviewComponent" >
                        <div className="topBar">
                            <div class="pageName">
								<h1>Interviews</h1>
							</div>
                        </div>

                            <div className="row firstRow" >

                                <div className="col-sm-12 col-md-8"  >
                                    <div className="twilio">
                                        <NotesProvider value={{
                                            meetingNotes: this.state.meetingNotes,
                                            meetingInfo: this.state.meetingInfo,
                                            meetingID: this.state.interviewId,
                                        }}>
                                            <TwilioVideo {...this.props} interviewId={this.state.interviewId}></TwilioVideo>
                                        </NotesProvider>
                                    </div>

                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="studentProfile">
                                                <StudentProfile interviewId={this.state.interviewId}></StudentProfile>
                                            </div>
                                        </div>
                                        <div className="col-12" >
                                            <div className="notes">
                                                {
                                                    this.state.userType === "Student" ?
                                                        <CorporateUserProfile corporateUserID={this.state.meetingInfo.corporateUserID}></CorporateUserProfile> : <Notes onChange={this.onMeetingNotesChange} > </Notes>
                                                }

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                }
            </div>
       ) 

    }
}

export default Interview
