import React from 'react';
import styles from '../Corporate/Partner.module.scss';
import ReactDOM from 'react-dom';
import marketing from '../../assets/icons/marketing-icon.png';
import graph from '../../assets/icons/graph-icon.png';
import chart from '../../assets/icons/chart-icon.png';
import computer from '../../assets/icons/computer-icon.png';
import handshake from '../../assets/icons/handshake-icon.png';
import web from '../../assets/icons/web-icon.png';
import certificate from '../../assets/icons/certificate-icon.png';
import firebase from 'firebase';

function validate(firstName, lastName, emailId, type, info) {
	return {
		firstName: firstName.length === 0,
		lastName: lastName.length === 0,
		emailId: emailId.length === 0 || !validateEmail(emailId),
		type: type.length === 6,
		info: info.length === 0,
	};
}

function validateEmail(emailId) {
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(emailId);
}

class University extends React.Component {
	constructor() {
		super();

		this.state = {
			firstName: '',
			lastName: '',
			emailId: '',
			type: 'select',
			info: '',

			everFocusedFirstName: false,
			everFocusedLastName: false,
			everFocusedEmailId: false,
			everFocusedType: false,
			everFocusedInfo: false,
			inFocus: '',

			isError: false,
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCloseMessage = this.handleCloseMessage.bind(this);

		let analytics = firebase.analytics();
		console.log('Visited University Page')
		analytics.logEvent('visited_university_page')
	}

	handleFormSubmit(evt) {
		this.setState({
			isError: true,
		});

		if (!this.canBeSubmitted()) {
			evt.preventDefault();
			return;
		}

		var x = document.getElementById('confirmSubmit');
		ReactDOM.findDOMNode(x).style.visibility = 'visible';
		ReactDOM.findDOMNode(x).style.opacity = '1';
		ReactDOM.findDOMNode(x).style.transition =
			'opacity 0.3s 0s, visibility 0s 0s';
	}

	handleCloseMessage() {
    this.setState({
			firstName: '',
			lastName: '',
			emailId: '',
			type: 'select',
			info: '',

			isError: false,
    });
    
		var x = document.getElementById('confirmSubmit');
		ReactDOM.findDOMNode(x).style.visibility = 'hidden';
		ReactDOM.findDOMNode(x).style.opacity = '0';
		ReactDOM.findDOMNode(x).style.transition =
			'opacity 0.3s 0s, visibility 0s 0.3s';
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleSubmit = (evt) => {
		if (!this.canBeSubmitted()) {
			evt.preventDefault();
			return;
		}
	};

	canBeSubmitted() {
		const errors = validate(
			this.state.firstName,
			this.state.lastName,
			this.state.emailId,
			this.state.type,
			this.state.info
		);
		const isDisabled = Object.keys(errors).some((x) => errors[x]);
		return !isDisabled;
	}

	render() {
		const errors = validate(
			this.state.firstName,
			this.state.lastName,
			this.state.emailId,
			this.state.type,
			this.state.info
		);
		return (
			<React.Fragment>
				<section className={styles.heroPartner}>
					<div className={styles.container}>
						<div className={styles.fade1}>
							<h1>University Partners</h1>
						</div>
						<div className={styles.fade2}>
							<p>
								A collaborative effort between Minos Labs and University
								Partners to ensure that recent college graduates are
								well equipped with the skills and knowledge needed to
								accelerate their employment and build connections that
								will continuously serve them throughout their
								professional career
							</p>
						</div>
					</div>
				</section>

				<section className={styles.partnerBenefits}>
					<div className={styles.left}>
						<div className={styles.container}>
							<h2>benefits of partnering with Minos Labs</h2>
							<p>
								We offer associates: executive coaching, professional
								development workshops, industry specific technical
								training, and access to a network of advisors and
								mentors ranging from established Software Engineers &
								Cybersecurity Analysts to C-Level Executives
							</p>
						</div>
					</div>

					<div className={styles.right}>
						<div className={styles.listContainer}>
							<div className={styles.container}>
								<img src={marketing} alt="portrait1" />
								<div className={styles.p1}>
									<p>
										Career coaching and professional development
									</p>
								</div>
							</div>

							<div className={styles.container}>
								<img src={graph} alt="portrait1" />
								<p>
									Upskilling of computer science graduates to
									professional developers and security analysts
								</p>
							</div>

							<div className={styles.container}>
								<img src={chart} alt="portrait1" />
								<p>
									Higher employment rates compared to individual job
									seekers
								</p>
							</div>

							<div className={styles.container}>
								<img src={certificate} alt="portrait1" />
								<div classname={styles.p4}>
									<p>6-week, accelerated program</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className={styles.howItWorks}>
					<h2>The Minos Labs Method</h2>

					<div className={styles.process}>
						<div className={styles.container}>
							<img src={handshake} alt="portrait1" />
							<h3>RECRUITMENT</h3>
							<p>
								Associates are on-boarded from partnered universities,
								bootcamps, and individual sourcing partners with an
								initial assessment to establish baseline technical
								knowledge
							</p>
						</div>
						<div className={styles.container}>
							<img src={computer} alt="portrait1" />
							<h3>TECHNICAL TRAINING</h3>
							<p>
								Associates are trained in Full-Stack Development,
								Cybersecurity, and industry knowledge through our fully
								digital learning platform
							</p>
						</div>
						<div className={styles.container}>
							<img src={marketing} alt="portrait1" />
							<h3>PROFESSIONAL DEVELOPMENT</h3>
							<p>
								Associates learn the professional skills they need from
								project management methodologies to effective
								communication and prioritization
							</p>
						</div>
						<div className={styles.container}>
							<img src={web} alt="portrait1" />
							<h3>WEBINARS</h3>
							<p>
								Associates at partnered universities will have access to
								weekly webinars with industry leading executives from
								Fortune 1000 organizations
							</p>
						</div>
					</div>
				</section>

				<section className={styles.contactUs}>
					<div className={styles.block}>
						<div className={styles.confirmSubmit} id="confirmSubmit">
							<button className={styles.close} onClick={this.handleCloseMessage}>
								<p> &#10006;</p>
							</button>
							<div className={styles.message}>
								<h2>Thank You</h2>
								<p>
									A Minos Labs team member will get back to you as soon
									as possible!
								</p>
							</div>
						</div>
						<div className={styles.container}>
							<h2>Contact Us</h2>
							<p>
								{' '}
								Ready to build your talent pipeline? <br /> Get in touch
								with a Minos Labs team member today to discuss our full,
								end-to-end delivery model
							</p>

							<form id ="contactForm" onSubmit={this.handleSubmit}>
								<input
									class={(errors.firstName && this.state.isError) ? styles.errorFirst : styles.first}
									id = "fName"
									name="firstName"
									type="text"
									placeholder="First name"
									value={this.state.firstName}
									onChange={this.handleInputChange}
								/>
								<input
									class={(errors.lastName && this.state.isError) ? styles.errorLast : styles.last}
									id = "lName"
									name="lastName"
									type="text"
									placeholder="Last name"
									value={this.state.lastName}
									onChange={this.handleInputChange}
								/>
								<input
									class={(errors.emailId && this.state.isError) ? styles.error : styles.email}
									id = "emailId"
									name="emailId"
									type="text"
									placeholder="Email address"
									value={this.state.emailId}
									onChange={this.handleInputChange}
								/>
								<select
									name="type"
									class={(errors.type && this.state.isError) ? styles.errorType : styles.type}
									id="whoAreYou"
									value={this.state.type}
									onChange={this.handleInputChange}
								>
									<option value="select" selected disabled hidden>
										Are you a...?
									</option>
									<option value="Student">Student</option>
									<option value="Corporate">Corporation</option>
									<option value="University">University</option>
									<option value="Other">Other</option>
								</select>
								<textarea
									class={(errors.info && this.state.isError) ? styles.error : ''}
									id="msg"
									name="info"
									placeholder="Your Message..."
									value={this.state.info}
									onChange={this.handleInputChange}
								/>
								<button
									type="button"
									class="hvr-sweep"
									onClick={this.handleFormSubmit}
								>
									SUBMIT
								</button>
							</form>
						</div>
					</div>
				</section>

				{/*
  <section class = "partner">
    <div class = "container">
      <h1>Become A Partner</h1>
     
      <button>Contact Us</button>

    </div>
  </section>
*/}
			</React.Fragment>
		);
	}
}
export default University;
