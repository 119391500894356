/**
 * Renders a modal to confirm deletion of a requirement when delete button is
 * clicked
 */
 import React, { Component } from 'react'
 import styles from "../HiringRequirements/HiringRequirements.module.scss";
 import  { useState } from 'react';
 import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'react-bootstrap';
 import close_sign from '../../assets/hiring_requirement/trash1.png';
 // export class DeleteConfirmationModal extends Component {
 
 //     constructor(props) {
 //         super(props);
 
 //         this.state = {
 //             modal: false
 //         }
 
 //         this.onToggle = this.onToggle.bind(this);
 //     }
 
 //     onToggle() {
 //         this.setState(
 //             {
 //                 modal: !this.state.modal,
 //             }
 //         )
 //     }
 
   
 //     render() {
 //         return (
 
 //             <div>
 //                 <img src={close_sign} alt="delete requirement" onClick={this.onToggle}/>
 //                 <Modal isOpen={this.state.modal} toggle={this.onToggle} size="lg" style={{maxWidth: '700px', width: '100%'}}>
 //                     <ModalHeader toggle={this.onToggle}>Are you sure you want to delete the requirement?</ModalHeader>
 //                     <ModalFooter>
 //                         <Button  onClick={this.props.handleOnDelete}>Delete</Button>{' '}
 //                         <Button color="secondary" onClick={this.onToggle}>Cancel</Button>
 //                     </ModalFooter>
 //                 </Modal>
 //             </div>
 
 //         )
 //     }
 // }
 
 
 export class DeleteConfirmationModalInterview extends Component {
     constructor(props){
       super(props);
     }
     
 
   render(){
     return (
       <Modal
       {...this.props}
       // size="sm"
       // aria-labelledby="contained-modal-title-vcenter"
       // centered
     >
       
       <Modal.Header>
         <Modal.Title>
           Are you sure you want to delete the requirement?
         </Modal.Title>
       </Modal.Header>
       <Modal.Footer>
         <div style={{  display: 'grid', gridTemplateColumns:'1fr 1fr', gridGap:'280px'}}>
         <Button className={styles.buttonmodald}  onClick={this.props.handleOnDelete}>Delete</Button>{' '}
         <Button className={styles.buttonmodalc} onClick={this.props.onHide}>Cancel</Button>
         </div>
 
       </Modal.Footer>
     </Modal>
   );
 }
 
 }
   export default DeleteConfirmationModalInterview;
 