import MaterialTable from 'material-table';
import React from 'react';

const DataTable = (props) => {
	return (
		<MaterialTable
			title={props.title}
			data={props.rows}
			columns={props.columns}
			options={{
				search: true,
				paging: true,
				filtering: true,
				exportButton: true,
			}}
		/>
	);
};

export default DataTable;
