import React from 'react';
import styles from './ViewPartners.module.scss';
import Dashboard from '../../components/AdminDashboard';
import firebase from 'firebase';
import UserContext from '../../User/User';
import moodle_client from 'moodle-client';
import axios from 'axios';
import { MDBDataTableV5 } from 'mdbreact';

class ViewCandidates extends React.Component {
	constructor() {
		super();

		this.state = {
			allCandidates: [],
			savedCands: [],
			fullStack: 0,
			cybersecurity: 0,
			datatable: {},
			sliderValue: 50,
		};
	}
	static contextType = UserContext;

	async componentDidMount() {
		const db = firebase.firestore();
		await db
			.collection('corporate')
			.get()
			.then((querySnapshot) => {
				const data = querySnapshot.docs.map((doc) => doc.data());
				var index = 0;
				console.log(data);
				this.setState({
					allCandidates: data,
				});
			});
		document.addEventListener('DOMContentLoaded', function () {
			var table = document.getElementByID('candidateTable').DataTable();
		});

		var rows = [];

		const { allCandidates } = this.state;

		var candidateList = [];
		var i;
		for (i = 0; i < allCandidates.length; i++) {
			let first;
			let last;
			let id;
			let email;
			// let grades =[];
			let type;
			let company;

			try {
				first = allCandidates[i].first;
				last = allCandidates[i].last;
				email = allCandidates[i].email;
				id = allCandidates[i].userID;
				// grades = allCandidates[i].grades[0];
				type = allCandidates[i].type;
				company = allCandidates[i].company;
			} catch (err) {
				continue;
			}
			if (type !== 'Admin') {
				rows.push({
					first: first,
					last: last,
					ID: id,
					email: email,
					type: type,
					company: company,
					// // grades:grades,
					// title:title,
					// role:role
				});
			}
		}
		const datatable = {
			columns: [
				{
					label: 'UserID',
					field: 'ID',
					sort: 'asc',
				},
				{
					label: 'First',
					field: 'first',
					sort: 'asc',
				},
				{
					label: 'Last',
					field: 'last',
					sort: 'asc',
				},
				{
					label: 'Email',
					field: 'email',
					sort: 'asc',
				},
				{
					label: 'Type',
					field: 'type',
					sort: 'asc',
				},
				{
					label: 'Company',
					field: 'company',
					sort: 'asc',
				},
			],
			rows,
		};
		this.setState({ datatable: datatable });
	}

	render() {
		//User data
		let { userID, logoutUser, setUser, loading } = this.context;

		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="View_Candidate" />

						{/*right expands when dashboard is closed*/}
						<div class="right">
							<div class="topBar">
								<div class="pageName">
									<h1>Partners</h1>
								</div>
							</div>

							{/*code here*/}
							<div className={styles.inner}>
								<div className={styles.partners_list}>
									<h3>Partner List</h3>
									<h5>Manage Your Partners</h5>
									<MDBDataTableV5
										hover
										entriesOptions={[5, 10, 20]}
										entries={5}
										pagesAmount={4}
										data={this.state.datatable}
									/>
									<script
										type="text/javascript"
										src="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/3.3.0/mdb.min.js"
									></script>
									<script type="text/javascript">
										{/* $(document).ready(function () {
										$('#dtBasicExample').DataTable();
										$('.dataTables_length').addClass('bs-select');
									}); */}
									</script>
								</div>
							</div>
						</div>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default ViewCandidates;
