import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//import './Exam.css';
//import './Home.css';
import { Examsheet } from '../../components/ExamFormat.js';
import { Question } from '../../components/ExamFormat.js';
import ReactDOM from 'react-dom';
import CodeMirror from 'react-codemirror';
import Dictionary from '../../assets/questions/DictionarySort.JPG';
import StringNumber from '../../assets/questions/StringNumber.JPG';
import Editor from '../../components/Editor.js';
import Form from '../../assets/questions/Form.JPG';
import Resize from '../../assets/questions/resize.JPG';
import firebase from 'firebase';
import UserContext from '../../User/User';
import axios from 'axios';

class Cyber_pt1 extends React.Component {
	constructor() {
		super();
		this.state = {
			startTime: new Date(),
			timer: 0,
			percent: 0,
		};
		this.onValueChange = this.onValueChange.bind(this);
		this.numberOfCorrect = this.numberOfCorrect.bind(this);
		this.movePg = this.movePg.bind(this);
		this.percentfunc = this.percentfunc.bind(this);
		this.increase = this.increase.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.applicantId=0;
		this.moodleError='Email was taken';
		this.exam_Flag='pending';
	}

	static contextType = UserContext;
	componentDidMount() {
		this.percentfunc();
		if (typeof this.props.location.state !== 'undefined') {
			this.setState({
				candidateEmail: this.props.location.state.candidateEmail,
				first: this.props.location.state.first,
				last: this.props.location.state.last,
				course: this.props.location.state.course,
			});
		}
	}

	async createMoodleAccount(){
		let success = false;
		try{
			await axios.post(`https://lms.minoslabs.com/webservice/rest/server.php?wstoken=01f04ab2f0eacb95cb7909261d5f7fb0&wsfunction=core_user_create_users&users[0][username]=${this.props.location.state.candidateEmail}&users[0][password]=${this.props.location.state.first}${this.props.location.state.last}2!M&users[0][firstname]=${this.props.location.state.first}&users[0][lastname]=${this.props.location.state.last}&users[0][email]=${this.props.location.state.candidateEmail}&moodlewsrestformat=json`)
			.then(async (response) => {

				let data = response.data[0];
				try{
					
						this.moodleError= response.data.message
					
					console.log(response.data.message)
				}catch(err){
						this.moodleError= err
					console.log(err)
				}
				if('id' in data) {
				   success =  true;
					   this.applicantId= data.id
				} else {

				   success =  false;
				   
				}
				
			})
			.catch(err => {
			   console.log(err);
			   
			})

			
			// await axios.get(`https://us-central1-minos-labs-57c35.cloudfunctions.net/createMoodleUser?username=${this.state.email}&first=${this.state.first}&last=${this.state.last}&course=${this.state.course}&email=${this.state.email}`)
			// .then(async(response)=>{
			// 	console.log(response.data);
				
			// })
			// .catch(err =>{
			// 	console.log(err);
			// 	return false;
			// })
			

		}
		catch(e){
			console.log(e);
			
		}

		return success;
		
	}

	addUser = async (event) => {

		//event.preventDefault();
		//const templateId = this.state.courseType;

		const db = firebase.firestore();
		var storage = firebase.storage();
		if(!await this.createMoodleAccount()){
				alert(this.moodleError);
				return;
		}

		//uses firebase
		var templateParams = {
			to_name: this.state.first,
			receiver: this.state.email
			// username: this.state.email,
			
			// password: this.state.first+this.state.last+"2!M"
		};
			
		// emailjs.send('mailgun', 'template_dw8zd0u', templateParams, 'user_ry821S5CQJTzdU5eSuuAk')
		// 	.then(function(response) {
		// 		console.log('SUCCESS!', response.status, response.text);
		// 	}, function(error) {
		// 		console.log('FAILED...', error);
		// 	});
		var correctAnswers = this.numberOfCorrect();
		await db.collection('ALL_USER')
			.doc(this.applicantId.toString())
			.set({
				first: this.props.location.state.first,
				last: this.props.location.state.last,
				email: this.props.location.state.candidateEmail,
				course: this.props.location.state.course,
				// hearAbout: this.state.hearAbout,			
				// gender: this.state.gender,
				// veteran: this.state.veteran,
				// disabilities: this.state.disabilities,
				// progress: 'new',
				candidateID: this.applicantId,
				// grades:[0,0,0,0,0,0],
				// ethnicity:this.state.ethnicity,
				// timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				exam_Flag: this.exam_Flag,
				// password: this.state.first + this.state.last+"2!M",
				// role: 'PT',
				examScore: correctAnswers,
				examDuration: (new Date() - this.state.startTime) / 60000, //Total time in minutes
				examTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
			});
	}

	handleClick(event) {
		console.log("Hii from exam");
		var correctAnswers = this.numberOfCorrect();
		const db = firebase.firestore();
		
		if(correctAnswers>=11){
			this.exam_Flag = "pass";
			this.addUser();
			const context = this.context;
			context.setExam("completed");

		}
		else{
			this.exam_Flag = "fail";
			const context = this.context;
			context.setExam("completed");
		}
		console.log("examflag",this.exam_Flag)
	}
	onValueChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	}

	numberOfCorrect() {
		var answers = [3, 3, 1, 2, 4, 2, 3, 4, 1, 1, 4, 4, 1, 4]; //Answer key (multiple choice)
		var correct = 0;
		for (let i = 1; i <= answers.length; i++) {
			var name = 'selected' + i.toString();
			var ans = answers[i - 1];
			if (this.state[name] === ans.toString()) {
				correct += 1;
			}
		}
		return correct;
	}

	increase() {
		var lim = 0;
		switch (this.state.curPage) {
			case '2':
				lim = 25;
				break;
			case '3':
				lim = 50;
				break;
			case '4':
				lim = 75;
				break;
		}
		this.setState({
			percent: this.state.percent + 1,
		});
		if (this.state.percent >= lim) {
			clearInterval(this.timer);
		}
	}

	percentfunc() {
		this.timer = setInterval(this.increase, 65);
	}

	movePg(event) {
		const target = event.target;
		const value = target.value;
		var start = 0;
		switch (value) {
			case '3':
				start = 25;
				break;
			case '4':
				start = 50;
				break;
		}
		var pgs = [
			document.getElementById('pg1'),
			document.getElementById('pg2'),
			document.getElementById('pg3'),
			document.getElementById('pg4'),
		];

		for (let i = 0; i < 4; i++) {
			if (Number(value) - 1 == i) {
				ReactDOM.findDOMNode(pgs[i]).style.display = 'block';
			} else {
				ReactDOM.findDOMNode(pgs[i]).style.display = 'none';
			}
		}
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		this.setState(
			{
				curPage: event.target.value,
				percent: start,
			},
			() => {
				this.percentfunc();
			}
		);
	}

	render() {
		return (
			<React.Fragment>
				{/*------------------------------------------------Page 1------------------------------------------------*/}

				<div class="pg1" id="pg1">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-0">
									<text>0%</text>
								</div>
							</div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>

							<form>
								<div class="question">
									<p>1. </p>

									<ul>
										<p>
											Which of the following is an example of a
											"phishing" attack?
										</p>

										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected1"
													value="1"
													checked={this.state.selected1 === '1'}
													onChange={this.onValueChange}
												/>
												Hacking someone's bank account to steal
												funds
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected1"
													value="2"
													checked={this.state.selected1 === '2'}
													onChange={this.onValueChange}
												/>
												Using someone's credit card information in a
												malicious way
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected1"
													value="3"
													checked={this.state.selected1 === '3'}
													onChange={this.onValueChange}
												/>
												Sending someone an email disguised to look
												legitimate with a malicious link
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected1"
													value="4"
													checked={this.state.selected1 === '4'}
													onChange={this.onValueChange}
												/>
												All of the above
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>2. </p>
									<ul>
										<p>
											One method employed by hackers is to use
											multiple computers at once to steal
											information. What is this group of computers
											called?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected2"
													value="1"
													checked={this.state.selected2 === '1'}
													onChange={this.onValueChange}
												/>
												MItM
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected2"
													value="2"
													checked={this.state.selected2 === '2'}
													onChange={this.onValueChange}
												/>
												Network exploitation
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected2"
													value="3"
													checked={this.state.selected2 === '3'}
													onChange={this.onValueChange}
												/>
												Botnet
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected2"
													value="4"
													checked={this.state.selected2 === '4'}
													onChange={this.onValueChange}
												/>
												Malicious Operating system
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>3. </p>
									<ul>
										<p>
											Encrypting a user's files and data until they
											pay to have it decrypted is called...
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected3"
													value="1"
													checked={this.state.selected3 === '1'}
													onChange={this.onValueChange}
												/>
												Ransomware
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected3"
													value="2"
													checked={this.state.selected3 === '2'}
													onChange={this.onValueChange}
												/>
												Botnet
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected3"
													value="3"
													checked={this.state.selected3 === '3'}
													onChange={this.onValueChange}
												/>
												Phishing
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected3"
													value="4"
													checked={this.state.selected3 === '4'}
													onChange={this.onValueChange}
												/>
												Keylogging
											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>4. </p>
									<ul>
										<p>
											What type of attack aims to slow or shut down a
											network and make it impossible to use by its
											intended users?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected4"
													value="1"
													checked={this.state.selected4 === '1'}
													onChange={this.onValueChange}
												/>
												Phishing
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected4"
													value="2"
													checked={this.state.selected4 === '2'}
													onChange={this.onValueChange}
												/>
												DoS Attack
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected4"
													value="3"
													checked={this.state.selected4 === '3'}
													onChange={this.onValueChange}
												/>
												Man in the Middle Attack
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected4"
													value="4"
													checked={this.state.selected4 === '4'}
													onChange={this.onValueChange}
												/>
												POS Attack
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>5. </p>
									<ul>
										<p>
											Which of the following is an example of a
											potential threat actor?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected5"
													value="1"
													checked={this.state.selected5 === '1'}
													onChange={this.onValueChange}
												/>
												Script kiddies
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected5"
													value="2"
													checked={this.state.selected5 === '2'}
													onChange={this.onValueChange}
												/>
												Internal Employee
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected5"
													value="3"
													checked={this.state.selected5 === '3'}
													onChange={this.onValueChange}
												/>
												Black Hat Hackers
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected5"
													value="4"
													checked={this.state.selected5 === '4'}
													onChange={this.onValueChange}
												/>
												All of the above
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>6. </p>
									<ul>
										<p>Which is an example of Malware?</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected6"
													value="1"
													checked={this.state.selected6 === '1'}
													onChange={this.onValueChange}
												/>
												anti-virus software
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected6"
													value="2"
													checked={this.state.selected6 === '2'}
													onChange={this.onValueChange}
												/>
												Keylogger
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected6"
													value="3"
													checked={this.state.selected6 === '3'}
													onChange={this.onValueChange}
												/>
												BitTorrent
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected6"
													value="4"
													checked={this.state.selected6 === '4'}
													onChange={this.onValueChange}
												/>
												Internet Explorer
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>7. </p>
									<ul>
										<p>
											Which type of hacker exposes a system's
											vulnerability to the public without the owner's
											permission?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected7"
													value="1"
													checked={this.state.selected7 === '1'}
													onChange={this.onValueChange}
												/>
												White Hat
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected7"
													value="2"
													checked={this.state.selected7 === '2'}
													onChange={this.onValueChange}
												/>
												Black Hat
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected7"
													value="3"
													checked={this.state.selected7 === '3'}
													onChange={this.onValueChange}
												/>
												Gray Hat
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected7"
													value="4"
													checked={this.state.selected7 === '4'}
													onChange={this.onValueChange}
												/>
												Red Hat
											</label>
										</li>
									</ul>
								</div>
							</form>
							<div class="exam-container3">
								<button
									type="submit"
									class="hvr-sweep"
									value="2"
									onClick={this.movePg}
								>
									Next Page
								</button>
							</div>
						</div>
					</section>

					<section></section>
				</div>

				{/*------------------------------------------------Page 2------------------------------------------------*/}

				<div class="pg2" id="pg2">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-1">
									<text>{this.state.percent}%</text>
								</div>
							</div>
							<div className="loadval"></div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>
							<p>
								8.  The function first discards as many whitespace
								characters as necessary until the first non-whitespace
								character is found. Then, starting from this character,
								takes an optional initial plus or minus sign followed by
								as many numerical digits as possible, and interprets
								them as a numerical value. <br></br>
								<br></br>The string can contain additional characters
								after those that form the integral number, which are
								ignored and have no effect on the behavior of this
								function.<br></br>
								<br></br>If the first sequence of non-whitespace
								characters in str is not a valid integral number, or if
								no such sequence exists because either str is empty or
								it contains only whitespace characters, no conversion is
								performed.<br></br>
								<br></br>If no valid conversion could be performed, a
								zero value is returned.
							</p>

							<Editor initialCode=" " />

							<p>
								9.  Given an integer array nums, find the contiguous
								subarray (containing at least one number) which has the
								largest sum and return its sum.
							</p>

							<Editor initialCode=" " />

							<div class="exam-container5">
								<button
									type="submit"
									class="hvr-sweep"
									value="1"
									onClick={this.movePg}
								>
									Previous Page
								</button>
								<button
									type="submit"
									class="hvr-sweep"
									value="3"
									onClick={this.movePg}
								>
									Next Page
								</button>
							</div>
						</div>
					</section>
				</div>

				{/*------------------------------------------------Page 3------------------------------------------------*/}

				<div class="pg3" id="pg3">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-2">
									<text>{this.state.percent}%</text>
								</div>
							</div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>

							<form>
								<div class="question">
									<p>10. </p>
									<ul>
										<p>
											Incorrect handling of program ______ is one of
											the most common problem areas in software
											security.
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected8"
													value="1"
													checked={this.state.selected8 === '1'}
													onChange={this.onValueChange}
												/>
												Passwords
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected8"
													value="2"
													checked={this.state.selected8 === '2'}
													onChange={this.onValueChange}
												/>
												Usernames
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected8"
													value="3"
													checked={this.state.selected8 === '3'}
													onChange={this.onValueChange}
												/>
												Security Clearance
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected8"
													value="4"
													checked={this.state.selected8 === '4'}
													onChange={this.onValueChange}
												/>
												Input
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>11. </p>
									<ul>
										<p>
											The method of coding information to make it
											harder to decipher for unintended parties is
											known as _____.
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected9"
													value="1"
													checked={this.state.selected9 === '1'}
													onChange={this.onValueChange}
												/>
												Encryption
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected9"
													value="2"
													checked={this.state.selected9 === '2'}
													onChange={this.onValueChange}
												/>
												Obscurification
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected9"
													value="3"
													checked={this.state.selected9 === '3'}
													onChange={this.onValueChange}
												/>
												Ciphering
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected9"
													value="4"
													checked={this.state.selected9 === '4'}
													onChange={this.onValueChange}
												/>
												Hiding
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>12. </p>
									<ul>
										<p>SIEM stands for ________.</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected10"
													value="1"
													checked={this.state.selected10 === '1'}
													onChange={this.onValueChange}
												/>
												Security Information and Event Management
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected10"
													value="2"
													checked={this.state.selected10 === '2'}
													onChange={this.onValueChange}
												/>
												Security Information and Encryption Modes
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected10"
													value="3"
													checked={this.state.selected10 === '3'}
													onChange={this.onValueChange}
												/>
												Secret identity exposure model
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected10"
													value="4"
													checked={this.state.selected10 === '4'}
													onChange={this.onValueChange}
												/>
												Secret identity and encrypted mandates
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>13. </p>
									<ul>
										<p>
											Which of the following is a system
											vulnerability?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected11"
													value="1"
													checked={this.state.selected11 === '1'}
													onChange={this.onValueChange}
												/>
												Lack of System updates
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected11"
													value="2"
													checked={this.state.selected11 === '2'}
													onChange={this.onValueChange}
												/>
												Lack of organizational policy
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected11"
													value="3"
													checked={this.state.selected11 === '3'}
													onChange={this.onValueChange}
												/>
												Lack of firewalls
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected11"
													value="4"
													checked={this.state.selected11 === '4'}
													onChange={this.onValueChange}
												/>
												All of the above
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>14. </p>
									<ul>
										<p>Which best describes Black Box testing?</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected12"
													value="1"
													checked={this.state.selected12 === '1'}
													onChange={this.onValueChange}
												/>
												Testers have full knowledge of the system
												they are testing
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected12"
													value="2"
													checked={this.state.selected12 === '2'}
													onChange={this.onValueChange}
												/>
												Testers have some knowledge of the system
												they are testing
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected12"
													value="3"
													checked={this.state.selected12 === '3'}
													onChange={this.onValueChange}
												/>
												Testers have knowledge of a similar system
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected12"
													value="4"
													checked={this.state.selected12 === '4'}
													onChange={this.onValueChange}
												/>
												Testers have no knowledge of the system they
												are testing
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>15. </p>
									<ul>
										<p>
											Which is an example of a security policy likely
											to be seen within a bank?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected13"
													value="1"
													checked={this.state.selected13 === '1'}
													onChange={this.onValueChange}
												/>
												Separation of duties
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected13"
													value="2"
													checked={this.state.selected13 === '2'}
													onChange={this.onValueChange}
												/>
												Minimal Vacation days
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected13"
													value="3"
													checked={this.state.selected13 === '3'}
													onChange={this.onValueChange}
												/>
												Self autonomy
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected13"
													value="4"
													checked={this.state.selected13 === '4'}
													onChange={this.onValueChange}
												/>
												Yearly password changes
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>16. </p>
									<ul>
										<p>What does SSO stand for? </p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected14"
													value="1"
													checked={this.state.selected14 === '1'}
													onChange={this.onValueChange}
												/>
												Security Services Office
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected14"
													value="2"
													checked={this.state.selected14 === '2'}
													onChange={this.onValueChange}
												/>
												System Security Oversight
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected14"
													value="3"
													checked={this.state.selected14 === '3'}
													onChange={this.onValueChange}
												/>
												Secure Sign Offs
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected14"
													value="4"
													checked={this.state.selected14 === '4'}
													onChange={this.onValueChange}
												/>
												Single Sign On
											</label>
										</li>
									</ul>
								</div>
							</form>
							<div class="exam-container5">
								<button
									type="submit"
									class="hvr-sweep"
									value="2"
									onClick={this.movePg}
								>
									Previous Page
								</button>
								<button
									type="submit"
									class="hvr-sweep"
									value="4"
									onClick={this.movePg}
								>
									Next Page
								</button>
							</div>
						</div>
					</section>

					<section></section>
				</div>

				{/*------------------------------------------------Page 4------------------------------------------------*/}

				<div class="pg4" id="pg4">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-3">
									<text>{this.state.percent}%</text>
								</div>
							</div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>
							<p>17.  Check if a substring is present in a given string</p>

							<Editor initialCode="//Python code " />

							<p>18.  Create a program that reverses a list:</p>

							<Editor initialCode="//list = [1,2,3,4,5,6,7] " />
							<div class="exam-container5">
								<button
									type="submit"
									class="hvr-sweep"
									value="3"
									onClick={this.movePg}
								>
									Previous Page
								</button>
								<Link
									to={{
										pathname: '/exam-complete',
										state: {
											timediff:
												(new Date() - this.state.startTime) / 60000, //Total time in minutes
										},
									}}
								>
									<button
										type="submit"
										class="hvr-sweep"
										onClick={this.handleClick}
									>
										Submit
									</button>
								</Link>
							</div>
						</div>
					</section>
					<section></section>
				</div>
			</React.Fragment>
		);
	}
}

export default Cyber_pt1;
