import React from 'react';
import { Link } from 'react-router-dom';
//import './portalInfo.css';
import UserContext from "../../User/User";
import b_Command from '../../assets/candidate_images/BlueIcons/b_command.png';
import b_ViewCandidates from '../../assets/candidate_images/BlueIcons/b_ViewCandidates.png';
import b_HireCandidatesNow from '../../assets/candidate_images/BlueIcons/b_deal 1.png';
import b_HiringReqs from '../../assets/candidate_images/BlueIcons/b_HiringReqs.png';
import b_Logout from '../../assets/candidate_images/BlueIcons/b_Logout.png';
import b_ManageAccount from '../../assets/candidate_images/BlueIcons/b_ManageAccount.png';
import b_SavedCandidates from '../../assets/candidate_images/BlueIcons/b_SavedCandidates.png';
import b_Group from '../../assets/candidate_images/BlueIcons/b_Group.png';
/*import { withFirebase } from '../Firebase';*/
import firebase from 'firebase';
/*import firestore from "../Firebase/firestore.js"*/

const INITIAL_STATE = {
	first: '',
	last: '',
	email: '',
	month: 'select',
	year: 'select',
	course: 'select',
	error: null,
	hearAbout: 'select',
	courseType: '',
	race: 'select',
	gender: 'select',
	veteran: 'select',
	disabilities: 'select',
};

class HRInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...INITIAL_STATE,
		};

	}
	static contextType = UserContext;

	render() {
		//user data
		let { userID, logoutUser, setUser, loading, userInfo } = this.context;

		return (
			<React.Fragment>
				<section class="hero-university2"></section>

				<section class="infoContainer">
					<div class="infoBlock">
						<h1> Welcome {userInfo.first} {userInfo.last}!</h1>
						<p> </p>
						<hr></hr>
						<table>
						<tr>
							<th><img src={b_Command} alt="command center Icon"/></th>
							<th><h3>Command Center</h3><p>Candidates selected by the hiring manager and recently uploaded documents can be viewed here.</p></th>
						</tr>
						<div class="tableSpacer"></div>
						<tr>
							<th><img src={b_ViewCandidates} alt="view candidates Icon"/></th>
							<th><h3>Hiring Requirments</h3><p>Displays requirements needed from candidates. Requirements are created and edited by the hiring manager. </p></th>
						</tr>
						<div class="tableSpacer"></div>
						<tr>
							<th><img src={b_SavedCandidates} alt="saved candidates Icon"/></th>
							<th><h3>Document Uploader</h3><p>Documents can be sent to selected candidates from here. Once a document is uploaded a candidate will be able to edit the document and provide a signature or other required information. </p></th>
						</tr>
						<div class="tableSpacer"></div>

						</table>

						<Link to="/hr_command_center">
						<button class="hvr-sweep continueButton">CONTINUE</button>
						</Link>
					</div>
				</section>
			</React.Fragment>
		);
	}
}

export default HRInfo;
