import React from 'react';
import './Candidate.scss';
import firebase from 'firebase';
import UserContext from '../../User/User';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import add_acc_logo from '../../assets/manage account/noprofilepic.jpg';
import calendar from '../../assets/icons/calendar.png';
import caretRight from '../../assets/icons/caret-right.png';
import caretDown from '../../assets/icons/caret-down.png';

import javaIcon from '../../assets/icons/progressBar/javaIcon.png';
import react from '../../assets/icons/progressBar/react.png';
import dataAnalytics from '../../assets/icons/progressBar/data.png';
import businessDocumentation from '../../assets/icons/progressBar/business.png'
import cloud from '../../assets/icons/progressBar/cloud.png'
import response from '../../assets/icons/progressBar/cyber incident response.png';
import compliance from '../../assets/icons/progressBar/Governance risk compliance.png';
import infrastructure from '../../assets/icons/progressBar/infrastructure design.png';
import secure from '../../assets/icons/progressBar/secure implementation.png';
import threat from '../../assets/icons/progressBar/Threat & Attack Vectors.png';
import ReactTooltip from 'react-tooltip';



class Candidate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.name,
			source: this.props.source /* delete this line */,
			title: this.props.title,
			grades: this.props.grades,
			skills: this.props.skills,
			id: this.props.id,
			experiences: this.props.experience,
			skillsAndProgress: this.props.skillsAndProgress,
			education: this.props.education,
			mentors: this.props.mentor,
			technicalSkills: this.props.technical,
			languageSkills: this.props.languages,
			profileCollapse: 'bottom-hide',
			isCollapsed: true,
			favoriteClass: this.props.initialFavorite,
			emailRef: '',
			profileUrl: this.props.profileUrl,
			caretDirection: "fa fa-caret-down caretIcon"
		};
		 
		this.handleProfileCollapse = this.handleProfileCollapse.bind(this);
		this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
		this.displayExperiences = this.displayExperiences.bind(this);
		this.displaySkills = this.displaySkills.bind(this);
	}

	displaySkills() {
		const { skills } = this.state;
		var skillsList = [];
		var i;
		try {
			for (i = 0; i < skills.length; i++) {
				let skill;

				try {
					skill = skills[i];
				} catch (err) {
					continue;
				}
				skillsList.push(<p className="skill">{skill}, </p>);
			}
			return skillsList;
		} catch (err) {
			console.log("Error: database fields don't match");
		}
	}

	static contextType = UserContext;

	displayExperiences() {
		const { experiences } = this.state;
		var experienceList = [];
		var i;
		try {
			for (i = 0; i < experiences.length; i++) {
				let company;
				let title;
				try {
					company = experiences[i].company;
					title = experiences[i].title;
				} catch (err) {
					continue;
				}
				experienceList.push(
					<p>
						<strong>{company}</strong>, {title}
						</p>
				);
			}
			return experienceList;
		} catch (err) {
			console.log("Error: database fields don't match");
		}
	}
	componentWillReceiveProps(newProps) {
		this.setState({
			name: newProps.name,
			email: newProps.email,
			title: newProps.title,
			languageSkills: newProps.languages,
			technicalSkills: newProps.technical,
			id: newProps.id,
			experiences: newProps.experience,
			education: newProps.education,
			mentors: newProps.mentor,
			grades:newProps.grades,
			skills:newProps.skills,
			profileUrl: newProps.profileUrl,

			
		});
	}

	componentDidMount() {
		var storage = firebase.storage().ref();

		const email = this.props.email + '.jpg';

		if (this.state.profileUrl == undefined) {
			storage
				.child('profilepics')
				.child(email)
				.getDownloadURL()
				.then((url) => {
					this.setState({
						profileUrl: url,
					});
				})
				.catch((e) => {});
		}
		if(this.state.grades==undefined){
			this.setState({
				grades: [75,90,60,70,75,80]
			});
		}
	}

	handleProfileCollapse() {
		
		const isCollapsed = this.state.isCollapsed;
		if (isCollapsed === true) {
			this.setState({
				profileCollapse: 'bottom-show',
				isCollapsed: false,
				caretDirection: "fa fa-caret-down caretIconDown"
			});
		}
		if (isCollapsed === false) {
			this.setState({
				profileCollapse: 'bottom-hide',
				isCollapsed: true,
				caretDirection: "fa fa-caret-down caretIcon"
			});
		}
	}

	handleFavoriteClick(event) {
		const context = this.context;
		const db = firebase.firestore();
		const userRef = db.collection('corporate').doc(context.userID);
		event.stopPropagation();
		if (this.state.favoriteClass === 'fa fa-star favorite gray move-again') {
			this.setState((prevState) => {
				return {
					favoriteClass: 'fa fa-star favorite gold move-again',
				};
			});

			context.addSavedCand(this.props.id);

			userRef.update({
				savedCandidates: firebase.firestore.FieldValue.arrayUnion(
					this.props.id
				), //adds to savedCandidates array in Firebase
			});
		}
		if (this.state.favoriteClass === 'fa fa-star favorite gold move-again') {
			this.setState((prevState) => {
				return {
					favoriteClass: 'fa fa-star favorite gray move-again',
				};
			});

			context.removeSavedCand(this.props.id);

			userRef.update({
				savedCandidates: firebase.firestore.FieldValue.arrayRemove(
					this.props.id
				), //removes from savedCandidates array in Firebase
			});
		}
	}

	render() {
		let favorite = "gray";
		if(this.state.favoriteClass === 'fa fa-star favorite gold move-again'){
			favorite="gold"
		}
		let grades=[0,0,0,0,0,0]
		if(this.state.grades!=undefined){
			grades = this.state.grades;
		}
		let skills=[]
		if(this.state.skills!=undefined){
			skills=this.state.skills
		}

		return (
			<React.Fragment>
				<div class="profile-container" onClick={this.handleProfileCollapse}>
					<div  class="top" onClick={this.handleProfileCollapse}>
						<img
							class="profile-pic"
							src={this.state.profileUrl || add_acc_logo}
						/>
						<div class="profile-title">
							<h2>{this.props.title}</h2>
							<p>{this.props.name}</p>
						</div>


						<div class="totalGrade">
							<div class={`c100 p${Math.round(grades[0])} small center blue`}>
							<span>{grades[0]}%</span>
								<div class="slice">
									<div class="bar"></div>
									<div class="fill"></div>
								</div>
							</div>
						</div>
						<i data-tip data-for="profile" class='fa fa-sticky-note calendarIcon'>

						</i>
						<ReactTooltip id="profile" place="top" effect="solid">
									Profile
								</ReactTooltip>	
						<i data-tip data-for="star"
							class={this.state.favoriteClass}
							onClick={this.handleFavoriteClick}
						></i>{' '}
						<ReactTooltip id="star" place="top" effect="solid">
									Favourite
								</ReactTooltip>	

						<Link 
							style={{ width: '100%' }}
							to={{
								pathname: '/candidate_profile',
								state: {
									grades: this.state.grades,
									id: this.state.id,
									name: this.state.name,
									source: this.state.source /* delete this line */,
									title: this.state.title,

									education: this.state.education,
									skills: this.state.skills,
									technicalSkills: this.state.technicalSkills,
									languageSkills: this.state.languageSkills,
									experiences: this.state.experiences,
									mentors: this.state.mentors,
									favoriteClass: this.state.favoriteClass,
									profileUrl: this.state.profileUrl,
								},
							}}
						>
						<i  data-tip data-for="fullProfile"class="fa fa-external-link openProfileIcon"></i>
						<ReactTooltip id="fullProfile" place="top" effect="solid">
									View Full Profile
								</ReactTooltip>	
						</Link>
						{/*
						<i
							class={this.state.caretDirection}
						/>
						*/}
					</div>

					<div class={this.state.profileCollapse}>
						{' '}
						{/*bottom*/}
						<hr class="candidateDivider"></hr>
						<div class="bottom-top">
						<div style={{display:"flex"}}class="bottomContainer">							
							<div class="courseGrades">
							<h3>Performance</h3>








							<div  style={{display: this.state.title==="Full-Stack Developer" ? "flex" : "none" }}class="gradesList2">
								<div class="grade2">
									<div class={`c100 p${grades[1]} small center`}>
										<span><img class="gradeIconHelper" src= {react}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>React</h5>
										<p>{grades[1]}%</p>
									</div>
								</div>
								<div class="grade2">
									<div class={`c100 p${grades[2]} small center`}>
										<span><img class="gradeIconHelper" src= {javaIcon}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>Java</h5>
										<p>{grades[2]}% </p>
									</div>
								</div>
								<div class="grade2">
									<div class={`c100 p${grades[3]} small center`}>
										<span><img class="gradeIconHelper" src= {dataAnalytics}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>Data Analytics</h5>
										<p>{grades[3]}% </p>
									</div>
								</div>
								<div class="grade2">
									<div class={`c100 p${grades[4]} small center`}>
										<span><img class="gradeIconHelper" src= {businessDocumentation}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>Documentation</h5>
										<p>{grades[4]}% </p>
									</div>
								</div>

								<div class="grade2">
									<div class={`c100 p${grades[5]} small center`}>
										<span><img class="gradeIconHelper" src= {cloud}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>Cloud Services</h5>
										<p>{grades[5]}% </p>
									</div>
								</div>


							</div>

							<div style={{display: this.state.title==="Cybersecurity" ? "flex" : "none" }} class="gradesList">

								<div class="grade2">
									<div class={`c100 p${grades[1]} small center`}>
										<span><img class="gradeIconHelper2" src={response}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>Threats &#38; Attack Vectors</h5>
										<p>{grades[1]}% </p>
									</div>
								</div>

								<div class="grade2">
									<div class={`c100 p${grades[2]} small center`}>
										<span><img class="gradeIconHelper2" src= {compliance}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>Infrastructure Design</h5>
										<p>{grades[2]}%</p>
									</div>
								</div>

								<div class="grade2">
									<div class={`c100 p${grades[3]} small center`}>
										<span><img class="gradeIconHelper2" src= {infrastructure}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>Secure Implementation</h5>
										<p>{grades[3]}%</p>
									</div>
								</div>

								<div class="grade2">
									<div class={`c100 p${grades[4]} small center`}>
										<span><img class="gradeIconHelper2" src= {secure}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>Cyber Incident Response</h5>
										<p>{grades[4]}%</p>
									</div>
								</div>

								<div class="grade2">
									<div class={`c100 p${grades[5]} small center`}>
										<span><img class="gradeIconHelper2" src= {threat}/></span>
										<div class="slice">
											<div class="bar"></div>
											<div class="fill"></div>
										</div>
									</div>
									<div class="gradeInfo2">
										<h5>Governance, Risk &#38; Compliance</h5>
										<p>{grades[5]}%</p>
									</div>
								</div>
							</div>


							</div>

							<div class="candidateInfo">
								<h3 class="bold">Notable Experiences:</h3>
								{this.displayExperiences()}
								<h3 class="bold">Top Skills:</h3>
								{this.displaySkills()}
							</div>
						</div>
							{/*}
							<div class="progress-left">
								<div class="progress-back">
									<p>{this.state.title == "Cybersecurity" ? "Python" : "Java"}</p>
									<div
										class="progress-bar"
										style={{
											backgroundColor: '#3240C3',
											width: java,
										}}
									></div>
								</div>
								<div class="progress-back">
									<p>{this.state.title == "Cybersecurity" ? "Penetration Testing" : "Vue"}</p>
									<div
										class="progress-bar"
										style={{ backgroundColor: '#FD9801', width: vue }}
									></div>
								</div>
								<div class="progress-back">
									<p>{this.state.title == "Cybersecurity" ? "Security Protocol" : "Angular"}</p>
									<div
										class="progress-bar"
										style={{
											backgroundColor: '#590F94',
											width: angular,
										}}
									></div>
								</div>
							</div>
							<div class="progress-right">
								<div class="progress-back">
									<p>{this.state.title == "Cybersecurity" ? "Vulnerability Assessment" : "React"}</p>
									<div
										class="progress-bar"
										style={{
											backgroundColor: '#CA2C2C',
											width: react,
										}}
									></div>
								</div>
								<div class="progress-back">
									<p>Agile/Scrum</p>
									<div
										class="progress-bar"
										style={{
											backgroundColor: '#32A9C3',
											width: presentation,
										}}
									></div>
								</div>
								<div class="progress-back">
									<p>Remote Productivity</p>
									<div
										class="progress-bar"
										style={{
											backgroundColor: '#57C03D',
											width: remote,
										}}
									></div>
								</div>
							</div>
									*/}
						</div>
						{/*} <div class="bottom-bottom"> 
                            <div class="bottom-half top-skils">
                                <h3>Top Skills</h3>
                                <ul>
                                    {this.displaySkills()}
                                    
                                 
                                </ul>
                            </div>
                            <div class="bottom-half top-experiences">
                                <h3>Experience</h3>
                                <ul>

                                    {this.displayExperiences()}

                                </ul>
                            </div>
                        </div> */}
						<Link
							style={{ width: '100%' }}
							to={{
								pathname: '/candidate_profile',
								state: {
									grades: this.state.grades,
									skills: this.state.skills,
									id: this.state.id,
									name: this.state.name,
									source: this.state.source /* delete this line */,
									title: this.state.title,

									education: this.state.education,
									skills: this.state.skills,
									technicalSkills: this.state.technicalSkills,
									languageSkills: this.state.languageSkills,
									experiences: this.state.experiences,
									mentors: this.state.mentors,
									favoriteClass: this.state.favoriteClass,
									profileUrl: this.state.profileUrl,
								},
							}}
						>
							<div class="bottomButtonWrapper">
								<button class="bottom-button hvr-sweep">
									View Full Profile
								</button>
							</div>
						</Link>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Candidate;
