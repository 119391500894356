import React from 'react';
import { Link } from 'react-router-dom';
import Dashboard from '../../components/Dashboard';
import styles from './EditManageAccount.module.scss';
import add_acc_logo from '../../assets/manage account/add_acc_logo.png';
import firebase from 'firebase';
import HR_Select from '../../components/HRSelect';
import grid from '@material-ui/core'
import UserContext from '../../User/User';
import input from '@material-ui/core';
import { Dropdown, Form } from 'semantic-ui-react'
//import 'semantic-ui-css/semantic.min.css';
const CryptoJS = require('crypto-js');

const options = [
	{ key: 'financial', text: 'Financial Services', value: 'financial' },
	{ key: 'consulting', text: 'Consulting', value: 'consulting' },
	{ key: 'pharmaceutical', text: 'Pharmaceutical', value: 'pharmaceutical' },
	{ key: 'healthcare', text: 'Healthcare', value: 'healthcare' },
	{ key: 'technology', text: 'Technology', value: 'technology' },
	{ key: 'consumer', text: 'Consumer Packaged Goods', value: 'consumer' },
	{ key: 'manufacturing', text: 'Manufacturing', value: 'manufacturing' },
  ]
  
class EditManageAccount extends React.Component {
	constructor() {
		super();

		this.state = {
			firstName: '',
			lastName: '',
			company: '',
			role: '',
			phoneNum: '',
			linkedIn:'',
			email: '',
			image: null,
			url: '',
			loading: true,
			subUsers: [],
			plainArray: ["Financial Services", "Consulting", "Pharmaceutical", "Healthcare", "Technology", "Consumer Packaged Goods","Manufacturing", "Retail"],
			
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.toResetPage = this.toResetPage.bind(this);
		this.handleChangeInput = this.handleChangeInput.bind(this);
		this.addHR = this.addHR.bind(this);
	}

	static contextType = UserContext;

	handleClick = () => {
		this.inputElement.click();
	};

	handleChange = (e) => {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			this.setState(
				{
					image: image,
				},
				this.handleUpload
			);
		}
	};

	handleChangeInput(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
	
		this.setState({
		  [name]: value,
		});
	
		if(name==="HRconfirm"){
		  if(this.state.HRpassword!==value){
			event.target.setCustomValidity("Password must match");
		  }else{
			event.target.setCustomValidity("");
		  }
		}
	  }

	handleUpload = () => {
		const context = this.context;
		var storage = firebase.storage();
		const { image } = this.state;
		const uploadTask = storage
			.ref(`corporatePics/${context.userID}`)
			.put(image);
		uploadTask.on(
			'state_changed',
			(snapshot) => {
				// progrss function ....
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				this.setState({ progress });
			},
			(error) => {
				// error function ....
				console.log(error);
			},
			() => {
				// complete function ....
				storage
					.ref('corporatePics')
					.child(context.userID)
					.getDownloadURL()
					.then((url) => {
						console.log(url);
						this.setState({ url });
					});
			}
		);
	};


	addHR(event){
		event.preventDefault();
		const context = this.context;
		const db = firebase.firestore();
		let hrUserId = db.collection("corporate").doc().id;
	
		let subUserInfo ={
		  company: context.userInfo.company,
		  companyTitle: this.state.HRrole,
		  email:this.state.HRemailId,
		  first:this.state.HRfirstName,
		  last:this.state.HRlastName,
		  linkedin:this.state.HRlinkedin,
		  managerID: context.userID,
		  password: this.encrypt(this.state.HRpassword),
		  type:"HR",
		  userID: hrUserId
		}
		db.collection("corporate").doc(hrUserId).set(subUserInfo)
		.then(function() {
		  console.log("Document successfully written!");
		})
		.catch(function(error) {
		  console.error("Error writing document: ", error);
		});
	
		let {subUsers} = this.state;
		subUsers.push(subUserInfo);
		this.setState({
		  subUsers: subUsers
		});
		console.log(subUserInfo)
	
	}

	encrypt(text){
		return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
	  };
	
	   decrypt(data){
		return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
	  };

	displaySubUsers(){
		const { subUsers } = this.state;
		var subUsersList = [];
	
		var i;
		for (i = 0; i < subUsers.length; i++) {
			const subUser = subUsers[i];
			let name;
			let title;
			let profileUrl;
			let id;
	   
			try{
			 name=subUser.first + " " + subUser.last;
			 title=subUser.companyTitle;
			 id= subUser.userID;
			 
	
			}catch(error){
	
			}
	
			subUsersList.push(
			  <HR_Select
						name={name}
						title={title}
						id={id}
					/>
			);
			}
		return subUsersList;
	}

	toResetPage() {
		window.location.href = '/reset';
	}

	componentDidMount() {
		this.initializeAccInfo();
	}

	initializeAccInfo() {
		const db = firebase.firestore();
		const context = this.context;

		db.collection('corporate')
			.doc(context.userID)
			.get()
			.then((snap) => {
				const data = snap.data();
				this.setState({
					firstName: data.first, 
					lastName: data.last,
					company: data.company,
					role: data.role,
					phoneNum: data.phoneNum,
					linkedIn: data.linkedin,
					email: data.email,
					loading: false,
				});
			});

			db.collection('corporate')
			.where('managerID', '==', context.userID)
			.get()
			.then((querySnapshot) => {
			  const data = querySnapshot.docs.map((doc) => doc.data());
			  this.setState({
				  subUsers: data,		
			  });	
			 });

		var storage = firebase.storage();
		storage
			.ref('corporatePics')
			.child(context.userID)
			.getDownloadURL()
			.then((url) => {
				this.setState({ url });
			});
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleFormSubmit = (event) => {
		event.preventDefault();

		const firstName = this.state.firstName;
		const lastName = this.state.lastName;
		const company = this.state.company;
		const role = this.state.role;
		const email = this.state.email;
		const phoneNum= this.state.phoneNum;
		const linkedIn= this.state.linkedIn;
		// var first = '';
		// var last= '';

		// const firstSpace = name.indexOf(' ');

		// if (firstSpace !== -1 && firstSpace !== name.length - 1) {
		// 	first = name.substring(0, firstSpace);
		// 	last = name.substring(firstSpace + 1);
		// } else {
		// 	first= name;
		// }

		const db = firebase.firestore();
		const context = this.context;
		var userRef = db.collection('corporate').doc(context.userID);

		userRef.update({
			first: firstName,
			last: lastName,
			company: company,
			role: role,
			email: email,
			linkedin: linkedIn,
			phoneNum: phoneNum,
		});

		this.props.history.push('./Edit_Manage_Account');
	};

	static contextType = UserContext;

	render() {
		//User data
		let { userID, logoutUser, setUser } = this.context;
		const { plainArray} = this.state;

		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="EditAccount" />

						{/*right expands when dashboard is closed*/}
						<div class="right">
							<grid container spacing={2}>
							<div class="topBar">
							<div class="pageName">
									<h1>Edit Account</h1>
								</div>
							</div>
							<div style={this.state.loading ? { display: 'none' } : {}}
								className={styles.editManageAccContainer}>
								<div className={styles.editAccountTop} >
									<input
										ref={(input) => (this.inputElement = input)}
										type="file"
										id="my_file"
										onChange={this.handleChange}
										style={{ display: 'none' }}
									/>
									<img
										onClick={this.handleClick}
										src={this.state.url || add_acc_logo}
										className={styles.edit_logo_img}
										alt="Uploaded images"
										style={{ height: '100px', width: '100px' }}
									/>
									<div style={{ marginLeft: '15px' }}>
										<h1>Account Details</h1>
										<p>
											Manage Your Information.{' '}
										</p>
									</div>
								</div>

								<br />
								<br />
								<div className={styles.mangAccNotifHide}>
									Please enter a valid email address
								</div>
								<form onSubmit={this.handleFormSubmit}>
									<div className={styles.accountLabelContainerLeft}>
										<h3>First Name:</h3>
										<div class="ui input">
										<input
											type="text"
											name="firstName"
											value={this.state.firstName}
											onChange={this.handleInputChange}
											size="25"
										/>
										</div>
									</div>
									<div className={styles.accountLabelContainer}>
										<h3>Last Name:</h3>
										<div class="ui input">
										<input
											type="text"
											name="lastName"
											value={this.state.lastName}
											onChange={this.handleInputChange}
											size="25"
										/>
										</div>
									</div>

									<div className={styles.accountLabelContainerEmail}>
										<h3>Email:</h3>
										<div class="ui input">
										<input
											type="text"
											name="email"
											value={this.state.email}
											onChange={this.handleInputChange}
											size="25"
										/>
										</div>
									</div>
									<div className={styles.accountLabelContainerBusiness} >
                                        <h3>Business Unit:</h3> 
                                        {/* <Multiselect
                                        style={this.style}
                                        placeholder="Business Unit" showArrow options={plainArray} isObject={false} 
                                        onSelect={this.onSelect} selectedValues={this.state.selectedSkills}
                                        onRemove={this.onRemove} hidePlaceholder={true} onSearch={this.onSearch}
                                        keepSearchTerm={true}
                                        /> */}
										{/* <select class="select" multiple data-mdb-filter="true">
  										<option value="1">One</option>
  										<option value="2">Two</option>
  										<option value="3">Three</option>
  										<option value="4">Four</option>
										</select> */}
                                        
										
										<Dropdown placeholder='Add Business' fluid multiple search selection options={options} />
										
										    
                                    </div>
											

									<div class={styles.accountLabelContainerLeft}>
										<h3>Company:</h3>
										<div class="ui input">
										<input
											type="text"
											name="company"
											value={this.state.company}
											onChange={this.handleInputChange}
											size="25"
										/>
										</div>
									</div>

									<div className={styles.accountLabelContainer}>
										<h3>Role:</h3>
										<div class="ui input">
										<input
											type="text"
											name="role"
											value={this.state.role}
											onChange={this.handleInputChange}
											size="25"
										/>
										</div>
									</div>

									<div className={styles.accountLabelContainerLeft}>
										<h3>Phone Number:</h3>
										<div class="ui input">
										<input
											type="text"
											name="phoneNum"
											value={this.state.phoneNum}
											onChange={this.handleInputChange}
											size="25"
										/>
										</div>
									</div>

									<div className={styles.accountLabelContainer}>
										<h3>LinkedIn:</h3>
										<div class="ui input">
										<input
											type="text"
											name="linkedIn"
											value={this.state.linkedIn}
											onChange={this.handleInputChange}
											size="25"
										/>
										</div>
									</div>

									<div className={styles.wrapButton}>

										<div className={styles.wrap_button_acc0}>
											<button
												type="button"
												onClick={this.toResetPage}
												class={`${styles.reset_button_edit} hvr-sweep`}
											>
												Reset Password
											</button>
											
											<button
												type="submit"
												class={`${styles.reset_change_button_edit} hvr-sweep`}
											>
												Save
											</button>

											{/* <button
												type="button"
												class={`${styles.reset_change_button_edit} hvr-sweep`}
											>
												Reset
											</button> */}
										</div>
										<br></br>
										
										{/* <div class={styles.wrap_button_acc}>
											
										</div> */}
									</div>
								</form>
							</div>
							<div
								style={{display: this.state.loading ? 'block' : 'none', minHeight: '700px'} }
								class={styles.editManageAccContainer}
							></div>


						{/*	<div className={styles.ManageHRContainer}>*/}
						{/*	<div className={styles.blockHeader}>*/}
						{/*			<h1>HR sub user List</h1>*/}
						{/*			<h2>Manage Your Sub users</h2>*/}
						{/*		</div>*/}
						{/*	{this.displaySubUsers()}*/}





						{/*<div className={styles.createHR}>*/}
						{/*<form autoComplete="off" className="createHRForm" onSubmit={this.addHR}>*/}
						{/*	<input*/}
						{/*	className={styles.HRInput}*/}
						{/*	name="HRfirstName"*/}
						{/*	type="text"*/}
						{/*	placeholder="First Name"*/}
						{/*	value = {this.state.HRfirstName}*/}
						{/*	onChange={(e) => this.handleChangeInput(e)}*/}
						{/*	style = {{width:'calc(50% - 5px)', marginRight: "0%",float: "left"}}*/}
						{/*	required*/}
						{/*/>*/}
						{/*<input*/}
						{/*	name="HRlastName"*/}
						{/*	className={styles.HRInput}*/}
						{/*	type="text"*/}
						{/*	placeholder="Last Name"*/}
						{/*	value = {this.state.HRlastName}*/}
						{/*	onChange={(e) => this.handleChangeInput(e)}*/}
						{/*	style = {{width: 'calc(50% - 5px)', marginLeft: "0%", float: "right"}}*/}
						{/*	required    */}
						{/*/>*/}
						{/*<input*/}
						{/*	name="HRemailId"*/}
						{/*	className={styles.HRInput}*/}
						{/*	type="email"*/}
						{/*	placeholder="Email address"*/}
						{/*	value = {this.state.HRemailId}*/}
						{/*	onChange={(e) => this.handleChangeInput(e)}*/}
						{/*	required*/}
						{/*/>*/}
						{/*<input*/}
						{/*autocomplete="new-password"*/}
						{/*className={styles.HRInput}*/}
						{/*	name="HRpassword"*/}
						{/*	type="password"*/}
						{/*	placeholder="Password"*/}
						{/*	value = {this.state.HRpassword}*/}
						{/*	onChange={(e) => this.handleChangeInput(e)}*/}
						{/*	required*/}
						{/*/>*/}
						{/*<input*/}
						{/*autocomplete="new-password"*/}
						{/*className={styles.HRInput}*/}
						{/*	name="HRconfirm"*/}
						{/*	type="password"*/}
						{/*	placeholder="Confirm Password"*/}
						{/*	value = {this.state.HRconfirm}*/}
						{/*	onChange={(e) => this.handleChangeInput(e)}*/}
						{/*	required*/}
						{/*/>*/}
						{/*<input*/}
						{/*	name="HRrole"*/}
						{/*	className={styles.HRInput}*/}
						{/*	type="text"*/}
						{/*	placeholder= "Role"*/}
						{/*	value = {this.state.HRrole}*/}
						{/*	onChange={(e) => this.handleChangeInput(e)}*/}
						{/*	required*/}
						{/*/>*/}

						{/*<input*/}
						{/*	name="HRlinkedin"*/}
						{/*	className={styles.HRInput}*/}
						{/*	type="url"*/}
						{/*	placeholder="LinkedIn"*/}
						{/*	value = {this.state.HRlinkedin}*/}
						{/*	onChange={(e) => this.handleChangeInput(e)}*/}
						{/*	required*/}
						{/*/>*/}
						{/*<button type="submit" className={`${styles.createHRButton} hvr-sweep`}>Add User</button>*/}
						
						{/*</form>*/}
						{/*</div>*/}





						{/*	</div>*/}

							</grid>
						</div>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default EditManageAccount;
