import React from 'react';
//import './Exam.css';
import { render } from 'react-dom';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

class Editor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			code: this.props.initialCode,
		};
		this.updateCode = this.updateCode.bind(this);
	}

	updateCode(newCode) {
		this.setState({
			code: newCode,
		});
	}

	render() {
		return (
			<AceEditor
			    style={{zIndex:"3"}}
				placeholder="Write Code here"
				mode="javascript"
				theme="monokai"
				name="blah2"
				onChange={this.updateCode}
				showPrintMargin={true}
				showGutter={true}
				highlightActiveLine={true}
				value={this.state.code}
				setOptions={{
					enableBasicAutocompletion: false,
					enableLiveAutocompletion: false,
					enableSnippets: false,
					showLineNumbers: true,
					tabSize: 2,
				}}
			/>
		);
	}
}

export default Editor;
