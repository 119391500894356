import React from 'react';
import styles from "./StudentProgress.module.scss";
import Dashboard from '../../components/DashboardUniv'
import Student from '../../components/Student/Student'
import firebase from 'firebase';
import UserContext from '../../User/User';

class StudentProgress extends React.Component{
    constructor() {
        super();
        
        this.state = {
            candidates: [],
            savedCands:[],
            fullStack: 0,
            cybersecurity: 0,
            angular: 0,
            java: 0,
            javaScript: 0,
            python: 0,
            react: 0,
            amazonEC2: 0,
            aws: 0,
            mongoDB: 0,
            MSazure: 0,
            stripe: 0,

            open: true,
            openLanguage: true,
            openTechnical:true,
          };

        this.handleCheck = this.handleCheck.bind(this);
        this.displayCandidates = this.displayCandidates.bind(this);
        this.checkList = this.checkList.bind(this);
        
      }
      static contextType = UserContext;

      displayCandidates(){
      const { candidates } = this.state;
      var candidateList = [];
      var i;

      for(i =0;i<candidates.length;i++){
        
        let name;
        let id;
        let email;
        name = candidates[i].first + " " + candidates[i].last;
        id = candidates[i].studentID;
        email = candidates[i].email;

        candidateList.push(<Student name={name} email={email} id ={id} />)
      }
      
      return candidateList;
        //return [<Candidate name="Jackie Green" title="Full-Stack Developer" source={require('./candidate_images/profile_pics/jackie_green.png') } initialFavorite="fa fa-star favorite gray move-again"/>,<Candidate name="John Hardwick" title="Full-Stack Developer" source={require('./candidate_images/profile_pics/john_hardwick.png')} initialFavorite="fa fa-star favorite gray move-again"/>];
      }

      checkList(title,languages,technical){
        let checks = [];
        const {fullStack,cybersecurity,angular,java,javaScript,python,react,amazonEC2,aws,mongoDB,MSazure,stripe}= this.state;
        if(fullStack!=cybersecurity){
            if(fullStack & title!=="Full-Stack Developer"){
                return false;
            }else if(cybersecurity & title!=="Cybersecurity"){
                return false;
            }
        }

        if(angular){
            if(!(languages.includes("Angular.JS"))){
                return false;
            }
        }
        if(java){
            if(!(languages.includes("Java"))){
                return false;
            }
        }
        if(javaScript){
            if(!(languages.includes("JavaScript"))){
                return false;
            }
        }
        if(python){
            if(!(languages.includes("Python"))){
                return false;
            }
        }
        if(react){
            if(!(languages.includes("React.JS"))){
                return false;
            }
        }

        if(amazonEC2){
            if(!(technical.includes("Amazon EC2"))){
                return false;
            }
        }
        if(aws){
            if(!(technical.includes("AWS"))){
                return false;
            }
        }
        if(mongoDB){
            if(!(technical.includes("MongoDB"))){
                return false;
            }
        }
        if(MSazure){
            if(!(technical.includes("MS Azure"))){
                return false;
            }
        }
        if(stripe){
            if(!(technical.includes("Stripe API"))){
                return false;
            }
        }

        return true;
      }

      componentDidMount() {

        const db = firebase.firestore();
        db.collection("students")
        .get()
        .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.setState({
            candidates: data
          });

        });      
      }

      handleCheck(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(this.state[name]==0){
            this.setState({
                [name]: 1
              });
        }else{
            this.setState({
                [name]: 0
              });
        }

      }
      
      handleButtonClick = () => {
        this.setState(state => {
          return {
            open: !state.open,
          };
        });
      };

      handleLanguageButtonClick = () => {
          this.setState(state =>{
              return {
                  openLanguage: !state.openLanguage,
              };
          });
      };

      handleTechnicalButtonClick = () => {
        this.setState(state => {
          return {
            openTechnical: !state.openTechnical,
          };
        });
      };
    render(){
        
        //User data
        let {userID,logoutUser,setUser} = this.context;
        
        return(
            <React.Fragment>
            <section class = "hero-university2">
            </section>
            <section class = "candidate_process">
            <section class = "col-container"> 
                  
                <Dashboard pageName="Student_Progress"/>

                {/*right expands when dashboard is closed*/} 
                <div class="right">
                {/*code here*/} 

                    {/*Candidate Filter starts here*/}
                    <div class="candidate_filter">
                        <div class="filterContainer">
                        <h1>Candidate Filter</h1>
                                <div class="dropdown-check-list">
                                    <span class="candidate_filter_elements">Type</span>                                    
                                    <a role="button">
                                        <i onClick={this.handleButtonClick} className={this.state.open ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true"></i>
                                    </a>
                                </div>
                        
                            {this.state.open && (
                                    <div>
                                        <div class="dropdown-check-list course_type">
                                        <input type="checkbox" class="checkbox_class" name="fullStack" checked={this.state.fullStack == 1} onChange={this.handleCheck}/>Full-Stack
                                        
                                        </div>
                                        <div class="dropdown-check-list course_type">

                                        <input type="checkbox" class="checkbox_class" name="cybersecurity" checked={this.state.cybersecurity == 1} onChange={this.handleCheck}/>Cybersecurity
                                        </div>
                                    </div>
                                        
                                    )}                   
                                    <br></br>       
                                <div class="dropdown-check-list">
                                    <span class="candidate_filter_elements">Languages/</span>
                                    <br></br>
                                    <span class="candidate_filter_elements">Frameworks</span>
                                    <a role="button">
                                        <i onClick={this.handleLanguageButtonClick} className={this.state.openLanguage ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true"></i>
                                    </a>
                                </div>
                            {this.state.openLanguage && (
                                    <div>
                                        <div class="dropdown-check-list course_type">
                                        <input type="checkbox" name="angular" checked={this.state.angular == 1} onChange={this.handleCheck}/>Angular.JS                                            
                                        </div>
                                        <div class="dropdown-check-list course_type" >
                                        <input type="checkbox" name="java" checked={this.state.java == 1} onChange={this.handleCheck}/>Java
                                        </div>
                                        <div class="dropdown-check-list course_type" >
                                        <input type="checkbox" name="javaScript" checked={this.state.javaScript == 1} onChange={this.handleCheck}/>JavaScript                                            
                                        </div>
                                        <div class="dropdown-check-list course_type" >
                                        <input type="checkbox" name="python" checked={this.state.python == 1} onChange={this.handleCheck}/>Python
                                        </div>
                                        <div class="dropdown-check-list course_type" >
                                        <input type="checkbox" name="react" checked={this.state.react == 1} onChange={this.handleCheck}/>React
                                        </div>
                                    </div>
                                    )}

                                <br></br>
                                <div class="dropdown-check-list">
                                    <span class="candidate_filter_elements">Technical</span>
                                    <a role="button">
                                        <i onClick={this.handleTechnicalButtonClick} className={this.state.openTechnical ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true"></i>
                                    </a>
                                </div>
                                {this.state.openTechnical && (
                                    <div>
                                        <div class="dropdown-check-list course_type">
                                            <input type="checkbox" name="amazonEC2" checked={this.state.amazonEC2== 1} onChange={this.handleCheck}/>Amazon EC2
                                        </div>
                                        <div class="dropdown-check-list course_type">
                                            <input type="checkbox" name="aws" checked={this.state.aws == 1} onChange={this.handleCheck}/>AWS
                                        </div>
                                        <div class="dropdown-check-list course_type">
                                            <input type="checkbox" name="mongoDB" checked={this.state.mongoDB == 1} onChange={this.handleCheck}/>MongoDB
                                        </div>
                                        <div class="dropdown-check-list course_type">
                                            <input type="checkbox" name="MSazure" checked={this.state.MSazure == 1} onChange={this.handleCheck}/>MS Azure
                                        </div>
                                        <div class="dropdown-check-list course_type">
                                            <input type="checkbox" name="stripe" checked={this.state.stripe == 1} onChange={this.handleCheck}/>Stripe API
                                        </div>
                                    </div>
                                )}
                                </div>
                    </div>
                    {/*Candidate Filter ends here*/}

                    <div class="candidates_list">
                        {this.displayCandidates()}
                        <button class="class-overview-button hvr-sweep">View Class</button>
                    </div>
                    
                </div>

            </section>
            </section>              
            </React.Fragment>
        )
    }
    
}


export default StudentProgress;