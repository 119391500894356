import React from 'react';
import {
    Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText, Container, Row, Col, Progress
} from 'reactstrap';
import javaIcon from '../../assets/icons/progressBar/javaIcon.png';
import react from '../../assets/icons/progressBar/react.png';

import response from '../../assets/icons/progressBar/cyber incident response.png';
import compliance from '../../assets/icons/progressBar/Governance risk compliance.png';
import infrastructure from '../../assets/icons/progressBar/infrastructure design.png';
import secure from '../../assets/icons/progressBar/secure implementation.png';
import threat from '../../assets/icons/progressBar/Threat & Attack Vectors.png';

export class CandidateGrades extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            grades:this.props.studentGrades,
		};
		this.displayGrades = this.displayGrades.bind(this);
		
	}
    displayGrades() {
		const { grades } = this.state;
		var gradesList = [];
		var i;

		let cats = [];
		let pics = [];
		let sizing = [];
		if(grades.length==6){
			cats=["Threats & Attack Vectors", "Infrastructure Design", "Secure Implementation", "Cyber Incident Response", "Governance, Risk & Compliance"];
			sizing=["gradeIconCyber","gradeIconCyber","gradeIconCyber","gradeIconCyber","gradeIconCyber"]
			pics=[response,compliance,infrastructure,secure,threat];

		}else{
			cats=["React","Java"];
			sizing=["gradeIconReact","gradeIconJava"]
			pics=[react,javaIcon];
		}


		try {
			for (i = 1; i < grades.length; i++) {
				
				gradesList.push(
					<div className="grade">
					<div className="ProgressBar">
						<div class={`c100 p${grades[i]} barSizeSmall center yellow`}>
							<span><img className={sizing[i-1]} src= {pics[i-1]}/></span>
							<div class="slice">
								<div class="bar"></div>
								<div class="fill"></div>
							</div>
						</div>
					</div>
					<div className="gradeInfo">
						<h5>{cats[i-1]}</h5>
						<p>{grades[i]}% </p>
					</div>
				</div>
				);
			}
			return gradesList;
		} catch (err) {
			console.log("Error: database fields don't match");
		}
	}

    render() {
        return (
            <div>

                {this.displayGrades()}


            </div>
        )
    }
}

export default CandidateGrades
