import React from 'react';
import { Link } from 'react-router-dom';
import './Resources.css';

import Resource from './Resource.js';
import Resource2 from './Resource2.js';
import firebase from 'firebase';

class Resources extends React.Component {
	constructor() {
		super();
		this.state = {
			articles: [],
			loading: true,
		};

		this.displayResources = this.displayResources.bind(this);
	}

	displayResources() {
		const { articles } = this.state;

		var articleList = [];
		var i;

		for (i = 0; i < articles.length; i++) {
			let title;
			let date;
			let id;
			let tags;
			let snippet;
			let readTime;

			try {
				title = articles[i].title;
				date = articles[i].date;
				id = articles[i].articleId;
				tags = articles[i].tags;
				snippet = articles[i].snippet;
				readTime = articles[i].readTime;
			} catch (err) {
				continue;
			}
			if (i % 2 == 0) {
				articleList.push(
					<Resource
						title={title}
						data={date}
						tags={tags}
						snippet={snippet}
						id={id}
						readTime={readTime}
					/>
				);
			} else {
				articleList.push(
					<Resource2
						title={title}
						data={date}
						tags={tags}
						snippet={snippet}
						id={id}
						readTime={readTime}
					/>
				);
			}
		}

		return articleList;
	}

	componentDidMount() {
		const db = firebase.firestore();
		db.collection('articles')
			.get()
			.then((querySnapshot) => {
				const data = querySnapshot.docs.map((doc) => doc.data());
				this.setState({
					articles: data,
					loading: false,
				});
			});
	}

	render() {
		return (
			<React.Fragment>
				<section class="hero-resourcesR">
					<div class="container">
						<div class="fade1">
							<h1>Keep Up To Date</h1>
						</div>
						<div class="fade2">
							<p>
								Follow along and keep yourself updated on the latest in
								technology, job seeking, professional development, and
								industry trends
							</p>
						</div>
					</div>
				</section>
				<div
					style={
						this.state.loading
							? { display: 'block' }
							: { display: 'none' }
					}
					class="loadingScreen"
				></div>

				{this.displayResources()}

				<section class="learnMore">
					<div class="container">
						<h1>Want to learn about something specific?</h1>
						<a href="/#contactUs">
							<button>Let Us Know</button>
						</a>
					</div>
				</section>
			</React.Fragment>
		);
	}
}
export default Resources;
