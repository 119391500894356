import React from "react";
import styles from "./HRCommandCenter.module.scss";
import Dashboard from "../../components/Dashboard";

import firebase from "firebase";
import UserContext from "../../User/User";
import add_acc_logo from "../../assets/manage account/noprofilepic.jpg";
import { Pie } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import edit_sign from "../../assets/hiring_requirement/edit_sign.png";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import HR_Select from "../../components/HRSelect";
import MonthlyNav from "../../components/DashboardCalendar.js"

import axios from 'axios';

const myEventsList = [
  {
    allDay: true,
    end: new Date('2021-03-26'),
    start: new Date('2021-03-26'),
    title: 'Testing',
  },
  {
    allDay: true,
    end: new Date('December 09, 2021 11:13:00'),
    start: new Date('December 09, 2021 11:13:00'),
    title: 'All Day Event',
  },
];


const diversity = {
  labels: [
    "Black/African American",
    "White/Caucasian ",
    "Native American",
    "Asian/Pacific Islander",
    "Hispanic/Latin American",
    "2 or more races",
    "Prefer not to identify",
  ],
  datasets: [
    {
      label: "Diversity",
      backgroundColor: [
        "#4285F4",
        "#EA4335",
        "#FBBC04",
        "#34A853",
        "#FF6D01",
        "#46BDC6",
        "#7BAAF7",
      ],
      hoverBackgroundColor: [
        "#152b4f",
        "#4f1712",
        "#4f3b02",
        "#194f27",
        "#4f2200",
        "#1c4b4f",
        "#28374f",
      ],
      data: [25.7, 28.6, 0, 40, 2.9, 2.9, 0],
    },
  ],
};



const state = {
  labels: [
    "Not Applicable",
    "Veteran",
  ],
  datasets: [
    {
      label: "Diversity",
      backgroundColor: [
        "#4285F4",
        "#EA4335",
        "#FBBC04",
        "#34A853",
        "#FF6D01",
        "#46BDC6",
        "#7BAAF7",
      ],
      hoverBackgroundColor: [
        "#152b4f",
        "#4f1712",
        "#4f3b02",
        "#194f27",
        "#4f2200",
        "#1c4b4f",
        "#28374f",
      ],
      data: [88.6, 11.4],
    },
  ],
};





class CommandCenter extends React.Component {
  constructor() {
    super();

    this.state = {
      first: "",
      last: "",
      company: "",
      role: "",
      email: "",
      phoneNumber: "",
      url: "",
      linkedin: "",
      industry: "",

      HRfirstName: "",
      HRlastName: "",
      HRemailId: "",
      HRpassword: "",
      HRlinkedin: "",
      HRconfirm: "",
      HRrole: "",

      subUsers: [],
      skills: [],
      skill: "",
      skillError: "",
      view: "overview",
      loading: true,
      textDisplay: "edit_req",
      inputDisplay: "edit_input_hide",
      editButton: "edit_button",
      setButton: "set_button_hide",
      calendarEvents: []
    };
  }

  static contextType = UserContext;
  async componentDidMount() {
    this.displayAccInfo();
    const db = firebase.firestore();
    //get nylas events
    var calendarStart= new Date();
    var calendarEnd= new Date();
    calendarStart.setDate(new Date().getDate()-31);
    calendarEnd.setDate(new Date().getDate()+31);
    var unixStart = Math.floor(calendarStart.getTime() / 1000);
    var unixEnd = Math.floor(calendarEnd.getTime() / 1000);

    let nylasEvents = [];
    await axios({
			url: `https://api.nylas.com/events?starts_after=${unixStart}&ends_before=${unixEnd}`,
			method: 'get',
			headers: {"Content-Type": "application/json", "Authorization":"Bearer bc4kBUdViZnR0tZymoGbwsc3KTgFHV"}, 
		})
		.then(res => {
			nylasEvents = res.data;
		});
    console.log(nylasEvents);
    let calendarEvents =[];
    for(let i =0;i<nylasEvents.length;i++){
      let calendarEvent ={}
      if(nylasEvents[i].when.end_time==undefined){
        calendarEvent = {
          allDay: true,
          end: nylasEvents[i].when.date,
          start: nylasEvents[i].when.date,
          title: nylasEvents[i].title,
        }
      }else{

        let interviewId = nylasEvents[i].location.replace('https://www.minoslabs.com/interview/','');
        

        try{
          
          var interviewRef = db.collection("interviews").doc(interviewId);
          let docFound = false;
          await interviewRef.get().then((doc) => {
              if (doc.exists) {
                console.log(interviewId)
                docFound= true;
            }
          }).catch((error) => {
              console.log("Error getting document:", error);
          });

          if(docFound){
            interviewRef.update({
              start_time: new Date(nylasEvents[i].when.start_time * 1000)
            })
          }

  
        }catch(err){
          
        }


        calendarEvent = {
          allDay: true,
          end: new Date(nylasEvents[i].when.end_time *1000),
          start: new Date(nylasEvents[i].when.start_time * 1000),
          title: nylasEvents[i].title,
        }
      }

      calendarEvents.push(calendarEvent);
    }
    this.setState({
      calendarEvents: calendarEvents,
    });

  }

  displayAccInfo() {
    const db = firebase.firestore();
    const context = this.context;

    this.setState({
      first: context.userInfo.first,
      last: context.userInfo.last,
      skills: context.userInfo.skills,
      company: context.userInfo.company,
      role: context.userInfo.companyTitle,
      email: context.userInfo.email,
      linkedin: context.userInfo.linkedin,
      phoneNumber: context.userInfo.phoneNumber,
      industry: context.userInfo.industry,
    });

    db.collection("corporate")
      .where("managerID", "==", context.userID)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        this.setState({
          subUsers: data,
        });
      });

    var storage = firebase.storage();
    storage
      .ref("corporatePics")
      .child(context.userID)
      .getDownloadURL()
      .then((url) => {
        this.setState({ url });
      })
      .catch((e) => {});
  }

  render() {
    //User data
    let { userID, logoutUser, setUser, loading, userInfo } = this.context;

    return (
      <div className={styles.borderbox}>
        <div className="hero-university2"></div>
        <div className="candidate_process">
          <div className="col-container">
            <Dashboard pageName="Dashboard" />

            {/*right expands when dashboard is closed*/}
            <div className="right">
            <div class="topBar">
								<div class="pageName">
									<h1>Dashboard</h1>
								</div>
							</div>
              
              <div className={styles.container}>
                <div className={styles.inner}>
                  <div className={styles.calendar}>
                    <div className={styles.calendarHeader}>
                      <h1>Calendar</h1>
                      <h2>Manage Your Upcoming Events</h2>
                      <div>
                      <MonthlyNav events={this.state.calendarEvents} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.block}>
                      <div className={styles.blockInner}>
                        <div className={styles.blockHeader}>
                          <h1>Cohort Diversity</h1>
                          
                        </div>
                        <Pie
                          data={diversity}
                          options={{
                            title: {
                              display: false,
                              text: "Diversity in the Minos Labs Cohort",
                              fontSize: 20,
                            },
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                boxWidth: 13,
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.block}>
                      <div className={styles.blockInner}>
                        <div className={styles.blockHeader}>
                          <h1>Veteran Status</h1>
                          
                        </div>
                        <Pie
                          data={state}
                          options={{
                            title: {
                              display: false,
                              text: "Diversity in the Minos Labs Cohort",
                              fontSize: 20,
                            },
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                boxWidth: 13,
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommandCenter;
