import React, { Component } from 'react'
import {
    Card, CardBody, CardText, Container, Row, Col, Spinner
} from 'reactstrap';
const axios = require('axios');

export class CorporateUserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            corporateUserID: this.props.corporateUserID,
            corporateData: {}
        }
    }

    async componentDidMount() {
        try {
            const corporateData = await axios.get(`https://us-central1-minos-labs-57c35.cloudfunctions.net/interviewApp/getCorporateUserDetail?id=${this.state.corporateUserID}`);
            console.log(corporateData.data.data)
            this.setState({
                corporateData: corporateData.data.data
            })
        } catch (e) {
            console.log(e)
        }
    }

    render() {

        if (this.state.corporateData) {
            return (
                <div>

                    <Container >
                        <Row >
                            <Col sm="12" >
                                <h4>Interviewer's Information</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <CardText>Name: {this.state.corporateData.fullName}</CardText>
                                        <CardText>Company: {this.state.corporateData.Company}</CardText>
                                        <CardText>Position: {this.state.corporateData.CompanyTitle}</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </div>
            )
        }
        else {
            return (
                <div>
                    <Container>
                        <Row className="align-items-center">
                            <Col className="d-flex justify-content-center">
                                <Spinner color="primary"></Spinner>
                            </Col>
                        </Row>

                    </Container>
                </div>
            )
        }



    }

}

export default CorporateUserProfile
