import React from 'react'
import { Link } from 'react-router-dom';
import styles from "./Careers.module.scss";
import firebase from 'firebase';

class ArticleSlide extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }

        let analytics = firebase.analytics();
		console.log('Visited Careers Page')
		analytics.logEvent('visited_careers_page')

    }


   


render() {

  return (
    <React.Fragment>
                    <section className={styles.positionSection}>
                    <div className={styles.positionHeader}>
                        <h1>Join Our Team</h1>
                        <p>Our interns have an exciting and productive experience. If you're interested in joining the team, apply today!</p>

                    </div>
                    <div className={styles.positions}>
                        <div className={styles.positionBlock}>
                            <div className={styles.positionLeft_1}>
                            
                            </div>
                            <div className={styles.positionRight}>
                                <h1>Growth &amp; Strategy Virtual Internship</h1>
                                <p>Join the Minos Labs Growth & Strategy team and see how a startup operates and scales from the inside! Work alongside industry professionals 
                                    on objectives ranging from data analytics to influencer marketing and strategy implementation on an Ed-Tech platform poised to disrupt the 
                                    human capital management sector</p>
                                <div className={styles.positionButtonWrapper}>
                                    <Link to="/Growth-Strategy-Virtual-Internship">
                                    <button>Apply Today</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    

                   
                        <div className={styles.positionBlock}>
                            <div className={styles.positionLeft_2}>
                            
                            </div>

                            <div className={styles.positionRight}>
                                <h1>Full-Stack Development Virtual Internship</h1>
                                <p>Be on the cutting edge of technology, building systems and developing a platform used by Fortune 1000 CIOs, CISOs, and CTOs. 
                                    Learn from developers with years of experience and refine your tech skills and gain real world experience with Minos Labs
                                    
                                    </p>
                                <div className={styles.positionButtonWrapper}>
                                    <Link to="/Full_Stack_Development_Virtual_Internship">
                                    <button>Apply Today</button>
                                    </Link>
                                </div>
                                </div>
                        </div>
                    </div>

                </section>
    </React.Fragment>
  )
}
}
export default ArticleSlide;
