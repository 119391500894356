import React, { Component } from 'react'
import styles from "./SamplePayment.module.scss";
import exclamation from '../../assets/payment/exclamation-circle.png';
export class PaymentStatusFailed extends Component {
    render() {
        return (
            <React.Fragment>

                <section class="hero-university2"></section>
                <div className={styles.payment_process}>
                    <div className={styles.topBar}>
                        <div className={styles.pageName}>
                            <h1>Checkout</h1>
                        </div>
                    </div>

                    <div className={styles.paymentBlock}>
                        <h1 style={{ color: '#CA2C2C' }} className={styles.paymentHeader}>Payment Failed</h1>
                        <div className={styles.imgWrapper}>
                            <img className={styles.paymentImage} src={exclamation} alt="Course img" />
                        </div>
                        <div >
                            <h1 className={styles.paymentMessage}>Your transaction has failed. Please try again or contact support</h1>
                        </div>
                        <div className={styles.paymentInfo}>
                            {/* <div className={styles.paymentInfoL}>
                                {this.state.course == "Cyber" ? <h4>Cybersecurity Course </h4> : <h4>Full-Stack Course </h4>}
                                <h4>Total: </h4>
                            </div>
                            <div className={styles.paymentInfoR}>
                                <h4>x1</h4>
                                <h4>$50.00</h4>
                            </div> */}
                        </div>
                        <div className={styles.buttonWrapper2}>
                            <button className={styles.checkoutButton2}>CONTACT SUPPORT</button>
                            <button className={styles.checkoutButton} >RETURN TO CHECKOUT</button>

                        </div>
                    </div>

                </div>

            </React.Fragment>

        )
    }
}

export default PaymentStatusFailed
