
import React from 'react';
import {
    Card, CardBody, CardText
} from 'reactstrap';
import add_acc_logo from '../../assets/manage account/noprofilepic.jpg';

export class CandidateContact extends React.Component {
    render() {
		console.log(this.props.candidateImg);
        return (
            <div>
							

								<div className="profileTop">
									<div className="profileImageContainer">
										<img
											className="profileImage"
											src={this.props.candidateImg||add_acc_logo}
										/>
									</div>
									<div className="profileInfo">
										<h1>{this.props.studentName}</h1>
										<p>{this.props.studentRole}</p>
									</div>
                                </div>
               

            </div>
        )
    }
}


export default CandidateContact
