import React from 'react';
import styles from './AdminViewCandidates.module.scss';
import Dashboard from '../../components/AdminDashboard';
import firebase from 'firebase';
import UserContext from '../../User/User';
import { MDBDataTableV5 } from 'mdbreact';
import { Link } from 'react-router-dom';
import moodle_client from "moodle-client";
import axios from 'axios';
import Candidate from '../../components/Candidate';

class AdminViewCandidates extends React.Component {
	constructor() {
		super();

		this.state = {
			allCandidates: [],
			savedCands: [],
			fullStack: 0,
			cybersecurity: 0,
			datatable:{},
			course: "all",
			role: "all",
			minGrade: 0,
			checkbox1: ''

		};
		this.updateTable = this.updateTable.bind(this);
		this.handleChange = this.handleChange.bind(this);
		
	}
	static contextType = UserContext;

	 handleChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		
		this.setState({
			[name]: value,
		},function(){
			this.updateTable()
		});
		
	}

	
	updateTable(){
		var rows =[];

		const {allCandidates}=this.state;
		
		var candidateList=[];
		var i;
		for(i=0;i<allCandidates.length; i++){
			let first;
			let last;
			let id;
			let email;
			let grades;
			let title;
			let role;
		
			
				first = allCandidates[i].first
				last = allCandidates[i].last;
				email = allCandidates[i].email;
				id = allCandidates[i].candidateID;
				if(allCandidates[i].grades){
					grades = allCandidates[i].grades[0];
				
				}else{
					grades = 0;
				}
				
				role = allCandidates[i].role;
				

				if (allCandidates[i].course === 'FullStack') {
					title = 'Full-Stack Developer';
				} else {
					title = 'Cybersecurity';
				}

				if(this.state.course==="fullstack"){
					if(title != 'Full-Stack Developer'){
						continue;
					}
				}
				if(this.state.course==="cyber"){
					if(title != 'Cybersecurity'){
						continue;
					}
				}

				if(this.state.role==="PT"){
					if(role != 'PT'){
						continue;
					}
				}
				if(this.state.role==="CAS"){
					if(role != 'CAS'){
						continue;
					}
				}
				
				
				if(this.state.minGrade<=grades){	
					
	

									
				}else{
					continue;
				}


		
			rows.push(
				{first:first,
				last:last,
				ID:id,
				email:email,
				grades:grades,
				course:title,
				role:role}				
			);
		}
		const datatable = ({
			columns:[
				{
					label:'UserID',
					field:"ID",
					sort:'asc'
				},
				{
					label:'First',
					field:"first",
					sort:'asc'
				},
				{
					label:'Last',
					field:"last",
					sort:'asc'
				},
				{
					label:'Email',
					field:"email",
					sort:'asc'
				},
				{
					label:'Role',
					field:"role",
					sort:'asc'
				},
				{
					label:'Course',
					field:"course",
					sort:'asc'
				},
				{
					label:'Grades',
					field:"grades",
					sort:'asc'
				}
			],
			rows
		
		})
		this.setState({datatable:datatable});
	}

	async componentDidMount(){
			const db = firebase.firestore();
			await db.collection('ALL_USER').get().then((querySnapshot)=>{
			const data = querySnapshot.docs.map((doc) => doc.data());
			var index = 0;
			
			this.setState({
				allCandidates: data,
			});	
		})
		document.addEventListener("DOMContentLoaded", function(){
			var table=document.getElementByID("candidateTable").DataTable();
		})

		this.updateTable();

	}
	searchFunction(){
		// Declare variables
		var input, filter, table, tr, td, i, txtValue;
		input = document.getElementById("search");
		if(input){
		filter = input.value.toUpperCase();
		table = document.getElementById("candidateTable");
		tr = table.getElementsByTagName("tr");
	
		// Loop through all table rows, and hide those who don't match the search query
		for (i = 0; i < tr.length; i++) {
		td = tr[i].getElementsByTagName("td")[0];
		if (td) {
			txtValue = td.textContent || td.innerText;
			if (txtValue.toUpperCase().indexOf(filter) > -1) {
			return tr[i].style.display = "";
			} else {
			return tr[i].style.display = "none";
			}
		}
		}
		}
	}


	

	render() {
		//User data
		let { userID, logoutUser, setUser, loading } = this.context;
		const {
	role,course
		} = this.state;
		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="View_Candidate" />

						{/*right expands when dashboard is closed*/}
						<div class="right">
							<div class="topBar">
								<div class="pageName">
									<h1>Candidates</h1>
								</div>
							</div>

							{/*code here*/}
							<div className={styles.inner}>
								<div className={styles.candidates_list}>
								<div className={styles.blockHeader}>
									<h1>Candidate List</h1>
									<h2>Manage Your Candidates</h2>
								</div>
								{/* <input type='text' id='search' onkeyup={this.searchFunction} placeholder='Search...'/> */}
								{/* <table id='candidateTable' class='datatable'>
									<thead>
										<tr>
											<th scope='col'>ID</th>
											<th scope='col'>First</th>
											<th scope='col'>Last</th>
											<th scope='col'>Email</th>
											<th scope='col'>Role</th>
											<th scope='col'>Grades</th>
										</tr>
									</thead>
									<tbody>
								{this.state.allCandidates && this.state.allCandidates.map(candidate=>(
									
									<tr>
										<td>{candidate.candidateID}</td>
										<td>{candidate.first}</td>
										<td>{candidate.last}</td>
										<td>{candidate.email}</td>
										<td>{candidate.role}</td>
										<td>{candidate.grades}</td>
										
										
									</tr>
									
									
								))}
								</tbody>
								</table> */}
								<label for='role'>Role: </label>
								<select 									
									name="role"
									value={role}
									onChange={this.handleChange}>
									<option value="all">All</option>
									<option value="CAS">CAS</option>
									<option value="PT">PT</option>
								</select>
								<label for='Course'>Course: </label>
								<select 									
									value={course}
									onChange={this.handleChange}
									name="course" id="course">
									<option value="all">All</option>
									<option value="cyber">Cyber</option>
									<option value="fullstack">Full-Stack</option>
								</select>
								<div class="slidecontainer">
 	 								<input name ="minGrade" onChange={this.handleChange} value={this.state.minGrade} type="range" min="0" max="100" class="slider" id="myRange"/>
								</div>
								Minimum grade: {this.state.minGrade}
								<MDBDataTableV5 hover entriesOptions={[5, 10, 20]} entries={5} pagesAmount={4} data={this.state.datatable} 
     
								/>
								<Link to="/add_user">
									<button className={styles.addButton}>Add Candidate</button>
								</Link>

								<Link to="/edit_user">
									<button className={styles.addButton}>edit Candidate</button>
								</Link>

								<script
									type="text/javascript"
									src="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/3.3.0/mdb.min.js"
								></script>
								<script type="text/javascript">

									{/* $(document).ready(function () {
									$('#dtBasicExample').DataTable();
									$('.dataTables_length').addClass('bs-select');
								}); */}
								</script>

								</div>
							</div>

						</div>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default AdminViewCandidates;
