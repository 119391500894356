import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase';
import ReactDOM from 'react-dom';

class Calendar extends React.Component {

    constructor() {
        super();

		this.state = {
			summary: '',
			location: '',
			description: '',
			startDate: '',
			startTime: '',
			endDate: '',
			endTime: '',
			recurrence: '',
			attendees: [],
			timezone: ''
		};
		
		const offset = -(new Date().getTimezoneOffset())/60;
		let timezone = offset<0 ? offset.toString()+":00" : "+"+offset+":00";
		if (timezone.length < 6) timezone = timezone[0] + '0' + timezone.slice(1);
		this.state.timezone = timezone;
		// console.log(offset);
		// console.log(timezone);
		// console.log(this.state.timezone);


		this.handleInputChange = this.handleInputChange.bind(this);
 		this.handleCalendar = this.handleCalendar.bind(this);
    }

    	handleCalendar() {
		const gapi = window.gapi;
		const CLIENT_ID = "56277225003-jd369lmi6kvq0dibb4qqftb6i349hdqv.apps.googleusercontent.com";
		const API_KEY = "AIzaSyBHekWrt8SPDYjFwvAk2MheNz8_e_F4wsg";
		const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
		const SCOPES = "https://www.googleapis.com/auth/calendar.events";

		const {
			summary,
			description,
			startDate,
			startTime,
			endDate,
			endTime,
			timezone,
		} = this.state;

		console.log(`${startDate}T${startTime}:00${timezone}`)
		console.log(gapi);
		gapi.load("client:auth2", () => {
			console.log('loaded client');

			gapi.client.init({
				apiKey: API_KEY,
				clientId: CLIENT_ID,
				discoveryDocs: DISCOVERY_DOCS,
				scope: SCOPES
			})
			gapi.client.load("calendar", "v3", () => console.log("BAM!"));
			gapi.auth2.getAuthInstance().signIn()
				.then(() => {
					var event = {
						'summary': summary,
						// use a location api to get a location? for timezone?
						'location': '800 Howard St., San Francisco, CA 94103',
						'description': description,
						'start': {
							'dateTime': `${startDate}T${startTime}:00${timezone}`,
							'timeZone': 'America/New_York'
						},
						'end': {
							'dateTime': `${endDate}T${endTime}:00${timezone}`,
							'timeZone': 'America/New_York'
						},
						'recurrence': [
							// 'RRULE:FREQ=DAILY;COUNT=2'
						],
						'attendees': [
							{'email': 'lpage@example.com'},
							{'email': 'sbrin@example.com'}
						],
						'reminders': {
							'useDefault': false,
							'overrides': [
							{'method': 'email', 'minutes': 24 * 60},
							{'method': 'popup', 'minutes': 30}
							]
						}
					};
	
					var request = gapi.client.calendar.events.insert({
					'calendarId': 'primary',
					'resource': event
					});
	
					request.execute(event => {
						window.open(event.htmlLink);
					});

					this.setState({
						summary: '',
						location: '',
						description: '',
						startDate: '',
						startTime: '',
						endDate: '',
						endTime: '',
						recurrence: '',
						attendees: []
					})

					
					
					gapi.client.calendar.events.list({
						'calendarId': 'primary',
						'timeMin': (new Date()).toISOString(),
						'showDeleted': false,
						'singleEvents': true,
						'maxResults': 10,
						'orderBy ': 'startTime',
					}).then (response => {
						const events = response.result.items;
						console.log('EVENTS: ', events);
					})
				});
		})

	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});

	}

    render() {
		return(
        <div>
            <form>
                <h1>Calendar</h1>
                <label>Title:</label>
                <input 
					name="summary"
					type="text"
					value={this.state.summary}
					onChange={this.handleInputChange}
				/><br />
                {/* <label>Location:</label>
                <input 
					name="location"
					type="text"
					value={this.state.location}
					onChange={this.handleInputChange}
				/><br /> */}
                <label>Description:<br/></label>
                <input 
					name="description"
					type="textarea"
					value={this.state.description}
					onChange={this.handleInputChange}
					style={{height: 40, width: 500}}
				/><br />
                <label>Start Date:</label>
                <input 
					name="startDate"
					type="date"
					value={this.state.startDate}
					onChange={this.handleInputChange}
				/><br />
				<div>{this.state.startDate}</div>
                <label>Start Time:</label>
                <input 
					name="startTime"
					type="time"
					value={this.state.startTime}
					onChange={this.handleInputChange}
				/><br />
				<div>{this.state.startTime}</div>
                <label>End Date:</label>
                <input 
					name="endDate"
					type="date"
					value={this.state.endDate}
					onChange={this.handleInputChange}
				/><br />
                <label>End Time:</label>
                <input 
					name="endTime"
					type="time"
					value={this.state.endTime}
					onChange={this.handleInputChange}
				/><br />
				{/* <label>Recurrance:</label>
                <input 
					name="recurrence"
					type="text"
					value={this.state.recurrence}
					onChange={this.handleInputChange}
				/><br />
                <label>Attendees:</label>
                <input 
					name="attendees"
					type="text"
					value={this.state.attendees}
					onChange={this.handleInputChange}
				/><br /> */}
            </form>
		    <button onClick={this.handleCalendar}>Submit</button>
			<div>
				{this.state.timezone}fsdfsd
			</div>
        </div>)
	}
}

export default Calendar;