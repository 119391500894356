import React from 'react'
import logo from '../../assets/Logo2.png';
import styles from "./Loading.module.scss";

function PageNotFound() {
  
  return (
    <React.Fragment>
      <section className = "hero-university2">
      </section>

      <section className = {styles.LoadingContainer}>
    <div className = {styles.LoadingBlock}>

    <h1>Loading</h1>
    <h3>....</h3>
    
    </div>




  </section>

    </React.Fragment>
  )
}

export default PageNotFound;