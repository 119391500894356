import React from 'react';
import ReactDOM from 'react-dom';
import styles from './HireCandidatesNow.module.scss';
import Dashboard from '../../components/Dashboard';
import Candidate_Select from '../../components/CandidateSelect';
import firebase from 'firebase';
import UserContext from '../../User/User';
import { Link } from 'react-router-dom';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Form, Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import {Grid, Row, Col} from "react-bootstrap"; 
import * as emailjs from 'emailjs-com'; 

const timeOptions = [
	{ key: '8', text: '8:00 AM', value: "800" },
	{ key: '9', text: '9:00 AM', value: "900" },
	{ key: '10', text: '10:00 AM', value: "1000" },
	{ key: '11', text: '11:00 AM', value: "1100" },
	{ key: '12', text: '12:00 AM', value: "1200" },
	{ key: '13', text: '1:00 PM', value: "1300" },
	{ key: '14', text: '2:00 PM', value: "1400" },
	{ key: '15', text: '3:00 PM', value: "1500" },
	{ key: '16', text: '4:00 PM', value: "1600" },
	{ key: '17', text: '5:00 PM', value: "1700" },
	{ key: '18', text: '6:00 PM', value: "1800" },
	{ key: '19', text: '7:00 PM', value: "1900" },
]

const roleOptions = [
	{ key: '1', text: 'Full-Stack Developer', value: 'Full-Stack Developer' },
	{ key: '2', text: 'Front-End Developer', value: 'Front-End Developer' },
	{ key: '3', text: 'Back-End Developer', value: 'Back-End Developer' },
	{ key: '4', text: 'UI/UX Designer', value: 'UI/UX Designer' },
	{ key: '5', text: 'Cybersecurity Analyst', value: 'Cybersecurity Analyst' },
	{ key: '6', text: 'Vulnerability Analyst', value: 'Vulnerability Analyst' },
	{ key: '7', text: 'Penetration Tester', value: 'Penetration Tester' },
]

class HireCandidatesNow extends React.Component {
	constructor() {
		super();

		this.state = {
			monthNames: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sept',
				'Oct',
				'Nov',
				'Dec',
			],
			dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'],
			calendarSection: false,
			selections: 0,
			error: "",
			email: "", //email of selected candidate
			name: "", //name of selected candidate
			title: "", //title of selected candidate
			id: "", //id of selected candidate
			selectedDate: "", //Time stamp for the start of the interview
			selectedEndDate: "", //Time stamp for the end of the interview
			selectedRole: "", //Role of the selected candidate
			savedCands: [], //unique IDs of user's savedCandidates
			candidates: [], //Phase-out (pull candidates from moodle)
			moodleCandidates: [], //all the data of every candidates from moodle


			//New Time selection
			start:"",
			end:"",
			mon: 0,
			tue: 0,
			wed: 0,
			thu: 0,
			fri: 0,
			interviewDuration: 60,


		};

		this.handleSelectedCandidate = this.handleSelectedCandidate.bind(this);
		this.handleCalendar = this.handleCalendar.bind(this);
		this.handleShowTime = this.handleShowTime.bind(this);
		this.handleShowDate = this.handleShowDate.bind(this);
		this.handleSelectedTime = this.handleSelectedTime.bind(this);
		this.generateDates = this.generateDates.bind(this);
		this.displayConfirm = this.displayConfirm.bind(this);
		this.showSchedular = this.showSchedular.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
		this.handleInterviewDuration = this.handleInterviewDuration.bind(this);
		this.handleInterviewStart= this.handleInterviewStart.bind(this);
		this.handleInterviewEnd = this.handleInterviewEnd.bind(this);
		this.handleRoleSelect = this.handleRoleSelect.bind(this);
	}
	static contextType = UserContext;

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		console.log(value)
		this.setState({
		  start: value
		});

		this.setState({
		  start: value
		});
	
	  }


	  handleCheck(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(this.state[name]==0){
            this.setState({
                [name]: 1
              });
        }else{
            this.setState({
                [name]: 0
              });
        }

      }

	  handleInterviewDuration(duration) {
		this.setState({
			interviewDuration: parseInt(duration.target.value)
			});
		
      }

	handleInterviewStart(event, { value }) {
		event.preventDefault();

		let start_time = value.toString();

		if(start_time.length == 3){
			start_time = '0' + start_time.slice(0,1) + ":" + start_time.slice(1)
		}
		else{
			start_time = start_time.slice(0,2) + ":" + start_time.slice(2)
		}
		
		this.setState({
			start: start_time
		});
		// if(this.state.interviewDuration == 60){
		// 	const interviewEnd = event.target.value + 100
		// 	var end_time = interviewEnd.toString(); 
		// 	if(end_time.length == 3){
		// 		end_time = end_time.slice(0,1) + ":" + end_time.slice(1)
		// 	}
		// 	else{
		// 		end_time = end_time.slice(0,2) + ":" + end_time.slice(2)
		// 	}
			

		// 	this.setState({
		// 		end: end_time
		// 		});
		// }else{
		// 	const interviewEnd = event.target.value + 30
		// 	var end_time = interviewEnd.toString(); 
		// 	if(end_time.length == 3){
		// 		end_time = end_time.slice(0,1) + ":" + end_time.slice(1)
		// 	}
		// 	else{
		// 		end_time = end_time.slice(0,2) + ":" + end_time.slice(2)
		// 	}
			

		// 	this.setState({
		// 		end: end_time
		// 		});
		// }
	}

	handleInterviewEnd(event, { value }) {
		event.preventDefault();

		let end_time = value.toString();
		
		if(end_time.length == 3){
			end_time = '0' + end_time.slice(0, 1) + ":" + end_time.slice(1)
		}
		else{
			end_time = end_time.slice(0, 2) + ":" + end_time.slice(2)
		}
		
		this.setState({
			end: end_time
		});
	}

	handleRoleSelect(event) {
		event.preventDefault();
		this.setState({
			selectedRole: event.target.value
		});
	}

	displayMoodleCandidates() {			
		const { moodleCandidates } = this.state;
		const { savedCands } = this.state;
		var candidateSelect = [];
		var i;

		for (i = 0; i < moodleCandidates.length; i++) {
			let savedRef;
			let saved;
			let name;
			let title;
			let profileUrl;
			let id;
			let email;
			let selected = false;

			try {
				saved = savedCands;
				name = moodleCandidates[i].first + ' ' + moodleCandidates[i].last;
				email = moodleCandidates[i].email;
				id = moodleCandidates[i].candidateID;
				if (!saved.includes(id)) {
					continue;
				}

				profileUrl = moodleCandidates[i].profileUrl;
				if(email === this.state.email){
					selected = true;
				}
				if (moodleCandidates[i].course === 'FullStack') {
					title = 'Full-Stack Developer';
				} else {
					title = 'Cybersecurity';
				}

			} catch (err) {
				continue;
			}
			candidateSelect.push(
				<Candidate_Select
					name={name}
					email={email}
					title={title}
					id={id}
					profileUrl={profileUrl}
					isSelected={this.handleSelectedCandidate}
					deSelect = {selected}
				/>
			);
		}

		return candidateSelect;
	}

	async displayConfirm() {
		const context = this.context;
		if(this.state.start === ""){// show error message if time block was not selected
			this.setState({
				error: "Please select a timeblock",
			});
			return;
		}else{
			this.setState({
				error: "",
			});
		}

		const fullName = this.context.userInfo.first + " " + this.context.userInfo.last;


		//	Calling nylas api to fetch calendars
		// axios({
		// 	url: "https://api.nylas.com/calendars",
		// 	method: 'get',
		// 	headers: { "Authorization": "bc4kBUdViZnR0tZymoGbwsc3KTgFHV"},
		//   })
		// .then(res => {
		// 	const account = res.data;
		// 	console.log(account);
		// });

		//	Calling the nylas api to schedule an interview

		/*
		axios({
			url: "https://api.nylas.com/events",
			method: 'post',
			headers: { "Authorization": "Bearer bc4kBUdViZnR0tZymoGbwsc3KTgFHV", "Content-Type": "application/json"}, 
			data: { 
					"title": `${fullName} <> ${this.state.name} - ${this.state.title} Interview - Minos Labs`,
					"calendar_id": "jbhjgsqx39d2l5wcr59jzrfv", 
					"location": "https://us02web.zoom.us/j/2903780861?pwd=eWx5aHJGZ0VXdHZxR2tneklyOXIwUT09",
					"status": "confirmed",
					"busy": true,
					"read_only": false,
					"participants": [
						{
							"name": this.state.name,
							"email": this.state.email
						},
						{
							"name": fullName,
							"email": this.context.userInfo.email
						}
					],
					"description": "",
					"when": {
						"start_time": this.state.selectedDate,
						"end_time": this.state.selectedEndDate
					},
					"notify_participants": "true", 
					"reminder_minutes": "[30]",
					"reminder_method": "email"
			}, 
			params: {
				notify_participants: true
			}
		})
		.then(res => {
			const account = res.data;
			console.log(account);
		});
		*/
	
		let interviewURL = "https://www.minoslabs.com/interview/";
		/*
		await axios({
			url: "/onInterviewCreate",
			method: 'post',
			headers: {"Content-Type": "application/json"}, 
			data: { 
				"candidateID": this.state.id,
				"userID": context.userID,
				"flag": true,
			}, 
		})
		.then((response) => {
			interviewURL = interviewURL + response["data"];
		})

*/

		const db = firebase.firestore();
		let interviewId = db.collection("interviews").doc().id;
		interviewURL = interviewURL + interviewId;
		
		let weekDays = [];
		if(this.state.mon==1){
			weekDays.push("M")
		}
		if(this.state.tue==1){
			weekDays.push("T")
		}
		if(this.state.wed==1){
			weekDays.push("W")
		}
		if(this.state.thu==1){
			weekDays.push("R")
		}
		if(this.state.fri==1){
			weekDays.push("F")
		}
		let bookingURL = context.userID + Date.now();
		
		await axios({
			url: "https://api.schedule.nylas.com/manage/pages",
			method: 'post',
			headers: {"Content-Type": "application/json"}, 
			data: { 
				"slug": bookingURL,
				"access_tokens": [this.context.userInfo.nylasAccessToken],
				"config": {
					"timezone": "America/New_York",
					"event": {
						"duration": this.state.interviewDuration,
						"location": interviewURL,
						"title": `${fullName} <> ${this.state.name} - ${this.state.title} Interview`
					},
					"appearance": {
						"color": "##4bace9",
						"company_name": "Minos Labs",
					},
					"booking": {
						"opening_hours": [{
							"days": weekDays,
							"start": this.state.start,
							"end": this.state.end
							}]
					},
					"reminders": []
				}
			}, 
		})
		.then(res => {
			const account = res.data;
			console.log(account);
			
			let interviewInfo = {
				candidateID: this.state.id,
				corporateUserID: context.userID,
				location: interviewURL,
				flag: true,
				notes: "", 
				nylasPageId: account["id"]
			}
			db.collection("interviews").doc(interviewId).set(interviewInfo).then(function() {
				console.log("Document successfully written!");
			})
			.catch(function(error) {
			console.error("Error writing document: ", error);
			});
		});



		var x = document.getElementById('HiringMessage');
		ReactDOM.findDOMNode(x).style.display = 'flex';

		//Sending out the booking email to the candidate
		var data = {
			to_email: this.state.email,
			booking_url: 'https://schedule.nylas.com/'+bookingURL, 
			to_name: this.state.name, 
			interviewer_name: fullName, 
			company: this.context.userInfo.company
		};

		emailjs.send('default_service', 'template_88kpqvg', data, 'user_ry821S5CQJTzdU5eSuuAk').then(
			function (response) {
				console.log(response.status, response.text);
			},
			function (err) {
				console.log(err);
			}
		);

	}

	generateDates() {
		var startDate = new Date();
		startDate.setDate(startDate.getDate() + 2);
		var day = 0;
		var i = 0;

		while (day < 10) {
			const date = 'date' + day.toString();
			var nextDate = new Date(startDate);
			nextDate.setDate(nextDate.getDate() + i);

			if (nextDate.getDay() !== 0 && nextDate.getDay() !== 6) {
				this.setState({
					[date]: nextDate,
				});
				day++;
				i++;
			} else {
				i++;
			}
		}
	}

	componentDidMount() {
		this.generateDates();

		const context = this.context;

		let currentComponent = this; 	//Saving the reference to the component to set the state from inside the API callback 

		this.setState({
			candidates: context.candidates, //Phase-out (pull candidates from moodle)
			moodleCandidates: context.moodleCandidates,
			savedCands: context.savedCands,
		});

		// Making an API call to nylas and picking the right calendar to create an interview; Will be crucial when we scale
		// axios({
		// 	url: "https://api.nylas.com/calendars",
		// 	method: 'get',
		// 	headers: { "Authorization": "GOeV9YroM1y4P0PX61rORpJaRybzaE"},	//Fetch auth token from user table in firebase when we scale
		//   })
		// .then(res => {
		// 	const account = res.data;
		// 	Object.keys(account).map(function(keyName, keyIndex) {
		// 		if(account[keyName]["name"] == "Calendar"){
		// 		    currentComponent.setState({
		// 				calendar_id: account[keyName]["id"]
		// 			})
		// 		}
		// 	});
		// 	console.log(account);
		// });
	}

	handleSelectedTime(block) {
		/*
		var section = block.substring(0, block.length - 1);
		var i;
		for (i = 1; i < 4; i++) {
			var unselect = section + i.toString();
			this.setState({
				[unselect]: false,
			});
		}
		*/
		var k;
		for (k=1;k<=10;k++){
			let section = "day" + k + "time";
			var i;
			for (i = 1; i <= 3; i++) {
				var unselect = section + i.toString();
				this.setState({
					[unselect]: false,
				});
			}
		}

		const timeblock = block;
		var selectedDay = block.substring(
			block.lastIndexOf("y") + 1, 
			block.lastIndexOf("t")
		);

		var selectedTime = block.substring(
			block.lastIndexOf("e") + 1
		);

		var selectedDate = new Date();
		var selectedEndDate = new Date();

		selectedDate.setDate(selectedDate.getDate() + parseInt(selectedDay) + 1);
		selectedEndDate.setDate(selectedEndDate.getDate() + parseInt(selectedDay) + 1);
		
		if(selectedTime ==1){
			selectedDate.setHours(8,0,0)
			selectedEndDate.setHours(11,0,0)
		}else if(selectedTime ==2){
			selectedDate.setHours(11,0,0)
			selectedEndDate.setHours(14,0,0)
		}else if(selectedTime ==3){
			selectedDate.setHours(14,0,0)
			selectedEndDate.setHours(17,0,0)
		}

		var unixTime = Math.floor(selectedDate.getTime() / 1000);
		var unixTimeEnd = Math.floor(selectedEndDate.getTime() / 1000);

		console.log(selectedDate);
		console.log(unixTime);

		if (this.state[timeblock] === true) {
			this.setState({
				[timeblock]: false,
				selectedDate: "", 
				selectedEndDate: ""
			});
		} else {
			
			this.setState({
				[timeblock]: true,
				selectedDate: unixTime, 
				selectedEndDate: unixTimeEnd
			});
		}
	}
	handleShowTime(num) {
		var dblock = 'dateBlock' + num;
		var tblock = 'timeBlock' + num;
		var x = document.getElementById(dblock);
		ReactDOM.findDOMNode(x).style.display = 'none';
		x = document.getElementById(tblock);
		ReactDOM.findDOMNode(x).style.display = 'block';
	}

	handleShowDate(num) {
		var time1 = 'day' + num + 'time1';
		var time2 = 'day' + num + 'time2';
		var time3 = 'day' + num + 'time3';

		if (!(this.state[time1] || this.state[time2] || this.state[time3])) {
			var dblock = 'dateBlock' + num;
			var tblock = 'timeBlock' + num;
			var x = document.getElementById(dblock);
			ReactDOM.findDOMNode(x).style.display = 'block';
			x = document.getElementById(tblock);
			ReactDOM.findDOMNode(x).style.display = 'none';
		}
	}

	handleSelectedCandidate(selected,id,email,name,title) {
		if (selected === false) {
			this.setState(
				{
					selections: 1,
					email: email,
					name: name,
					title: title,
					id:id,
				},
				() => {
					this.handleCalendar();
				}
			);
		} else {
			this.setState(
				{
					selections: this.state.selections - 1,
					email: "",
					name: "",
					title: "",
					id:"",
				},
				() => {
					this.handleCalendar();
				}
			);
		}
	}

	handleCalendar() {
		if (this.state.selections > 0) {
			this.setState({
				calendarSection: true,
			});
		} else {
			this.setState({
				calendarSection: false,
			});
		}
	}
	showSchedular(){
		// Testing git
		       // Invoke the schedule editor when a user clicks on the button
			   
			   window.nylas.scheduler.show({
				auth: {
				  // Account access_token with active calendar
				  // grab token from from object of logged in user 
				  accessToken: "bc4kBUdViZnR0tZymoGbwsc3KTgFHV", 
				},
				style: {
				  // Style the schedule editor
				  tintColor: '#32325d',
				  backgroundColor: 'white',
				},
				defaults: {
				  event: {
					  // discuss apporiate title with business side, pulling from prop
					  // candidate name <> interviewer name - (company name, Wells, BofA, etc)
					title: 'Interview',
					duration: 60,
					// pull link from prop stored in firebase
					location: 'https://us02web.zoom.us/j/2903780861?pwd=eWx5aHJGZ0VXdHZxR2tneklyOXIwUT09'
				  },
				},
			  });
			  
	}

	render() {
		//User data
		let { userID, logoutUser, setUser } = this.context;
		//names of days and months
		const months = this.state.monthNames;
		const days = this.state.dayNames;

		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="Hire_Candidate" />

						{/*right expands when dashboard is closed*/}
						<div className="right">
							{/*code here*/}
							<div class="topBar">
								<div class="pageName">
									<h1>View Candidates</h1>
								</div>
							</div>
							<div id="HiringMessage" className={styles.HiringMessage}>
								<div className={styles.modalContent}>
									<p>
										Thank you for submitting a Hiring Inquiry. A Minos
										Labs team member will confirm your interview
										slots.
									</p>
									<Link
										to="/scheduledinterviews"
										style={{
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<button class="hvr-sweep">Confirm</button>
									</Link>
								</div>
							</div>

							<div className={styles.hireCandidates}>
								<div className={styles.hireCandidatesTop}>
									<h1>Hire Candidates</h1>
									<p>
										Choose the candidates you’re interested in
										onboarding onto your team
									</p>
									<br></br>
									<br></br>
									<div className={styles.candidateSelect}>
										{this.displayMoodleCandidates()}
									</div>
									{this.state.calendarSection && (
										<div>
											<br></br>
											<br></br>
											<p>
												Please select the time blocks based on your
												schedule, and a Minos Labs associate will
												set up the interview slots on your calendar
											</p>
											<br></br>
											<br></br>
										
                       {/*     <table style= {{width:"100%"}}>
                            <tr>
                                <th>{days[this.state.date0.getDay()]}</th>
                                <th>{days[this.state.date1.getDay()]}</th>
                                <th>{days[this.state.date2.getDay()]}</th>
                                <th>{days[this.state.date3.getDay()]}</th>
                                <th>{days[this.state.date4.getDay()]}</th>
                            </tr>
                            <tr>

                                <td onMouseEnter={() => this.handleShowTime('1')} onMouseLeave={ () => this.handleShowDate('1')}>
                                    <div id ="dateBlock1"><h3>{months[this.state.date0.getMonth()]}</h3><h1>{this.state.date0.getDate()}</h1></div>
                                    <div className={styles.timeBlock} id ="timeBlock1">
                                        <h3 style={this.state.day1time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day1time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day1time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day1time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day1time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day1time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                                <td onMouseEnter={() => this.handleShowTime('2')} onMouseLeave={ () => this.handleShowDate('2')}>
                                    <div id ="dateBlock2"><h3>{months[this.state.date1.getMonth()]}</h3><h1>{this.state.date1.getDate()}</h1></div>
                                    <div  className={styles.timeBlock} id ="timeBlock2">
                                    <h3 style={this.state.day2time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day2time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day2time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day2time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day2time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day2time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                                <td onMouseEnter={() => this.handleShowTime('3')} onMouseLeave={ () => this.handleShowDate('3')}>
                                    <div id ="dateBlock3"><h3>{months[this.state.date2.getMonth()]}</h3><h1>{this.state.date2.getDate()}</h1></div>
                                    <div  className={styles.timeBlock} id ="timeBlock3">
                                    <h3 style={this.state.day3time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day3time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day3time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day3time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day3time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day3time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                                <td onMouseEnter={() => this.handleShowTime('4')} onMouseLeave={ () => this.handleShowDate('4')}>
                                    <div id ="dateBlock4"><h3>{months[this.state.date3.getMonth()]}</h3><h1>{this.state.date3.getDate()}</h1></div>
                                    <div  className={styles.timeBlock} id ="timeBlock4">
                                    <h3 style={this.state.day4time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day4time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day4time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day4time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day4time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day4time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                                <td onMouseEnter={() => this.handleShowTime('5')} onMouseLeave={ () => this.handleShowDate('5')}>
                                    <div id ="dateBlock5"><h3>{months[this.state.date4.getMonth()]}</h3><h1>{this.state.date4.getDate()}</h1></div>
                                    <div  className={styles.timeBlock} id ="timeBlock5">
                                    <h3 style={this.state.day5time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day5time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day5time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day5time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day5time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day5time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{days[this.state.date5.getDay()]}</th>
                                <th>{days[this.state.date6.getDay()]}</th>
                                <th>{days[this.state.date7.getDay()]}</th>
                                <th>{days[this.state.date8.getDay()]}</th>
                                <th>{days[this.state.date9.getDay()]}</th>
                            </tr>
                            <tr>
                            <td onMouseEnter={() => this.handleShowTime('6')} onMouseLeave={ () => this.handleShowDate('6')}>
                                    <div id ="dateBlock6"><h3>{months[this.state.date5.getMonth()]}</h3><h1>{this.state.date5.getDate()}</h1></div>
                                    <div className={styles.timeBlock} id ="timeBlock6">
                                    <h3 style={this.state.day6time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day6time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day6time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day6time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day6time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day6time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                                <td onMouseEnter={() => this.handleShowTime('7')} onMouseLeave={ () => this.handleShowDate('7')}>
                                    <div id ="dateBlock7"><h3>{months[this.state.date6.getMonth()]}</h3><h1>{this.state.date6.getDate()}</h1></div>
                                    <div  className={styles.timeBlock} id ="timeBlock7">
                                    <h3 style={this.state.day7time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day7time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day7time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day7time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day7time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day7time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                                <td onMouseEnter={() => this.handleShowTime('8')} onMouseLeave={ () => this.handleShowDate('8')}>
                                    <div id ="dateBlock8"><h3>{months[this.state.date7.getMonth()]}</h3><h1>{this.state.date7.getDate()}</h1></div>
                                    <div  className={styles.timeBlock} id ="timeBlock8">
                                    <h3 style={this.state.day8time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day8time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day8time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day8time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day8time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day8time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                                <td onMouseEnter={() => this.handleShowTime('9')} onMouseLeave={ () => this.handleShowDate('9')}>
                                    <div id ="dateBlock9"><h3>{months[this.state.date8.getMonth()]}</h3><h1>{this.state.date8.getDate()}</h1></div>
                                    <div  className={styles.timeBlock} id ="timeBlock9">
                                    <h3 style={this.state.day9time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day9time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day9time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day9time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day9time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day9time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                                <td onMouseEnter={() => this.handleShowTime('10')} onMouseLeave={ () => this.handleShowDate('10')}>
                                    <div id ="dateBlock10"><h3>{months[this.state.date9.getMonth()]}</h3><h1>{this.state.date9.getDate()}</h1></div>
                                    <div  className={styles.timeBlock} id ="timeBlock10">
                                    <h3 style={this.state.day10time1 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day10time1')}>8 AM - 11 AM</h3>
                                        <h3 style={this.state.day10time2 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day10time2')}>11 AM - 2 PM</h3>
                                        <h3 style={this.state.day10time3 == true  ? { backgroundColor:'#4BACE9'} : { backgroundColor: 'transparent'}} onClick={() => this.handleSelectedTime('day10time3')}>2 PM - 6 PM</h3>
                                        </div>
                                </td>
                            </tr>
                            </table>

									*/}


							<br></br>
                            <br></br>
							<form>
							<div>
								<Row>
									<div class="col-sm">
										<div className={styles.dropdownCheckList}>
										<input type="checkbox" name="mon" checked={this.state.mon == 1} onChange={this.handleCheck}/> &nbsp; <b>Monday</b>                                          
										</div>
									</div>
									<div class="col-sm">
										<div className={styles.dropdownCheckList} >
										<input type="checkbox" name="tue" checked={this.state.tue == 1} onChange={this.handleCheck}/> &nbsp; <b>Tuesday</b>
										</div>
									</div>	
									<div class="col-sm">	
										<div className={styles.dropdownCheckList} >
										<input type="checkbox" name="wed" checked={this.state.wed == 1} onChange={this.handleCheck}/> &nbsp; <b>Wednesday</b>                                           
										</div>
									</div>		
									<div class="col-sm">	
										<div className={styles.dropdownCheckList} >
										<input type="checkbox" name="thu" checked={this.state.thu == 1} onChange={this.handleCheck}/> &nbsp; <b>Thursday</b>
										</div>
									</div>

									<div class="col-sm">
										<div className={styles.dropdownCheckList} >
										<input type="checkbox" name="fri" checked={this.state.fri == 1} onChange={this.handleCheck}/> &nbsp; <b>Friday</b>
										</div>
									</div>
									
								</Row>
								<br />
								<large> <b>Please select availability</b> </large>
													<br />
																					
								<Form>
									<Row className="mt-2">
										<Col>
											<Form.Group
												widths="equal"
											>
												<Form.Select
													fluid
													placeholder="Start"
													options={timeOptions}													
													onChange={this.handleInterviewStart}
												/>
											</Form.Group>
										</Col>

										<Col>
											<Form.Group
												widths="equal"
											>
												<Form.Select
													fluid
													placeholder="End"
													options={timeOptions}
													onChange={this.handleInterviewEnd}
												/>
											</Form.Group>
										</Col>
									</Row>
								</Form>

								<br />
								<br />
								<large> <b>Select the duration of the interview</b> </large>
								<br />
								<Row style={{paddingLeft: '47%'}}>
									{this.state.interviewDuration == 60? 
										<div onChange={this.handleInterviewDuration} >
											<div >
												<input type="radio" value="30" name="gender" />  &nbsp; <b>30 Minutes</b>
											</div>
											<input type="radio" value="60" checked name="gender"/> &nbsp; <b>60 Minutes</b>
										</div>
									:
										<div onChange={this.handleInterviewDuration} >
											<div >
												<input type="radio" value="30" checked name="gender" /> &nbsp; <b>30 Minutes</b>
											</div>
											<input type="radio" value="60"  name="gender"/> &nbsp; <b>60 Minutes</b>
										</div>
									}
								</Row>
                </div>
									

												<Row className="d-flex flex-column align-items-center justify-content-center mt-5">
													<large> <b>Please select desired role</b> </large>
													<Form className="mt-2">
														<Form.Group
															widths="equal"
															size="large"
														>
															<Form.Select
																fluid
																placeholder="Role"
																options={roleOptions}
																value={this.state.selectedRole}
																onChange={this.handleRoleSelect}
															/>
														</Form.Group>
													</Form>
												</Row>
							</form>






                            <br></br>
                            <br></br>
                            <button className={`${styles.candidateSubmit} hvr-sweep`} onClick = {this.displayConfirm}>Submit Hiring Inquiry</button>
							<br></br>
							<br></br>
							
							{/*<button className={`${styles.candidateSubmit} hvr-sweep`} onClick = {this.showSchedular}>Testing</button>
							<br></br>
								<br></br>*/}
							<Link to="/scheduledinterviews">
							<button className={`${styles.candidateSubmit} hvr-sweep`}>View Scheduled Interviews</button>
							</Link>
							<br></br>
							<br></br>
							{this.state.error}
                            </div>
							
									)}
                        </div>

                    </div>

                </div>

            </section>
            </section>

            </React.Fragment>
        );
    }

}

export default HireCandidatesNow;
