import React from "react";

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Modal,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  UncontrolledTooltip,
} from "reactstrap";

import img1 from '../../assets/img/sections/anders-jilden.jpg'
import img2 from '../../assets/img/sections/daniel-olahh.jpg'
import img3 from '../../assets/img/sections/daniel-olahs.jpg'
import img4 from '../../assets/img/sections/forest-bg.jpg'
import back from './back.jpg'

// core components

function SectionPricing() {
  const [activePill, setActivePill] = React.useState("1");
  // pills for the last pricing
  const [pillActive, setPillActive] = React.useState("personal");
  const [pillCustomer, setPillCustomer] = React.useState("candidate");
  const [noticeModal, setNoticeModal] = React.useState(false);
  return (
    <>
      {/* <div className="section section-pricing cd-section" id="pricing"> */}
        {/* ********* PRICING 1 ********* */}
        <div className="pricing-1 section" style={{height: '100vh',padding: '25px', backgroundSize: 'cover ', backgroundBlendMode: 'luminosity' ,backgroundImage:"url(https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)"}}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                {/* <h2 className="title" style={{fontFamily: 'helvetica'}}><b>How it works?</b></h2> */}
                <CardTitle tag="h2" style={{fontWeight: '300', margin: '25px', fontSize: '2.4em', color: 'black'}}>How it works</CardTitle>
                  <p className="mb-0" style={{color: 'black', fontSize:'20px'}}>
                    <b>Whether you're a candidate looking for a job or a firm looking to hire, Minos Labs has you covered!</b>
                  </p>

                
                {/* <h5 className="description">
                  Whether you're a candidate looking for a job or a firm looking to hire, Minos Labs has you covered!
                </h5> */}
                <br />

                <div className="choose-plan" style={{marginLeft: '34%'}}>
                  <Nav className="nav-pills-info" pills role="tablist">
                    <NavItem>
                      <NavLink
                        className={pillCustomer === "candidate" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPillCustomer("candidate");
                        }}
                      >
                        Candidate
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pillCustomer === "business" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPillCustomer("business");
                        }}
                      >
                        Hiring Manager
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <br />
                <br />
              </Col>
            </Row>

            <div className="space-top" />

            <TabContent className="text-center" activeTab={pillCustomer}>
              <TabPane tabId="candidate">
                {/* <h6 className="description">
                  *Tagline about the program, how easy it is to secure a job and how effective the program is*
                </h6> */}
                <Row style={{alignItems: 'center', display: 'flex', paddingLeft: '8.3%'}}>
                  
                  <Col md="3" lg="3">
                    <Card className="card-pricing" style={{backgroundColor: '#00ADED', height:'500px'}}>
                      <CardBody>
                      {/* <Button className="btn-just-icon" color="neutral">
                        1
                      </Button> */}
                        <div className="card-icon">
                          <span className="icon-simple">
                            <i className="fa fa-globe"  style={{color: 'white'}}/>
                            {/* <img style={{color: 'white', height: '100px'}} src='https://image.flaticon.com/icons/png/512/2754/2754518.png'></img> */}
                          </span>
                        </div>
                        <CardTitle tag="h3" style={{color: 'white'}}>Let us help you</CardTitle>
                        <p className="card-description" style={{color: 'white'}}>
                          Join the career transition process, where you will be assessed on your skills. Minos Labs partners with organizations to help guide the candidates to the right roles
                        </p>
                        <CardFooter>
                          {/* <Button
                            className="btn-neutral btn-round"
                            color="default"
                            style={{backgroundColor: 'white', borderRadius: '30px'}}
                            
                            onClick={() => setNoticeModal(true)}
                          >
                            <i className="nc-icon nc-alert-circle-i"  style={{fontSize: '25px'}}/>
                          </Button> */}
                            
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="1">
                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                      <Button className="btn-just-icon" color="neutral" style={{backgroundColor: 'white', borderRadius: '30px'}}>
                        <i className="nc-icon nc-minimal-right" /> 
                      </Button>
                    </div>
                  </Col>
                  <Col md="3" lg="3">
                    <Card className="card-pricing" style={{backgroundColor: '#E5E1EE', height:'450px'}}>
                      <CardBody>
                        {/* <Button className="btn-just-icon" color="neutral">
                          2
                        </Button> */}
                        <div className="card-icon">
                          <span className="icon-simple" style={{color: 'black'}}>
                            <i className="fa fa-road" />
                            {/* <img style={{color: 'white', height: '80px'}} src='https://image.flaticon.com/icons/png/512/992/992601.png'></img> */}
                          </span>
                        </div>
                        <CardTitle tag="h3">Path Forward</CardTitle>
                        <p className="card-description" style={{color: 'black'}}>
                          Knowledge transfer from hiring managers so you can get insight on organizations to get selected and hired
                        </p>
                        <CardFooter>
                          {/* <Button
                            className="btn-neutral btn-round"
                            color="default"
                            style={{backgroundColor: 'white', borderRadius: '30px'}}
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="nc-icon nc-alert-circle-i"  style={{fontSize: '25px'}}/>  
                          </Button> */}
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="1">
                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                      <Button className="btn-just-icon" color="neutral" style={{backgroundColor: 'white', borderRadius: '30px'}}>
                        <i className="nc-icon nc-minimal-right" /> 
                      </Button>
                    </div>
                  </Col>
                  <Col md="3" lg="3">
                    <Card className="card-pricing" style={{backgroundColor: '#00ADED', height:'500px'}}>
                      <CardBody>
                        {/* <Button className="btn-just-icon" color="neutral">
                          3
                        </Button> */}
                        <div className="card-icon">
                          <span className="icon-simple">
                            <i className="fa fa-rocket" style={{color: 'white'}}/>
                            {/* <img style={{color: 'white', height: '80px'}} src='https://image.flaticon.com/icons/png/512/166/166340.png'></img> */}
                          </span>
                        </div>
                        <CardTitle tag="h3" style={{color: 'white'}}>Get Hired!</CardTitle>
                        <p className="card-description" style={{color: 'white'}}>
                          Network with Technology executives to build your brand to get hired, Minos Labs should be the last application you ever have to do!
                        </p>
                        <CardFooter>
                          {/* <Button
                            className="btn-neutral btn-round"
                            color="default"
                            style={{backgroundColor: 'white', borderRadius: '30px'}}
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="nc-icon nc-alert-circle-i"  style={{fontSize: '25px'}}/>  
                          </Button> */}
                        </CardFooter>
                      </CardBody>
                    </Card>
                    
                  </Col>
                  {/* <Col md="3" lg="3">
                    <Card className="card-pricing" data-color="blue">
                      <CardBody>
                        <Button className="btn-just-icon" color="neutral">
                          4
                        </Button>
                        <div className="card-icon">
                          <span className="icon-simple">
                            <i className="nc-icon nc-istanbul" />
                          </span>
                        </div>
                        <CardTitle tag="h3">Get Hired!</CardTitle>
                        <p className="card-description">
                          Brief description and basic features/metrics about this step. Limited to 1-2 sentences.
                        </p>
                        <CardFooter>
                          <Button
                            className="btn-neutral btn-round"
                            color="default"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Details
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col> */}
                </Row>
                <Button href="https://calendly.com/himanshu_tandon/30-min-meeting" target='_blank'
              className="btn-neutral btn-round"
              color="default"
              style={{backgroundColor: '#00ADED', color: 'white', borderRadius: '30px'}}
              // onClick={() => setNoticeModal(true)}
            >
              <a style={{color: 'white'}}>Learn more</a>
            </Button>
              </TabPane>

              <TabPane tabId="business">
                <Row style={{alignItems: 'center', display: 'flex', paddingLeft: '8.3%'}}>
                  
                  <Col md="3" lg="3">
                    <Card className="card-pricing" style={{backgroundColor: '#E5E1EE', height:'400px'}}>
                      <CardBody>
                      {/* <Button className="btn-just-icon" color="neutral">
                        1
                      </Button> */}
                        <div className="card-icon">
                          <span className="icon-simple">
                            <i className="fa fa-search"  style={{color: 'black'}}/>
                          </span>
                        </div>
                        <CardTitle tag="h3" style={{color: 'black'}}>What Are You Working On?</CardTitle>
                        <p className="card-description" style={{color: 'black'}}>
                          Cybersecurity & Full-Stack teams are growing rapidly. Let us know your hiring needs! Minos Labs can help!
                        </p>
                        <CardFooter>
                          {/* <Button
                            className="btn-neutral btn-round"
                            color="default"
                            style={{backgroundColor: 'white', borderRadius: '30px'}}
                            
                            onClick={() => setNoticeModal(true)}
                          >
                            Details
                          </Button> */}
                           
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="1">
                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                      <Button className="btn-just-icon" color="neutral" style={{backgroundColor: 'white', borderRadius: '30px'}}>
                        <i className="nc-icon nc-minimal-right" /> 
                      </Button>
                    </div>
                  </Col>
                  <Col md="3" lg="3">
                    <Card className="card-pricing" style={{backgroundColor: '#00ADED'}}>
                      <CardBody>
                        {/* <Button className="btn-just-icon" color="neutral">
                          2
                        </Button> */}
                        <div className="card-icon">
                          <span className="icon-simple" style={{color: 'white'}}>
                            <i className="fa fa-sitemap" />
                          </span>
                        </div>
                        <CardTitle tag="h3" style={{color: 'white'}}>Screen Top Tech Talent</CardTitle>
                        <p className="card-description" style={{color: 'white'}}>
                          Filter and search for entry-level talent with the exact skillsets and experience you need. All of our candidates have been trained, assessed, and vetted by industry professionals.
                        </p>
                        <CardFooter>
                          {/* <Button
                            className="btn-neutral btn-round"
                            color="default"
                            style={{backgroundColor: 'white', borderRadius: '30px'}}
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Details
                          </Button> */}
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="1">
                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                      <Button className="btn-just-icon" color="neutral" style={{backgroundColor: 'white', borderRadius: '30px'}}>
                        <i className="nc-icon nc-minimal-right" /> 
                      </Button>
                    </div>
                  </Col>
                  <Col md="3" lg="3">
                    <Card className="card-pricing" style={{backgroundColor: '#E5E1EE', height:'400px'}}>
                      <CardBody>
                        {/* <Button className="btn-just-icon" color="neutral">
                          3
                        </Button> */}
                        <div className="card-icon">
                          <span className="icon-simple">
                            <i className="fa fa-rocket" style={{color: 'black'}}/>
                          </span>
                        </div>
                        <CardTitle tag="h3" style={{color: 'black'}}>Seamless Hiring</CardTitle>
                        <p className="card-description" style={{color: 'black'}}>
                          With our suite of in-platform tools, streamline your process by screening, interviewing, and onboarding directly within Minos Labs!
                        </p>
                        <CardFooter>
                          {/* <Button
                            className="btn-neutral btn-round"
                            color="default"
                            style={{backgroundColor: 'white', borderRadius: '30px'}}
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Details
                          </Button> */}
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* <Col md="3" lg="3">
                    <Card className="card-pricing" data-color="blue">
                      <CardBody>
                        <Button className="btn-just-icon" color="neutral">
                          4
                        </Button>
                        <div className="card-icon">
                          <span className="icon-simple">
                            <i className="nc-icon nc-istanbul" />
                          </span>
                        </div>
                        <CardTitle tag="h3">Get Hired!</CardTitle>
                        <p className="card-description">
                          Brief description and basic features/metrics about this step. Limited to 1-2 sentences.
                        </p>
                        <CardFooter>
                          <Button
                            className="btn-neutral btn-round"
                            color="default"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Details
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col> */}
                </Row>
                <Button href="https://calendly.com/mayursuchdev/30min?month=2021-06" target='_blank'
              className="btn-neutral btn-round"
              color="default"
              style={{backgroundColor: '#00ADED', color: 'white', borderRadius: '30px'}}
              // onClick={() => setNoticeModal(true)}
            >
              <a style={{color: 'white'}}>Book a demo!</a>
            </Button>
              </TabPane>
            </TabContent>
            <br />
            <Modal style={{marginTop: '7%'}} isOpen={noticeModal} toggle={() => setNoticeModal(false)}>
              <div className="modal-header no-border-header">
                <h5 className="modal-title" id="myModalLabel">
                  How Do You Come On Board?
                </h5>
                <button
                  className="close"
                  onClick={() => setNoticeModal(false)}
                  type="button"
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="instruction">
                  <Row>
                    <Col md="8">
                      <p>
                        <strong>1. Key Point 1</strong>- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut fermentum magna eu lacus sollicitudin, at condimentum eros ornare. Nullam leo mi, placerat sit amet dui et, commodo ullamcorper erat.
                      </p>
                    </Col>
                    <Col md="4">
                      <div className="picture">
                        {/* <img
                          alt="..."
                          className="img-rounded img-responsive"
                          src="https://images.unsplash.com/photo-1502101872923-d48509bff386?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80"
                        ></img> */}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="instruction">
                  <Row>
                    <Col md="8">
                      <p>
                        <strong>2. Key Point 2</strong>- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut fermentum magna eu lacus sollicitudin, at condimentum eros ornare. Nullam leo mi, placerat sit amet dui et, commodo ullamcorper erat.
                      </p>
                    </Col>
                    <Col md="4">
                      <div className="picture">
                        <img
                          alt="..."
                          className="img-rounded img-responsive"
                          src="http://96bda424cfcc34d9dd1a-0a7f10f87519dba22d2dbc6233a731e5.r41.cf2.rackcdn.com/ermu/sliders-1/fall-residential-faders/Fall_Fader2.jpg"
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </div>
                <p>
                  If you have more questions, don't hesitate to contact us at support@minoslabs.com. We{"'"}re here to help!
                </p>
              </div>
              <div className="modal-footer" >
                <Button
                  className="btn-link"
                  color="primary"
                  type="button"
                  style={{padding: '10px'}}
                  onClick={() => setNoticeModal(false)}
                >
                  Got it!
                </Button>
              </div>
            </Modal>
          </Container>
        </div>
    </>
  );
}

export default SectionPricing;
