import React from 'react';
import styles from './SavedCandidates.module.scss';
import Dashboard from '../../components/Dashboard';
import Candidate from '../../components/Candidate';
import firebase from 'firebase';
import UserContext from '../../User/User';

class SavedCandidates extends React.Component {
	constructor() {
		super();

		this.state = {
			candidates: [],
			moodleCandidates: [],
			savedCands: [],
			fullStack: 0,
			cybersecurity: 0,
			angular: 0,
			java: 0,
			javaScript: 0,
			python: 0,
			react: 0,
			amazonEC2: 0,
			aws: 0,
			mongoDB: 0,
			MSazure: 0,
			stripe: 0,

			open: true,
			openLanguage: true,
			openTechnical: true,
			savedCands: [],
		};

		this.handleCheck = this.handleCheck.bind(this);
		this.displayCandidates = this.displayCandidates.bind(this);
		this.checkList = this.checkList.bind(this);
	}
	static contextType = UserContext;

	displayCandidates() {
		const { candidates } = this.state;
		const { savedCands } = this.state;

		var candidateList = [];
		var i;

		for (i = 0; i < candidates.length; i++) {
			let saved = [];
			let name;
			let title;
			let id;
			let languages = [];
			let technical = [];
			let experience = [];
			let education = [];
			let mentor = [];
			let skillsAndProgress = [];
			let profileUrl;
			let initial;
			let email;
			let grades;
			try {
				saved = savedCands;
				id = candidates[i].candidateID;
				initial = 'fa fa-star favorite gold move-again';
				if (!saved.includes(id)) {
					continue;
				}
				name = candidates[i].first + ' ' + candidates[i].last;
				languages = candidates[i].languages;
				technical = candidates[i].technical;
				email = candidates[i].email;

				experience = candidates[i].experience;
				education = candidates[i].education;
				mentor = candidates[i].mentor;
				grades = candidates[i].grades;
				profileUrl = candidates[i].profileUrl;

				if (candidates[i].course === 'FullStack') {
					title = 'Full-Stack Developer';
				} else {
					title = 'Cybersecurity';
				}
				if (!this.checkList(title, languages, technical)) {
					continue;
				}
			} catch (err) {
				continue;
			}
			candidateList.push(
				<Candidate
					name={name}
					email={email}
					title={title}
					languages={languages}
					technical={technical}
					id={id}
					experience={experience}
					education={education}
					mentor={mentor}
					grades={grades}
					profileUrl={profileUrl}
					initialFavorite={initial}
				/>
			);
		}

		return candidateList;
		//return [<Candidate name="Jackie Green" title="Full-Stack Developer" source={require('./candidate_images/profile_pics/jackie_green.png') } initialFavorite="fa fa-star favorite gray move-again"/>,<Candidate name="John Hardwick" title="Full-Stack Developer" source={require('./candidate_images/profile_pics/john_hardwick.png')} initialFavorite="fa fa-star favorite gray move-again"/>];
	}
	displayMoodleCandidates() {
		const { moodleCandidates } = this.state;
		const { savedCands } = this.state;
		var candidateList = [];
		var i;

		for (i = 0; i < moodleCandidates.length; i++) {
			let name;
			let saved = [];
			let title;
			let id;
			let languages = [];
			let technical = [];
			let experience = [];
			let education = [];
			let mentor = [];
			let skillsAndProgress = [];
			let profileUrl;
			let initial;
			let email;
			let grades;
			try {
				saved = savedCands;
				
				name = moodleCandidates[i].first + ' ' + moodleCandidates[i].last;
				email = moodleCandidates[i].email;
				id = moodleCandidates[i].candidateID;
				if (!saved.includes(id)) {
					continue;
				}
				languages = [];
				technical = [];

				experience = [];
				education = [];
				mentor = [];
				grades = moodleCandidates[i].grades;
				profileUrl = moodleCandidates[i].profileUrl;
				if (saved.includes(id)) {
					initial = 'fa fa-star favorite gold move-again';
				} else {
					initial = 'fa fa-star favorite gray move-again';
				}
				if (moodleCandidates[i].course === 'FullStack') {
					title = 'Full-Stack Developer';
				} else {
					title = 'Cybersecurity';
				}
				if (!this.checkList(title, languages, technical)) {
					continue;
				}
			} catch (err) {
				continue;
			}
			candidateList.push(
				<Candidate
					key={i}
					name={name}
					email={email}
					title={title}
					languages={languages}
					technical={technical}
					id={id}
					experience={experience}
					education={education}
					mentor={mentor}
					grades={grades}
					profileUrl={profileUrl}
					initialFavorite={initial}
				/>
			);
		}

		return candidateList;
	}

	checkList(title, languages, technical) {
		let checks = [];
		const {
			fullStack,
			cybersecurity,
			angular,
			java,
			javaScript,
			python,
			react,
			amazonEC2,
			aws,
			mongoDB,
			MSazure,
			stripe,
		} = this.state;
		if (fullStack != cybersecurity) {
			if (fullStack & (title !== 'Full-Stack Developer')) {
				return false;
			} else if (cybersecurity & (title !== 'Cybersecurity')) {
				return false;
			}
		}

		if (angular) {
			if (!languages.includes('Angular.JS')) {
				return false;
			}
		}
		if (java) {
			if (!languages.includes('Java')) {
				return false;
			}
		}
		if (javaScript) {
			if (!languages.includes('JavaScript')) {
				return false;
			}
		}
		if (python) {
			if (!languages.includes('Python')) {
				return false;
			}
		}
		if (react) {
			if (!languages.includes('React.JS')) {
				return false;
			}
		}

		if (amazonEC2) {
			if (!technical.includes('Amazon EC2')) {
				return false;
			}
		}
		if (aws) {
			if (!technical.includes('AWS')) {
				return false;
			}
		}
		if (mongoDB) {
			if (!technical.includes('MongoDB')) {
				return false;
			}
		}
		if (MSazure) {
			if (!technical.includes('MS Azure')) {
				return false;
			}
		}
		if (stripe) {
			if (!technical.includes('Stripe API')) {
				return false;
			}
		}

		return true;
	}

	componentDidMount() {
		const context = this.context;
		this.setState({
			candidates: context.candidates,
			moodleCandidates: context.moodleCandidates,
			savedCands: context.savedCands,
		});
	}

	handleCheck(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		if (this.state[name] == 0) {
			this.setState({
				[name]: 1,
			});
		} else {
			this.setState({
				[name]: 0,
			});
		}
	}

	handleButtonClick = () => {
		this.setState((state) => {
			return {
				open: !state.open,
			};
		});
	};

	handleLanguageButtonClick = () => {
		this.setState((state) => {
			return {
				openLanguage: !state.openLanguage,
			};
		});
	};

	handleTechnicalButtonClick = () => {
		this.setState((state) => {
			return {
				openTechnical: !state.openTechnical,
			};
		});
	};
	render() {
		//User data
		let { userID, logoutUser, setUser } = this.context;

		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="View_Candidate" />

						{/*right expands when dashboard is closed*/}
						<div class="right">
							{/*code here*/}

							{/*Candidate Filter starts here*/}
							<div className={styles.candidate_filter}>
								<div className={styles.filterContainer}>
									<h1>Candidate Filter</h1>
									<div className={styles.dropdownCheckList}>
										<span className={styles.candidate_filter_elements}>
											Program Type
										</span>
										<span role="button" className={styles.typeButton}>
											<a role="button">
												<i
													onClick={this.handleButtonClick}
													className={
														this.state.open
															? 'fa fa-chevron-up'
															: 'fa fa-chevron-down'
													}
													aria-hidden="true"
												></i>
											</a>
										</span>
									</div>

									{this.state.open && (
										<div
											className={
												this.state.open
													? styles.dropdownListOpen
													: styles.dropdownListClose
											}
										>
											<div classname={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="fullStack"
													checked={this.state.fullStack == 1}
													onChange={this.handleCheck}
												/>
												Full-Stack
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													className={styles.checkbox_class}
													name="cybersecurity"
													checked={this.state.cybersecurity == 1}
													onChange={this.handleCheck}
												/>
												Cybersecurity
											</div>
										</div>
									)}
									<br></br>
									<div className={styles.dropdownCheckList}>
										<span className={styles.candidate_filter_elements}>
											Languages &#38;
										</span>
										<br></br>
										<span className={styles.candidate_filter_elements}>
											Frameworks
										</span>
										<span role="button" className="type-button">
											<i
												onClick={this.handleLanguageButtonClick}
												className={
													this.state.openLanguage
														? 'fa fa-chevron-up'
														: 'fa fa-chevron-down'
												}
												aria-hidden="true"
											></i>
										</span>
									</div>
									{this.state.openLanguage && (
										<div
											className={
												this.state.openLanguage
													? 'dropdown-list-open'
													: 'dropdown-list-close'
											}
										>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="angular"
													checked={this.state.angular == 1}
													onChange={this.handleCheck}
												/>
												Angular.JS
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="java"
													checked={this.state.java == 1}
													onChange={this.handleCheck}
												/>
												Java
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="javaScript"
													checked={this.state.javaScript == 1}
													onChange={this.handleCheck}
												/>
												JavaScript
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="python"
													checked={this.state.python == 1}
													onChange={this.handleCheck}
												/>
												Python
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="react"
													checked={this.state.react == 1}
													onChange={this.handleCheck}
												/>
												React
											</div>
										</div>
									)}

									<br></br>
									<div className={styles.dropdownCheckList}>
										<span className={styles.candidate_filter_elements}>
											Competency
										</span>
										<span role="button" className="type-button">
											<i
												onClick={this.handleTechnicalButtonClick}
												className={
													this.state.openTechnical
														? 'fa fa-chevron-up'
														: 'fa fa-chevron-down'
												}
												aria-hidden="true"
											></i>
										</span>
									</div>
									{this.state.openTechnical && (
										<div
											className={
												this.state.openTechnical
													? 'dropdown-list-open'
													: 'dropdown-list-close'
											}
										>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="amazonEC2"
													checked={this.state.amazonEC2 == 1}
													onChange={this.handleCheck}
												/>
												Amazon EC2
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="aws"
													checked={this.state.aws == 1}
													onChange={this.handleCheck}
												/>
												AWS
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="mongoDB"
													checked={this.state.mongoDB == 1}
													onChange={this.handleCheck}
												/>
												MongoDB
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="MSazure"
													checked={this.state.MSazure == 1}
													onChange={this.handleCheck}
												/>
												MS Azure
											</div>
											<div className={`${styles.dropdownCheckList} ${styles.course_type}`}>
												<input
													type="checkbox"
													name="stripe"
													checked={this.state.stripe == 1}
													onChange={this.handleCheck}
												/>
												Stripe API
											</div>
										</div>
									)}
								</div>
							</div>
							{/*Candidate Filter ends here*/}

							<div className={styles.candidates_list}>
								{this.displayCandidates()}
								{this.displayMoodleCandidates()}
							</div>
						</div>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default SavedCandidates;
