import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import CandidateExpereinces from './InnerComponents/CandidateExpereinces';
import CandidateEducation from './InnerComponents/CandidateEducation';
import CandidateContact from './InnerComponents/CandidateContact';
import CandidateGrades from './InnerComponents/CandidateGrades';
import CandidateSkills from './InnerComponents/CandidateSkills';
import firebase from 'firebase';
import { Spinner } from 'reactstrap';
const axios = require('axios');

/**
 * Important Note on Switching to display Education and Experience:
 * -> Use the commented code as a baseline.
 * -> The commented firebase call needs to be taken simialry to how candidate
 *    list is built but based on candidateID filter.
 * -> Once the data is received, we can just use the commented code in the render
 *    function, pass the data to display experience and education.
 */

export class StudentProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            studentData: null,
            interviewId:this.props.interviewId,
            moodleData: null,
            candidateID: "",
            candidateImg: "",
            role:"Cybersecurity"
        }
    }
    async componentDidMount() {
        const db = firebase.firestore();
        await db.collection('interviews').doc(this.state.interviewId)
        .get()
        .then((doc) => {
            this.setState({
                candidateID: doc.data().candidateID,		
            });	
         });


         await db.collection('students')
         .where('candidateID', '==', this.state.candidateID)
         .get()
         .then((querySnapshot) => {
             const data = querySnapshot.docs.map((doc) => doc.data());
             this.setState({
                studentData: data[0],		
             });	
          });

        /**  await db
            .collection('ALL_USER').where('candidateID', '==', this.state.candidateID)
            .get()
            .then((querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => doc.data());

                console.log(data)
                var index = 0;

                querySnapshot.forEach((document) => {
                    document.ref
                        .collection('experience')
                        .orderBy('start', 'desc')
                        .get()
                        .then((querySnapshot) => {
                            const experience = querySnapshot.docs.map((doc) =>
                                doc.data()
                            );
                            data[index].experience = experience;
                        });
                    document.ref
                        .collection('education')
                        .get()
                        .then((querySnapshot) => {
                            const education = querySnapshot.docs.map((doc) =>
                                doc.data()
                            );
                            data[index].education = education;
                        });
                    document.ref
                        .collection('mentor')
                        .get()
                        .then((querySnapshot) => {
                            const mentor = querySnapshot.docs.map((doc) =>
                                doc.data()
                            );
                            data[index].mentor = mentor;
                        });
                    document.ref
                        .collection('skillsAndProgress')
                        .get()
                        .then((querySnapshot) => {
                            const skillsAndProgress = querySnapshot.docs.map(
                                (doc) => doc.data()
                            );
                            data[index].skillsAndProgress = skillsAndProgress;
                            index++;
                        });
                    data[index].skills = document.data().skills


                    //Pull Moodle Data to get PFP URL
                        //Update the user data. pfp URL with that info

                    
                    //console.log(data[index].skills)
                });

                this.setState({
                    moodleCandidates: data,
                });

            });*/

        if(this.state.studentData==null){
            await db.collection('candidates')
            .where('candidateID', '==', this.state.candidateID)
            .get()
            .then((querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => doc.data());
                this.setState({
                    studentData: data[0],		
                });	
            });
        }
          var storage = firebase.storage().ref();

          const email = this.props.email + '.jpg';
  
          storage
          .child('profilepics')
          .child(email)
          .getDownloadURL()
          .then((url) => {
              this.setState({
                candidateImg: url,
              });
          })
          .catch((e) => {

          });

    }

    render() {


        if (this.state.studentData == null) {

            return (
                <div>
                    <Container >
                        <Row className="studentProfileSpinner  align-items-center">
                            <Col sm="12" className="d-flex justify-content-center" >
                                <Spinner color="primary"></Spinner>

                                Loading Candidate Profile
                            </Col>
                        </Row>
                    </Container>

                </div>
            )
        }
        else {
            let studentData = this.state.studentData

            let name = studentData.first + " " + studentData.last;
            let role = studentData.course;
            let grades = studentData.grades;
            return (
                <div>
                    <Container >
                        <Row >
                            <Col sm="12" className="overflow-auto studentProfile studentProfileInnerComponent">
                                <h4 className ="profileTitle">Candidate Profile</h4>

                                <CandidateContact studentName={name} studentRole={role} studentImg={this.state.candidateImg}></CandidateContact>
                                {/* These are hardcoded for now the Grades. Need fix */}
                                <hr className="profileDivider"></hr>
                                <h4 className="gradeTitle">Course Grades</h4>
                                <CandidateGrades studentGrades={grades}></CandidateGrades>

                                {/* <h4>Candidate Expereinces</h4>
                                {
                                    this.state.studentData.experiences && this.state.studentData.experiences.map(studentExp => (

                                        < CandidateExpereinces key={studentExp.expId} studentExp={studentExp} ></CandidateExpereinces>
                                    ))
                                } */}
                                {/* <h4>Candidate Skills</h4> */}
                                {/* <CandidateSkills studSkills={this.state.studentData.skills}></CandidateSkills> */}
                                {/* <h4>Candidate Education</h4>
                                {
                                    this.state.studentData.educations.map(studentEdu => (
                                        <CandidateEducation key={studentEdu.edId} studentEdu={studentEdu}></CandidateEducation>
                                    ))

                                } */}

                            </Col>
                        </Row>

                    </Container>


                </div>
            )
        }
    }
}

export default StudentProfile
