import React, { Component } from 'react'
import styles from "./SamplePayment.module.scss";
import check from '../../assets/payment/check-circle.png';
export class PaymentStatusSuccess extends Component {
    render() {
        return (
            <React.Fragment>

                <section class="hero-university2"></section>
                <div className={styles.payment_process}>
                    <div className={styles.topBar}>
                        <div className={styles.pageName}>
                            <h1>Checkout</h1>
                        </div>
                    </div>

                    <div className={styles.paymentBlock}>
                        <h1 style={{ color: '#57C03D' }} className={styles.paymentHeader}>Payment Successful</h1>
                        <div className={styles.imgWrapper}>
                            <img className={styles.paymentImage} src={check} alt="Course img" />
                        </div>
                        <div >
                            <h1 className={styles.paymentMessage}>order Number: CB1221DS</h1>
                        </div>
                        <div className={styles.paymentInfo}>
                            {/* <div className={styles.paymentInfoL}>
                                {this.state.course == "Cyber" ? <h4>Cybersecurity Course </h4> : <h4>Full-Stack Course </h4>}
                                <h4>Total: </h4>
                            </div>
                            <div className={styles.paymentInfoR}>
                                <h4>x1</h4>
                                <h4>$50.00</h4>
                            </div> */}
                        </div>
                        <div className={styles.buttonWrapper2}>
                            <button className={styles.checkoutButton} >RETURN TO HOME</button>
                        </div>
                    </div>

                </div>

            </React.Fragment>
        )
    }
}

export default PaymentStatusSuccess
