import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import styles from './Veterans.module.scss';
import cubeimg from '../../assets/icons/cube-icon.png';
import graphimg from '../../assets/icons/graph-icon2.png';
import lectureimg from '../../assets/icons/lecture-icon.png';
import peerimg from '../../assets/icons/peer-icon.png';
import trainimg from '../../assets/icons/train-icon.png';
import pairimg from '../../assets/icons/pair-icon.png';
import firebase from 'firebase';

function Veterans() {

    //Tracking Analytics
	useEffect(() => {
		let analytics = firebase.analytics();
		console.log('Visited Veterans Page')
		analytics.logEvent('visited_veterans_page')
	}, []); 

    return (
        <React.Fragment>
            <section className={styles.heroAbout}>
                <div className={styles.container}>
                    <div className={styles.fade1}>
                        <h1> How we Support Our Veterans</h1>
                    </div>

                    <div className={styles.fade2}>
                        <p>





                            <br></br>
                            Rooted in the belief that anyone can succeed through the virtue of effort, Minos Labs strives to connect veterans looking to break into the technology field with top level employers to make the hiring process easier and more effective on both sides.
                        </p>
                    </div>
                </div>
            </section>

            <section className={styles.pros}>
                <div className={styles.col}>
                    <div className={styles.container}>
                        {/*<strong>OUR COMMITMENT TO VETERANS</strong>*/}
                        <p>
                            Adapting to civilian life after the service is no easy task - That’s why Minos Labs is here to make the transition as smooth as possible. We believe that anyone, no matter their background, can succeed and excel in a technical career. Our Career Acceleration Program promises to teach you the skills necessary to thrive in a competitive work environment. Our veterans have done so much to help our country - Let Minos Labs help you find your place in the technical industry.</p>
                    </div>
                </div>
            </section>

            <section className={styles.process}>
                <section className={styles.colContainer}>
                    <div className={styles.left}>
                        <div className={styles.container}>
                            <div className={styles.sticky} >
                                <h1>Our Process</h1>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.container}>
                            <div className={styles.row}>
                                <div className={styles.image}>
                                    <img src="https://image.flaticon.com/icons/png/512/942/942799.png" />
                                </div>
                                <div className={styles.text}>
                                    <h1>Application</h1>
                                    <p>Click apply now to start our quick application and technical assessment!</p>
                                </div>
                            </div>
                            <div className={styles.line}></div>
                            <div className={styles.row}>
                                <div className={styles.image}>
                                    <img src="https://image.flaticon.com/icons/png/512/1436/1436716.png" />
                                </div>
                                <div className={styles.text}>
                                    <h1>TRAINING</h1>
                                    <p>
                                        Go through interview training, career coaching, personal projects, and more!
                                    </p>
                                </div>
                            </div>
                            <div className={styles.line}></div>
                            <div className={styles.row}>
                                <div className={styles.image}>
                                    <img src="https://image.flaticon.com/icons/png/512/1535/1535012.png" />
                                </div>
                                <div className={styles.text}>
                                    <h1>PROGRESS</h1>
                                    <p>
                                        Potential employers can view your progress and see your technical growth!
                                    </p>
                                </div>
                            </div>
                            <div className={styles.line}></div>
                            <div className={styles.row}>
                                <div className={styles.image}>
                                    <img src="https://image.flaticon.com/icons/png/512/1642/1642056.png" />
                                </div>
                                <div className={styles.text}>
                                    <h1>STAND OUT</h1>
                                    <p>
                                        As a veteran, you’ll receive a special veteran profile to help you stand out amongst other candidates!
                                    </p>
                                </div>
                            </div>
                            <div className={styles.line}></div>
                            <div className={styles.row}>
                                <div className={styles.image}>
                                    <img src="https://image.flaticon.com/icons/png/512/942/942833.png" />
                                </div>
                                <div className={styles.text}>
                                    <h1>GET HIRED</h1>
                                    <p>
                                        We put you front and center for top employers in technology who want you in the field!

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>
            </section>

            <section className={styles.employed}>
                <div className={'container'}>
                    <img style={{width: "13%"}}  src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Mark_of_the_United_States_Air_Force.svg/1200px-Mark_of_the_United_States_Air_Force.svg.png"/>
                    <img style={{width: "13%"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Seal_of_the_United_States_Coast_Guard.svg/1200px-Seal_of_the_United_States_Coast_Guard.svg.png" />
                    <img style={{width: "13%"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Logo_of_the_United_States_Army.svg/1200px-Logo_of_the_United_States_Army.svg.png" />
                    <img style={{width: "13%"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Emblem_of_the_United_States_Marine_Corps.svg/1200px-Emblem_of_the_United_States_Marine_Corps.svg.png" />
                    <img style={{width: "13%"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Emblem_of_the_United_States_Navy.svg/1200px-Emblem_of_the_United_States_Navy.svg.png" />
                </div>
            </section>

            <section className={styles.apply}>
                <div className={styles.container}>
                    <h1>Accelerate Your Career</h1>
                    <Link to="/exam-start">
                        <button>Apply Now</button>
                    </Link>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Veterans;
