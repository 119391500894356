import React from 'react';
import { Link } from 'react-router-dom';
//import './portalInfo.css';
import UserContext from "../../User/User";
import b_Command from '../../assets/candidate_images/BlueIcons/b_command.png';
import b_ViewCandidates from '../../assets/candidate_images/BlueIcons/b_ViewCandidates.png';
import b_HireCandidatesNow from '../../assets/candidate_images/BlueIcons/b_deal 1.png';
import b_HiringReqs from '../../assets/candidate_images/BlueIcons/b_HiringReqs.png';
import b_SavedCandidates from '../../assets/candidate_images/BlueIcons/b_SavedCandidates.png';
/*import { withFirebase } from '../Firebase';*/
import firebase from 'firebase';
import ReactTooltip from "react-tooltip";
import { Icon } from 'semantic-ui-react'
/*import firestore from "../Firebase/firestore.js"*/

import axios from 'axios';

const INITIAL_STATE = {
	first: '',
	last: '',
	email: '',
	month: 'select',
	year: 'select',
	course: 'select',
	error: null,
	hearAbout: 'select',
	courseType: '',
	race: 'select',
	gender: 'select',
	veteran: 'select',
	disabilities: 'select',
	nylasLogin: "",
};

class CorporateInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...INITIAL_STATE,
		};
		this.nylasHandler = this.nylasHandler.bind(this);
	}
	static contextType = UserContext;


	nylasHandler(){
		
		window.open("https://us-central1-minos-labs-57c35.cloudfunctions.net/getNylasAccessCode", "_self");
		
		/*
		axios({
			method: 'get',
			url: 'https://us-central1-minos-labs-57c35.cloudfunctions.net/getNylasAccessToken',
		  })
		.then((response) => {
			console.log(response.data);

		}).catch(err => {
		 
	   })
	   */

	}


	async componentDidMount() {
		let nylasToken ="";
		let nylasURL = window.location.href;
		let urlSplit = nylasURL.split("token=");
		if(urlSplit.length>1){
			let nylasToken = urlSplit[1].substring(0, urlSplit[1].length - 1);
			console.log(nylasToken);
			const context = this.context;
			const db = firebase.firestore();
			const userRef = db.collection('corporate').doc(context.userID);
			console.log(context.userID);
			userRef.update({
				nylasAccessToken: nylasToken,
			});
		}
	}

	render() {
		//user data
		
		let { userID, logoutUser, setUser, loading, userInfo, nylasAccessToken } = this.context;
		
		return (
			<React.Fragment>
				<section class="hero-university2"></section>

				<section class="infoContainer">
					<div class="infoBlock">
						<h1> Welcome {userInfo.first} {userInfo.last}!</h1>
						<p> </p>
						<hr></hr>
						<table>
						<tr>
							<th><img src={b_Command} alt="command center Icon"/></th>
							<th><h3>Command Center</h3><p>Your profile information, upcoming interviews, skills that you are looking for, and the ability to view demographics of the current Minos Labs cohort all in one place! </p></th>
						</tr>
						<div class="tableSpacer"></div>
						<tr>
							<th><img src={b_ViewCandidates} alt="view candidates Icon"/></th>
							<th><h3>View Candidates</h3><p>View, Filter, Save, and Deep Dive into candidate profiles and progress through the Minos Labs program!</p></th>
						</tr>
						<div class="tableSpacer"></div>
						<tr>
							<th><img src={b_SavedCandidates} alt="saved candidates Icon"/></th>
							<th><h3>Saved candidates</h3><p>See your short list and compare candidates side by side to find the perfect fit for your open roles.</p></th>
						</tr>
						<div class="tableSpacer"></div>
						<tr>
							<th><img src={b_HiringReqs} alt="Hiring req Icon"/></th>
							<th><h3>Hiring Requirments</h3><p>Add in the open roles you're looking for! Keep track of the roles you need to fill and let us know if you're looking for something specific so we can help!</p></th>
						</tr>
						<div class="tableSpacer"></div>
						<tr>
							<th><img src={b_HireCandidatesNow} alt="Hire candidates now Icon"/></th>
							<th><h3>Hire Candidates Now</h3><p>Select candidates from your saved list that you want to interview! Set your availability, and the role you're looking to fill. Once confirmed, a meeting invite will be sent to both yourself and the candidate to make the interview process as seamless as possible!</p></th>
						</tr>
						<div class="tableSpacer"></div>

						</table>

						<div class="curriculumContainer">
							<div class="curriculumCol">
								<div class="container">
									<h3>Full-Stack Development</h3>
									<p>Staying at the forefront of technology education and advancement, Candidates in the Minos Labs Full-Stack Development track cover Front & Back-end development through projects and training in React, Java, Python, and more!</p>
									{/* <button class="hvr-sweep" style={{width: '100%'}}>LEARN MORE</button> */}
								</div>
							</div>
							<div class="curriculumCol">
								<div class="container">
									<h3>Cybersecurity</h3>
									<p>In an increasingly remote world, defending and securing information is paramount. In the Minos Labs cybersecurity track, candidates cover: Endpoint protection, Incident response, Threat intelligence, Penetration testing, and much more!</p>
									{/* <button class="hvr-sweep" style={{width: '100%'}}>LEARN MORE</button> */}
								</div>
							</div>
						</div>
						{userInfo["nylasAccessToken"] != undefined?
							<Link to="/command_center">
								<button class="hvr-sweep continueButton">CONTINUE</button>
							</Link>
						:
							<> 
								<button onClick={this.nylasHandler} class="hvr-sweep continueButton" data-tip data-for="calendarConnect"><Icon name='calendar alternate' /> &nbsp; Calendar Connect</button>
								<ReactTooltip id="calendarConnect" place="top" effect="solid">
									We require you to connect your calendar so you always stay connected and in touch with interview updates.
								</ReactTooltip>	
							</>
						
						}
						
					</div>
				</section>
			</React.Fragment>
		);
	}
}

export default CorporateInfo;
