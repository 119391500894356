/**
 * Renders the list of scheduled inteviews for the given hiring manger
 */

import React, { Component } from 'react';
import styles from './InterviewSchedule.module.scss';
import UserContext from '../../User/User';
import firebase from 'firebase';
import ActionButton from './ActionButtons';
import Dashboard from '../../components/Dashboard';
import * as emailjs from 'emailjs-com'; 
import axios from 'axios';

export class InterviewSchedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			interviewList: [],
		};
		this.handleOnDelete = this.handleOnDelete.bind(this);
	}

	async componentDidMount() {
		const db = firebase.firestore();
		const interviewRef = db.collection('interviews');
		const interviewData = await interviewRef
			.where('corporateUserID', '==', this.context.userID)
			.get();


		const studentDetail = db.collection('ALL_USER');
		const studentDetailData = await studentDetail.get();

		// const studentDetail2 = db.collection('candidates'); //For hard coded candidates
		// const studentDetailData2 = await studentDetail2.get();

		var studentNames = {};

		studentDetailData.forEach((stud) => {
			studentNames[stud.id] = stud.data().first;
		});

		// studentDetailData2.forEach((stud) => {
		// 	studentNames[stud.id] = stud.data().first;
		// });

		var interviewList = [];
		interviewData.forEach((interviewDoc) => {
			var interviewObj = interviewDoc.data();
			interviewObj.meetingId = interviewDoc.id;
			interviewObj.name = studentNames[interviewObj.candidateID];
			interviewList.push(interviewObj);
		});

		this.setState({
			interviewList: interviewList,
		});
	}

	static contextType = UserContext;
	/**
	 * Deletes the meeiting from the firebase collection interviews by the
	 * given interview id
	 * @param {interview ID} id
	 */
	async handleOnDelete(id) {
		const context = this.context;
		const db = firebase.firestore();
		const snapshot= await db.collection('interviews').doc(id).get();
		const data = snapshot.data();

        console.log(data);
		const candidateName= await db.collection('ALL_USER').doc(data.candidateID.toString()).get();
				if(data.start_time!=undefined){
					var start_t = new Date(data.start_time.seconds * 1000);
					start_t = start_t.toString();
				}
				else{
					start_t = "";
				}
				
                let token = "Bearer" + " " + context.userInfo.nylasAccessToken
                let eventId 
                try{
                    await axios({
                            url: `https://api.nylas.com/events/`,
                            method: 'get',
                            headers: {"Content-Type": "application/json", "Authorization": token}, 
                            params: {'location': data.location}
                    }).then(res => {
                        eventId = res.data[0]["id"];
                    }); 
                } catch(error){
                    console.log('ERROR::::Fetching the event' + error)
                }

                try{
                    await axios({
                            url: `https://api.nylas.com/events/${eventId}`,
                            method: 'delete',
                            headers: {"Content-Type": "application/json", "Authorization": token}, 
                            params: {'notify_participants': true}
                    })
                } catch(error){
                    console.log('ERROR::::Deleting the event' + error)
                }

               //email for hiring manager
				var temp = {
					clientEmail: this.context.userInfo.email,
					clientName: this.context.userInfo.first, 
					candName: candidateName.data().first +" "+ candidateName.data().last,
				    eventDate: start_t
				};

				emailjs.send('mailgun', 'template_btladlj', temp, 'user_ry821S5CQJTzdU5eSuuAk').then(
					function (response) {
						console.log(response.status, response.text);
					},
					function (err) {
						console.log(err);
					}
				);
				// email for candidate
				var tempC = {
					candName: candidateName.data().first +" "+ candidateName.data().last,
					eventDate: start_t,
					clientName: this.context.userInfo.first +" "+ this.context.userInfo.last,
					studentEmail:  candidateName.data().email
				}
				emailjs.send('mailgun', 'template_s5fj5sq', tempC, 'user_ry821S5CQJTzdU5eSuuAk').then(
					function (response) {
						console.log(response.status, response.text);
					},
					function (err) {
						console.log(err);
					}
				);
		
			
		var currInterviews = [...this.state.interviewList];

		var updatedInterviewList = currInterviews.filter((interview) => {
			return interview.meetingId !== id;
		});
		console.log('updated', updatedInterviewList);
		this.setState(
			{
				interviewList: updatedInterviewList,
			},
			async () => {
				const db = firebase.firestore();
				const reqRef = await db.collection('interviews').doc(id);
				await reqRef.delete();
			}
		);


	}

	render() {
		let {userInfo} = this.context;
		return (
			<div className={styles.borderbox}>
				<div className="hero-university2"></div>
				<div className="candidate_process">
					<div className="col-container">
						<Dashboard />

						<div className="right">
							<div class="topBar">
								<div class="pageName">
									<h1>Scheduled Interviews</h1>
								</div>
							</div>
							<div className={styles.container}>
								<div className={styles.inner}>
									<div className={styles.calendar}>
										<div className={styles.calendarHeader}>
											<h1>Upcoming Interviews</h1>
											<h2>Manage Your Interviews</h2>
										</div>
										<div>
											<table className="table table-hover">
												<thead>
													<tr>
														<th
															className={styles.hrreqTh}
															scope="col"
														>
															Name
														</th>
														<th
															className={styles.hrreqTh}
															scope="col"
														>
															Position
														</th>
														{/* <th
															className={styles.hrreqTh}
															scope="col"
														>
															Job ID
														</th> */}
														<th
															className={styles.hrreqTh}
															scope="col"
														>
															Interview Date
														</th>
														<th
															className={styles.hrreqTh}
															scope="col"
														>
															Actions
														</th>
													</tr>
												</thead>

												<tbody>
													{this.state.interviewList &&
														this.state.interviewList.map(
															(interview) => (
																<tr key={interview.meetingId}>
																	<ActionButton
																		handleOnDelete={
																			this.handleOnDelete
																		}
																		interviewDetails={
																			interview
																		}
																	></ActionButton>
																</tr>
															)
														)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default InterviewSchedule;
