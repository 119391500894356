import React from 'react';
import firebase from 'firebase';
import styles from './CandidateSelect.module.scss';
import add_acc_logo from '../../assets/manage account/noprofilepic.jpg';

class Candidate_Select extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isHighlighted: false,
			containerClass: 'containerSelect',
			profileUrl: this.props.profileUrl,
		};
		this.handleSelect = this.handleSelect.bind(this);
	}

	handleSelect() {
		this.props.isSelected(this.state.isHighlighted,this.props.id,this.props.email,this.props.name,this.props.title);
		console.log(this.state.isHighlighted)
		if (this.state.isHighlighted === false) {
			console.log("selected")
			this.setState({
				containerClass: 'containerSelectHighlight',
				isHighlighted: true,
			});
		} else {
			console.log("deselected")
			this.setState({
				containerClass: 'containerSelect',
				isHighlighted: false,
			});
		}
	}

	componentWillReceiveProps(newProps) {

		try{
			if(!newProps.deSelect){
				this.setState({
					containerClass: 'containerSelect',
					isHighlighted: false,
				});
			}
		} catch (err){

		}
	}


	componentDidMount() {
		var storage = firebase.storage().ref();

		const email = this.props.email + '.jpg';

		if(this.state.profileUrl==undefined){
            storage
			.child('profilepics')
			.child(email)
			.getDownloadURL()
			.then((url) => {
				this.setState({
					profileUrl: url,
				});
			})
			.catch((e) => {

			});

		}
		
	}

	render() {
		return (
			<React.Fragment>
				<div className={styles[this.state.containerClass]} onClick={this.handleSelect}>
					<img className={styles.candidateProfilePic} src={this.state.profileUrl||add_acc_logo} />
					<p className={styles.candidateInfo}>
						<span className={styles.candidateName}>{this.props.name}</span>
						<br />
						<span className={styles.candidateTitle}>{this.props.title}</span>
					</p>
				</div>
			</React.Fragment>
		);
	}
}

export default Candidate_Select;
