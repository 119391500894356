import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import firebase, { analytics } from 'firebase';
import styles from './Home.module.scss';
import ReactDOM from 'react-dom';
import computer from '../../assets/icons/computer-icon.png';
import ai from '../../assets/icons/ai-icon.png';
import team from '../../assets/icons/team-icon.png';
import bookmark from '../../assets/icons/bookmark-icon.png';
import newsletter from '../../assets/icons/newsletter-icon.png';
import certificate from '../../assets/icons/certificate-icon.png';
import students from '../../assets/people/students.png';
import employers from '../../assets/people/employers.png';
import universities from '../../assets/people/universities.png';
import portrait1 from '../../assets/people/anshika-khare.png';
import portrait2 from '../../assets/people/redhin-john.png';
import portrait3 from '../../assets/people/dhanush-gandham.png';
import portrait4 from '../../assets/people/lesa-brewer.png';
import Resource from '../../components/Resources/Resource.js';
import Resource2 from '../../components/Resources/Resource2.js';
import tech_united from '../../assets/partner_icons/tech_united.png';
import aPlan from '../../assets/partner_icons/aPlan.png';
import * as emailjs from 'emailjs-com'; 
import Calendar from '../../components/Calendar.js'
import SectionPricing from './SectionPricing.js'
import SectionTestimonials from './SectionTestimonials.js'
import SectionBlog from './SectionBlog.js'
import SectionFeatureCandidates from './SectionFeatureCandidates'
import SectionFeatureHiring from './SectionFeatureHiring'
import '../../assets/scss/paper-kit.scss'
// import {analytics} from '../../Firebase/firebase'

function validate(firstName, lastName, emailId, type, info) {
	return {
		firstName: firstName.length === 0,
		lastName: lastName.length === 0,
		emailId: emailId.length === 0 || !validateEmail(emailId),
		type: type.length === 6,
		info: info.length === 0,
	};
}

function validateEmail(emailId) {
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(emailId);
}

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			first: '',
			last: '',
			email: '',
			course: 'select',
			firstName: '',
			lastName: '',
			emailId: '',
			type: 'select',
			info: '',
			articles: [],
			loading: true,

			everFocusedFirstName: false,
			everFocusedLastName: false,
			everFocusedEmailId: false,
			everFocusedType: false,
			everFocusedInfo: false,
			inFocus: '',

			isError: false,
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCloseMessage = this.handleCloseMessage.bind(this);
		//this.sendMail = this.sendMail.bind(this);
		this.displayResourcesHome = this.displayResourcesHome.bind(this);
	}

	displayResourcesHome() {
		const { articles } = this.state;

		var articleList = [];
		var i;

		for (i = 0; i < 2; i++) {
			let title;
			let date;
			let id;
			let tags;
			let snippet;
			let readTime;

			try {
				title = articles[i].title;
				date = articles[i].date;
				id = articles[i].articleId;
				tags = articles[i].tags;
				snippet = articles[i].snippet;
				readTime = articles[i].readTime;
			} catch (err) {
				continue;
			}

			if (i % 2 == 0) {
				articleList.push(
					<Resource
						title={title}
						data={date}
						tags={tags}
						snippet={snippet}
						id={id}
						readTime={readTime}
					/>
				);
			} else {
				articleList.push(
					<Resource2
						title={title}
						data={date}
						tags={tags}
						snippet={snippet}
						id={id}
						readTime={readTime}
					/>
				);
			}
		}

		return articleList;
	}
	componentDidMount() {
		// let analytics = firebase.analytics(); 
		// analytics.logEvent('landing_page_visited')
		this.handleAnalytics('Landing'); 
		const db = firebase.firestore();
		db.collection('articles')
			.get()
			.then((querySnapshot) => {
				const data = querySnapshot.docs.map((doc) => doc.data());
				this.setState({
					articles: data,
					loading: false,
				});
			});
	}

	handleFormSubmit(evt) {
		this.setState({
			isError: true,
		});

		if (!this.canBeSubmitted()) {
			evt.preventDefault();
			return;
		}
		var x = document.getElementById('confirmSubmit');
		ReactDOM.findDOMNode(x).style.visibility = 'visible';
		ReactDOM.findDOMNode(x).style.opacity = '1';
		ReactDOM.findDOMNode(x).style.transition =
			'opacity 0.3s 0s, visibility 0s 0s';

		// added firebase database setup
		// adds details of all the boxes of contact form to firebase database collection "contactUs"
		const db = firebase.firestore();
		db.collection('contactUs').add({
			email: this.state.emailId,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			whoAreYou: this.state.type,
			message: this.state.info,
			timestamp: firebase.firestore.FieldValue.serverTimestamp(),
		});
		var templateParams = {
			to_name: this.state.firstName,
			receiver: this.state.emailId,
			full_name: this.state.firstName+" "+this.state.lastName,
			contact_body: this.state.info,
			type_of_user: this.state.type
		};
		 
		emailjs.send('mailgun', 'template_e5owm6r', templateParams, 'user_ry821S5CQJTzdU5eSuuAk')
			.then(function(response) {
			   console.log('SUCCESS!', response.status, response.text);
			}, function(error) {
			   console.log('FAILED...', error);
			});
	}

	// function to send the contact form's message as an email to ifo@MinosLabs.com
	// this doesn't automatically send email to us but opens an email client in user's computer
	// with To, Subject, and body already filled
	// User just has to click on "Send"
	sendMail() {
		var fName = document.getElementById('fName').value;
		var lName = document.getElementById('lName').value;
		var whoAreYou = document.getElementById('whoAreYou').value;
		var msg = document.getElementById('msg').value;

		var bodypartOne = 'Message: ' + msg + ' from ' + fName + ' ' + lName;
		var bodypartTwo = ', a ' + whoAreYou;
		console.log(bodypartTwo);

		window.location.href =
			'mailto:info@MinosLabs.com?subject=Contact Us Query&body=' +
			bodypartOne +
			bodypartTwo;
	}

	// after clicking on close sign, the form doesn't get cleared
	// I set the states of form fields to empty once you click on cancel sign
	// so you get an empty form again
	handleCloseMessage() {
		var x = document.getElementById('confirmSubmit');
		ReactDOM.findDOMNode(x).style.visibility = 'hidden';
		ReactDOM.findDOMNode(x).style.opacity = '0';
		ReactDOM.findDOMNode(x).style.transition =
			'opacity 0.3s 0s, visibility 0s 0.3s';

		this.setState({
			firstName: '',
			lastName: '',
			emailId: '',
			type: 'select',
			info: '',

			isError: false,
		});
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});

		if (name === 'course') {
			if (value === 'FullStack') {
				this.setState({
					link: '/exam-pt1',
				});
			} else if (value === 'Cyber') {
				this.setState({
					link: '/cyber-pt1',
				});
			}
		}
	}

	handleSubmit = (event) => {
		if (!this.canBeSubmitted()) {
			event.preventDefault();
		}

		return;
	};

	// Function to track analytics
	handleAnalytics = (event) => {

		let analytics = firebase.analytics(); 

		if(event == 'Landing'){
			console.log('Logging Landing Page Visit')
			analytics.logEvent('landing_page_visited')
		}
		return;
	};

	canBeSubmitted() {
		const errors = validate(
			this.state.firstName,
			this.state.lastName,
			this.state.emailId,
			this.state.type,
			this.state.info
		);
		const isDisabled = Object.keys(errors).some((x) => errors[x]);
		return !isDisabled;
	}

	render() {
		const errors = validate(
			this.state.firstName,
			this.state.lastName,
			this.state.emailId,
			this.state.type,
			this.state.info
		);
		const isDisabled = Object.keys(errors).some((x) => errors[x]);
		return (
			<React.Fragment>
				<section className={styles.heroHome}>
					<div className={styles.container}>
						<div className={styles.c1}>
							<h3>Grow Your Team.</h3>
							<h2>Expand Your Skills.</h2>
						</div>

						<div className={styles.c2}>
							<p>
							Whether you're looking to hire talent or looking to expand your skillset within Full-Stack Development or Cybersecurity, Reach out Today!
							</p>
						</div>
					</div>

					<div className={styles.applyNow}>
						<p>
							Join our upcoming cohorts for Full-Stack Development &
							Cybersecurity
						</p>
						<form>
							<input
								name="first"
								type="text"
								value={this.state.first}
								onChange={this.handleInputChange}
								placeholder="First name"
							/>

							<input
								name="last"
								type="text"
								value={this.state.last}
								onChange={this.handleInputChange}
								placeholder="Last name"
							/>

							<input
								className={styles.email}
								name="email"
								type="text"
								value={this.state.email}
								onChange={this.handleInputChange}
								placeholder="Email address"
							/>

							<select
								name="course"
								value={this.state.course}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Select Program
								</option>
								<option value="FullStack">
									Full-Stack Development
								</option>
								<option value="Cyber">Cybersecurity</option>
							</select>

							<Link
								to={{
									pathname: '/future-cohorts',
									state: {
										first: this.state.first,
										last: this.state.last,
										email: this.state.email,
										course: this.state.course,
										link: this.state.link,
									},
								}}
							>
								<button>
									<span>Apply Now</span>
								</button>
							</Link>
						</form>
					</div>
				</section>

				<hr></hr>

				<div className={styles.userContainer}>
					<div style={{ backgroundColor: '#eaedf2' }} className={styles.userCol}>
						<div className={styles.container}>
							<img src={students} alt="students" />
							<h3>Students</h3>
							<p className={styles.addPadding}>
								Finding a job post-graduation can be difficult. At Minos
								Labs, we’re here to help recent college graduates refine
								their professional skills and land their first job with
								a Fortune 1000 organization. Accelerate your career
								through real world applications, mentorship from
								industry experts, and exposure to relevant technical
								coursework.
							</p>
						</div>
						<Link to="/about">
							<button class="hvr-sweep">LEARN MORE</button>
						</Link>
					</div>

					<div style={{ backgroundColor: '#f6f6ff' }} className={styles.userCol}>
						<div className={styles.container}>
							<img src={employers} alt="employers" />
							<h3>Employers</h3>
							<p>
								Minos Labs partners with and serves Fortune 1000
								organizations to better understand and meet their hiring
								needs and business goals. By developing a pipeline of
								industry focused talent within Cybersecurity and
								Full-stack development, we ensure that our applicants
								leave our programs as value adding associates. We
								pre-screen, train, and assess potential talent through
								our 6-Week Career Acceleration Program.
							</p>
						</div>
						<Link to="/Corporate">
							<button class="hvr-sweep">LEARN MORE</button>
						</Link>
					</div>

					<div style={{ backgroundColor: '#eaedf2' }} className={styles.userCol}>
						<div className={styles.container}>
							<img src={universities} alt="universities" />
							<h3>Universities</h3>
							<p>
								By developing, not only the technology skills of our
								associates, but offering them executive coaching,
								mentorship, and networking opportunities. We aim to
								accelerate the careers of recent graduates to help them
								build skills and connections that will serve them
								throughout their lifetime.
							</p>
						</div>
						<Link to="/university">
							<button class="hvr-sweep">LEARN MORE</button>
						</Link>
					</div>
				</div>

				<SectionPricing />

				{/* <section className={styles.ourCurriculum}>
					<div className={styles.containerMain}>
						<h1>Our Curriculum</h1>
						<p>
							Our goal is to equip Minos Labs Associates with industry
							knowledge and expertise needed to succeed
						</p>
					</div>

					<div className={styles.benefitsRow}>
						<div className={styles.benefitsCol1}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={computer} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>FULL-STACK DEVELOPMENT</h3>
									<p>
										Enhance your skills and be able to work across a
										full stack with our fully digital sessions, led by
										industry professionals
									</p>
								</div>
							</div>
						</div>

						<div className={styles.benefitsCol2}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={ai} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>CYBERSECURITY</h3>
									<p>
										Gain exposure to industry leading technologies and
										frameworks while simultaneously applying what you
										learn to real world projects and scenarios
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className={styles.benefitsRow}>
						<div className={styles.benefitsCol1}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={bookmark} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>REAL WORLD SIMULATION</h3>
									<p>
										Apply the knowledge you gain, using industry
										leading API’s in a full scale, Agile team project
									</p>
								</div>
							</div>
						</div>

						<div className={styles.benefitsCol2}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={newsletter} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>CAREER COACHING</h3>
									<p>
										Perfect your resume and interview skills with our
										in house team of executive coaches who work
										everyday as hiring managers for Fortune 1000
										companies
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className={styles.benefitsRow}>
						<div className={styles.benefitsCol1}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={team} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>ADVISORY NETWORK</h3>
									<p>
										Gain access to, and hear from C-Level executives
										across multiple industries and build connections
										that will serve you for a lifetime
									</p>
								</div>
							</div>
						</div>

						<div className={styles.benefitsCol2}>
							<div className={styles.container}>
								<div className={styles.image}>
									<img src={certificate} alt="QR" />
								</div>

								<div className={styles.text}>
									<h3>DIGITAL PROGRAM</h3>
									<p>
										Enter the workforce, prepared for remote work with
										our industry tailored process, designed to help
										you accelerate your career as efficiently as
										possible
									</p>
								</div>
							</div>
						</div>
					</div>
				</section> */}

				{/* <SectionBlog /> */}
				{/* <div className={styles.resources_home}>
					<div className={styles.col_home}>
						<h1 className={styles.col_home_header}>What's New?</h1>
						<h3 className={styles.col_home_subheader}>
							Follow Along For The Latest From The Minos Labs Blog
						</h3>
					</div>
					<div className={styles.col_home}>{this.displayResourcesHome()}</div>
					<div className={styles.col_home}>
						<Link
							to="/resources"
							style={{ display: 'flex', justifyContent: 'center' }}
						>
							<button classNam={styles.r_button}>View All</button>
						</Link>
					</div>
				</div> */}

				{/* <hr></hr>

					<SectionFeatureCandidates />
					
				<hr></hr>

					<SectionFeatureHiring /> */}

				

					<SectionTestimonials />
                    {/* <section className ={styles.sectionTestimonials}> 
                        <div className = {styles.container}>
                            <h1>Testimonials</h1>
                            <p>Here what our practitioners and associates have to say about Minos Labs</p>
                        </div>
                        <div className ={styles.testimonials}>
                            <div className={styles.testimonial}>
                                <div className={styles.person}>                
                                    <div className={styles.profile}>
                                        <img src={portrait3} alt="portrait1" className={styles.portrait}/>
                                        <div className={styles.info}>
                                            <h5>Dhanush Gandham</h5>
                                            <h6>Full-Stack Developer, AT&T</h6>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p>"Minos Labs really helped me land a job so much faster. While my friends were sending hundreds of applications out, I went on 2 interviews and got my full-time offer!"</p>
                            
                                </div>
                            </div>
                        
                            <div className={styles.testimonial}>
                                <div className={styles.person}>
                                    <div className={styles.profile}>
                                        <img src={portrait2} alt="portrait1" className={styles.portrait}/>
                                        <div className={styles.info}>
                                            <h5>Redhin John</h5>
                                            <h6>Minos Labs Cybersecurity Cohort - Winter 2020-2021</h6>
                                        </div>
                                    </div>
                                    
                                    <hr></hr>
                                    <p>"The program has been extremely beneficial to me! All the instructors are exceptionally skilled and knowledgeable in cybersecurity and the weekly coaching sessions 
										really helped to improve soft skills and gain insight into the industry." </p>          
                                </div>

                            </div>
                        
                            <div className={styles.testimonial}>
                                <div className={styles.person}>
                                    <div className={styles.profile}>
                                        <img src={portrait4} alt="portrait1" className={styles.portrait}/>
                                        <div className={styles.info}>
                                            <h5>Lesa Brewer</h5>
                                            <h6>Minos Labs Cybersecurity Cohort - Winter 2020-2021</h6>
                                        </div>
                                    </div>
                                    
                                    <hr></hr>
                                    <p>"I didn't have the resources or opportunity to advance in the field of cybersecurity where I live. Minos Labs expanded my learning and experience! The instructors, staff, and 
										advisors have inspired me to stay focused and work hard to enter the field.

 </p>          
                                </div>

                            </div>
                
                            <div className={styles.testimonial}>

                                <div className={styles.person}>
                                
                                    <div className={styles.profile}>
                                        <img src={portrait1} alt="portrait1" className={styles.portrait}/>
                                        <div className={styles.info}>
                                            <h5>Anshika Khare</h5>
                                            <h6>Verizon</h6>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <p>"Minos Labs really helps their students develop both technical and professional skills that will serve them throughout their careers!" </p>
                                
                                </div>

                            </div>
                        </div>
                </section> */}
                
			


				<section className={styles.contactUsHome} id="contactUs">
					<div className={styles.contactUsRight}>
						<div className={styles.applyNow2}>
							<h1>
								Join Our Upcoming Cohort <br></br>
							Accelerate Your Career{' '}
							</h1>
							<Link to="/future-cohorts">
								<button>
									<span>Apply Now</span>
								</button>
							</Link>
						</div>
					</div>
					<div className={styles.contactUsLeft}>
						<div className={styles.blockHome}>
							<div className={styles.confirmSubmit} id="confirmSubmit">
								<button className={styles.close} onClick={this.handleCloseMessage}>
									<p> &#10006;</p>
								</button>
								<div className={styles.message}>
									<h2>Thank You</h2>
									<p>
										A Minos Labs team member will get back to you as
										soon as possible!
									</p>
								</div>
							</div>
							<div className={styles.container}>
								<h2>Contact Us</h2>
								<p>
									{' '}
									Have any questions? <br /> Fill out the form
									and we'll get back to you as soon as we can!
								</p>
								<form id="contactForm" onSubmit={this.handleSubmit}>
									<input
										class={(errors.firstName && this.state.isError) ? styles.errorFirst : styles.first}
										id="fName"
										name="firstName"
										type="text"
										placeholder="First name"
										value={this.props.firstName}
										onChange={this.handleInputChange}
									/>

									<input
										class={(errors.lastName && this.state.isError) ? styles.errorLast : styles.last}
										name="lastName"
										id="lName"
										type="text"
										placeholder="Last name"
										value={this.state.lastName}
										onChange={this.handleInputChange}
									/>

									<input
										class={(errors.emailId && this.state.isError) ? styles.error : styles.email}
										name="emailId"
										id="emailId"
										type="text"
										placeholder="Email address"
										value={this.state.emailId}
										onChange={this.handleInputChange}
									/>

									<select
										name="type"
										class={(errors.type && this.state.isError) ? styles.errorType : styles.type}
										id="whoAreYou"
										value={this.state.type}
										onChange={this.handleInputChange}
									>
										<option value="select" selected disabled hidden>
											Are you a...?
										</option>
										<option value="Student">Student</option>
										<option value="Corporate">Corporation</option>
										<option value="University">University</option>
										<option value="Other">Other</option>
									</select>

									<textarea
										class={(errors.info && this.state.isError) ? styles.error : ''}
										id="msg"
										name="info"
										placeholder="Your Message..."
										value={this.state.info}
										onChange={this.handleInputChange}
									/>

									<button
										type="button"
										class="hvr-sweep"
										onClick={this.handleFormSubmit}
									>
										SUBMIT
									</button>
								</form>
							</div>
						</div>
					</div>
				</section>
				{/* <hr></hr>
				<section className={styles.minos_partners} style={{minHeight: "100px", margin: "0px", paddingTop: "0px"}}>
                    
					<div>
						<div style={{ display: "flex"}}>
						<h1 style={{fontSize: '45px'}}>Our Partners</h1>
						<div style={{margin:'auto'}}>
							<img src={aPlan} />
							<img src={tech_united} />
						</div>
						<h1 style={{fontSize: '45px',visibility:'hidden'}}>Our Partners</h1>
						</div>
						
					</div>
				
			</section> */}

				{/* <section className="calendar">
					<Calendar />
				</section> */}
			</React.Fragment>
		);
	}
}

export default Home;
