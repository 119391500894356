import React from 'react';
import styles from './AddPartner.module.scss';
import Dashboard from '../../components/AdminDashboard';
import firebase from 'firebase';
import UserContext from '../../User/User';
import Education from '../../components/Education';
import Experience from '../../components/Experience';
import { MDBDataTableV5 } from 'mdbreact';
import * as emailjs from 'emailjs-com'; 
import moodle_client from "moodle-client";
import axios from 'axios';
import Candidate from '../../components/Candidate';
const CryptoJS = require('crypto-js');

const INITIAL_STATE = {
	first: '',
	last: '',
	email: '',
	type: 'select',
	companyName: "",
	phoneNumber: "",
	linkedin: '',
};

class AdminContactMessages extends React.Component {
	constructor() {
		super();

		this.state = {
			...INITIAL_STATE,
			partnerId:0,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
	}

	static contextType = UserContext;

	encrypt(text){
		return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
	};
	
	handleInputChange(event) {

		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});

	}

	addPartner = async (event) => {
		//uses firebase

		event.preventDefault();
		const templateId = this.state.courseType;

		const db = firebase.firestore();
		var storage = firebase.storage();

		var templateParams = {
			to_name: this.state.first,
			receiver: this.state.email,
			username: this.state.email,
			
			password: this.state.first+this.state.last+"2!M"
		};
		 
		emailjs.send('mailgun', 'template_dw8zd0u', templateParams, 'user_ry821S5CQJTzdU5eSuuAk')
			.then(function(response) {
			   console.log('SUCCESS!', response.status, response.text);
			}, function(error) {
			   console.log('FAILED...', error);
			});

		let partnerId = db.collection('corporate').doc().id;
		await db.collection('corporate')
            .doc(partnerId)
            .set({})
		this.setState({ partnerId: partnerId })

		await db.collection('corporate')
			.doc(this.state.partnerId.toString())
			.set({
				first: this.state.first,
				last: this.state.last,
				email: this.state.email,
				companyName: this.state.companyName,	
				phoneNumber: this.state.phoneNumber,
				linkedin: this.state.linkedin,
				type: this.state.type,
				userID: this.state.partnerId,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				password: this.encrypt(this.state.first + this.state.last+"2!M"),
			});

			window.location.reload();

	};
	

	render() {
		//User data
		const {
			first,
			last,
			email,
			type,
			companyName,
			phoneNumber,
			linkedin
		} = this.state;
		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="View_Candidate" />

						{/*right expands when dashboard is closed*/}
						<div class="right">
							<div class="topBar">
								<div class="pageName">
									<h1>Partners</h1>
								</div>
							</div>

							{/*code here*/}
							<div className={styles.inner}>
								<div className={styles.block}>
								<div className={styles.blockHeader}>
									<h1>Add Partner</h1>
									
								</div>
								<div className={styles.formContainer}>
								<form>
									<input
									name="first"
									value={first}
									className={styles.inputField}
									onChange={this.handleInputChange}
									type="text"
									placeholder="First Name"
									/>


									<input
										name="last"
										value={last}
										className={styles.inputField}
										onChange={this.handleInputChange}
										type="text"
										placeholder="Last Name"
									/>
										<br></br>
									<input
										className={styles.email}
										name="email"
										value={email}
										onChange={this.handleInputChange}
										type="text"
										placeholder="Email Address"
									/>
									{/* <br></br> */}
									<select
										name="type"
										value={type}
										onChange={this.handleInputChange}
									>
										<option value="select" selected disabled hidden>
											Please Select Type
										</option>
										<option value="HR">
											HR
										</option>
										<option value="Corporate">Corporate</option>
										<option value='University'>University</option>
									</select>

									<br></br>
									<br></br>

									<div style={{ display: 'flex' }}>
										<h1 className={styles.resumeTitle}>Company Details</h1>
										
									</div>
									<div>
										<input
											name="companyName"
											value={companyName}
											className={styles.inputField}
											onChange={this.handleInputChange}
											type="text"
											placeholder="Company Name"
										/>

										<input
											name="phoneNumber"
											value={phoneNumber}
											className={styles.inputField}
											onChange={this.handleInputChange}
											type="text"
											placeholder="Phone Number"
										/>

										<input
											name="linkedin"
											value={linkedin}
											// className={styles.inputField}
											style={{width: '100%'}}
											onChange={this.handleInputChange}
											type="text"
											placeholder="www.linkedin.com/in/xxx"
										/>
									</div>
									{/* {this.displayEducation()} */}


									<br></br>
									<button onClick={this.addPartner}>Add</button>
								</form>
								</div>
								</div>
							</div>

						</div>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default AdminContactMessages;
