import React from 'react'
import { Link } from 'react-router-dom';
import "./Resources.css";
import firebase from 'firebase';
import ArticleSlideShow from './ArticleSlideShow';
import "./ArticleSlideShow.css";
class ArticleSlide extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            title: this.props.title,
            date: this.props.date,
            tags: this.props.tags,
            snippet: this.props.snippet,
            id: this.props.id,
            readTime: this.props.readTime,
            profileUrl: "",
        }
        this.slideClick =this.slideClick.bind(this);
        this.handleLink =this.handleLink.bind(this);
    }


    handleLink(){
        let articleUrl = "/articles/" + this.state.id;
        window.location.href = articleUrl;
    }

    slideClick(num){

        this.props.changeSlide(num);
    }
    

    componentDidMount(){
      var storage = firebase.storage().ref();

          const articleId = this.props.id+'.png';

          console.log(articleId);


          
            storage.child('articlePics').child(articleId).getDownloadURL().then((url) => {
                this.setState({
                    profileUrl: url,
                })
            }).catch(e => {
                console.log(e);
                storage.child('profilepics').child('noprofilepic.jpg').getDownloadURL().then((url2) => {
                    this.setState({
                        profileUrl: url2,
                    })
                })
            })
    }
  


render() {

  return (
    <React.Fragment>
                 

        <div class ="articleSlide">
            <div class ="slideBlock">
                <div class="slideImgContainer">
                    <img class="slideImg" src={this.state.profileUrl} alt="Article Image"/>
                </div>  
                <h1>{this.state.title}</h1>
                <div class="slideShowButton">
                
                <button onClick={this.handleLink}>Read More</button>
                
                </div>

                <a class="prev" onClick={() => this.slideClick(-1)}>&#10094;</a>
                <a class="next" onClick={() => this.slideClick(1)}>&#10095;</a>
            </div>
        </div> 

    </React.Fragment>
  )
}
}
export default ArticleSlide;
