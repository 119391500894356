/**
 * This is the main page for the Hiring Requirement
 * Renders the hiring requirement in a table
 * and for each requirement, renders list of skills which can be edited with
 * with add/delete options
 */
import React, { Component } from 'react'
import firebase from 'firebase';
import styles from "./HiringRequirements.module.scss";
import UserContext from '../../User/User';
import { ActionButtons } from './ActionButtons'
import add_sign from '../../assets/hiring_requirement/plus.png';
import Skills from './Skills';
import usState from './stateList';
import Dashboard from '../../components/Dashboard';
import { Multiselect } from "multiselect-react-dropdown";
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css';


export class HiringRequirements extends Component {

    constructor(props) {
        super(props)
        this.state = {
            skillsArray:[],
            requirements: [],
            location: "",
            position: "Select",
            urgency: "Immediately",
            hires: 1,
            skills:[],
            errorMessage: "",
            selectedPosition: [],
            usStates: usState(),
            selectedSkills:[],
            jobTitles:[],
        };
        // This style is for the Multiselect dropdown box
        // This is the CSS customization
        // this.style = {
        //     multiselectContainer: { // To change css for multiselect (Width,height,etc..)
        //     margin:"0px 11px",
        //     fontStyle:"bold",
        //     fontWeight:"300px",
        //     positon:"fixed",
        //       },
        //       searchBox: { // To change search box element look
        //         border: "1px solid #4bace9",
        //         width:"246px",
        //         height:"34px",
        //         padding:"0px",
        //         borderRadius:"10px",
                
        //       },
        //       inputField: { // To change input field position or margin
        //       },
        //       chips: { // To change css chips(Selected options)
        //         margin:"5px 5px 5px 5px"
               
        //       },
        //       optionContainer: { // To change css for option container 
        //         border: "1px solid",
        //         backgroundColor:"transparent"
        //       },
        //       option: { // To change css for dropdown options
        //         fontsize:"5px",
        //         lineHeight:"3px"
        //       },
             
        //   };
          
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.handleOnDelete = this.handleOnDelete.bind(this);
        this.handlePosSelect = this.handlePosSelect.bind(this);
        // this.onChange = this.onChange.bind(this);
        // this.onRemove =this.onRemove.bind(this);
        // this.onSearch = this.onSearch.bind(this);

    }


    //Basic state update for all input components
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    /**
     * handles selection of a particular position to render the Skills.js
     * component with the appropriate props
     * @param {requirement_id} id 
     */
    async handlePosSelect(id) {
       
        var selectedReq = this.state.requirements.filter((req) => {
            return req[1] === id;
        })
       

        this.setState({
            selectedPosition: selectedReq[0]
        })
    }
    
    /**
     * Updates the given requirement in the firebase hiringreq collection for
     * the given requirement_id
     * @param {requirement_id} id 
     * @param {updated object for the requirement} newObj 
     */
    async handleOnEdit(id, newObj) {
       
        var prevReq = [...this.state.requirements]
        
        var idx = 0
        var rowToEdit = {}
        for (let i = 0; i < prevReq.length; i++) {
            var req = prevReq[i]
            if (req[1] === id) {
                rowToEdit = req
                idx = i
            }
        }

        rowToEdit[0] = newObj
        prevReq[idx] = rowToEdit
        
        const db = firebase.firestore();
        const reqRef = db.collection('corporate').doc(this.context.userID).collection('hiringReqs').doc(id);
        await reqRef.update(newObj)

        this.setState({
            requirements: prevReq
        })

    }

    /**
     * Deleted the the given requirement in the firebase hiringreq collection for
     * the given requirement_id
     * @param {requirement_id} id 
     */
    async handleOnDelete(id) {
       
        var prevReq = [...this.state.requirements]
        var idx = -1
        for (let i = 0; i < prevReq.length; i++) {
            var req = prevReq[i]
            if (req[1] === id) {
                idx = i
            }
        }
        if (idx > -1) {
            prevReq.splice(idx, 1)
        }

        const db = firebase.firestore();
        const reqRef = await db.collection('corporate').doc(this.context.userID).collection('hiringReqs').doc(id);
        await reqRef.delete()


        this.setState({
            requirements: prevReq
        })

    }

    /**
     * This function gets triggered when add button is clicked to add a new
     * requirement 
     * @returns Null
     */
    async handleSubmit() {

        if (this.state.position === "Select" ||
            this.state.location === "" ||
            this.state.urgency === "" ) {

            this.setState({
                errorMessage: "Please make sure you entered all valid inputs"
            })
            return
        }


        else {
            var newObj = {
                field: this.state.position,
                location: this.state.location,
                resources: this.state.hires,
                timeline: this.state.urgency,
                skills: [...this.state.selectedSkills],

            }
            //Nothing
            const db = firebase.firestore();
            const reqRef = await db.collection('corporate').doc(this.context.userID).collection('hiringReqs');
            const status = await reqRef.add(newObj)
           

            var prevReq = [...this.state.requirements]
            prevReq.push([newObj, status.id])

            this.setState({
                requirements: prevReq,
                errorMessage: "",
                location: "",
                position: "Select",
                urgency: "",
                hires: 1,
                selectedSkills:[],

            })

        }


    }

 
    static contextType = UserContext;
    async componentDidMount() {
        const context = this.context;
        const zip = (a, b) => a.map((k, i) => [k, b[i]]);
        const zippedreq = zip(context.requirements, context.reqDocID)
       
        
        //const db = firebase.firestore();
        // const skillCollection = db.collection('skills');
		// const skillCollectionData = await skillCollection.get();

		// var skillList = [];
        

		// skillCollectionData.forEach((skill) => {
		// 	skillList = skill.data().skillList;
        //     console.log("hi aneri",skill.data().skillList)
		// });

        

        console.log("job",context.userInfo["skills"])
        const jobCollectionData = context.userInfo["jobTitle"]
        
        let tempJob = jobCollectionData.map(job => {
            return { "key": job, "text": job, "value": job }
        })

        const skillsCollectionData = context.userInfo["skills"]
        
        let tempSkill = skillsCollectionData.map(skillList => {
            return { "key": skillList, "text": skillList, "value": skillList }
        })
        console.log("job",tempSkill)

        this.setState({
            requirements: zippedreq,
            skillsArray:tempSkill,
            jobTitles:tempJob,
        })
    }

    render() {
        let { userID, logoutUser, setUser, loginType, userInfo } = this.context;
        const { jobTitles, skillsArray } = this.state;
      
        return (
            <div className={styles.borderbox}>
                <div className="hero-university2"></div>
                <div className="candidate_process">
                    <div className="col-container">
                    <Dashboard pageName="View_Candidate" />
                        {/*right expands when dashboard is closed*/}
                        <div className="right">
                            <div class="topBar">
                                <div class="pageName">
                                    <h1>Hiring Requirements</h1>
                                </div>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.inner}>
                                    <div className={styles.calendar}>
                                        <div>
                                            <table className="table table-borderless" >
                                                <thead>
                                                    <tr>
                                                        <th className={styles.hrreqTh} scope="col">Position</th>
                                                        <th className={styles.hrreqTh} scope="col">Location</th>
                                                        <th className={styles.hrreqTh} scope="col">Duration</th>
                                                        <th className={styles.hrreqTh} scope="col">Positions Available</th>
                                                        <th className={styles.hrreqTh} scope="col">Skills</th>
                                                        <th style={{display: loginType==='HR' ? 'none' : ''}} className={styles.hrreqTh} scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.requirements && this.state.requirements.map((req) => (
                                                            <tr key={req[1]}>
                                                                <ActionButtons handleOnEdit={this.handleOnEdit} requirements={req} skillList={this.state.skillsArray}
                                                                    handleOnDelete={this.handleOnDelete} handlePosSelect={this.handlePosSelect}
                                                                ></ActionButtons>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* this is the edit field display */}
                                        <div style={{display: loginType==='HR' ? 'none' : ''}} className={styles.hrreqToolbar}>
                                            <div style={{width:"246px"}}>
                                                {/* <select
                                                    name="position"
                                                    value={this.state.position}
                                                    onChange={this.handleInputChange}
                                                    className={styles.toolbarInput}
                                                >
                                                    <option value="Select">
                                                        Select Field
													</option>
                                                    <option value="Full-Stack">
                                                        Full-Stack
													</option>
                                                    <option value="Cybersecurity">
                                                        Cybersecurity
													</option>
                                                </select> */}
                                                <Dropdown placeholder="Select Field" fluid multiple search selection options={jobTitles} onChange={this.handleInputChange} >

                                                </Dropdown>
                                            </div>
                                            <div>
                                                {/* <input type="text" name="location" value={this.state.location} onChange={this.handleInputChange} placeholder="Location"></input> */}

                                                <input
                                                    autoComplete="on"
                                                    name="location"
                                                    onChange={this.handleInputChange}
                                                    type="text"
                                                    className={styles.toolbarInput}
                                                    value={this.state.location}
                                                    placeholder="Select Location"
                                                    list="states"

                                                />


                                                <datalist id="states">
                                                    {this.state.usStates.map(currState => (
                                                        <option key={currState} value={currState}></option>
                                                    ))}
                                                </datalist>


                                            </div>
                                            <div>
                                                <select
                                                    name="urgency"
                                                    value={this.state.urgency}
                                                    onChange={this.handleInputChange}
                                                    className={styles.toolbarInput}
                                                >
                                                   
                                                    <option value="Immediately">
                                                        Immediately
													</option>
                                                    <option value="4 Weeks">4 Weeks</option>
                                                    <option value="8 Weeks">8 Weeks</option>
                                                    <option value="12 Weeks">
                                                        12 Weeks
													</option>
                                                </select>
                                            </div>
                                            <div>
                                                <input
                                                    type="number"
                                                    name="hires"
                                                    min="1"
                                                    value={this.state.hires}
                                                    placeholder="1"
                                                    onChange={this.handleInputChange}
                                                    className={styles.toolbarInput}
                                                ></input>
                                            </div>
{/* 
                                            <div >
                                           
                                            <Multiselect
                                           style={this.style}
                                            placeholder="Skills" showArrow options={plainArray} isObject={false} 
                                            onSelect={this.onSelect} selectedValues={this.state.selectedSkills}
                                            onRemove={this.onRemove} hidePlaceholder={true} onSearch={this.onSearch}
                                            keepSearchTerm={true}
                                            />
                                            
                                            </div> */}
                                            <div style={{width:"246px"}}>
                                            <Dropdown placeholder='Skills' 
                                            fluid 
                                            multiple 
                                            search 
                                            selection 
                                            options={skillsArray}
                                           />
                                            </div>
                                           

                                           {/* <div style={{width:"246px"}}>
                                           <Skills></Skills>
                                           </div>
                                                      */}

                                            <img style={{margin:"0px 9px"}}src={add_sign} alt="add requirement" onClick={this.handleSubmit} />
                                        </div>
                                        <div>
                                            {this.state.errorMessage}
                                        </div>
                                    </div>

                                    {/* <div className={styles.rightContainer}>
                                        <div className={styles.block}>
                                            <div className={styles.blockInner}>
                                                <div className={styles.blockHeader}>
                                                    <h1>Skills</h1>
                                                    <h2>Specify Desired Skills for Each Position</h2>
                                                    <Skills selectedPosition={this.state.selectedPosition}></Skills>



                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HiringRequirements
