import React from 'react';
import { Link } from 'react-router-dom';
import Dashboard from '../../components/DashboardUniv';
import './EditManageAccountUniv.module.scss';
import add_acc_logo from '../../assets/manage account/add_acc_logo.png';
import firebase from 'firebase';
import UserContext from '../../User/User';


class EditManageAccountUniv extends React.Component{
    constructor(){
        super();

        this.state = {
            name: "",
            company: "",
            role: "",
            email: "",
            image: null,
            url: '',
            loading:true
           
        }
        this.handleChange = this
        .handleChange
        .bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toResetPage = this.toResetPage.bind(this);
    }

    static contextType = UserContext;

    handleClick = () => {
        this.inputElement.click();
    }

    handleChange = e => {
        if (e.target.files[0]) {
          const image = e.target.files[0];
          this.setState({
              image: image
            },this.handleUpload);
        }
      }

      handleUpload = () => {
        
        const context = this.context;
        var storage = firebase.storage();
          const {image} = this.state;
          const uploadTask = storage.ref(`corporatePics/${context.userID}`).put(image);
          uploadTask.on('state_changed', 
          (snapshot) => {
            // progrss function ....
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({progress});
          }, 
          (error) => {
               // error function ....
            console.log(error);
          }, 
        () => {
            // complete function ....
            storage.ref('corporatePics').child(context.userID).getDownloadURL().then(url => {
                console.log(url);
                this.setState({url});
            })
        });
      }


    toResetPage(){
        window.location.href = "/reset";
    }

    componentDidMount(){
        this.initializeAccInfo();
    }

    initializeAccInfo(){
        const db = firebase.firestore();
        const context = this.context;

        db.collection('corporate').doc(context.userID)
        .get()
        .then(snap => {
            const data = snap.data();
            this.setState({
                name: data.first + " " + data.last,
                company: data.company,
                role: data.companyTitle,
                email: data.email,
                loading: false,
            })
        })

        var storage = firebase.storage();
        storage.ref('corporatePics').child(context.userID).getDownloadURL().then(url => {
            
            this.setState({url});
        })
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });

    }

    handleFormSubmit = (event) => {
        event.preventDefault();

        const name = this.state.name;
        const company = this.state.company;
        const role = this.state.role;
        const email = this.state.email;
        var firstName = "";
        var lastName = "";

        const firstSpace = name.indexOf(" ");

        if(firstSpace !== -1 && firstSpace !== name.length - 1 ){
            
            firstName = name.substring(0, firstSpace);
            lastName = name.substring(firstSpace + 1);
        }
        else{
            firstName = name;
        }

        const db = firebase.firestore();
        const context = this.context;
        var userRef = db.collection('corporate').doc(context.userID);

        userRef.update({
            first: firstName,
            last: lastName,
            company: company,
            companyTitle: role,
            email: email,
        })

        this.props.history.push("./Manage_Account_Univ");

    }


    static contextType = UserContext;
    
    render(){

        //User data
        let {userID,logoutUser,setUser} = this.context;

        return(
            <React.Fragment>
                <section class="hero-university2"></section>
                    <section class="candidate_process">
                        <section class="col-container">
                           <Dashboard />

                        {/*right expands when dashboard is closed*/}
                        <div class="right">
                            <div style={this.state.loading? {display:'none'}:{} } class="edit_manage-acc-container">
                                
                            <div class="editAccountTop">
                            
                            <input ref={input => this.inputElement = input} type="file" id="my_file" onChange={this.handleChange} style={{display:'none'}} /> 
                            <img onClick={this.handleClick} src={this.state.url || add_acc_logo} class="edit_logo_img" alt="Uploaded images" style={{height:"100px",width:'100px'}}/>
                            <div style={{marginLeft:'15px'}}><h1>Edit Profile</h1><p>Edit your account information by clicking the white boxes or the profile image. </p></div>
                            </div>

                                
                                <br/><br/>
                                <div class="mang-acc-notif-hide">Please enter a valid email address</div>
                                <form onSubmit={this.handleFormSubmit}>
                                <div class="accountLabelContainer">
                                <h3>Name:</h3>
                                    <input 
                                        type="text" 
                                        name="name"
                                        value={this.state.name}
                                        onChange = {this.handleInputChange} 
                                    />
                                </div>

                                <div class="accountLabelContainer">
                                <h3>University:</h3>
                                    <input 
                                        type="text" 
                                        name="company"
                                        value={this.state.company} 
                                        onChange = {this.handleInputChange}
                                    />
                                </div>

                                <div class="accountLabelContainer">
                                <h3>Position:</h3>
                                    <input 
                                        type="text" 
                                        name="role"
                                        value={this.state.role} 
                                        onChange = {this.handleInputChange}
                                    />
                                </div>

                                <div class="accountLabelContainer">
                                <h3>Email:</h3>
                                    <input 
                                        type="text" 
                                        name="email"
                                        value={this.state.email} 
                                        onChange = {this.handleInputChange}
                                    />
                                </div>

                                    <div class="wrap_button_acc0">
                                    
                                        <button type="button"  onClick={this.toResetPage} class="reset_button_edit hvr-sweep">Reset Password</button>
                                        
                                        <button type="button" class="reset_change_button_edit hvr-sweep">Reset</button>
                                    </div>
                                    <div class="wrap_button_acc">
                                    <button type="submit" class="save_button_edit hvr-sweep">Save</button>
                                    </div>
                                </form>
                            </div>    
                        </div>
                    </section>
                </section>
            </React.Fragment>
        )
    }
}

export default EditManageAccountUniv;
