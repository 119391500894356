import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
import portrait3 from '../../assets/people/dhanush-gandham.png';
import portrait2 from '../../assets/people/redhin-john.png';
import portrait1 from '../../assets/people/anshika-khare.png';
import backgroundImage from '../../assets/img/sections/uriel-soberanes.jpg'
// core components
// const items = [
//   {
//     src:
//       "https://s3.amazonaws.com/creativetim_bucket/photos/110862/thumb.?1482812727",
//     content: (
//       <CardBody>
//         <h5 className="card-description">
//           "I'm newer to the front-end... With my creative side lacking in
//           experience this really put me in the right spot to speed through the
//           fast lane. I love this Design kit so much!"
//         </h5>
//         <CardFooter>
//           <CardTitle tag="h4">Chase Jackson</CardTitle>
//           <h6 className="card-category">Web Developer</h6>
//           <div className="card-stars">
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star" />
//           </div>
//         </CardFooter>
//       </CardBody>
//     ),
//     altText: "",
//     caption: "",
//   },
//   {
//     src:
//       "https://s3.amazonaws.com/creativetim_bucket/photos/134607/thumb.?1487680276",
//     content: (
//       <CardBody>
//         <h5 className="card-description">
//           "Love the shapes and color palette on this one! Perfect for one of my
//           pet projects!"
//         </h5>
//         <CardFooter>
//           <CardTitle tag="h4">Robin Leysen</CardTitle>
//           <h6 className="card-category">Web Developer</h6>
//           <div className="card-stars">
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star" />
//           </div>
//         </CardFooter>
//       </CardBody>
//     ),
//     altText: "",
//     caption: "",
//   },
//   {
//     src:
//       "https://s3.amazonaws.com/creativetim_bucket/photos/125268/thumb.jpeg?1497799215",
//     content: (
//       <CardBody>
//         <h5 className="card-description">
//           "Love it. Use it for prototypes and along with Paper Dashboard."
//         </h5>
//         <CardFooter>
//           <CardTitle tag="h4">Cristi Jora</CardTitle>
//           <h6 className="card-category">Web Developer</h6>
//           <div className="card-stars">
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star mr-1" />
//             <i className="fa fa-star" />
//           </div>
//         </CardFooter>
//       </CardBody>
//     ),
//     altText: "",
//     caption: "",
//   },
// ];

function SectionTestimonials() {
  // carousel - TESTIMONIALS 2
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  // const next = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
  //   setActiveIndex(nextIndex);
  // };
  // const previous = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
  //   setActiveIndex(nextIndex);
  // };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div
        className="section section-testimonials cd-section"
        id="testimonials"
        style={{
          paddingTop: '0px', 
          paddingBottom: '0px',
          //height: '100vh'
        }}
      >
        {/* ********* TESTIMONIALS 1 ********* */}
        <div
          className="testimonials-1 section-image"
          style={{
            backgroundImage:
              "url(https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80)",
              height: "100vh"
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                {/* <h2 className="title" style={{fontSize: '2.8em', fontWeight: '300'}}>Our Clients Love Us</h2> */}
                <CardTitle tag="h2" style={{color: 'white', fontSize: '2.8em', fontWeight: '300', margin: '30px', fontFamily: "SFMono-Regular, Helvetica"}}>Our Associates Love Us</CardTitle>
                <h5 className="description">
                  Hear what our practitioners and associates have to say about Minos Labs
                </h5>
                <br />
                <br />
              </Col>
             
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="4">
                <Card className="card-testimonial" style={{height:"550px"}}>
                  <div className="card-icon" style={{height:"100px"}}>
                    <i className="fa fa-quote-right" />
                  </div>
                  <CardBody>
                    <p className="card-description">
                      "The program has been extremely beneficial to me! All the instructors are exceptionally skilled and knowledgeable in cybersecurity and the weekly coaching sessions 
										  really helped to improve soft skills and gain insight into the industry."
                    </p>
                    <CardFooter>
                      <CardTitle tag="h4">Redhin</CardTitle>
                      <h6 className="card-category">Minos Labs Cybersecurity Cohort 2020-2021</h6>
                      <div className="card-avatar">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={
                              portrait2
                            }
                          />
                        </a>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial" style={{height:"550px"}}>
                  <div className="card-icon"style={{height:"100px"}}>
                    <i className="fa fa-quote-right" />
                  </div>
                  <CardBody>
                    <p className="card-description">
                      "Minos Labs really helped me land a job so much faster. While my friends were sending hundreds of applications out, I went on 2 interviews and got my full-time offer!"
                    </p>
                    <CardFooter style={{marginTop:'50px'}}>
                      <CardTitle tag="h4">Dhanush</CardTitle>
                      <h6 className="card-category">Full-Stack Developer, AT&T</h6>
                      <div className="card-avatar">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={
                              portrait3
                            }
                          />
                        </a>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial" style={{height:"550px"}}>
                  <div className="card-icon" style={{height:"100px"}}>
                    <i className="fa fa-quote-right" />
                  </div>
                  <CardBody>
                    <p className="card-description">
                      "I didn’t have the resources to advance in the field of Full-Stack Development. Minos Labs expanded my learning and experience! The instructors, staff, and advisors have inspired me to stay focused and work hard to enter the field."
                    </p>
                    <CardFooter>
                      <CardTitle tag="h4">Anshika</CardTitle>
                      <h6 className="card-category">Systems Engineer, Verizon</h6>
                      <div className="card-avatar">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={
                              portrait1
                            }
                          />
                        </a>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END TESTIMONIALS 1 ********* */}
        {/* ********* TESTIMONIALS 2 ********* */}
      </div>
    </>
  );
}

export default SectionTestimonials;
