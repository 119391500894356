import React from 'react';
import { Link } from 'react-router-dom';
import './Resources.css';
import firebase from 'firebase';
import {
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
} from 'react-share';

class Resource extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: this.props.title,
			date: this.props.date,
			tags: this.props.tags,
			snippet: this.props.snippet,
			id: this.props.id,
			readTime: this.props.readTime,
			profileUrl: '',
		};
	}

	componentDidMount() {
		var storage = firebase.storage().ref();

		const articleId = this.props.id + '.png';

		storage
			.child('articlePics')
			.child(articleId)
			.getDownloadURL()
			.then((url) => {
				this.setState({
					profileUrl: url,
				});
			})
			.catch((e) => {
				console.log(e);
				storage
					.child('profilepics')
					.child('noprofilepic.jpg')
					.getDownloadURL()
					.then((url2) => {
						this.setState({
							profileUrl: url2,
						});
					});
			});
	}

	render() {
		return (
			<React.Fragment>
				<div style={{ backgroundColor: '#fff' }} class="resourceContainer">
					<div style={{ marginRight: '25px' }} class="resourceImages">
						<img
							class="articleImg"
							src={this.state.profileUrl}
							alt="Article Image"
						/>
						<div style={{ left: '0' }} class="share">
							<h6>Share on:</h6>
							<div class="socials">
								<FacebookShareButton
									className={'share_button'}
									url={`https://www.minoslabs.com/articles/${this.state.id}`}
									quote={this.state.title}
									summary={this.state.snippet}
									hashtag="#minoslabs"
								>
									<FacebookIcon round={true} size={43} />
								</FacebookShareButton>

								<LinkedinShareButton
									className={'share_button'}
									title={this.state.title}
									summary={this.state.snippet}
									url={`https://www.minoslabs.com/articles/${this.state.id}`}
								>
									<LinkedinIcon round={true} size={43} />
								</LinkedinShareButton>

								<TwitterShareButton
									className={'share_button'}
									title={this.state.title}
									via="Minos Labs"
									url={`https://www.minoslabs.com/articles/${this.state.id}`}
									hashtags={['minoslabs', 'career']}
								>
									<TwitterIcon round={true} size={43} />
								</TwitterShareButton>
							</div>
						</div>
					</div>

					<div
						style={{ backgroundColor: '#EDF1F5' }}
						class="resourceBlock"
					>
						<div class="resourceHead">
							<div>
								<h1>{this.state.title}</h1>
								<p class="resourceTags">Tags: {this.state.tags}</p>
								<p class="resourceTags">
									Read Time: {this.state.readTime}
								</p>
							</div>
							<p style={{ marginTop: '10px' }}>November 1, 2020</p>
						</div>
						<div class="resourceContent">
							<p>{this.state.snippet}</p>
						</div>

						<div class="resourceButtonWrapper">
							<Link to={`/articles/${this.state.id}`}>
								<button>Read More</button>
							</Link>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Resource;
