import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactDOM from 'react-dom';
import logoImage from '../../assets/Logo.png';
import styles from './Header.module.scss';
import firebase from 'firebase';
import UserContext from '../../User/User';
import context from 'react-bootstrap/esm/AccordionContext';
import moodle_client from "moodle-client";
import axios from 'axios';
import * as emailjs from 'emailjs-com'; 

//Password generator to send random password
var generator = require('generate-password');

var passwordGenerated = generator.generate({
	length: 10,
	numbers: true,
});

const CryptoJS = require('crypto-js');
function validateLogin(userName, password) {
	return {
		userName: userName.length === 0,
		password: password.length === 0,
	};
}

function validateAccount(
	firstName,
	lastName,
	company,
	title,
	emailId,
	linkedin
) {
	return {
		firstName: firstName.length === 0,
		lastName: lastName.length === 0,
		company: company.length === 0,
		title: title.length === 0,
		emailId: emailId.length === 0 || !validateEmail(emailId),
		linkedin: linkedin.length === 0 || !validateLinkedin(linkedin),
	};
}

function validateEmail(emailId) {
	const re = /^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo|outlook|icloud|aol|hotmail)).*\.[a-zA-Z]{2,6}$/;
	return re.test(emailId);
}

function validateLinkedin(linkedin) {
	const re = /^http(s)?:\/\/(www\.)?linkedin\.com\/in\/.*$/;
	return re.test(linkedin);
}

class Header extends React.Component{
  constructor() {
    super();
    this.state = {
      userName:"",
      password:"",
      emailSend:"",
      firstName: "",
      lastName: "",
      company:"",
      title: "",
      emailId:"",
      linkedin:"",
      navDash: "default",
      loginNav: "dropdown",
      userType: "Corporate",
      placeHolderVal: "",
    }
 
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.studentFormSubmit = this.studentFormSubmit.bind(this);
    this.responsiveHandler = this.responsiveHandler.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleEmailSent = this.handleEmailSent.bind(this);
    this.linkHandler = this.linkHandler.bind(this);
    this.loginHandler = this.loginHandler.bind(this);
    this.studentLoginHandler = this.studentLoginHandler.bind(this);
    /*
    this.getStudentInfo = this.getStudentInfo.bind(this);
    */
    this.pageHandler = this.pageHandler.bind(this);
    this.pageHandler1 = this.pageHandler1.bind(this);
    this.createAccountSubmit = this.createAccountSubmit.bind(this);
    this.createAccountConfirm = this.createAccountConfirm.bind(this);
    this.accessDashboard = this.accessDashboard.bind(this);
    this.invalidLogin = this.invalidLogin.bind(this);
    this.invalidEmail = this.invalidEmail.bind(this);
    this.handleUserTypeChange = this.handleUserTypeChange.bind(this);
    this.handleFormSubmit1 = this.handleFormSubmit1.bind(this);
  };

  static contextType = UserContext;


  componentDidMount() {
    /*window.onscroll = function() {
      var x = document.getElementById('myHeader');
      if(window.pageYOffset === 0) {
      ReactDOM.findDOMNode(x).style.backgroundColor = 'transparent';
      }else{
        ReactDOM.findDOMNode(x).style.transitionProperty = 'background-color';
        ReactDOM.findDOMNode(x).style.backgroundColor = '#021939';
        ReactDOM.findDOMNode(x).style.transitionDuration = '0.22s';
      }
    };*/

    this.loginHandler();
    this.studentLoginHandler();
    this.accessDashboard();
  }

  handleUserTypeChange(event) {

    this.onChange(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    if(name === "userType"){
      if(value === "Corporate"){
        this.setState({
          placeHolderVal: "Corporate"
        });
      }
      else if(value === "University"){
        this.setState({
          placeHolderVal: "University"
        });
      }
    }
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
};

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
  
    this.setState({
      [name]: value
    });

    //custom invalid message for create account email
    if(name==="emailId"){
      if(!validateEmail(value)){
        event.target.setCustomValidity("Work email required");
      }else{
        event.target.setCustomValidity("");
      }
    }

    //custom invalid message for linkedin
    if(name==="linkedin"){
      if(!validateLinkedin(value)){
        event.target.setCustomValidity("LinkedIn profile url required");
      }else{
        event.target.setCustomValidity("");
      }
    }

  }
  async studentFormSubmit(event){
    event.preventDefault();
    const context = this.context;
    const username = this.state.userName;
    const password = this.state.password;
    /*

    moodle_client.init({
			wwwroot: "http://lms.minoslabs.com/",
			username: username,
			password: password,
			service: "moodle_mobile_app"
		
		}).then((client) => {
			
      console.log("logged in")
      this.getStudentInfo(username);
		
		}).catch((err) => {
      this.invalidLogin();
      console.log("Login failed " + err);
    });	
    */

    let data;
   await axios.get(`https://us-central1-minos-labs-57c35.cloudfunctions.net/studentLogin?username=${username}&password=${password}`)
     .then((response) => {
       data = response["data"];
       if(data!=="Invalid Login"){
        console.log(data);
        context.setUser(data[0].id);
        context.setloginType("Student");
        window.location.href = "/student_command_center";
       }else{
        this.invalidLogin();
       }
    
     }).catch(err => {
      this.invalidLogin();
    })
  
  };

  /*
  getStudentInfo(username){
    const context = this.context;
    moodle_client.init({
      wwwroot: "http://lms.minoslabs.com/",
      token: "01f04ab2f0eacb95cb7909261d5f7fb0"
    
    }).then((client) => {
      
      client.call({
        wsfunction: "core_user_get_users_by_field",
        args: {
          field:"username",
          values:[username]
        }
    
      }).then((info) => {
        context.setUser(info[0].id);
        context.setloginType("Student");
        
        window.location.href = "/student_command_center";
      });
    
    }).catch(function(err) {
      console.log("Unable to initialize the client: " + err);
    });	

  }
*/
  handleFormSubmit(event){
      event.preventDefault();
      
      const email = this.state.userName.toLowerCase();
      const password = this.encrypt(this.state.password); //Checks if the encrypted password is there
      const context = this.context;

      /*console.log(context.email);

      context.logoutUser();*/


      const db = firebase.firestore();
      let userData="";
      db.collection("corporate").where("email", "==", email)
        .where("password", "==", password) //
        .get()
        .then(querySnapshot => {
          if(querySnapshot.empty){
            this.invalidLogin();
          }
          querySnapshot.forEach(function(doc){
            console.log('in');
            context.setUser(doc.id);
            context.setloginType(doc.data().type);
            var login_type = doc.data().type;
            console.log(login_type);
            window.location.reload();
            if(login_type === "Corporate")
            {
              window.location.href = "/command_center";
            }
            else if(login_type === "University"){
              window.location.href = "/Student_Progress";
            } else if(login_type === "HR"){
              window.location.href = "/Hiring_Requirements";
            }else if(login_type === "Admin"){
              window.location.href = "/admin_command_center";
            }
            
          })
        });

  }

  invalidLogin(){
    console.log("inside from invalid login")
    var x = document.getElementById('invalidMessage');
    ReactDOM.findDOMNode(x).style.display = 'block';
    // var y = document.getElementById('studentInvalidMessage');
    // ReactDOM.findDOMNode(y).style.display = 'block';
  }

  handleFormSubmit1(event){
    event.preventDefault();
    
    const email = this.state.emailSend.toLowerCase();
    
    const context = this.context;
  
    console.log("email 1",email);
  
    const db = firebase.firestore();
    let userData="";
    db.collection("corporate").where("email", "==", email)
      .get()
      .then(querySnapshot => {
        if(querySnapshot.empty){
          this.invalidEmail();
        console.log("no email found")
        }
          
       
       });
  
      
  }

  invalidEmail(){
    console.log("hi from invalid email")
    var x = document.getElementById('invalidMessage1');
    ReactDOM.findDOMNode(x).style.display = 'block';
  }

  accessDashboard(){  //handles login and dashboard navbar
    const context = this.context;
    if(context.userID === "" || context.userID == null){
      console.log(context.userID);
      console.log("empty or null");
      this.setState({
        navDash: "none",
        loginNav: "dropdown",
      })
    }
    else if(context.userID !== ""){
      this.setState({
        navDash: "default",
        loginNav: "hide_loginNav",
      })
    }
  }

/*user(document){
    console.log(document);
}*/
  
  // store the account info in firebase database collection named "corporate"
  createAccountSubmit(event){
    event.preventDefault();
  
    const db = firebase.firestore();
    db.collection("corporate").add({
      first: this.state.firstName,
      last: this.state.lastName,
      company: this.state.company,
      companyTitle: this.state.title,
      email: this.state.emailId,
      password: this.state.lastName,  //This will be changed by reset password
      linkedin: this.state.linkedin,
      savedCandidates: [],
      hrUsers: [],
      type: this.state.userType,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    }).then((docRef) =>{
          db.collection("corporate").doc(docRef.id).set({
            userID: docRef.id,
          }, {merge: true});
    })
  }

  handleForgotPassword(){
    var x = document.getElementById('enterEmail');
    ReactDOM.findDOMNode(x).style.visibility = 'visible';
    ReactDOM.findDOMNode(x).style.opacity = '1';
    ReactDOM.findDOMNode(x).style.transition = 'opacity 0.3s 0s, visibility 0s 0s';
  }

  handleEmailSent(){
    var x = document.getElementById('emailSent');
    ReactDOM.findDOMNode(x).style.visibility = 'visible';
    ReactDOM.findDOMNode(x).style.opacity = '1';
    ReactDOM.findDOMNode(x).style.transition = 'opacity 0.3s 0s, visibility 0s 0s';
  }

  responsiveHandler() {
    var x = document.getElementById('myTopnav');
    if (x.className === "topnav") {
      x.className += " responsive";
    }else {
      x.className = "topnav";
    }
  }
  studentLoginHandler(){

    document.getElementById("studentButton").onclick = function () {
        window.location.href = "https://lms.minoslabs.com/login/index.php";
    };
    var x = document.getElementById('myStudentLogin');
    var x1 = ReactDOM.findDOMNode(x).style.display;
    var y = document.getElementById('myStudentLoginBox');
    var y1 = ReactDOM.findDOMNode(y).style.display;
    if(x1 === "none"){
      ReactDOM.findDOMNode(x).style.display = "flex";
      ReactDOM.findDOMNode(y).style.display = "flex";
    }
    else{
      ReactDOM.findDOMNode(x).style.display = "none";
      ReactDOM.findDOMNode(y).style.display = "none";
    }

  }

  loginHandler(){
    var x = document.getElementById('myLogin');
    var x1 = ReactDOM.findDOMNode(x).style.display;
    var y = document.getElementById('myLoginBox');
    var y1 = ReactDOM.findDOMNode(y).style.display;
    if(x1 === "none"){
      ReactDOM.findDOMNode(x).style.display = "flex";
      ReactDOM.findDOMNode(y).style.display = "flex";
    }
    else{
      ReactDOM.findDOMNode(x).style.display = "none";
      ReactDOM.findDOMNode(y).style.display = "none";
    }
  
    var pgs = [
      document.getElementById('forgotpass'),
      document.getElementById('createAcct'),
      document.getElementById('forgotpassConfirm'),
      document.getElementById('createAcctConfirm')
    ];
    
    for(let i=0; i<pgs.length;i++){
      ReactDOM.findDOMNode(pgs[i]).style.visibility = 'hidden';
      ReactDOM.findDOMNode(pgs[i]).style.opacity = '0';
      ReactDOM.findDOMNode(pgs[i]).style.transition = 'opacity 0.22s 0s, visibility 0s 0s';
    }

  }

  linkHandler(){
    var x = document.getElementById('myTopnav');
    if(x.className === "topnav responsive"){
      this.responsiveHandler();
    }
  }

  pageHandler(event){
    const target = event.target;
    const value = target.value;
    var pgs = [
      document.getElementById('myLoginBox'),
      document.getElementById('forgotpass'),
      document.getElementById('createAcct'),
      document.getElementById('forgotpassConfirm'),
      document.getElementById('createAcctConfirm'),

    ];
    
    for(let i=0; i<pgs.length;i++){
      if(Number(value)-1 == i){
        ReactDOM.findDOMNode(pgs[i]).style.display = "flex";
        ReactDOM.findDOMNode(pgs[i]).style.visibility = 'visible';
        ReactDOM.findDOMNode(pgs[i]).style.opacity = '1';
        ReactDOM.findDOMNode(pgs[i]).style.transition = 'opacity 0.25s 0s, visibility 0s 0s';
        console.log("hey",value);
      }
    }
  
  };

  pageHandler1(event){
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const email = this.state.emailSend.toLowerCase();
    console.log("hu from page handler",passwordGenerated)
    var first = "";
    var pgs = [
      document.getElementById('myLoginBox'),
      document.getElementById('forgotpass'),
      document.getElementById('createAcct'),
      document.getElementById('forgotpassConfirm'),
      document.getElementById('createAcctConfirm'),

    ];
    
    for(let i=0; i<pgs.length;i++){
      if(Number(value)-1 == i){
        ReactDOM.findDOMNode(pgs[i]).style.display = "flex";
        ReactDOM.findDOMNode(pgs[i]).style.visibility = 'visible';
        ReactDOM.findDOMNode(pgs[i]).style.opacity = '1';
        ReactDOM.findDOMNode(pgs[i]).style.transition = 'opacity 0.25s 0s, visibility 0s 0s';
        console.log("hey",value);
      }
    }
    
        const db = firebase.firestore();
       
      
        db.collection('corporate').where("email","==",email)
        .get()
        .then((querySnapshot) => {
          let data= querySnapshot.docs.map((doc) => doc.data());

            first = data[0].first
          
            var templateParams = {
              to_name: first,
              receiver: this.state.emailSend,
              tempPass:passwordGenerated,
            };

            emailjs.send('mailgun', 'template_8455z4i', templateParams, 'user_ry821S5CQJTzdU5eSuuAk')
              .then(function(response) {
                 console.log('SUCCESS!', response.status, response.text);
              }, function(error) {
                 console.log('FAILED...', error);
              });

     db.collection('corporate').doc(data[0].userID).update({ password: this.encrypt(passwordGenerated) });

    });
    
    
  };

  // data[0].candidateID
  // .collection('ALL_USER').doc(data[0].candidateID.toString()).update({password:tempPassword})
  // after registering account, you see a thank you screen 
  // after you click outside, that screen goes away
  // but when you click on register an acocunt again, those details are still there
  // this function clears those details by setting state of those fields to their initial values
  createAccountConfirm(event){
    event.preventDefault();
    // this function is called when you click on "Home" button3.
    this.setState({
      firstName: '',
      lastName: '',
      company: '',
      title: '',
      emailId: '',
      linkedin: ''
    });
  }

  encrypt(text){
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
  };

   decrypt(data){
    return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
  };


  render(){
    
    //User data
    let {userID,loginType,logoutUser,setUser,setloginType} = this.context;

    //Validate login
    const loginErrors = validateLogin(this.state.userName, this.state.password);
    const canLogin= Object.keys(loginErrors).some(x => loginErrors[x]);


    //Validate create account input data
    const accountErrors = validateAccount(this.state.firstName, this.state.lastName,this.state.company,this.state.title,this.state.emailId,this.state.linkedin);
    const canCreateAccount = Object.keys(accountErrors).some(x => accountErrors[x]);

    //Validate email for forgot password
    const canSendEmail = !validateEmail(this.state.emailSend);


    
    return(
      <React.Fragment>
        <div className = {styles.login} id = "myLogin" onClick = {this.loginHandler}/>

        {/*------------------------------------------------------Login - Login------------------------------------------------------*/}
        <div className = {styles.loginbox} id = "myLoginBox"> 
          <img src={logoImage} alt="LogoImage" style = {{marginTop: "3vh"}}/>
          <br></br>
          <h1>Log In</h1>
          <form onSubmit={this.handleFormSubmit}>
            <h3>Email</h3>
            <input
              name="userName"
              type="text"
              placeholder="Email"
              value = {this.state.userName}
              onChange = {this.handleInputChange}
              required
            />
            <h3>Password</h3>
            <input
              name="password"
              type="password"
              placeholder="Password"
              value = {this.state.password}
              onChange = {this.handleInputChange}
              required
            />
            <div className={styles.checkContainer}>
            <input
            className={styles.check}
            name="keep"
            type="checkbox"/>
            <p>Keep me logged in</p>
            </div>
            <div id = "invalidMessage" className={styles.invalidMessage}>Invalid username or password. Please try again.</div>
            
           <button type="submit" className="hvr-sweep">Log In</button> {/* LOGIN BUTTON */}
          </form>
      
          <div className = {styles.loginlink}>
            <button value = "2" onClick = {this.pageHandler}
            style = {{textAlign: "center"}}>Forgot Password</button>
            
            {/* <button value = "3" onClick = {this.pageHandler} 
            style = {{textAlign: "right"}}>Create an Account</button> */}
          </div>
        
          {/*------------------------------------------------------Login - Forgot Password------------------------------------------------------*/}

          <div className = {styles.forgotpass} id = "forgotpass">
            <h1>Enter Your Email</h1>
            <form onSubmit={this.handleFormSubmit1}>
              <input
                name="emailSend"
                type="text"
                placeholder="Email address"
                value = {this.state.emailSend}
                onChange = {this.handleInputChange}
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                required
              />
              <div id = "invalidMessage1" className={styles.invalidMessage}>This email doesn't exist in the database. Please try again!</div>
              <button type="submit" className="hvr-sweep" value="4" onClick = {(event)=>{if(!canSendEmail)this.pageHandler1(event)}}>Reset Password</button>
            </form>
          </div>

          {/*------------------------------------------------Login - Forgot Password - Confirm-------------------------------------------------*/}

          <div className= {styles.forgotpassConfirm} id = "forgotpassConfirm">
            <div className = {styles.container}>
              <h3>An email has been sent to your account with instructions on how to reset your password.</h3>
              <Link to ="/">
              <button className="hvr-sweep" value="4" onClick = {this.loginHandler}>Home</button>
              </Link>
            </div>
          </div>
        
        
          {/*------------------------------------------------------Login - Create Account------------------------------------------------------*/}
          <div className = {styles.createAcct} id = "createAcct">
            <h1>Create an Account</h1>
            <p>Recruit our candidates today!</p>
            <form  onSubmit = {this.handleFormSubmit}>
            <select name="userType" style={{width:"100%", height:"53px", fontSize:"16px"}} value={this.state.userType} onChange={this.handleUserTypeChange} >
                <option value = "Corporate">Corporate</option>
                <option value = "University">University</option>
            </select>
              <input
                name="firstName"
                type="text"
                placeholder="First Name"
                value = {this.state.firstName}
                onChange = {this.handleInputChange}
                style = {{width:'calc(50% - 12px)', marginRight: "0%",float: "left"}}
                required
              />
              <input
                name="lastName"
                type="text"
                placeholder="Last Name"
                value = {this.state.lastName}
                onChange = {this.handleInputChange}
                style = {{width: 'calc(50% - 12px)', marginLeft: "0%", float: "right"}}
                required    
              />
              <input
                name="company"
                type="text"
                placeholder={this.state.userType === "Corporate" ? "Company" : "University" } 
                value = {this.state.company}
                onChange = {this.handleInputChange}
                required
              />
              <input
                name="title"
                type="text"
                placeholder= {this.state.userType === "Corporate" ? "Company title" : "Position" }
                value = {this.state.title}
                onChange = {this.handleInputChange}
                required
              />
              <input
                name="emailId"
                id="emailId"
                type="text"
                placeholder="Email address"
                value = {this.state.emailId}
                onChange = {this.handleInputChange}
                required
              />
              <input
                name="linkedin"
                type="url"
                placeholder="LinkedIn"
                value = {this.state.linkedin}
                onChange = {this.handleInputChange}
                required
              />
              <button type="submit" className="hvr-sweep" value = "5" onClick = { (event)=> { if(!canCreateAccount){this.pageHandler(event); this.createAccountSubmit(event);} }}>Register</button>
              
            </form>
          </div>
        </div>
      
        {/*------------------------------------------------Login - Create Account - Confirm-------------------------------------------------*/}

        <div className = {styles.createAcctConfirm} id = "createAcctConfirm">
          <div className = {styles.container}>
            <h3>Thank you for registering!</h3>
            <h3>An activation email has been sent to the email address you provided.</h3>
            <Link to ="/">
            <button className="hvr-sweep" onClick = { (event) => {this.loginHandler(); this.createAccountConfirm(event);} }>Home</button>
            </Link>
          </div>
        </div>
        {/*--------------------------------------------------Student Login-------------------------------*/}
        <div className = {styles.studentLogin} id = "myStudentLogin" onClick = {this.studentLoginHandler}/>

        <div className = {styles.studentLoginbox} id = "myStudentLoginBox"> 
          <img src={logoImage} alt="LogoImage" style = {{marginTop: "3vh"}}/>
          <br></br>
          <h1>Student Log In</h1>
          <form onSubmit={this.studentFormSubmit}>
            <h3>Username</h3>
            <input
              name="userName"
              type="text"
              placeholder="username"
              value = {this.state.userName}
              onChange = {this.handleInputChange}
              required
            />
            <h3>Password</h3>
            <input
              name="password"
              type="password"
              placeholder="Password"
              value = {this.state.password}
              onChange = {this.handleInputChange}
              required
            />
            <div className={styles.checkContainer}>
            <input
            className={styles.check}
            name="keep"
            type="checkbox"/>
            <p>Keep me logged in</p>
            </div>
            <div id = "studentInvalidMessage" className={styles.invalidMessage}>Invalid username or password. Please try again.</div>
            
            <button type="submit" className="hvr-sweep">Log In</button> {/* LOGIN BUTTON */}
          </form>
          </div>

        {/*------------------------------------------------------Header------------------------------------------------------*/}
        <header>
          <div className={styles.topnav} id="myTopnav">
            <Link className={styles.active} style= {{float:"left",margin:"0px"}} to="/"><img src={logoImage} alt="LogoImage" /></Link>
            {loginType == "Corporate" || loginType == "University" ?
              <div />:
              <Link className = {styles.applyButton} style= {{padding:"10px 20px"}} to="/future-cohorts" onClick = {this.linkHandler}>Apply Now</Link>

            }
            

            <div style={{display: this.state.navDash}}><Link className={styles.student_Login} to={loginType === "Corporate" ? "/command_center" : loginType === "University" ? "/student_progress" : loginType === "Student" ? "/student_command_center" :loginType === "HR" ?"/Hiring_Requirements" : "/admin_command_center"}  onClick = {this.linkHandler}>Dashboard</Link> </div>

              <div className={styles[this.state.loginNav]}>
                <button className={styles.dropbtn}>Log In  <i className="fa fa-caret-down"></i></button>
                <div className={styles.dropdownContent}>
                  <button className={styles.student_Login} style={{margin: "0px"}} onClick={this.studentLoginHandler} id='studentButton'>Student</button>
                    {/*<a style={{margin: "0px"}} href="https://lms.minoslabs.com/login/index.php">Student</a>*/}
                  {/* <Link className={styles.student_Login} style={{margin: "0px"}} onClick={this.studentLoginHandler}>Student</Link> */}
                  <button className={styles.student_Login} style={{margin: "0px"}} onClick={this.loginHandler}>Client</button>
                </div>
              </div>

            <Link className = {styles.student_Login} to="/resources">Resources</Link>

            <div className={styles.dropdown}>
              <button className={styles.dropbtn}>Partners <i className="fa fa-caret-down"></i></button>
              <div className={styles.dropdownContent}>
                <Link style={{margin:"0px"}} to="/corporate" onClick = {this.linkHandler}>Corporate</Link>
                <Link style={{margin:"0px"}} to="/university" onClick = {this.linkHandler}>University</Link>
              </div>
            </div>

            <Link className = {styles.student_Login} to="/veterans" onClick = {this.linkHandler}>Veterans</Link>
            <Link className = {styles.student_Login} to="/about" onClick = {this.linkHandler}>About Us</Link>

            <a to="javascript:void(0);" style= {{fontSize:"15px"}} className="icon" onClick={this.responsiveHandler}>
              <div className={styles.bar1}></div>
              <div className={styles.bar2}></div>
              <div className={styles.bar3}></div>
            </a>
          </div>
        </header>
      </React.Fragment>
    )
  }
}

export default Header;

// import React from 'react';
// import { Link, Redirect } from 'react-router-dom';
// import ReactDOM from 'react-dom';
// import logoImage from '../../assets/Logo.png';
// import styles from './Header.module.scss';
// import firebase from 'firebase';
// import UserContext from '../../User/User';
// import context from 'react-bootstrap/esm/AccordionContext';
// import moodle_client from "moodle-client";
// import axios from 'axios';

// const CryptoJS = require('crypto-js');
// function validateLogin(userName, password) {
// 	return {
// 		userName: userName.length === 0,
// 		password: password.length === 0,
// 	};
// }

// function validateAccount(
// 	firstName,
// 	lastName,
// 	company,
// 	title,
// 	emailId,
// 	linkedin
// ) {
// 	return {
// 		firstName: firstName.length === 0,
// 		lastName: lastName.length === 0,
// 		company: company.length === 0,
// 		title: title.length === 0,
// 		emailId: emailId.length === 0 || !validateEmail(emailId),
// 		linkedin: linkedin.length === 0 || !validateLinkedin(linkedin),
// 	};
// }

// function validateEmail(emailId) {
// 	const re = /^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo|outlook|icloud|aol|hotmail)).*\.[a-zA-Z]{2,6}$/;
// 	return re.test(emailId);
// }

// function validateLinkedin(linkedin) {
// 	const re = /^http(s)?:\/\/(www\.)?linkedin\.com\/in\/.*$/;
// 	return re.test(linkedin);
// }

// class Header extends React.Component{
//   constructor() {
//     super();
//     this.state = {
//       userName:"",
//       password:"",
//       emailSend:"",
//       firstName: "",
//       lastName: "",
//       company:"",
//       title: "",
//       emailId:"",
//       linkedin:"",
//       navDash: "default",
//       loginNav: "dropdown",
//       userType: "Corporate",
//       placeHolderVal: "",
//     }
 
//     this.handleInputChange = this.handleInputChange.bind(this);
//     this.handleFormSubmit = this.handleFormSubmit.bind(this);
//     this.studentFormSubmit = this.studentFormSubmit.bind(this);
//     this.responsiveHandler = this.responsiveHandler.bind(this);
//     this.handleForgotPassword = this.handleForgotPassword.bind(this);
//     this.handleEmailSent = this.handleEmailSent.bind(this);
//     this.linkHandler = this.linkHandler.bind(this);
//     this.loginHandler = this.loginHandler.bind(this);
//     this.studentLoginHandler = this.studentLoginHandler.bind(this);
//     /*
//     this.getStudentInfo = this.getStudentInfo.bind(this);
//     */
//     this.pageHandler = this.pageHandler.bind(this);
//     this.createAccountSubmit = this.createAccountSubmit.bind(this);
//     this.createAccountConfirm = this.createAccountConfirm.bind(this);
//     this.accessDashboard = this.accessDashboard.bind(this);
//     this.invalidLogin = this.invalidLogin.bind(this);
//     this.handleUserTypeChange = this.handleUserTypeChange.bind(this);
//   };

//   static contextType = UserContext;


//   componentDidMount() {
//     window.onscroll = function() {
//       var x = document.getElementById('myHeader');
//       if(window.pageYOffset === 0) {
//       ReactDOM.findDOMNode(x).style.backgroundColor = 'transparent';
//       }else{
//         ReactDOM.findDOMNode(x).style.transitionProperty = 'background-color';
//         ReactDOM.findDOMNode(x).style.backgroundColor = '#021939';
//         ReactDOM.findDOMNode(x).style.transitionDuration = '0.22s';
//       }
//     };
//     this.loginHandler();
//     this.studentLoginHandler();
//     this.accessDashboard();
//   }

//   componentWillUnmount() {
//     window.onscroll = null;
//   }

//   handleUserTypeChange(event) {

//     this.onChange(event);
//     const target = event.target;
//     const value = target.value;
//     const name = target.name;

//     this.setState({
//       [name]: value
//     });

//     if(name === "userType"){
//       if(value === "Corporate"){
//         this.setState({
//           placeHolderVal: "Corporate"
//         });
//       }
//       else if(value === "University"){
//         this.setState({
//           placeHolderVal: "University"
//         });
//       }
//     }
//   }
 
//   onChange = event => {
//     this.setState({ [event.target.name]: event.target.value });
// };

//   handleInputChange(event) {
//     const target = event.target;
//     const value = target.value;
//     const name = target.name;
  
//     this.setState({
//       [name]: value
//     });

//     //custom invalid message for create account email
//     if(name==="emailId"){
//       if(!validateEmail(value)){
//         event.target.setCustomValidity("Work email required");
//       }else{
//         event.target.setCustomValidity("");
//       }
//     }

//     //custom invalid message for linkedin
//     if(name==="linkedin"){
//       if(!validateLinkedin(value)){
//         event.target.setCustomValidity("LinkedIn profile url required");
//       }else{
//         event.target.setCustomValidity("");
//       }
//     }

//   }
//   async studentFormSubmit(event){
//     event.preventDefault();
//     const context = this.context;
//     const username = this.state.userName;
//     const password = this.state.password;
//     /*
//     moodle_client.init({
// 			wwwroot: "http://lms.minoslabs.com/",
// 			username: username,
// 			password: password,
// 			service: "moodle_mobile_app"
		
// 		}).then((client) => {
			
//       console.log("logged in")
//       this.getStudentInfo(username);
		
// 		}).catch((err) => {
//       this.invalidLogin();
//       console.log("Login failed " + err);
//     });	
//     */

//     let data;
//    await axios.get(`https://us-central1-minos-labs-57c35.cloudfunctions.net/studentLogin?username=${username}&password=${password}`)
//      .then((response) => {
//        data = response["data"];
//        if(data!=="Invalid Login"){
//         console.log(data);
//         context.setUser(data[0].id);
//         context.setloginType("Student");
//         window.location.href = "/student_command_center";
//        }else{
//         this.invalidLogin();
//        }
    
//      }).catch(err => {
//       this.invalidLogin();
//     })
  
//   }

//   /*
//   getStudentInfo(username){
//     const context = this.context;
//     moodle_client.init({
//       wwwroot: "http://lms.minoslabs.com/",
//       token: "01f04ab2f0eacb95cb7909261d5f7fb0"
    
//     }).then((client) => {
      
//       client.call({
//         wsfunction: "core_user_get_users_by_field",
//         args: {
//           field:"username",
//           values:[username]
//         }
    
//       }).then((info) => {
//         context.setUser(info[0].id);
//         context.setloginType("Student");
        
//         window.location.href = "/student_command_center";
//       });
    
//     }).catch(function(err) {
//       console.log("Unable to initialize the client: " + err);
//     });	
//   }
// */
//   handleFormSubmit(event){
//       event.preventDefault();
      
//       const email = this.state.userName.toLowerCase();
//       const password = this.encrypt(this.state.password); //Checks if the encrypted password is there
//       const context = this.context;

//       /*console.log(context.email);
//       context.logoutUser();*/

//       console.log(email);

//       const db = firebase.firestore();
//       let userData="";
//       db.collection("corporate").where("email", "==", email)
//         .where("password", "==", password) //
//         .get()
//         .then(querySnapshot => {
//           if(querySnapshot.empty){
//             this.invalidLogin();
//             this.invalidEmail();
//           }
//           querySnapshot.forEach(function(doc){
//             console.log('in');
//             context.setUser(doc.id);
//             context.setloginType(doc.data().type);
//             var login_type = doc.data().type;
//             console.log(login_type);
//             window.location.reload();
//             if(login_type === "Corporate")
//             {
//               window.location.href = "/command_center";
//             }
//             else if(login_type === "University"){
//               window.location.href = "/Student_Progress";
//             } else if(login_type === "HR"){
//               window.location.href = "/Hiring_Requirements";
//             }else if(login_type === "Admin"){
//               window.location.href = "/admin_command_center";
//             }
            
//           })
//         });

//   }

//   invalidLogin(){
//     var x = document.getElementById('invalidMessage');
//     ReactDOM.findDOMNode(x).style.display = 'block';
//     var y = document.getElementById('studentInvalidMessage');
//     ReactDOM.findDOMNode(y).style.display = 'block';
//   }
//     invalidEmail(){
//     var x = document.getElementById('invalidMessage1');
//     ReactDOM.findDOMNode(x).style.display = 'block';
//   }

//   accessDashboard(){  //handles login and dashboard navbar
//     const context = this.context;
//     if(context.userID === "" || context.userID == null){
//       console.log(context.userID);
//       console.log("empty or null");
//       this.setState({
//         navDash: "none",
//         loginNav: "dropdown",
//       })
//     }
//     else if(context.userID !== ""){
//       this.setState({
//         navDash: "default",
//         loginNav: "hide_loginNav",
//       })
//     }
//   }

// /*user(document){
//     console.log(document);
// }*/
  
//   // store the account info in firebase database collection named "corporate"
//   createAccountSubmit(event){
//     event.preventDefault();
  
//     const db = firebase.firestore();
//     db.collection("corporate").add({
//       first: this.state.firstName,
//       last: this.state.lastName,
//       company: this.state.company,
//       companyTitle: this.state.title,
//       email: this.state.emailId,
//       password: this.state.lastName,  //This will be changed by reset password
//       linkedin: this.state.linkedin,
//       savedCandidates: [],
//       hrUsers: [],
//       type: this.state.userType,
//       timestamp: firebase.firestore.FieldValue.serverTimestamp()
//     }).then((docRef) =>{
//           db.collection("corporate").doc(docRef.id).set({
//             userID: docRef.id,
//           }, {merge: true});
//     })
//   }

//   handleForgotPassword(){
//     var x = document.getElementById('enterEmail');
//     ReactDOM.findDOMNode(x).style.visibility = 'visible';
//     ReactDOM.findDOMNode(x).style.opacity = '1';
//     ReactDOM.findDOMNode(x).style.transition = 'opacity 0.3s 0s, visibility 0s 0s';
//   }

//   handleEmailSent(){
//     var x = document.getElementById('emailSent');
//     ReactDOM.findDOMNode(x).style.visibility = 'visible';
//     ReactDOM.findDOMNode(x).style.opacity = '1';
//     ReactDOM.findDOMNode(x).style.transition = 'opacity 0.3s 0s, visibility 0s 0s';
//   }

//   responsiveHandler() {
//     var x = document.getElementById('myTopnav');
//     if (x.className === "topnav") {
//       x.className += " responsive";
//     }else {
//       x.className = "topnav";
//     }
//   }
//   studentLoginHandler(){
//     var x = document.getElementById('myStudentLogin');
//     var x1 = ReactDOM.findDOMNode(x).style.display;
//     var y = document.getElementById('myStudentLoginBox');
//     var y1 = ReactDOM.findDOMNode(y).style.display;
//     if(x1 === "none"){
//       ReactDOM.findDOMNode(x).style.display = "flex";
//       ReactDOM.findDOMNode(y).style.display = "flex";
//     }
//     else{
//       ReactDOM.findDOMNode(x).style.display = "none";
//       ReactDOM.findDOMNode(y).style.display = "none";
//     }

//   }

//   loginHandler(){
//     var x = document.getElementById('myLogin');
//     var x1 = ReactDOM.findDOMNode(x).style.display;
//     var y = document.getElementById('myLoginBox');
//     var y1 = ReactDOM.findDOMNode(y).style.display;
//     if(x1 === "none"){
//       ReactDOM.findDOMNode(x).style.display = "flex";
//       ReactDOM.findDOMNode(y).style.display = "flex";
//     }
//     else{
//       ReactDOM.findDOMNode(x).style.display = "none";
//       ReactDOM.findDOMNode(y).style.display = "none";
//     }
  
//     var pgs = [
//       document.getElementById('forgotpass'),
//       document.getElementById('createAcct'),
//       document.getElementById('forgotpassConfirm'),
//       document.getElementById('createAcctConfirm')
//     ];
    
//     for(let i=0; i<pgs.length;i++){
//       ReactDOM.findDOMNode(pgs[i]).style.visibility = 'hidden';
//       ReactDOM.findDOMNode(pgs[i]).style.opacity = '0';
//       ReactDOM.findDOMNode(pgs[i]).style.transition = 'opacity 0.22s 0s, visibility 0s 0s';
//     }

//   }

//   linkHandler(){
//     var x = document.getElementById('myTopnav');
//     if(x.className === "topnav responsive"){
//       this.responsiveHandler();
//     }
//   }

//   pageHandler(event){
//     const target = event.target;
//     const value = target.value;
//     console.log(value);
//     var pgs = [
//       document.getElementById('myLoginBox'),
//       document.getElementById('forgotpass'),
//       document.getElementById('createAcct'),
//       document.getElementById('forgotpassConfirm'),
//       document.getElementById('createAcctConfirm'),

//     ];
    
//     for(let i=0; i<pgs.length;i++){
//       if(Number(value)-1 == i){
//         ReactDOM.findDOMNode(pgs[i]).style.display = "flex";
//         ReactDOM.findDOMNode(pgs[i]).style.visibility = 'visible';
//         ReactDOM.findDOMNode(pgs[i]).style.opacity = '1';
//         ReactDOM.findDOMNode(pgs[i]).style.transition = 'opacity 0.25s 0s, visibility 0s 0s';
//         console.log(value);
//       }
//     }
//   }

//   // after registering account, you see a thank you screen 
//   // after you click outside, that screen goes away
//   // but when you click on register an acocunt again, those details are still there
//   // this function clears those details by setting state of those fields to their initial values
//   createAccountConfirm(event){
//     event.preventDefault();
//     // this function is called when you click on "Home" button3.
//     this.setState({
//       firstName: '',
//       lastName: '',
//       company: '',
//       title: '',
//       emailId: '',
//       linkedin: ''
//     });
//   }

//   encrypt(text){
//     return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
//   };

//    decrypt(data){
//     return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
//   };


//   render(){
    
//     //User data
//     let {userID,loginType,logoutUser,setUser,setloginType} = this.context;

//     //Validate login
//     const loginErrors = validateLogin(this.state.userName, this.state.password);
//     const canLogin= Object.keys(loginErrors).some(x => loginErrors[x]);


//     //Validate create account input data
//     const accountErrors = validateAccount(this.state.firstName, this.state.lastName,this.state.company,this.state.title,this.state.emailId,this.state.linkedin);
//     const canCreateAccount = Object.keys(accountErrors).some(x => accountErrors[x]);

//     //Validate email for forgot password
//     const canSendEmail = !validateEmail(this.state.emailSend);


    
//     return(
//       <React.Fragment>
//         <div className = {styles.login} id = "myLogin" onClick = {this.loginHandler}/>

//         {/*------------------------------------------------------Login - Login------------------------------------------------------*/}
//         <div className = {styles.loginbox} id = "myLoginBox"> 
//           <img src={logoImage} alt="LogoImage" style = {{marginTop: "3vh"}}/>
//           <br></br>
//           <h1>Log In</h1>
//           <form onSubmit={this.handleFormSubmit}>
//             <h3>Email</h3>
//             <input
//               name="userName"
//               type="text"
//               placeholder="Email"
//               value = {this.state.userName}
//               onChange = {this.handleInputChange}
//               required
//             />
//             <h3>Password</h3>
//             <input
//               name="password"
//               type="password"
//               placeholder="Password"
//               value = {this.state.password}
//               onChange = {this.handleInputChange}
//               required
//             />
//             <div className={styles.checkContainer}>
//             <input
//             className={styles.check}
//             name="keep"
//             type="checkbox"/>
//             <p>Keep me logged in</p>
//             </div>
//             <div id = "invalidMessage" className={styles.invalidMessage}>Invalid username or password. Please try again.</div>
            
//            <button type="submit" class="hvr-sweep">Log In</button> {/* LOGIN BUTTON */}
//           </form>
      
//           <div className = {styles.loginlink}>
//             <button value = "2" onClick = {this.pageHandler}
//             style = {{textAlign: "left"}}>Forgot Password</button>
// {/*             
//             <button value = "3" onClick = {this.pageHandler} 
//             style = {{textAlign: "right"}}>Create an Account</button> */}
//           </div>
        
//           {/*------------------------------------------------------Login - Forgot Password------------------------------------------------------*/}

//           <div className = {styles.forgotpass} id = "forgotpass">
//             <h1>Enter Your Email</h1>
//             <form onSubmit={this.handleFormSubmit}>
//               <input
//                 name="emailSend"
//                 type="text"
//                 placeholder="Email address"
//                 value = {this.state.emailSend}
//                 onChange = {this.handleInputChange}
//                 pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
//                 required
//               />
//                <div id = "invalidMessage1" className={styles.invalidMessage}>This email doesn't exist in the database. Please try again!</div>
//               <button type="submit" class="hvr-sweep" value="4" onClick = {(event)=>{if(!canSendEmail)this.pageHandler(event)}}>Reset Password</button>
//             </form>
//           </div>

//           {/*------------------------------------------------Login - Forgot Password - Confirm-------------------------------------------------*/}

//           <div className= {styles.forgotpassConfirm} id = "forgotpassConfirm">
//             <div className = {styles.container}>
//               <h3>An email has been sent to your account with instructions on how to reset your password.</h3>
//               <Link to ="/">
//               <button class="hvr-sweep" onClick = {this.loginHandler}>Home</button>
//               </Link>
//             </div>
//           </div>
        
        
//           {/*------------------------------------------------------Login - Create Account------------------------------------------------------*/}
//           <div className = {styles.createAcct} id = "createAcct">
//             <h1>Create an Account</h1>
//             <p>Recruit our candidates today!</p>
//             <form  onSubmit = {this.handleFormSubmit}>
//             <select name="userType" style={{width:"100%", height:"53px", fontSize:"16px"}} value={this.state.userType} onChange={this.handleUserTypeChange} >
//                 <option value = "Corporate">Corporate</option>
//                 <option value = "University">University</option>
//             </select>
//               <input
//                 name="firstName"
//                 type="text"
//                 placeholder="First Name"
//                 value = {this.state.firstName}
//                 onChange = {this.handleInputChange}
//                 style = {{width:'calc(50% - 12px)', marginRight: "0%",float: "left"}}
//                 required
//               />
//               <input
//                 name="lastName"
//                 type="text"
//                 placeholder="Last Name"
//                 value = {this.state.lastName}
//                 onChange = {this.handleInputChange}
//                 style = {{width: 'calc(50% - 12px)', marginLeft: "0%", float: "right"}}
//                 required    
//               />
//               <input
//                 name="company"
//                 type="text"
//                 placeholder={this.state.userType === "Corporate" ? "Company" : "University" } 
//                 value = {this.state.company}
//                 onChange = {this.handleInputChange}
//                 required
//               />
//               <input
//                 name="title"
//                 type="text"
//                 placeholder= {this.state.userType === "Corporate" ? "Company title" : "Position" }
//                 value = {this.state.title}
//                 onChange = {this.handleInputChange}
//                 required
//               />
//               <input
//                 name="emailId"
//                 id="emailId"
//                 type="text"
//                 placeholder="Email address"
//                 value = {this.state.emailId}
//                 onChange = {this.handleInputChange}
//                 required
//               />
//               <input
//                 name="linkedin"
//                 type="url"
//                 placeholder="LinkedIn"
//                 value = {this.state.linkedin}
//                 onChange = {this.handleInputChange}
//                 required
//               />
//               <button type="submit" class="hvr-sweep" value = "5" onClick = { (event)=> { if(!canCreateAccount){this.pageHandler(event); this.createAccountSubmit(event);} }}>Register</button>
              
//             </form>
//           </div>
//         </div>
      
//         {/*------------------------------------------------Login - Create Account - Confirm-------------------------------------------------*/}

//         <div className = {styles.createAcctConfirm} id = "createAcctConfirm">
//           <div className = {styles.container}>
//             <h3>Thank you for registering!</h3>
//             <h3>An activation email has been sent to the email address you provided.</h3>
//             <Link to ="/">
//             <button class="hvr-sweep" onClick = { (event) => {this.loginHandler(); this.createAccountConfirm(event);} }>Home</button>
//             </Link>
//           </div>
//         </div>
//         {/*--------------------------------------------------Student Login-------------------------------*/}
//         <div className = {styles.studentLogin} id = "myStudentLogin" onClick = {this.studentLoginHandler}/>

//         <div className = {styles.studentLoginbox} id = "myStudentLoginBox"> 
//           <img src={logoImage} alt="LogoImage" style = {{marginTop: "3vh"}}/>
//           <br></br>
//           <h1>Student Log In</h1>
//           <form onSubmit={this.studentFormSubmit}>
//             <h3>Username</h3>
//             <input
//               name="userName"
//               type="text"
//               placeholder="username"
//               value = {this.state.userName}
//               onChange = {this.handleInputChange}
//               required
//             />
//             <h3>Password</h3>
//             <input
//               name="password"
//               type="password"
//               placeholder="Password"
//               value = {this.state.password}
//               onChange = {this.handleInputChange}
//               required
//             />
//             <div className={styles.checkContainer}>
//             <input
//             className={styles.check}
//             name="keep"
//             type="checkbox"/>
//             <p>Keep me logged in</p>
//             </div>
//             <div id = "studentInvalidMessage" className={styles.invalidMessage}>Invalid username or password. Please try again.</div>
            
//             <button type="submit" class="hvr-sweep">Log In</button> {/* LOGIN BUTTON */}
//           </form>
//           </div>

//         {/*------------------------------------------------------Header------------------------------------------------------*/}
//         <header id="myHeader">
//           <div className={styles.topnav} id="myTopnav">
//             <Link className={styles.active} style= {{float:"left",margin:"0px"}} to="/"><img src={logoImage} alt="LogoImage" /></Link>
//             <Link className = {styles.applyButton} style= {{padding:"10px 20px"}} to="/exam-start" onClick = {this.linkHandler}>Apply Now</Link>

//             <div style={{display: this.state.navDash}}><Link className={styles.student_Login} to={loginType === "Corporate" ? "/command_center" : loginType === "University" ? "/student_progress" : loginType === "Student" ? "/student_command_center" :loginType === "HR" ?"/Hiring_Requirements" : "/admin_command_center"}  onClick = {this.linkHandler}>Dashboard</Link> </div>

//               <div className={styles[this.state.loginNav]}>
//                 <button className={styles.dropbtn}>Log In  <i className="fa fa-caret-down"></i></button>
//                 <div className={styles.dropdownContent}>
//                   <Link className={styles.student_Login} style={{margin: "0px"}} onClick={this.studentLoginHandler}>Student</Link>
//                     {/*<a style={{margin: "0px"}} href="https://lms.minoslabs.com/login/index.php">Student</a>*/}
//                   {/* <Link className={styles.student_Login} style={{margin: "0px"}} onClick={this.studentLoginHandler}>Student</Link> */}
//                   <Link className={styles.student_Login} style={{margin: "0px"}} onClick={this.loginHandler}>Client</Link>
//                 </div>
//               </div>

//             <Link className = {styles.student_Login} to="/resources">Resources</Link>

//             <div className={styles.dropdown}>
//               <button className={styles.dropbtn}>Partners <i class="fa fa-caret-down"></i></button>
//               <div className={styles.dropdownContent}>
//                 <Link style={{margin:"0px"}} to="/corporate" onClick = {this.linkHandler}>Corporate</Link>
//                 <Link style={{margin:"0px"}} to="/university" onClick = {this.linkHandler}>University</Link>
//               </div>
//             </div>

//             <Link className = {styles.student_Login} to="/veterans" onClick = {this.linkHandler}>Veterans</Link>
//             <Link className = {styles.student_Login} to="/about" onClick = {this.linkHandler}>About Us</Link>

//             <a to="javascript:void(0);" style= {{fontSize:"15px"}} class="icon" onClick={this.responsiveHandler}>
//               <div className={styles.bar1}></div>
//               <div className={styles.bar2}></div>
//               <div className={styles.bar3}></div>
//             </a>
//           </div>
//         </header>
//       </React.Fragment>
//     )
//   }
// }

// export default Header;