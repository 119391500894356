/**
 * Renders each row of the scheduled interview table in the parent component
 * InterviewSchedule.js
 * 
 */
 import React, { Component } from 'react'
 import DeleteConfirmationModal from '../HiringRequirements/DeleteConfirmationModal';
 import video_sign from '../../assets/hiring_requirement/video.png';
 import { Link } from 'react-router-dom';
 import firebase from "firebase";
 
 
 export class ActionButtons extends Component {
 
     constructor(props) {
         super(props);
         this.state = {
             interviewDetails: {}
         }
 
         this.handleOnDelete = this.handleOnDelete.bind(this);
     }
 
     async componentDidMount() {
 
 
 
         this.setState({
             interviewDetails: this.props.interviewDetails,
 
         })
 
 
     }
 
 
 
     /**
      * Gets triggereg when delete button is pressed in the modal
      * DeleteConfirmationModal
      */
     handleOnDelete() {
         this.props.handleOnDelete(this.state.interviewDetails.meetingId)
     }
 
 
     render() {
        let interviewTime = "unconfirmed";
        let start_time = this.state.interviewDetails.start_time
        if(start_time!= undefined){     
            interviewTime = new Date(start_time.seconds * 1000);
        }
       
 
         return (
             <React.Fragment>
                 <td>
                     {this.state.interviewDetails.name}
                 </td>
                 <td>
                     New York
                 </td>
                 <td>
                     102032
                 </td>
                 <td>
                 {interviewTime.toString()}
                 </td>
                 <td>
                     <div style={{ display: "flex" }}>
                         <div>
 
                             <Link to={`/interview/${this.state.interviewDetails.meetingId}`} ><img src={video_sign} alt="edit requirement" /></Link>
 
                         </div>
                         <div>
                             <DeleteConfirmationModal handleOnDelete={this.handleOnDelete}></DeleteConfirmationModal>
                         </div>
                     </div>
                 </td>
             </React.Fragment>
 
         )
 
 
     }
 }
 
 export default ActionButtons
 