import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col } from 'react-bootstrap'
import './StudentManageAccount.module.scss';
import StudentDashboard from "../../components/DashboardStudent";
import logo_manage_acc from '../../assets/manage account/logo_manage_acc.png';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import UserContext from '../../User/User';
import add_acc_logo from '../../assets/manage account/noprofilepic.jpg';

class StudentManageAccount extends React.Component{
    constructor(){
        super();

    }

    static contextType = UserContext;


    render(){

        //User data
        let {userID,logoutUser,setUser} = this.context;

        return(
            <React.Fragment>
            <section class = "hero-university2">
            </section>
            <section class = "candidate_process">
                <section class = "col-container"> 
                    
                <StudentDashboard/>

                    {/*right expands when dashboard is closed*/}  
                    <div class="right">
                    <div class="topBar">
                    <div class="pageName">
                            <h1>Profile</h1>
                        </div>
                    </div>
                    {/*code here*/}
                    <Container fluid>
                        <Row>
                            <Col xs="12" lg="12">
                                <Row>
                                    <Col xs="12" xl="6" className="mb-3">
                                        <div className="dashboard-component mb-4" style={{background: "#f9f9f9", padding: '40px 40px 40px 40px'}}>
                                            {/* <div className='dashboard-form'> */}
                                                <form>
                                                    {/* <Row> */}
                                                    <h3 className="dashboard-component-title"
                                                    style={{color: '#7a7a7a', fontWeight: '600', fontSize: '1rem'}}
                                                    >
                                                        Basic Information
                                                    </h3>
                                                    {/* </Row> */}
                                                    <Row>
                                                        <Col xs='12' lg='6'>
                                                            <label>Headline</label>
                                                            <input type='text' style={{marginBottom: '20px'}} placeholder='Headline' />
                                                        </Col>
                                                        <Col xs='12' lg='6'>
                                                            <label>Desired Role</label>
                                                            <input type='text' style={{marginBottom: '20px'}} placeholder='Desired Role' />
                                                        </Col>
                                                    </Row>
                                                    <div className='sub-title'>
                                                        <h3 className='dashboard-component-title'
                                                        style={{color: '#7a7a7a', fontWeight: '600', fontSize: '1rem'}}
                                                        >
                                                            Education
                                                        </h3>
                                                        <Row>
                                                            <Col xs='12' lg='6'>
                                                                <label>Institution</label>
                                                                <input type='text' style={{marginBottom: '20px'}} placeholder='Institution' />
                                                            </Col>
                                                            <Col xs='12' lg='6'>
                                                                <label>GPA</label>
                                                                <input type='text' style={{marginBottom: '20px'}} placeholder='0.0' />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' lg='6'>
                                                                <label>Start Date</label>
                                                                <input type='text' style={{marginBottom: '20px'}} placeholder='Start Date' />
                                                            </Col>
                                                            <Col xs='12' lg='6'>
                                                                <label>End Date</label>
                                                                <input type='text' style={{marginBottom: '20px'}} placeholder='End Date' />
                                                            </Col>
                                                        </Row>
                                                        <button className='btn btn-primary'
                                                        style={{marginBottom: '20px', textAlign: 'center'}}
                                                        >
                                                            Add New Education <i className='bi bi-plus-circle'></i>
                                                        </button>
                                                    </div>
                                                    <div className='sub-title'>
                                                        <h3 className='dashboard-component-title'
                                                        style={{color: '#7a7a7a', fontWeight: '600', fontSize: '1rem'}}
                                                        >
                                                            Professional Experience
                                                        </h3>
                                                        <Row>
                                                            <Col xs='12' lg='6'>
                                                                <label>Company</label>
                                                                <input type='text' style={{marginBottom: '20px'}} placeholder='Company' />
                                                            </Col>
                                                            <Col xs='12' lg='6'>
                                                                <label>Role</label>
                                                                <input type='text' style={{marginBottom: '20px'}} placeholder='Role' />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' lg='6'>
                                                                <label>Responsibilities</label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' lg='12'>
                                                                <input type='text' style={{marginBottom: '20px', paddingBottom: '100px'}} placeholder='Responsibilities' />
                                                            </Col>                                                        
                                                        </Row>
                                                        
                                                        <button className='btn btn-primary' style={{marginBottom: '20px', textAlign:'center'}}>Add New Experience <i className='bi bi-plus-circle'></i></button>
                                                    </div>
                                                    
                                                </form>
                                            {/* </div> */}
                                        </div>
                                        
                                    </Col>
                                    <Col xs='12' lg='6'>
                                    <div className="dashboard-component mb-4" style={{background: "#f9f9f9", padding: '40px 40px 40px 40px'}}>
                                        <h3 className="dashboard-component-title"
                                            style={{color: '#7a7a7a', fontWeight: '600', fontSize: '1rem'}}
                                            >
                                                Skills
                                        </h3>
                                    </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container> 
    

                    </div>

                </section>
            </section>

            </React.Fragment>
        )
    }
}

export default StudentManageAccount;
