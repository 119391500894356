import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
//import './Dashboard_Univ.css';
import arrowleft from '../../assets/candidate_images/arrowleft.png';
import HireCandidatesNow from '../../assets/candidate_images/WhiteIcons/HireCandidatesNow.png';
import HiringReqs from '../../assets/candidate_images/WhiteIcons/HiringReqs.png';
import Logout from '../../assets/candidate_images/WhiteIcons/Logout.png';
import ManageAccount from '../../assets/candidate_images/WhiteIcons/ManageAccount.png';
import SavedCandidates from '../../assets/candidate_images/WhiteIcons/SavedCandidates.png';
import ViewCandidates from '../../assets/candidate_images/WhiteIcons/ViewCandidates.png';
import b_ViewCandidates from '../../assets/candidate_images/BlueIcons/b_ViewCandidates.png';
import b_HireCandidatesNow from '../../assets/candidate_images/BlueIcons/b_deal 1.png';
import b_HiringReqs from '../../assets/candidate_images/BlueIcons/b_HiringReqs.png';
import b_Logout from '../../assets/candidate_images/BlueIcons/b_Logout.png';
import b_ManageAccount from '../../assets/candidate_images/BlueIcons/b_ManageAccount.png';
import b_SavedCandidates from '../../assets/candidate_images/BlueIcons/b_SavedCandidates.png';
import UserContext from '../../User/User';

class Candidate_Profile_Univ extends React.Component {
	constructor() {
		super();
		this.state = {
			arrowDirection: 'collapse_arrow_left',
			dashCollapse: 'left',
		};
		this.handleCollapseClick = this.handleCollapseClick.bind(this);
		this.logoutHandler = this.logoutHandler.bind(this);
	}

	static contextType = UserContext;

	componentDidMount() {
		this.logoutHandler();
	}

	logoutHandler() {
		var x = document.getElementById('logoutSection');
		var x1 = ReactDOM.findDOMNode(x).style.display;
		var y = document.getElementById('logoutBox');
		var y1 = ReactDOM.findDOMNode(y).style.display;

		if (x1 === 'none') {
			ReactDOM.findDOMNode(x).style.display = 'flex';
			ReactDOM.findDOMNode(y).style.display = 'flex';
		} else {
			ReactDOM.findDOMNode(x).style.display = 'none';
			ReactDOM.findDOMNode(y).style.display = 'none';
		}
	}

	signOutUser() {
		const context = this.context;
		context.logoutUser();
	}

	handleCollapseClick() {
		if (this.state.arrowDirection === 'collapse_arrow_left') {
			this.setState((prevState) => {
				return {
					arrowDirection: 'collapse_arrow_right',
					dashCollapse: 'left_collapse',
				};
			});
		}
		if (this.state.arrowDirection === 'collapse_arrow_right') {
			this.setState((prevState) => {
				return {
					arrowDirection: 'collapse_arrow_left',
					dashCollapse: 'left',
				};
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<div
					class="logoutSection"
					id="logoutSection"
					onClick={this.logoutHandler}
				>
					<div
						class="logoutBox"
						id="logoutBox"
						onClick={this.logoutHandler}
					>
						<h1> Are you sure you want to logout?</h1>
						<div class="logoutButtons">
							<button class="hvr-sweep" onClick={this.logoutHandler}>
								Cancel
							</button>
							<Link to="/">
								<button
									class="hvr-sweep"
									onClick={() => {
										this.signOutUser();
										this.logoutHandler();
										window.location.reload();
									}}
								>
									Logout
								</button>
							</Link>
						</div>
					</div>
				</div>

				<div class="stay_button_sticky">
					<button
						onClick={this.handleCollapseClick}
						className="stay_button"
					>
						<img className={this.state.arrowDirection} src={arrowleft} />
					</button>
				</div>

				<div class={this.state.dashCollapse}>
					<div class="dash_background">
						<div class="sticky">
							<div className="rectangle">
								<Link to="Class_Progress">
									<button
										className="dash_button"
										style={
											this.props.pageName == 'Class_Progress'
												? { color: '#4BACE9' }
												: { color: '#FFFFFF' }
										}
									>
										<img
											src={
												this.props.pageName == 'Class_Progress'
													? b_ViewCandidates
													: ViewCandidates
											}
										/>
										<h3>Class Progress</h3>
									</button>
								</Link>
								<Link to="Student_Progress">
									<button
										className="dash_button"
										style={
											this.props.pageName == 'Student_Progress'
												? { color: '#4BACE9' }
												: { color: '#FFFFFF' }
										}
									>
										<img
											src={
												this.props.pageName == 'Student_Progress'
													? b_HiringReqs
													: HiringReqs
											}
										/>
										<h3>Student Progress</h3>
									</button>
								</Link>

								<Link to="Manage_Account_Univ">
									<button
										className="dash_button"
										style={
											this.props.pageName == 'Manage_acc'
												? { color: '#4BACE9' }
												: { color: '#FFFFFF' }
										}
									>
										<img
											src={
												this.props.pageName == 'Manage_acc'
													? b_ManageAccount
													: ManageAccount
											}
										/>
										<h3>Manage Account</h3>
									</button>
								</Link>
								<button
									className="dash_button logout"
									onClick={this.logoutHandler}
									style={
										this.props.pageName == 'Logout_id'
											? { color: '#4BACE9' }
											: { color: '#FFFFFF' }
									}
								>
									<img
										src={
											this.props.pageName == 'Logout_id'
												? b_Logout
												: Logout
										}
									/>
									<h3>Log Out</h3>
								</button>

								<button
									onClick={this.handleCollapseClick}
									className="dash_collapse_button"
								>
									<img
										className={this.state.arrowDirection}
										src={arrowleft}
									/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Candidate_Profile_Univ;
