import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Examsheet } from '../../components/ExamFormat.js';
import { Question } from '../../components/ExamFormat.js';
import ReactDOM from 'react-dom';
import CodeMirror from 'react-codemirror';
import Dictionary from '../../assets/questions/DictionarySort.JPG';
import StringNumber from '../../assets/questions/StringNumber.JPG';
import Editor from '../../components/Editor.js';
import Form from '../../assets/questions/Form.JPG';
import Resize from '../../assets/questions/resize.JPG';
import firebase from 'firebase';
import UserContext from '../../User/User';
import * as emailjs from 'emailjs-com'; 
import axios from 'axios';

class ExamJava extends React.Component {
	constructor() {
		super();
		this.state = {
			startTime: new Date(),
			timer: 0,
			percent: 0,
		};
		this.onValueChange = this.onValueChange.bind(this);
		this.numberOfCorrect = this.numberOfCorrect.bind(this);
		this.movePg = this.movePg.bind(this);
		this.percentfunc = this.percentfunc.bind(this);
		this.increase = this.increase.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.applicantId=0;
		this.moodleError='Email was taken';
		this.exam_Flag='pending';
	}
	static contextType = UserContext;
	componentDidMount() {
		this.percentfunc();
		if (typeof this.props.location.state !== 'undefined') {
			this.setState({
				candidateEmail: this.props.location.state.candidateEmail,
				first: this.props.location.state.first,
				last: this.props.location.state.last,
				course: this.props.location.state.course,
			});
		}
		
	}
	async createMoodleAccount(){
		let success = false;
		try{
			await axios.post(`https://lms.minoslabs.com/webservice/rest/server.php?wstoken=01f04ab2f0eacb95cb7909261d5f7fb0&wsfunction=core_user_create_users&users[0][username]=${this.props.location.state.candidateEmail}&users[0][password]=${this.props.location.state.first}${this.props.location.state.last}2!M&users[0][firstname]=${this.props.location.state.first}&users[0][lastname]=${this.props.location.state.last}&users[0][email]=${this.props.location.state.candidateEmail}&moodlewsrestformat=json`)
			.then(async (response) => {

				let data = response.data[0];
				try{
					
						this.moodleError= response.data.message
					
					console.log(response.data.message)
				}catch(err){
						this.moodleError= err
					console.log(err)
				}
				if('id' in data) {
				   success =  true;
					   this.applicantId= data.id
				} else {

				   success =  false;
				   
				}
				
			})
			.catch(err => {
			   console.log(err);
			   
			})

			
			// await axios.get(`https://us-central1-minos-labs-57c35.cloudfunctions.net/createMoodleUser?username=${this.state.email}&first=${this.state.first}&last=${this.state.last}&course=${this.state.course}&email=${this.state.email}`)
			// .then(async(response)=>{
			// 	console.log(response.data);
				
			// })
			// .catch(err =>{
			// 	console.log(err);
			// 	return false;
			// })
			

		}
		catch(e){
			console.log(e);
			
		}

		return success;
		
	}

	addUser = async (event) => {

		//event.preventDefault();
		//const templateId = this.state.courseType;

		const db = firebase.firestore();
		var storage = firebase.storage();
		if(!await this.createMoodleAccount()){
				alert(this.moodleError);
				return;
		}

		//uses firebase
		var templateParams = {
			to_name: this.state.first,
			receiver: this.state.email
			// username: this.state.email,
			
			// password: this.state.first+this.state.last+"2!M"
		};
			
		// emailjs.send('mailgun', 'template_dw8zd0u', templateParams, 'user_ry821S5CQJTzdU5eSuuAk')
		// 	.then(function(response) {
		// 		console.log('SUCCESS!', response.status, response.text);
		// 	}, function(error) {
		// 		console.log('FAILED...', error);
		// 	});
		var correctAnswers = this.numberOfCorrect();
		await db.collection('ALL_USER')
			.doc(this.applicantId.toString())
			.set({
				first: this.props.location.state.first,
				last: this.props.location.state.last,
				email: this.props.location.state.candidateEmail,
				course: this.props.location.state.course,
				// hearAbout: this.state.hearAbout,			
				// gender: this.state.gender,
				// veteran: this.state.veteran,
				// disabilities: this.state.disabilities,
				// progress: 'new',
				candidateID: this.applicantId,
				// grades:[0,0,0,0,0,0],
				// ethnicity:this.state.ethnicity,
				// timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				exam_Flag: this.exam_Flag,
				// password: this.state.first + this.state.last+"2!M",
				// role: 'PT',
				examScore: correctAnswers,
				examDuration: (new Date() - this.state.startTime) / 60000, //Total time in minutes
				examTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
			});

		// for (let i = 1; i <= 3; i++) {
		// 	let name = 'education' + i;
		// 	if (JSON.stringify(this.state[name]) === '{}') {
		// 		continue;
		// 	}
		// 	let education = this.state[name];
		// 	console.log(education);
			
		// 	db.collection("ALL_USER").doc(this.state.applicantId.toString()).collection("education").doc(name).set(
		// 		education
		// 	)
		// 	.then(() => {
		// 		console.log("Document successfully written!");
		// 	})
		// 	.catch((error) => {
		// 		console.error("Error writing document: ", error);
		// 	});
			
		// }

		// for (let i = 1; i <= 3; i++) {
		// 	let name = 'experience' + i;
		// 	if (JSON.stringify(this.state[name]) === '{}') {
		// 		continue;
		// 	}
		// 	let experience = this.state[name];

		// 	db.collection("ALL_USER").doc(this.state.applicantId.toString()).collection("experience").doc(name).set(
		// 		experience
		// 	)
		// 	.then(() => {
		// 		console.log("Document successfully written!");
		// 	})
		// 	.catch((error) => {
		// 		console.error("Error writing document: ", error);
		// 	});
			
		// }

		// const { resume } = this.state;
		// storage
		// 	.ref(
		// 		`resumes/${this.state.first}_${this.state.last}_${this.state.course}`
		// 	)
		// 	.put(resume)
		// 	.then((snapshot) => {
		// 		console.log('Resume uploaded');
		// 		this.setState({ submitted: true });
		// 	});

		// 	this.moveRight();

		// window.emailjs.send('mailgun', templateId).then((res) => {
		// 	console.log('Email successfully sent!');
		// 	console.log(templateId);
		// });
	};


	handleClick(event) {
		console.log("Hii from exam");
		var correctAnswers = this.numberOfCorrect();
		const db = firebase.firestore();
		/*
		db.collection('examApplicants').add({
			first: this.props.location.state.first,
			last: this.props.location.state.last,
			email: this.props.location.state.candidateEmail,
			course: this.props.location.state.course,
			examScore: correctAnswers,
			examDuration: (new Date() - this.state.startTime) / 60000, //Total time in minutes
			examTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
		});
		*/
		if(correctAnswers>=11){
			this.exam_Flag = "pass";
			this.addUser();
			const context = this.context;
			context.setExam("completed");
			// await db.collection('ALL_USER').where("email","==",this.props.location.state.candidateEmail)
			// .get()
			// .then((snapshot) => {
			//   snapshot.forEach((doc) => {
			// 	console.log(doc.id, ' => ', doc.data());
			// 	const docData = doc.data();
			// 	const docID = doc.id;
			// 	if (docData) {
			// 	  db.collection('ALL_USER')
			// 		.doc(docID)
					// .set({
					// 	examScore: correctAnswers,
					// 	examDuration: (new Date() - this.state.startTime) / 60000, //Total time in minutes
					// 	examTimestamp: 12,
					// 	// firebase.firestore.FieldValue.serverTimestamp(),
					// 	exam_Flag: exam_Flag
			// 		});
			// 	}
			//   });
			// });
			//await db.collection('ALL_USER')
			//.doc(this.applicantId.toString())
			//.set({
			// 	examScore: correctAnswers,
			// 	examDuration: (new Date() - this.state.startTime) / 60000, //Total time in minutes
			// 	examTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
			// 	exam_Flag: exam_Flag
			// }, { merge: true });

		}else{
			this.exam_Flag = "fail";
			const context = this.context;
			context.setExam("completed");
		}
		

console.log("examflag",this.exam_Flag)
	}
	onValueChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	}

	numberOfCorrect() {
		var answers = [2, 4, 1, 3, 1, 1, 4, 2, 1, 2, 3, 2, 2, 1, 1, 4]; //Answer key (multiple choice)
		var correct = 0;
		for (let i = 1; i <= answers.length; i++) {
			var name = 'selected' + i.toString();
			var ans = answers[i - 1];
			if (this.state[name] === ans.toString()) {
				correct += 1;
			}
		}
		console.log(correct);
		return correct;
	}

	increase() {
		var lim = 0;
		switch (this.state.curPage) {
			case '2':
				lim = 25;
				break;
			case '3':
				lim = 50;
				break;
			case '4':
				lim = 75;
				break;
		}
		this.setState({
			percent: this.state.percent + 1,
		});
		if (this.state.percent >= lim) {
			clearInterval(this.timer);
		}
	}

	percentfunc() {
		this.timer = setInterval(this.increase, 65);
	}

	movePg(event) {
		const target = event.target;
		const value = target.value;
		var start = 0;
		switch (value) {
			case '3':
				start = 25;
				break;
			case '4':
				start = 50;
				break;
		}
		var pgs = [
			document.getElementById('pg1'),
			document.getElementById('pg2'),
			document.getElementById('pg3'),
			document.getElementById('pg4'),
		];

		for (let i = 0; i < 4; i++) {
			if (Number(value) - 1 == i) {
				ReactDOM.findDOMNode(pgs[i]).style.display = 'block';
			} else {
				ReactDOM.findDOMNode(pgs[i]).style.display = 'none';
			}
		}
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		this.setState(
			{
				curPage: event.target.value,
				percent: start,
			},
			() => {
				this.percentfunc();
			}
		);
	}

	render() {
		return (
			<React.Fragment>
				{/*------------------------------------------------Page 1------------------------------------------------*/}
				<div class="pg1" id="pg1">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-0">
									<text>0%</text>
								</div>
							</div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>

							<form>
								<div class="question">
									<p>1. </p>

									<ul>
										<p>
											Find Output:<br></br>
											<code>
												class StringCompare<br></br>
												&#123;<br></br>
												&ensp;public static void main(String args[])
												<br></br>
												&ensp;&#123; <br></br>
												&ensp;&ensp;String s1 = new String("MINOS
												LABS"); <br></br>
												&ensp;&ensp;String s2 = new String("MINOS
												LABS "); <br></br>
												&ensp;&ensp;if (s1 == s2) <br></br>
												&ensp;&ensp;&ensp;System.out.println("Equal");{' '}
												<br></br>
												&ensp;&ensp;else<br></br>
												&ensp;&ensp;&ensp;System.out.println("Not
												equal"); <br></br>
												&ensp;&#125;<br></br>
												&#125;<br></br>
											</code>
										</p>

										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected1"
													value="1"
													checked={this.state.selected1 === '1'}
													onChange={this.onValueChange}
												/>
												Equal
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected1"
													value="2"
													checked={this.state.selected1 === '2'}
													onChange={this.onValueChange}
												/>
												Not Equal
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected1"
													value="3"
													checked={this.state.selected1 === '3'}
													onChange={this.onValueChange}
												/>
												Error
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected1"
													value="4"
													checked={this.state.selected1 === '4'}
													onChange={this.onValueChange}
												/>
												Nothing
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>2. </p>
									<ul>
										<p>
											_____ is used to find and fix bugs in the Java
											programs.
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected2"
													value="1"
													checked={this.state.selected2 === '1'}
													onChange={this.onValueChange}
												/>
												JVM
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected2"
													value="2"
													checked={this.state.selected2 === '2'}
													onChange={this.onValueChange}
												/>
												JRE
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected2"
													value="3"
													checked={this.state.selected2 === '3'}
													onChange={this.onValueChange}
												/>
												JDK
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected2"
													value="4"
													checked={this.state.selected2 === '4'}
													onChange={this.onValueChange}
												/>
												JDB
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>3. </p>
									<ul>
										<p>
											Which of the following is a valid declaration
											of a char?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected3"
													value="1"
													checked={this.state.selected3 === '1'}
													onChange={this.onValueChange}
												/>
												char ch = '\utea';
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected3"
													value="2"
													checked={this.state.selected3 === '2'}
													onChange={this.onValueChange}
												/>
												char ca = 'tea';
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected3"
													value="3"
													checked={this.state.selected3 === '3'}
													onChange={this.onValueChange}
												/>
												char cr = \u0223;
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected3"
													value="4"
													checked={this.state.selected3 === '4'}
													onChange={this.onValueChange}
												/>
												char cc = '\itea';
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>4. </p>
									<ul>
										<p>
											What does the expression float a = 35 / 0
											return?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected4"
													value="1"
													checked={this.state.selected4 === '1'}
													onChange={this.onValueChange}
												/>
												0
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected4"
													value="2"
													checked={this.state.selected4 === '2'}
													onChange={this.onValueChange}
												/>
												Not a Number
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected4"
													value="3"
													checked={this.state.selected4 === '3'}
													onChange={this.onValueChange}
												/>
												Infinity
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected4"
													value="4"
													checked={this.state.selected4 === '4'}
													onChange={this.onValueChange}
												/>
												Run time exception
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>5. </p>
									<ul>
										<p>
											Evaluate the following Java expression, if x=2,
											y=12, and z=10: ++z + y - y + z + x++
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected5"
													value="1"
													checked={this.state.selected5 === '1'}
													onChange={this.onValueChange}
												/>
												24
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected5"
													value="2"
													checked={this.state.selected5 === '2'}
													onChange={this.onValueChange}
												/>
												23
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected5"
													value="3"
													checked={this.state.selected5 === '3'}
													onChange={this.onValueChange}
												/>
												21
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected5"
													value="4"
													checked={this.state.selected5 === '4'}
													onChange={this.onValueChange}
												/>
												25
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>6. </p>
									<ul>
										<p>
											Given that Student is a class, how many
											reference variables and objects are created by
											the following code?<br></br>
											<code>
												Student studentName, studentId;<br></br>
												studentName = new Student(); <br></br>
												Student stud_class = new Student();{' '}
											</code>
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected6"
													value="1"
													checked={this.state.selected6 === '1'}
													onChange={this.onValueChange}
												/>
												Three reference variables and two objects
												are created.
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected6"
													value="2"
													checked={this.state.selected6 === '2'}
													onChange={this.onValueChange}
												/>
												Two reference variables and two objects are
												created.
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected6"
													value="3"
													checked={this.state.selected6 === '3'}
													onChange={this.onValueChange}
												/>
												One reference variable and two objects are
												created.
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected6"
													value="4"
													checked={this.state.selected6 === '4'}
													onChange={this.onValueChange}
												/>
												Three reference variables and three objects
												are created.
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>7. </p>
									<ul>
										<p>
											Which of the following is a mutable class in
											java?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected7"
													value="1"
													checked={this.state.selected7 === '1'}
													onChange={this.onValueChange}
												/>
												java.lang.String
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected7"
													value="2"
													checked={this.state.selected7 === '2'}
													onChange={this.onValueChange}
												/>
												java.lang.Byte
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected7"
													value="3"
													checked={this.state.selected7 === '3'}
													onChange={this.onValueChange}
												/>
												java.lang.Short
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected7"
													value="4"
													checked={this.state.selected7 === '4'}
													onChange={this.onValueChange}
												/>
												java.lang.StringBuilder
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>8. </p>
									<ul>
										<p>Which of the following is false?</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected8"
													value="1"
													checked={this.state.selected8 === '1'}
													onChange={this.onValueChange}
												/>
												Arraylists, stacks and queues do not have
												fixed sizes.
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected8"
													value="2"
													checked={this.state.selected8 === '2'}
													onChange={this.onValueChange}
												/>
												Adding a value to a sorted array of values
												is less computationally expensive than doing
												the same with a linked list.
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected8"
													value="3"
													checked={this.state.selected8 === '3'}
													onChange={this.onValueChange}
												/>
												Stacks can be used to implement queues.
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected8"
													value="4"
													checked={this.state.selected8 === '4'}
													onChange={this.onValueChange}
												/>
												The main difference between a stack and a
												queue is removal.
											</label>
										</li>
									</ul>
								</div>
							</form>
							<div class="exam-container3">
								<button
									class="hvr-sweep"
									type="submit"
									value="2"
									onClick={this.movePg}
								>
									Next Page
								</button>
							</div>
						</div>
					</section>

					<section></section>
				</div>

				{/*------------------------------------------------Page 2------------------------------------------------*/}

				<div class="pg2" id="pg2">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-1">
									<text>{this.state.percent}%</text>
								</div>
							</div>
							<div className="loadval"></div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>
							<p>
								9.  Sort The Elements in the String below in Alphabetical
								Order<br></br>
								Expected output:
							</p>
							<div class="imageJava">
								<img src={Dictionary} alt="Dictionary" />
								<br></br>
							</div>
							<Editor initialCode="String[] words = { 'Java', 'React', 'Angular', 'Vue'}; " />
							<p>
								10.  Check if a String is a Number <br></br>
								Expected output:
							</p>
							<div class="imageJava">
								<img src={StringNumber} alt="StringNumber" />
								<br></br>
							</div>
							<Editor initialCode="//Write Code here" />
							<div class="exam-container5">
								<button
									type="submit"
									class="hvr-sweep"
									value="1"
									onClick={this.movePg}
								>
									Previous Page
								</button>
								<button
									type="submit"
									class="hvr-sweep"
									value="3"
									onClick={this.movePg}
								>
									Next Page
								</button>
							</div>
						</div>
					</section>
					<section></section>
				</div>

				{/*------------------------------------------------Page 3------------------------------------------------*/}

				<div class="pg3" id="pg3">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-2">
									<text>{this.state.percent}%</text>
								</div>
							</div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>

							<form>
								<div class="question">
									<p>11. </p>
									<ul>
										<p>
											Inside which HTML element do we put the
											JavaScript?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected9"
													value="1"
													checked={this.state.selected9 === '1'}
													onChange={this.onValueChange}
												/>
												&lt;script&gt;
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected9"
													value="2"
													checked={this.state.selected9 === '2'}
													onChange={this.onValueChange}
												/>
												&lt;head&gt;
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected9"
													value="3"
													checked={this.state.selected9 === '3'}
													onChange={this.onValueChange}
												/>
												&lt;meta&gt;
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected9"
													value="4"
													checked={this.state.selected9 === '4'}
													onChange={this.onValueChange}
												/>
												&lt;style&gt;
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>12. </p>
									<ul>
										<p>
											Choose the correct JavaScript syntax to change
											the content of the following HTML code.
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected10"
													value="1"
													checked={this.state.selected10 === '1'}
													onChange={this.onValueChange}
												/>
												document.getElement (“title”).innerHTML =
												”My website”;
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected10"
													value="2"
													checked={this.state.selected10 === '2'}
													onChange={this.onValueChange}
												/>
												document.getElementById (“title”).innerHTML
												= ”My website”;
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected10"
													value="3"
													checked={this.state.selected10 === '3'}
													onChange={this.onValueChange}
												/>
												document.getId (“title”) = ” My website”;
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected10"
													value="4"
													checked={this.state.selected10 === '4'}
													onChange={this.onValueChange}
												/>
												document.getElementById (“title”).innerHTML
												= My website;
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>13. </p>
									<ul>
										<p>
											What will be the output of the following
											Javascript code?<br></br>
											var string1 = ”MINOS LABS”;<br></br>
											var intvalue = 30;<br></br>
											alert( string1 + intvalue );<br></br>
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected11"
													value="1"
													checked={this.state.selected11 === '1'}
													onChange={this.onValueChange}
												/>
												MINOS LABS 30
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected11"
													value="2"
													checked={this.state.selected11 === '2'}
													onChange={this.onValueChange}
												/>
												30
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected11"
													value="3"
													checked={this.state.selected11 === '3'}
													onChange={this.onValueChange}
												/>
												MINOS LABS30
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected11"
													value="4"
													checked={this.state.selected11 === '4'}
													onChange={this.onValueChange}
												/>
												Exception
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>14. </p>
									<ul>
										<p>
											What does javascript use instead of == and !=?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected12"
													value="1"
													checked={this.state.selected12 === '1'}
													onChange={this.onValueChange}
												/>
												It uses bitwise checking
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected12"
													value="2"
													checked={this.state.selected12 === '2'}
													onChange={this.onValueChange}
												/>
												It uses === and !== instead
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected12"
													value="3"
													checked={this.state.selected12 === '3'}
													onChange={this.onValueChange}
												/>
												It uses equals() and notequals() instead
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected12"
													value="4"
													checked={this.state.selected12 === '4'}
													onChange={this.onValueChange}
												/>
												It uses equalto()
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>15. </p>
									<ul>
										<p>
											What is the output of below Javascript code?
											<br></br>
											alert (typeof new Date() );
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected13"
													value="1"
													checked={this.state.selected13 === '1'}
													onChange={this.onValueChange}
												/>
												Throws Error
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected13"
													value="2"
													checked={this.state.selected13 === '2'}
													onChange={this.onValueChange}
												/>
												object
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected13"
													value="3"
													checked={this.state.selected13 === '3'}
													onChange={this.onValueChange}
												/>
												Displays Nothing
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected13"
													value="4"
													checked={this.state.selected13 === '4'}
													onChange={this.onValueChange}
												/>
												Current Date
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>16. </p>
									<ul>
										<p>
											Consider the following code snippet <br></br>
											const pi=3.14;<br></br>
											var pi=4;<br></br>
											console.log(pi);<br></br>
											What will be the output for the above code
											snippet?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected14"
													value="1"
													checked={this.state.selected14 === '1'}
													onChange={this.onValueChange}
												/>
												This will flash an error
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected14"
													value="2"
													checked={this.state.selected14 === '2'}
													onChange={this.onValueChange}
												/>
												Prints 4
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected14"
													value="3"
													checked={this.state.selected14 === '3'}
													onChange={this.onValueChange}
												/>
												Prints 3.14
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected14"
													value="4"
													checked={this.state.selected14 === '4'}
													onChange={this.onValueChange}
												/>
												Ambiguity
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>17. </p>
									<ul>
										<p>
											Consider the code snippet given below<br></br>
											var count = [1,,3];<br></br>
											What is the observation made?
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected15"
													value="1"
													checked={this.state.selected15 === '1'}
													onChange={this.onValueChange}
												/>
												The omitted value takes “undefined”
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected15"
													value="2"
													checked={this.state.selected15 === '2'}
													onChange={this.onValueChange}
												/>
												This results in an error
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected15"
													value="3"
													checked={this.state.selected15 === '3'}
													onChange={this.onValueChange}
												/>
												This results in an exception
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected15"
													value="4"
													checked={this.state.selected15 === '4'}
													onChange={this.onValueChange}
												/>
												The omitted value takes an integer value
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>18. </p>
									<ul>
										<p>
											What will be the output of the following
											JavaScript code?<br></br>
											<code>
												var a = 10;<br></br>
												do&#123;<br></br>
												&ensp;a += 1;<br></br>
												&ensp;console.log(a);<br></br>
												&#125; while (a &lt; 5);
											</code>
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected16"
													value="1"
													checked={this.state.selected16 === '1'}
													onChange={this.onValueChange}
												/>
												11121314
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected16"
													value="2"
													checked={this.state.selected16 === '2'}
													onChange={this.onValueChange}
												/>
												1112
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected16"
													value="3"
													checked={this.state.selected16 === '3'}
													onChange={this.onValueChange}
												/>
												12345
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected16"
													value="4"
													checked={this.state.selected16 === '4'}
													onChange={this.onValueChange}
												/>
												11
											</label>
										</li>
									</ul>
								</div>
							</form>
							<div class="exam-container5">
								<button
									type="submit"
									class="hvr-sweep"
									value="2"
									onClick={this.movePg}
								>
									Previous Page
								</button>
								<button
									type="submit"
									class="hvr-sweep"
									value="4"
									onClick={this.movePg}
								>
									Next Page
								</button>
							</div>
						</div>
					</section>
					<section></section>
				</div>

				{/*------------------------------------------------Page 4------------------------------------------------*/}

				<div class="pg4" id="pg4">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-3">
									<text>{this.state.percent}%</text>
								</div>
							</div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>
							<p>
								19.  Create the Following Form: <br></br>
								Expected output:
							</p>
							<div class="imageJavascript">
								<img src={Form} alt="Dictionary" />
								<br></br>
							</div>
							<Editor initialCode="//JavaScript code " />

							<p>
								20.  Create the following program that gives window size
								(Width/Height) and dynamically changes based on resizing
								<br></br>
								Expected output:
							</p>
							<div class="imageJavascript">
								<img src={Resize} alt="Dictionary" />
								<br></br>
							</div>
							<Editor initialCode="//JavaScript code " />

							<div class="exam-container5">
								<button
									class="hvr-sweep"
									type="submit"
									value="3"
									onClick={this.movePg}
								>
									Previous Page
								</button>
								<Link
									to={{
										pathname: '/exam-complete',
										state: {
											timediff:
												(new Date() - this.state.startTime) / 60000, //Total time in minutes
										},
									}}
								>
									<button
										class="hvr-sweep"
										type="submit"
										onClick={this.handleClick}
									>
										Submit
									</button>
								</Link>
							</div>
						</div>
					</section>

					<section></section>
				</div>
			</React.Fragment>
		);
	}
}

export default ExamJava;
