import React from 'react';
import { Link } from 'react-router-dom';
import styles from './BCReferralProgram.module.scss';
import Education from '../../components/Education';
import Experience from '../../components/Experience';
/*import { withFirebase } from '../Firebase';*/
import firebase, { functions } from 'firebase';
import * as emailjs from 'emailjs-com'; 
import axios from 'axios';
import { getDefaultNormalizer } from '@testing-library/dom';
/*import firestore from "../Firebase/firestore.js"*/
import ReactTooltip from "react-tooltip";

const INITIAL_STATE = {
	bootCamp:'select',
	relocate:'select',
	first: '',
	last: '',
	email: '',
	course: 'select',
	error: null,
	hearAbout: 'select',
	moodleError: "Email was taken",
	courseType: '',
	gender: 'select',
	veteran: 'select',
	disabilities: 'select',
	ethnicity: 'select',
};

class BCReferralProgram extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...INITIAL_STATE,
			filename: 'Upload Resume',
			link: '/Application',
			acceptTerms: false,
			termsContainer: 'terms-container-hide',
			resume: [],
			// applicantId:0,
			submitted: false,
			page:1,
			percent: 10,
			education: 1,
			experience: 1,
			education1: {},
			education2: {},
			education3: {},
			experience1: {},
			experience2: {},
			experience3: {},
		};
		this.applicantId=0;
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleEChange = this.handleEChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
		this.canSubmit = this.canSubmit.bind(this);
		this.handleTermsShow = this.handleTermsShow.bind(this);
		this.handleTermsHide = this.handleTermsHide.bind(this);

		this.createMoodleAccount = this.createMoodleAccount.bind(this);

		this.displayEducation = this.displayEducation.bind(this);
		this.addEducation = this.addEducation.bind(this);
		this.removeEducation = this.removeEducation.bind(this);

		this.displayExperience = this.displayExperience.bind(this);
		this.addExperience = this.addExperience.bind(this);
		this.removeExperience = this.removeExperience.bind(this);
		this.moveRight = this.moveRight.bind(this);
		this.moveLeft = this.moveLeft.bind(this);

		
	}

	moveRight(){
		window.scrollTo(0, 0);
		this.setState({
			page: this.state.page + 1,
		});
	}

	moveLeft(){
		window.scrollTo(0, 0);
		this.setState({
			page: this.state.page - 1,
		});
	}

	addEducation() {
		this.setState({
			education: this.state.education + 1,
		});
		console.log(this.state.education);
	}

	removeEducation() {
		this.setState({
			education: this.state.education - 1,
		});
		console.log(this.state.education);
	}

	displayEducation() {
		let educations = [];
		for (let i = 0; i < this.state.education; i++) {
			educations.push(
				<Education onChange={this.handleEChange} number={i} />
			);
		}
		return educations;
	}

	addExperience() {
		this.setState({
			experience: this.state.experience + 1,
		});
	}

	removeExperience() {
		this.setState({
			experience: this.state.experience - 1,
		});
	}

	displayExperience() {
		let experiences = [];
		for (let i = 0; i < this.state.experience; i++) {
			experiences.push(
				<Experience onChange={this.handleEChange} number={i} />
			);
		}
		return experiences;
	}

	handleTermsShow() {
		this.setState({
			termsContainer: 'terms-container',
		});
	}

	handleTermsHide() {
		this.setState({
			termsContainer: 'terms-container-hide',
		});
	}

	componentDidMount() {
		if (typeof this.props.location.state !== 'undefined') {
			this.setState({
				bootCamp:this.props.location.state.bootCamp,
				relocate:this.props.location.state.relocate,
				first: this.props.location.state.first,
				last: this.props.location.state.last,
				email: this.props.location.state.email,
				course: this.props.location.state.course,
				link: this.props.location.state.link,
				hearAbout: this.props.location.state.hearAbout,
				courseType: this.props.location.state.courseType,
				race: this.props.location.state.race,
				gender: this.props.location.state.gender,
				veteran: this.props.location.state.veteran,
				disbilities: this.props.location.state.disabilities,
				ethnicity: this.props.location.state.ethnicity,
			});
		}
	}

	handleEChange(info, index, type) {
		let name = '';
		if (type === 'education') {
			name = 'education' + (index + 1);
		} else {
			name = 'experience' + (index + 1);
		}
		this.setState({
			[name]: info,
		});

	}

	handleInputChange(event) {
		this.onChange(event);
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});

		if (name === 'course') {
			window.scrollTo(0, 0);
			if (value === 'FullStack') {
				this.setState({
					link: '/exam-pt1',
					courseType: 'fullstack-email',
				});
			} else if (value === 'Cyber') {
				this.setState({
					link: '/cyber-pt1',
					courseType: 'cyber-email',
				});
			}
		}
		if (name === 'acceptTerms') {
			var x = this.state.acceptTerms;
			this.setState({
				acceptTerms: !x,
			});
		}
	}

	validResume = true;
	validateResume() {
		var name = this.state.filename;
		var len = name.length;
		try {
			var form1 = name.substring(len - 4, len);
			var form2 = name.substring(len - 3, len);
			if (
				form1.toLowerCase() === 'docx' ||
				form2.toLowerCase() === 'doc' ||
				form2.toLowerCase() === 'pdf'
			) {
				return true;
			} else {
				this.validResume = false;
				return false;
			}
		} catch (err) {
			console.log(err);
			this.validResume = false;
			return false;
		}
	}

	validateEmail() {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(this.state.email);
	}

	async canSubmit() {
		//console.log(this.state.acceptTerms);
		
		try {
			this.validEmail = true;
			this.validResume = true;
			if (
				this.state.bootCamp!== 'select' &&
				this.state.relocate !== 'select' &&
				this.state.first &&
				this.state.last &&
				this.state.email &&
				this.state.course !== 'select' &&
				this.state.hearAbout !== 'select' &&
				this.state.gender !== 'select' &&
				this.state.veteran!== 'select' &&
				this.state.disabilities !== 'select' &&
				this.state.ethnicity !== 'select' 
			) {
				if (!this.validateEmail()) {
					this.validEmail = false;
					return false;
				} else if (!this.validateResume()) {
					this.validResume = false;
					return false;
				} else {
					if (this.state.acceptTerms) {
						return true;
					} else {
						return false;
					}
				}
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	}

	async createMoodleAccount(){
		let success = false;
		try{
			await axios.post(`https://lms.minoslabs.com/webservice/rest/server.php?wstoken=01f04ab2f0eacb95cb7909261d5f7fb0&wsfunction=core_user_create_users&users[0][username]=${this.state.email}&users[0][password]=${this.state.first}${this.state.last}2!M&users[0][firstname]=${this.state.first}&users[0][lastname]=${this.state.last}&users[0][email]=${this.state.email}&moodlewsrestformat=json`)
			.then(async (response) => {

				let data = response.data[0];
				try{
					this.setState({
						moodleError: response.data.message,
					});
					console.log(response.data.message)
				}catch(err){
					this.setState({
						moodleError: err,
					});
					console.log(err)
				}
				if('id' in data) {
				   success =  true;
				   this.setState({
					   applicantId: data.id,
				   });
				} else {

				   success =  false;
				   
				}
				
			})
			.catch(err => {
			   console.log(err);
			   
			})

			
			// await axios.get(`https://us-central1-minos-labs-57c35.cloudfunctions.net/createMoodleUser?username=${this.state.email}&first=${this.state.first}&last=${this.state.last}&course=${this.state.course}&email=${this.state.email}`)
			// .then(async(response)=>{
			// 	console.log(response.data);
				
			// })
			// .catch(err =>{
			// 	console.log(err);
			// 	return false;
			// })
			

		}
		catch(e){
			console.log(e);
			
		}

		return success;
		
	}

	validEmail = true;
	async handleClick(event) {
		console.log('out');
		if (!await this.canSubmit()) {
			if (
				!(
					this.state.bootCamp !== 'select'  &&
					this.state.relocate !== 'select' &&
					this.state.first &&
					this.state.last &&
					this.state.email &&
					this.state.course !== 'select' &&
					this.state.hearAbout !== 'select' &&
					this.state.gender !== 'select' &&
					this.state.veteran!== 'select' &&
					this.state.disabilities !== 'select' &&
					this.state.ethnicity !== 'select' 
				)
			) {
				this.setState({
					msg: 'Please complete all fields and upload your resume.',
				});
				event.preventDefault();
			} else if (!this.validEmail) {
				this.setState({
					msg: 'Please enter valid email.',
				});
				event.preventDefault();
			} else if (!this.validResume) {
				this.setState({
					msg: 'Please upload a resume in a valid format.',
				});
				event.preventDefault();
			} else {
				this.setState({
					msg:
						'You must accept the Terms of Agreement before taking the assessment.',
				});
				event.preventDefault();
			}
		} else {
			this.addUser(event); 
			
			/*this.addUser(event);       
		 console.log('in');
		 var selectedFile = document.getElementById("upload").files[0]; 
		 var selectedFilename  = selectedFile.name;
		 console.log(selectedFile);
		 console.log(selectedFilename);*/
		 console.log(this.state.first);
		//  var templateParams = {
		// 	to_name: this.state.first,
		// 	receiver: this.state.email
		// };
		 
		// emailjs.send('mailgun', 'template_dw8zd0l', templateParams)
		// 	.then(function(response) {
		// 	   console.log('SUCCESS!', response.status, response.text);
		// 	}, function(error) {
		// 	   console.log('FAILED...', error);
		// 	});


		}

	}

	handleChange(event) {
		this.setState({ feedback: event.target.value });
	}

	handleSubmit() {}

	handleSubmit(event) {
		const templateId = 'template_id';

		this.sendFeedback(templateId, {
			message_html: this.state.feedback,
			from_name: this.state.name,
			reply_to: this.state.email,
		});
	}

	sendFeedback(templateId, variables) {
		window.emailjs
			.send('full-stack-email')
			.then((res) => {
				console.log('Email successfully sent!');
			})
			// Handle errors here however you like, or use a React error boundary
			.catch((err) =>
				console.error(
					'Oh well, you failed. Here are some thoughts on the error that occured:',
					err
				)
			);
	}

	uploadFile(event) {
		let file = event.target.files[0];
		if (file) {
			this.setState(
				{
					resume: file,
					filename: `${file.name}`,
				},
				() => {
					console.log(this.state.resume);
					console.log(this.state.filename);
				}
			);
		}
	}

	 addUser = async (event) => {

		event.preventDefault();
		const templateId = this.state.courseType;

		const db = firebase.firestore();
		var storage = firebase.storage();
		// if(!await this.createMoodleAccount()){
		// 		alert(this.state.moodleError);
		// 		return;
		// }

		//uses firebase
		var templateParams = {
			to_name: this.state.first,
			receiver: this.state.email
			// username: this.state.email,
			
			// password: this.state.first+this.state.last+"2!M"
		};
			
		emailjs.send('mailgun', 'template_pcwrguy', templateParams, 'user_ry821S5CQJTzdU5eSuuAk')
			.then(function(response) {
				console.log('SUCCESS!', response.status, response.text);
			}, function(error) {
				console.log('FAILED...', error);
			});

			await db.collection('ALL_USER').where("email","==",this.state.email)
			.get()
			.then((snapshot) => {
			snapshot.forEach((doc) => {
				console.log(doc.id, ' => ', doc.data());
				const docData = doc.data();
				this.applicantId=doc.id;
				console.log(this.applicantId)
			})
		})
		 
		await db.collection('ALL_USER')
			.doc(this.state.applicantId.toString())
			.set({
				bootCamp: this.state.bootCamp,
				relocate: this.state.relocate,
				hearAbout: this.state.hearAbout,			
				gender: this.state.gender,
				veteran: this.state.veteran,
				disabilities: this.state.disabilities,
				progress: 'new',
				grades:[0,0,0,0,0,0],
				ethnicity:this.state.ethnicity,
				password: this.state.first + this.state.last+"2!M",
			}, { merge: true });

		for (let i = 1; i <= 3; i++) {
			let name = 'education' + i;
			if (JSON.stringify(this.state[name]) === '{}') {
				continue;
			}
			let education = this.state[name];
			console.log(education);
			
			db.collection("ALL_USER").doc(this.applicantId.toString()).collection("education").doc(name).set(
				education
			)
			.then(() => {
				console.log("Document successfully written!");
			})
			.catch((error) => {
				console.error("Error writing document: ", error);
			});
			
		}

		for (let i = 1; i <= 3; i++) {
			let name = 'experience' + i;
			if (JSON.stringify(this.state[name]) === '{}') {
				continue;
			}
			let experience = this.state[name];

			db.collection("ALL_USER").doc(this.applicantId.toString()).collection("experience").doc(name).set(
				experience
			)
			.then(() => {
				console.log("Document successfully written!");
			})
			.catch((error) => {
				console.error("Error writing document: ", error);
			});
			
		}

		const { resume } = this.state;
		storage
			.ref(
				`resumes/${this.state.first}_${this.state.last}_${this.state.course}`
			)
			.put(resume)
			.then((snapshot) => {
				console.log('Resume uploaded');
				this.setState({ submitted: true });
			});

			this.moveRight();

		// window.emailjs.send('mailgun', templateId).then((res) => {
		// 	console.log('Email successfully sent!');
		// 	console.log(templateId);
		// });
	};

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const {
			bootCamp,
			relocate,
			first,
			last,
			email,
			course,	
			hearAbout,	
			gender,
			veteran,
			disabilities,
			ethnicity,
			error,
		} = this.state;

		const currYear = new Date().getFullYear();

		const progress ="prog" + (this.state.page-1);
		const progPercent = 20*(this.state.page-1)+"%";

		return (
			<React.Fragment>
				
				<div className="hero-university2"></div>
				<section className={styles.appContainer}>
				<section className={styles.examContainer4}>
						<div className={styles.progress}>
							<div className={styles.stripes}>
								<div className={`${styles.progVal} ${styles[progress]}`}>
									<text>{progPercent}</text>
								</div>
							</div>
						</div>
					</section>
					

					<div style={{display: this.state.page==1 ? "" : "none"}}className={styles.container}>
						<h1>Welcome to Minos Labs!</h1>

						<div
							style={{
								display:
									this.state.course === 'select' ? 'block' : 'none',
							}}
							className={styles.bold}
						>
							The next step is to create your candidate profile!
							<br></br>
							<br></br>
							This is what our client companies and partnered hiring managers are going to see, so put your best foot forward and fill in all the information below!
						</div>

						<div
							style={{
								display:
									this.state.course === 'FullStack' ? 'block' : 'none',
							}}
						>
							<p>
								Minos Labs focuses on supporting upcoming initiatives at
								Fortune 1000 Organizations within Full-Stack Development
								and Cybersecurity by Screening, Training, and Assessing
								top technology talent looking to accelerate their
								careers within the field
							</p>
							<h2>What We Offer</h2>
							<ul>
								<li>
									6-week accelerated training focused on industry
									specific technologies and skillsets
								</li>
								<li>
									Learn industry specific skills used by our clients
								</li>
								<li>Apply leading project management methodologies</li>
								<li>
									Collaborate with your peers and experts in the space
									alike
								</li>
								<li>Executive coaching by industry leading mentors</li>
								<li>
									The opportunity to work for a Fortune 1000
									organization while networking with an Advisory board
									made up of C-Level Executives and Directors
								</li>
							</ul>

							<h2>The ideal candidate</h2>
							<p>Bachelor’s degree in:</p>
							<ul style={{ marginTop: '10px' }}>
								<li>Computer Science</li>
								<li>Management Information Systems</li>
								<li>Cybersecurity</li>
								<li>Computer Engineering</li>
								<li>Information Technology</li>
								<li>Or a recent graduate of a coding bootcamp</li>
							</ul>

							<p>
								A strong interest in technology and the drive to excel
								Able to work remotely and efficiently over a 6-week,
								training period, 30 hours per week. Strong communication
								and interpersonal skills A Problem solver who looks for
								solutions and takes initiative Willing to relocate
								within the US if needed, to accelerate your career
								Remote work opportunities are currently open as well
							</p>

							<h2>Preferred</h2>
							<p>Skills:</p>
							<ul>
								<li>Proficiency with: Java, Java Script</li>
								<li>
									Exposure to: React.JS, Angular.JS, Vue.JS, Python,
									SQL
								</li>
							</ul>

							<h2>Culture</h2>
							<p>
								At Minos Labs, our core values include Diversity,
								Transparency, Adaptability, and Accountability. We aim
								to create the technology leaders of tomorrow by giving
								Minos Labs associates exposure to industry specific,
								relevant skills to ensure they are ahead of the
								competition and ready to launch a successful career in
								Full-Stack development or Cybersecurity
							</p>

							<h2>Quick Apply Below!</h2>
							<p style={{ fontSize: '14px' }}>
								Minos Labs is an Equal Opportunity Employer. All
								qualified applicants will receive consideration for
								employment without regard to race, color, religion, sex,
								sexual orientation, national origin, age, disability,
								veteran status, or any other status protected by
								federal, provincial, or local laws
							</p>
						</div>

						<div
							style={{
								display:
									this.state.course === 'Cyber' ? 'block' : 'none',
							}}
						>
							<p>
								Minos Labs focuses on supporting upcoming initiatives at
								Fortune 1000 Organizations within Full-Stack Development
								and Cybersecurity by Screening, Training, and Assessing
								top technology talent looking to accelerate their
								careers within the field
							</p>
							<h2>What We Offer</h2>
							<ul>
								<li>
									6-week accelerated training focused on industry
									specific technologies and skillsets
								</li>
								<li>
									Learn industry specific skills used by our clients
								</li>
								<li>Apply leading project management methodologies</li>
								<li>
									Collaborate with your peers and experts in the space
									alike
								</li>
								<li>Executive coaching by industry leading mentors</li>
								<li>
									The opportunity to work for a Fortune 1000
									organization while networking with an Advisory board
									made up of C-Level Executives and Directors
								</li>
							</ul>

							<h2>The ideal candidate</h2>
							<p>Bachelor’s degree in:</p>
							<ul style={{ marginTop: '10px' }}>
								<li>Computer Science</li>
								<li>Management Information Systems</li>
								<li>Cybersecurity</li>
								<li>Computer Engineering</li>
								<li>Information Technology</li>
								<li>Or a recent graduate of a coding bootcamp</li>
							</ul>

							<p>
								A strong interest in technology and the drive to excel
								Able to work remotely and efficiently over a 6-week,
								training period, 30 hours per week. Strong communication
								and interpersonal skills A Problem solver who looks for
								solutions and takes initiative Willing to relocate
								within the US if needed, to accelerate your career
								Remote work opportunities are currently open as well
							</p>

							<h2>Preferred</h2>
							<p>Skills:</p>
							<ul>
								<li>Proficiency with: Python, Java</li>
								<li>
									Preferred: CompTIA Security+ certification, Linux,
									AWS, Azure, SQL
								</li>
							</ul>

							<h2>Culture</h2>
							<p>
								At Minos Labs, our core values include Diversity,
								Transparency, Adaptability, and Accountability. We aim
								to create the technology leaders of tomorrow by giving
								Minos Labs associates exposure to industry specific,
								relevant skills to ensure they are ahead of the
								competition and ready to launch a successful career in
								Full-Stack development or Cybersecurity
							</p>

							<h2>Quick Apply Below!</h2>
							<p style={{ fontSize: '14px' }}>
								Minos Labs is an Equal Opportunity Employer. All
								qualified applicants will receive consideration for
								employment without regard to race, color, religion, sex,
								sexual orientation, national origin, age, disability,
								veteran status, or any other status protected by
								federal, provincial, or local laws
							</p>
						</div>

						<form
							style={{
								display: this.state.submitted ? 'none' : 'block',
							}}
							
						>
							<input
								name="first"
								value={first}
								onChange={this.handleInputChange}
								type="text"
								placeholder="First Name"
							/>

							<div className={styles.inputgap1}></div>

							<input
								name="last"
								value={last}
								onChange={this.handleInputChange}
								type="text"
								placeholder="Last Name"
							/>

							

							<input
								className={styles.email}
								name="email"
								value={email}
								onChange={this.handleInputChange}
								type="text"
								placeholder="Email address"
							/>

<select
								name="bootCamp"
								value={bootCamp}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									BootCamp Program
								</option>
								<option value="rithm">Rithm School</option>
								<option value="flatiron">Flatiron School</option>
								<option value="general">General Assembly</option>
								<option value="dojo">Coding Dojo</option>

							</select>		
							<select
								name="relocate"
								value={relocate}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Relocation Options
								</option>
								<option value="notWilling">Not willing to relocate</option>
								<option value="willingState">Willing to work within the state</option>
								<option value="willingRegion">Willing to work within the Region</option>
								<option value="willingCountry">Willing to work anywhere in the Country</option>

							</select>							

							<select
								name="course"
								value={course}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Please Select Program
								</option>
								<option value="FullStack">
									Full-Stack Development
								</option>
								<option value="Cyber">Cybersecurity</option>
							</select>

						</form>

						<div style={{justifyContent:"flex-end"}} className={styles.buttonWrapper}>
							
							<button className={styles.buttonRight} onClick={this.moveRight}>Continue</button>
						</div>


					</div>







{/*page 2 -----------------------------------------------------------*/}








					<div style={{display: this.state.page==2 ? "" : "none"}}className={styles.container}>
						<h1>Welcome to Minos Labs!</h1>

						<form
							style={{
								display: this.state.submitted ? 'none' : 'block',
							}}
						
						>

							<div className={styles.inputgap3}></div>


							<select
								name="ethnicity"
								value={ethnicity}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Ethnicity
								</option>
								<option value="AmericanIndian">
									American Indian or Alaska Native
								</option>
								<option value="Asian">Asian</option>
								<option value="Black">Black or African American</option>
								<option value="Hispanic">Hispanic or Latino</option>
								<option value="NativeHawaiianPacificIslander">
									Native Hawaiian or other Pacific Islander
								</option>
								<option value="Not">Not Specified</option>
								<option value="TwoOrMore">Two or more races</option>
								<option value="White">White</option>
								<option value="NotAnswer">
									I do not wish to answer
								</option>
							</select>

							<select
								name="gender"
								value={gender}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Please Select Gender
								</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
								<option value="preferNot">Prefer Not to Say</option>
							</select>

							<select
								name="veteran"
								value={veteran}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Veteran Status
								</option>
								<option value="veteran">
									I am a protected veteran
								</option>
								<option value="notVeteran">
									I am not a protected veteran
								</option>
								<option value="preferNot">Prefer Not to Say</option>
							</select>

							<select
								name="disabilities"
								value={disabilities}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Disability Status
								</option>
								<option value="disabled">
									Yes, I have a disability, or have a history/record of
									having a disability
								</option>
								<option value="notDisabled">
									No, I don't have a disability, or a history/record of
									having a disability
								</option>
								<option value="preferNot">Prefer Not to Say</option>
							</select>
							<br></br>
		
						</form>
						<div
							style={{
								marginTop: '30px',
								display: this.state.submitted ? 'block' : 'none',
							}}
						>
							<h1
								style={{
									lineHeight: '30px',
									textAlign: 'center',
									marginTop: '40px',
								}}
							>
								Application Submitted
							</h1>
							<p
								style={{
									fontSize: '20px',
									lineHeight: '160%',
									color: '#4b5566',
								}}
							>
								Thank you for your interest in Minos Labs. We have
								received your application and will reach out to you if
								you are a good fit for the program.
							</p>
							<Link to="/">
								<button className={'hvr-sweep'}>
									Return to Home Page
								</button>
							</Link>
						</div>

						<div className={styles.buttonWrapper}>
							<button className={styles.buttonLeft} onClick={this.moveLeft}>Back</button>
							<button className={styles.buttonRight} onClick={this.moveRight}>Continue</button>
						</div>
					</div>








{/*page 3 -----------------------------------------------------------*/}





<div style={{display: this.state.page==3 ? "" : "none"}}className={styles.container}>
						<h1>Welcome to Minos Labs!</h1>

							<div style={{ display: 'flex' }}>
								<h1 className={styles.resumeTitle}>Education</h1>
							</div>
							{this.displayEducation()}
							<div className={styles.gridc}>	
							<div>
							<button data-tip data-for="addTip" onClick={this.addEducation} className={styles.addButton} 
							style={{
										display:
											this.state.education >= 3 ? 'none' : 'block',
									}}>Add
								<i
									className={`${styles.plusButton} fa fa-plus-square`}
								></i>
								
								</button>
								<ReactTooltip id="addTip" place="top" effect="solid">
									Add an education
								</ReactTooltip>	
							</div>

								<div>
								<button data-tip data-for="removeTip" onClick={this.removeEducation} className={styles.addButton} style={{
										display:
											this.state.education > 1 ? 'block' : 'none',
									}}>Remove
								<i
									className={`${styles.minusButton} fa fa-minus-square`}
								></i>
								</button>
								<ReactTooltip id="removeTip" place="top" effect="solid">
									Remove the last education
								</ReactTooltip>	
								</div>
								
							</div>

						<div className={styles.buttonWrapper}>
							<button className={styles.buttonLeft} onClick={this.moveLeft}>Back</button>
							<button className={styles.buttonRight} onClick={this.moveRight}>Continue</button>
						</div>

					</div>









					{/*page 4 -----------------------------------------------------------*/}



					<div style={{display: this.state.page==4 ? "" : "none"}}className={styles.container}>
						<h1>Welcome to Minos Labs!</h1>


						<div style={{ display: 'flex' }}>
								<h1 className={styles.resumeTitle}>Experience</h1>
						</div>
						{this.displayExperience()}
						<div className={styles.gridc}>	
							<div>
							<button data-tip data-for="addTip" onClick={this.addExperience} className={styles.addButton} 
							style={{
										display:
											this.state.experience >= 3 ? 'none' : 'block',
									}}>Add
								<i
									className={`${styles.plusButton} fa fa-plus-square`}
								></i>
								
								</button>
								<ReactTooltip id="addTip" place="top" effect="solid">
									Add an experience
								</ReactTooltip>	
							</div>

								<div>
								<button data-tip data-for="removeTip" onClick={this.removeExperience} className={styles.addButton} style={{
										display:
											this.state.experience > 1 ? 'block' : 'none',
									}}>Remove
								<i
									className={`${styles.minusButton} fa fa-minus-square`}
								></i>
								</button>
								<ReactTooltip id="removeTip" place="top" effect="solid">
									Remove the last experience
								</ReactTooltip>	
								</div>
								
							</div>


							<div className={styles.buttonWrapper}>
							<button className={styles.buttonLeft} onClick={this.moveLeft}>Back</button>
							<button className={styles.buttonRight} onClick={this.moveRight}>Continue</button>
							</div>
	
						</div>
					








					{/*page 5 -----------------------------------------------------------*/}





					<div style={{display: this.state.page==5 ? "" : "none"}}className={styles.container}>
						<h1>Welcome to Minos Labs!</h1>



						<form
							style={{
								display: this.state.submitted ? 'none' : 'block',
							}}
							
						>

							<select
								name="hearAbout"
								value={hearAbout}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Where did you hear about us?
								</option>
								<option value="LinkedIn">LinkedIn</option>
								<option value="Instagram">Instagram</option>
								<option value="JobBoard">Job Board</option>
								<option value="Referal">Referral</option>
								<option value="other">Other</option>
							</select>

							<label
								for="upload"
								className={`${styles.appButton} ${'hvr-sweep'}`}
							>
								<input
									id="upload"
									type="file"
									accept=".pdf, .doc,.docx"
									onChange={this.uploadFile}
								/>
								{this.state.filename}
							</label>

							<label className={styles.checkmarkbox}>
								You must accept the{' '}
								<Link to="/privacy-policy">Terms of Agreement</Link>{' '}
								before applying.
								<input
									type="checkbox"
									name="acceptTerms"
									onClick={
										('checked',
										this.state.acceptTerms ===
											!this.state.acceptTerms)
									}
									onChange={this.handleInputChange}
								/>
								<span className={styles.checkmark}></span>
							</label>

							<div className={styles.errormsg}>{this.state.msg}</div>
						</form>
						{/* <div
							style={{
								marginTop: '30px',
								display: this.state.submitted ? 'block' : 'none',
							}}
						>
							<h1
								style={{
									lineHeight: '30px',
									textAlign: 'center',
									marginTop: '40px',
								}}
							>
								Application Submitted
							</h1>
							<p
								style={{
									fontSize: '20px',
									lineHeight: '160%',
									color: '#4b5566',
								}}
							>
								Thank you for your interest in Minos Labs. We have
								received your application and will reach out to you if
								you are a good fit for the program.
							</p>
							<Link to="/">
								<button className={'hvr-sweep'}>
									Return to Home Page
								</button>
							</Link>
						</div> */}

						<div className={styles.buttonWrapper}>
							<button className={styles.buttonLeft} onClick={this.moveLeft}>Back</button>
							<button 						
								name="msg"
								value="T" className={styles.buttonRight} onClick={this.handleClick}>Submit</button>
						</div>
					</div>






					{/*page 5 -----------------------------------------------------------*/}





					<div style={{display: this.state.page==6 ? "" : "none"}}className={styles.container}>
						<h1>Application Submitted!</h1>

						<div
							style={{
								marginTop: '30px',
							}}
						>

							<p
								style={{
									fontSize: '20px',
									lineHeight: '160%',
									color: '#4b5566',
								}}
							>
								Thank you for your interest in Minos Labs. We have
								received your application and will reach out to you if
								you are a good fit for the program.
								<br></br>
								<br></br>
								<p style={{
									fontSize: '16px',
									lineHeight: '160%',
									color: '#4b5566',
								}}>You can proceed with the initial assessment or return to the Home page</p>
							</p>
		
						</div>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<div className={styles.buttonWrapper}>
							<Link to="/">
								<button className={styles.buttonLeft}>Return Home</button>
							</Link>
							
							<Link to="/bc-exam-start">
								<button className={styles.buttonRight}>Assessment</button>
							</Link>
						</div>
							

						</div>
					


				</section>
				{/* <script type="text/javascript"
        			src="https://cdn.jsdelivr.net/npm/emailjs-com@2/dist/email.min.js">
				</script>
				<script type="text/javascript">
					(function(){
						emailjs.init("user_ry821S5CQJTzdU5eSuuAk")
					})();
				</script> */}
				
			</React.Fragment>
		);
	}
}

export default BCReferralProgram;
