import React from "react";
import styles from "./AdminCommandCenter.module.scss";
import Dashboard from "../../components/AdminDashboard";
import firebase from "firebase";
import UserContext from "../../User/User";
import add_acc_logo from "../../assets/manage account/noprofilepic.jpg";
import { Pie } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import edit_sign from "../../assets/hiring_requirement/edit_sign.png";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import HR_Select from "../../components/HRSelect";
import MonthlyNav from "../../components/DashboardCalendar.js"
import * as emailjs from 'emailjs-com'; 
import {Button, Modal, Icon, Header, FormField, Label} from 'semantic-ui-react';
import {Formik, Form, Field, ErrorMessage} from 'formik'; 
import * as Yup from 'yup'; 
import axios from 'axios';
const CryptoJS = require('crypto-js');



// const diversity = {
//   labels: [
//     "Black/African American",
//     "White/Caucasian ",
//     "Native American",
//     "Asian/Pacific Islander",
//     "Hispanic/Latin American",
//     "2 or more races",
//     "Prefer not to identify",
//   ],
//   datasets: [
//     {
//       label: "Diversity",
//       backgroundColor: [
//         "#4285F4",
//         "#EA4335",
//         "#FBBC04",
//         "#34A853",
//         "#FF6D01",
//         "#46BDC6",
//         "#7BAAF7",
//       ],
//       hoverBackgroundColor: [
//         "#152b4f",
//         "#4f1712",
//         "#4f3b02",
//         "#194f27",
//         "#4f2200",
//         "#1c4b4f",
//         "#28374f",
//       ],
//       data: [americanIndian, asian, black, hispanic, nativeHawi, na, white ],
//     },
//   ],
// };

class CommandCenter extends React.Component {
  constructor() {
    super();

    this.state = {
      courses: {},
      roles:{},
      veteran:{},
      show:"diversity",
      diversity:{},
      gender: {},
      
      //show:"veteran",
      hiringModal: false, 
      successModal: false
    };

		this.showChart1 = this.showChart1.bind(this);
		this.showChart2 = this.showChart2.bind(this);
		this.showChart3 = this.showChart3.bind(this);
    this.showChart4 = this.showChart4.bind(this)
    this.handleModalToggle = this.handleModalToggle.bind(this);

    
	}

	showChart1() {
		this.setState({
			show: "veteran",
		});
		//console.log(this.state.show);
	}
  showChart2() {
		this.setState({
			show: "diversity",
		});
		//console.log(this.state.show);
	}
  showChart3() {
		this.setState({
			show: "role",
		});
		//console.log(this.state.show);
	}
  showChart4(){
    this.setState({
      show: "gender",
    });
  }


  static contextType = UserContext;
  async componentDidMount() {
    const db = firebase.firestore();
    let fullstack = 0;
    let cyber = 0;
    let PT = 0;
    let CAS = 0;
    let vet_status = 0;
    let nap = 0
    let americanIndian = 0;
    let asian = 0;
    let black = 0;
    let hispanic = 0;
    let nativeHawi = 0;
    let na = 0;
    let twoOrMore = 0;
    let white = 0;
    let female = 0;
    let male = 0;
    let fsArray=[]
    let cyberArray=[]

    

    await db
    .collection('ALL_USER')
    .get()
    .then(async(querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data());
      //console.log(data);
      for(let index = 0;index<data.length;index++){

        if(data[index].candidateID>=10400){
          if(typeof data[index].ethnicity=="string"){
            if(data[index].course=="Cyber"){
              try{
                await axios.post(`https://lms.minoslabs.com/webservice/rest/server.php?wstoken=01f04ab2f0eacb95cb7909261d5f7fb0&wsfunction=enrol_manual_enrol_users&enrolments[0][roleid]=5&enrolments[0][userid]=${data[index].candidateID}&enrolments[0][courseid]=3`)
                .then(async (response) => {
                  //console.log(response)                  
                })
                .catch(err => {
                 console.log(err);
                 
                })
         
             }
             catch(e){
               console.log(e);
               
             }
            }
            else{
              
              try{
                await axios.post(`https://lms.minoslabs.com/webservice/rest/server.php?wstoken=01f04ab2f0eacb95cb7909261d5f7fb0&wsfunction=enrol_manual_enrol_users&enrolments[0][roleid]=5&enrolments[0][userid]=${data[index].candidateID}&enrolments[0][courseid]=2`)
                .then(async (response) => {
                  //  console.log(response)                  
                })
                .catch(err => {
                 console.log(err);
                 
                })
         
             }
             catch(e){
               console.log(e);
               
             }


            }
          }
        }
        if(data[index].course==="FullStack"){
          fullstack++;
        }else{
          cyber++;
        }
  
        if(data[index].role==="CAS"){
          CAS++;
        }else{
          PT++;
        }
        if (data[index].veteran === "veteran"){
          vet_status++;
        }
        else{
          nap++;
        }
        //this is grabbing the information for ethnicity
        if(data[index].ethnicity === 'AmericanIndian'){
          americanIndian++;
        } else if(data[index].ethnicity === 'Asian'){
          asian++;
        } else if(data[index].ethnicity ==='Black'){
          black++;
        } else if(data[index].ethnicity === 'Hispanic'){
          hispanic++;
        } else if(data[index].ethnicity === 'NativeHawaiianPacificIslander'){
          nativeHawi++;
        } else if(data[index].ethnicity === 'NotAnswer' || data[index].ethnicity === 'Not'){
          na++;
        } else if(data[index].ethnicity === 'TwoOrMore'){
          twoOrMore++;
        } else{
          white++;
        }

        //this is grabbing gender information
        if(data[index].gender === 'female'){
          female++;
        }else{
          male++;
        }


      }
      //console.log("FSD Enrolled: "+fsArray+" "+fsArray.length)
      //console.log("Cyber Enrolled: "+ cyberArray+" "+cyberArray.length)


    });

    const courses = {
      labels: [
        "Full-Stack",
        "Cyber",
      ],
      datasets: [
        {
          label: "Course",
          backgroundColor: [
            "#4285F4",
            "#EA4335",
            "#FBBC04",
            "#34A853",
            "#FF6D01",
            "#46BDC6",
            "#7BAAF7",
          ],
          hoverBackgroundColor: [
            "#152b4f",
            "#4f1712",
            "#4f3b02",
            "#194f27",
            "#4f2200",
            "#1c4b4f",
            "#28374f",
          ],
          data: [fullstack, cyber],
        },
      ],
    };
    const diversity = {
      labels: [
        "Black/African American",
        "White/Caucasian ",
        "Native American",
        "Asian",
        "Hispanic/Latin American",
        "Native Hawaiian/Pacific Islander",
        "2 or more races",
        "Prefer not to identify",
      ],
      datasets: [
        {
          label: "Diversity",
          backgroundColor: [
            "#4285F4",
            "#EA4335",
            "#FBBC04",
            "#34A853",
            "#FF6D01",
            "#46BDC6",
            "#7BAAF7",
          ],
          hoverBackgroundColor: [
            "#152b4f",
            "#4f1712",
            "#4f3b02",
            "#194f27",
            "#4f2200",
            "#1c4b4f",
            "#28374f",
          ],
          data: [black, white, americanIndian, asian, hispanic ,nativeHawi, twoOrMore ,na],
        },
      ],
    };

    const roles = {
      labels: [
        "CAS",
        "PT",
      ],
      datasets: [
        {
          label: "Student Role",
          backgroundColor: [
            "#4285F4",
            "#EA4335",
            "#FBBC04",
            "#34A853",
            "#FF6D01",
            "#46BDC6",
            "#7BAAF7",
          ],
          hoverBackgroundColor: [
            "#152b4f",
            "#4f1712",
            "#4f3b02",
            "#194f27",
            "#4f2200",
            "#1c4b4f",
            "#28374f",
          ],
          data: [CAS, PT],
        },
      ],
    };

    const veteran = {
      labels: [
        "Not Applicable",
        "Veteran",
      ],
      datasets: [
        {
          label: "Veteran",
          backgroundColor: [
            "#4285F4",
            "#EA4335",
            "#FBBC04",
            "#34A853",
            "#FF6D01",
            "#46BDC6",
            "#7BAAF7",
          ],
          hoverBackgroundColor: [
            "#152b4f",
            "#4f1712",
            "#4f3b02",
            "#194f27",
            "#4f2200",
            "#1c4b4f",
            "#28374f",
          ],
          data: [nap, vet_status],
        },
      ],
    };
    const gender = {
      labels: [
        "Female",
        "Male",
      ],
      datasets: [
        {
          label: "Gender",
          backgroundColor: [
            "#4285F4",
            "#EA4335",
            "#FBBC04",
            "#34A853",
            "#FF6D01",
            "#46BDC6",
            "#7BAAF7",
          ],
          hoverBackgroundColor: [
            "#152b4f",
            "#4f1712",
            "#4f3b02",
            "#194f27",
            "#4f2200",
            "#1c4b4f",
            "#28374f",
          ],
          data: [female, male],
        },
      ],
    };

    this.setState({
      courses:courses,
      roles:roles,
      veteran:veteran,
      diversity:diversity,
      gender: gender
    });
  }

  handleModalToggle(value) {
		this.setState({
      hiringModal: value,
    });
	}

  handleSuccessModalToggle(value) { 
		this.setState({
      successModal: value,
    });
	}

  handleCreateUser(value) {

		this.setState({
      hiringModal: false,
    });

    var templateParams = {
			to_name: value.firstName,
			to_email: value.email,
			password: value.password,
		};

    const encryptedPassword = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(value.password))


    //Adding a reference to the firestore database
    const db = firebase.firestore();
    const userRef = db.collection('corporate').add({
      first: value.firstName,
      last: value.lastName,
      email: value.email,
      password: encryptedPassword,
      role: value.role, 
      company: value.company,
      phoneNumber:value.phoneNumber,
      type: 'Corporate', 
      savedCandidates: [], 
    }).then(function(docRef) {
      db.collection('corporate').doc(docRef.id).update({
        userID: docRef.id
      })
    }).then(
      emailjs.send('mailgun', 'template_6v1rpln', templateParams, 'user_ry821S5CQJTzdU5eSuuAk')
			.then(function(response) {
			   console.log('SUCCESS!', response.status, response.text);
			}, function(error) {
			   console.log('FAILED...', error);
			})
    );  

    this.setState({
      successModal: true,
    });
	}

 

  render() {
    //User data
    let { userID, logoutUser, setUser, loading, userInfo } = this.context;
    let dataSet = this.state.veteran;
    console.log("IF YOU SEE THIS THEN YOU WILL BE CONFUSED!")
    // let dataSet = this.state.diversity;
    const initialValues = {
      name: '', 
      role: '', 
      company: '', 
      email: ''
    }
    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required('Please enter a first name.'), 
      lastName: Yup.string().required('Please enter a last name.'), 
      role: Yup.string().required('Please enter a role.'), 
      email: Yup.string().email('Invalid Email Format').required('Please enter a valid email'),
      password: Yup.string().required('Please enter a password').min(6, 'Password should be 6 characters long'), 
      company: Yup.string().required('Please enter a company.'), 
      phoneNumber: Yup.string().required('Please enter your phone number'),
  })
    if(this.state.show==="diversity"){
      dataSet= this.state.diversity
    }

    if(this.state.show==="course"){
      dataSet = this.state.courses;
    }else if(this.state.show==="role"){
      dataSet = this.state.roles;
    }else if(this.state.show==="gender"){
      dataSet = this.state.gender;
    }
    return (
      <div className={styles.borderbox}>
        <div className="hero-university2"></div>
        <div className="candidate_process">
          <div className="col-container">
            <Dashboard pageName="Dashboard" />

            {/*right expands when dashboard is closed*/}
            <div className="right">
            <div class="topBar">
								<div class="pageName">
									<h1>Dashboard</h1>
								</div>
							</div>
              
              <div className={styles.container}>
                <div className={styles.inner}>
                 
                  <div className={styles.calendar}>
                    <Modal
                      open={this.state.hiringModal}
                      dimmer= {'blurring'}
                      onClose={() => this.handleModalToggle(false)}
                      onOpen={() => this.handleModalToggle(true)}
                      trigger={<button className='ui icon button' style={{backgroundColor: '#4183c4', color: 'white'}}>Add a Hiring Manager</button>}
                      style={{marginLeft: '25%', marginRight: '25%', marginTop: '15%', 'height': 'auto', width: '50%'}}
                    >
                      <Modal.Header style={{display:'flex', justifyContent: 'space-between'}}>
                        Add a Hiring Manager
                      </Modal.Header>

                      <Modal.Content>
                        
                        <Formik 
                          initialValues={initialValues} 
                          validationSchema={validationSchema}
                          onSubmit={(values) => this.handleCreateUser(values)}>
                            {(formProps) => (
                              <Form className='ui form'>
                                  <FormField>
                                      <Field name='firstName' placeholder='First Name:'></Field>
                                      <ErrorMessage name='firstName' render={error => <Label basic color='red' style={{marginTop: '3px'}} content={error}/>}/>
                                  </FormField>
                                  <FormField>
                                      <Field name='lastName' placeholder='Last Name:'></Field>
                                      <ErrorMessage name='lastName' render={error => <Label basic color='red' style={{marginTop: '3px'}} content={error}/>}/>
                                  </FormField>
                                  <FormField>
                                      <Field name='email' placeholder='Email:'></Field>
                                      <ErrorMessage name='email' render={error => <Label basic color='red' style={{marginTop: '3px'}} content={error}/>}/>
                                  </FormField>
                                  <FormField>
                                      <Field name='password' placeholder='Password:'></Field>
                                      <ErrorMessage name='password' render={error => <Label basic color='red' style={{marginTop: '3px'}} content={error}/>}/>
                                  </FormField>
                                  <FormField>
                                      <Field name='role' placeholder='Role:'></Field>
                                      <ErrorMessage name='role' render={error => <Label basic color='red' style={{marginTop: '3px'}} content={error}/>}/>
                                      </FormField>
                                  <FormField>
                                      <Field name='company' placeholder='Company:'></Field>
                                      <ErrorMessage name='company' render={error => <Label basic color='red' style={{marginTop: '3px'}} content={error}/>}/>
                                  </FormField>
                                  <FormField>
                                      <Field name='phoneNumber' placeholder='Phone Number:'></Field>
                                      <ErrorMessage name='phoneNumber' render={error => <Label basic color='red' style={{marginTop: '3px'}} content={error}/>}/>
                                  </FormField>
                                  <Button type="submit" style={{float:'right', backgroundColor: '#21ba45', color: 'white', marginBottom: '10px'}}>Add a new Hiring Manager!</Button>
                              </Form>
                              )}
                        </Formik>
                       
                      </Modal.Content>

                    </Modal>

                    <Modal
                      open={this.state.successModal}
                      dimmer= {'blurring'}
                      onClose={() => this.handleSuccessModalToggle(false)}
                      onOpen={() => this.handleSuccessModalToggle(true)}
                      style={{marginLeft: '25%', marginRight: '25%', marginTop: '15%', 'height': 'auto', width: '50%'}}
                    >
                      <Header icon='check circle' content='Successfully added a new hiring manager!' />
                    </Modal>

                    <div className={styles.calendarHeader}>

                    </div>
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.block}>
                      <div className={styles.blockInner}>
                        <div className={styles.blockHeader}>
                          <h1>Cohort Enrollment</h1>
                          
                        </div>
                        <Pie
                          data={this.state.courses}
                          options={{
                            title: {
                              display: false,
                              text: "Diversity in the Minos Labs Cohort",
                              fontSize: 20,
                            },
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                boxWidth: 13,
                              },
                            },
                          }}
                        />

                      </div>
                    </div>
                    <div className={styles.block}>



                    <div className={styles.blockInner}>
                        <div className={styles.blockHeader}>
                          <button className={this.state.show==="diversity" ? styles.chartButton : styles.chartButtonSelected}onClick={this.showChart2} >Diversity</button>
                          <button className={this.state.show==="veteran" ? styles.chartButton : styles.chartButtonSelected} onClick={this.showChart1}>Veteran Status</button>  
                          <button className={this.state.show==="role" ? styles.chartButton : styles.chartButtonSelected} onClick={this.showChart3}>Student Role</button>
                          <button className={this.state.show==="gender" ? styles.chartButton : styles.chartButtonSelected} onClick={this.showChart4}>Gender</button>      
                        </div>




                      <div >

                        <Pie
                          data={dataSet}
                          options={{
                            title: {
                              display: false,
                              text: "Diversity in the Minos Labs Cohort",
                              fontSize: 20,
                            },
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                boxWidth: 13,
                              },
                            },
                          }}
                        />
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default CommandCenter;
