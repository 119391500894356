import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import styles from "./Reset.module.scss";
import firebase from 'firebase';
import UserContext from '../../User/User';
const CryptoJS = require('crypto-js');


function validate(newPassword,rePassword)
{
    return{
        newPassword: newPassword.length === 0 || !validatePassword(newPassword),
        rePassword: rePassword !== newPassword,

    };
}

function validatePassword(password) {
  const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
  return re.test(password);
}



class Reset extends React.Component{

  constructor() {
    super();
    
    this.state = {
        newPassword: "",
        rePassword: "",
        hasLowercase: false,
        hasUppercase: false,
        hasNumber: false,
        hasLength: false,
        isConfirmed: false,

        everFocusedNewPassword: false,
        everFocusedRePassword: false,
        inFocus: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCheckList = this.handleCheckList.bind(this);

  }

  static contextType = UserContext;

  encrypt(text){
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
  };

  _onBlur() {
    var x = document.getElementById("message")

    ReactDOM.findDOMNode(x).style.visibility = 'hidden';
    ReactDOM.findDOMNode(x).style.opacity = '0';
    ReactDOM.findDOMNode(x).style.transition = 'opacity 0.3s 0s, visibility 0s 0.3s,max-height 0.3s ease-out';
    ReactDOM.findDOMNode(x).style.maxHeight = '0';
  }
  _onFocus() {
    var x = document.getElementById("message")

    ReactDOM.findDOMNode(x).style.visibility = 'visible';
    ReactDOM.findDOMNode(x).style.opacity = '1';
    ReactDOM.findDOMNode(x).style.transition = 'opacity 0.3s 0s, visibility 0s 0s, 0.3s ease-in';
    ReactDOM.findDOMNode(x).style.maxHeight = '600px';
  }
  handleCheckList() {
    // Validate lowercase letters
  const password = this.state.newPassword;
  var lowerCaseLetters = /^(.*[a-z].*)$/;
  if(lowerCaseLetters.test(password)) {  
    this.setState({hasLowercase: true});
  } else {
    this.setState({hasLowercase: false});
  }
  
  // Validate capital letters
  var upperCaseLetters = /^(.*[A-Z].*)$/;
  if(upperCaseLetters.test(password)) { 
    this.setState({hasUppercase: true});
  } else {
    this.setState({hasUppercase: false});
  }

  // Validate numbers
  var numbers = /[0-9]/g;
  if(numbers.test(password)) {  
    this.setState({hasNumber: true});
  } else {
    this.setState({hasNumber: false});
  }
  
  // Validate length
  if(password.length >= 8) {
    this.setState({hasLength: true});
  } else {
    this.setState({hasLength: false});
  }

    // Confirmed Password
    if((password === this.state.rePassword)&(this.state.hasLength)&(this.state.hasNumber)&(this.state.hasLowercase)&(this.state.hasUppercase)) {
      this.setState({isConfirmed: true});
      this._onBlur();

    } else {
      this.setState({isConfirmed: false});
    }

  }



  handleFormSubmit(){
    //alert("hi");
    const context = this.context;
    const db = firebase.firestore();
    console.log(context.userID);
    db.collection("corporate").doc(context.userID).update({
      password: this.encrypt(this.state.newPassword)
    });
    var x = document.getElementById('confirmReset');
    ReactDOM.findDOMNode(x).style.visibility = 'visible';
    ReactDOM.findDOMNode(x).style.opacity = '1';
    ReactDOM.findDOMNode(x).style.transition = 'opacity 0.3s 0s, visibility 0s 0s';
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }


render() {
   //User data
   let {userID,logoutUser,setUser} = this.context;

  const errors = validate(this.state.newPassword, this.state.rePassword);
  const isDisabled = Object.keys(errors).some(x => errors[x]);
  return (
    <React.Fragment>
      <section class = "hero-university2">
    

    </section>

  <section className = {styles.resetContainer}>
    <div className = {styles.blockReset}>

    <div className={styles.confirmReset} id="confirmReset">
  
      <div className={styles.resetMessage}>
        <h1>Your Password Has been changed</h1>
        <Link to ="/">
        <button className={`${styles.resetButton} hvr-sweep`}>Home</button>
        </Link>
      </div>
            
            
    </div>

    <div className ={styles.container}>
    <h1>Change Password</h1>
    <p>In order to protect your account, make sure your password:</p>
        <ul>
          <li>Does not match or significantly contain your username, e.g do not use 'username123'.</li>
          <li>is not a member of this <a href="https://en.wikipedia.org/wiki/List_of_the_most_common_passwords">list of common passwords</a></li>
          <li>is not a member of the <a href="https://haveibeenpwned.com/Passwords">Have I been Pwned</a> breach database</li>
    </ul>

    <div className={styles.message} id="message">
      <h3>Password must contain the following:</h3>
      <p id="letter" class={this.state.hasLowercase ? styles.valid : styles.invalid}>A <b>lowercase</b> letter</p>
      <p id="capital" class={this.state.hasUppercase ? styles.valid : styles.invalid}>A <b>capital (uppercase)</b> letter</p>
      <p id="number" class={this.state.hasNumber? styles.valid : styles.invalid}>A <b>number</b></p>
      <p id="length" class={this.state.hasLength ? styles.valid : styles.invalid}>Minimum <b>8 characters</b></p>
      <p id="confirm" class={this.state.isConfirmed ? styles.valid : styles.invalid}>Confirm <b>Password</b></p>
    </div>


    <form>
    <h3>New Password</h3>
    <input
        name="newPassword"
        type="password"
        placeholder="New Password"
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        value = {this.state.newPassword}
        onChange = {this.handleInputChange}
        onKeyUp={this.handleCheckList}
    />
    <h3>Re-enter Your New Password</h3>
    <input
        name="rePassword"
        type="password"
        placeholder="Confirm Password"
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        value = {this.state.rePassword}
        onChange = {this.handleInputChange}
        onKeyUp={this.handleCheckList}
    />

<button type="button" className={`${styles.resetButton} hvr-sweep`} disabled={isDisabled}  onClick={this.handleFormSubmit}>Change Password</button>
    </form>
    </div>
    </div>




  </section>

    </React.Fragment>
  )
}
}
export default withRouter(Reset);
