import React from 'react'
import { Link } from 'react-router-dom';
import "./Resources.css";
import ArticleSlide from './ArticleSlide.js'

import firebase from 'firebase';

class ArticleSlideShow extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      articles: [],
      loadingSlides: true,
      slide:1,
      id:this.props.id,
    }

    this.displayResources = this.displayResources.bind(this);
    this.changeSlide = this.changeSlide.bind(this);
}

changeSlide(num){
    let newSlide= this.state.slide + num;
    if(newSlide>this.state.articles.length-1){
        newSlide=0;
    }
    if(newSlide<0){
        newSlide=this.state.articles.length-1;
    }
    this.setState({
        slide: newSlide,
        
      });
}



displayResources(){
  const { articles } = this.state;
  
  var articleList = [];
  var i;

  for(i =0;i<articles.length;i++){
    
    let title;
    let date;
    let id;
    let tags;
    let snippet;
    let readTime;

    try {
        title=articles[i].title;
        date=articles[i].date;
        id=articles[i].articleId;
        tags=articles[i].tags;
        snippet=articles[i].snippet;
        readTime=articles[i].readTime;
        
      }
      catch(err) {
        continue;
      }
      
        articleList.push(<div style={this.state.slide==i ? {display:'block'} :{display:'none'}}> <ArticleSlide changeSlide={this.changeSlide} index={i} title={title} data={date} tags={tags} snippet={snippet} id={id} readTime={readTime} /> </div>)
      
  }
  
  return articleList;
    
  }


componentDidMount() {
    const db = firebase.firestore();
    const id = this.state.id;

    db.collection("articles").where('articleId', '!=', id)
    .get()
    .then(querySnapshot => {
    const data = querySnapshot.docs.map(doc => doc.data());
    this.setState({
        articles: data,
        loadingSlides:false
      });
      console.log(data);
      
    });

}

render() {
  
  return (
    <React.Fragment>
        {/*
                 <section class="articleSlides">

<div class ="slide">
<img class="slideImg" src={UIUX} alt="slide Image"/>
<h1> Title</h1>
<button>Read More</button>
</div> 

        </section>*/}
  {this.displayResources()}

    </React.Fragment>
  )
}

}
export default ArticleSlideShow;
