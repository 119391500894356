import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//import './Exam.css';
//import './Home.css';
import { Examsheet } from '../../components/ExamFormat.js';
import { Question } from '../../components/ExamFormat.js';

class ExamComplete extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			timer: 0,
			percent: 75,
			timediff:0
		};
		this.percentfunc = this.percentfunc.bind(this);
		this.increase = this.increase.bind(this);
	}

	increase() {
		this.setState({
			percent: this.state.percent + 1,
		});
		if (this.state.percent >= 100) {
			clearInterval(this.timer);
		}
	}
	percentfunc() {
		this.timer = setInterval(this.increase, 65);
	}

	componentDidMount() {
		if (typeof this.props.location.state !== 'undefined') {
			this.setState({
				timediff: this.props.location.state.timediff,
			});
		}
		console.log(this.props.location.state.timediff);
		this.percentfunc();
	}

	render() {
		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="exam-container4">
					<div class="progress">
						<div class="stripes">
							<div class="prog-val prog-4">
								<text>{this.state.percent}%</text>
							</div>
						</div>
					</div>
				</section>
				<section class="exam-container">
					<div class="container">
						<h1>Assesment Complete!</h1>
						<p>
							Thank you for completing the initial assessment.
						</p>
						<p>
							A Minos Labs team member will reach out to you in the next
							3 business days with your results and next steps!
						</p>
						<p>
							If you have any questions or concerns in the meantime,
							please contact us at: info@minoslabs.com
						</p>

						<div class="exam-container3">
							<Link to="/">
								<button type="submit" class="hvr-sweep">
									RETURN TO HOME PAGE
								</button>
							</Link>
						</div>
					</div>
				</section>

				<section></section>
			</React.Fragment>
		);
	}
}

export default ExamComplete;