import React from 'react';
import styles from './EditUser.module.scss';
import Dashboard from '../../components/AdminDashboard';
import firebase from 'firebase';
import UserContext from '../../User/User';
import Education from '../../components/Education';
import Experience from '../../components/Experience';
import { MDBDataTableV5 } from 'mdbreact';
import * as emailjs from 'emailjs-com'; 
import moodle_client from "moodle-client";
import axios from 'axios';
import Candidate from '../../components/Candidate';

const INITIAL_STATE = {
	first: '',
	last: '',
	email: '',
	course: 'select',
	error: null,
	hearAbout: 'select',
	courseType: '',
	gender: 'select',
	veteran: 'select',
	disabilities: 'select',
	ethnicity: 'select',
};

class AdminContactMessages extends React.Component {
	constructor() {
		super();

		this.state = {
			...INITIAL_STATE,
			selected:"",
			show:false,
			candidateInfo:{},
			selectedID:"",
			education: 1,
			experience: 1,
			education1: {},
			education2: {},
			education3: {},
			experience1: {},
			experience2: {},
			experience3: {},

		};
		this.displayEducation = this.displayEducation.bind(this);
		this.addEducation = this.addEducation.bind(this);
		this.removeEducation = this.removeEducation.bind(this);

		this.displayExperience = this.displayExperience.bind(this);
		this.addExperience = this.addExperience.bind(this);
		this.removeExperience = this.removeExperience.bind(this);

		this.handleEChange = this.handleEChange.bind(this);

		this.handleInputChange = this.handleInputChange.bind(this);
		this.getUser = this.getUser.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		
	}
	static contextType = UserContext;

	async deleteUser(){


		const db = firebase.firestore();
		await db.collection('ALL_USER')
		.where('candidateID', 'in', [parseInt(this.state.selected),this.state.selected])
		.get()
		.then(async (querySnapshot) => {
			const data = querySnapshot.docs.map((doc) => doc.data());
			const id = querySnapshot.docs.map((doc) => doc.id);

			if(data.length<1){
				return;
			}
			//delete firebase document
			await db.collection("ALL_USER").doc(id[0]).delete().then(() => {
				console.log("Document successfully deleted!");
			}).catch((error) => {
				console.error("Error removing document: ", error);
			});

			//delete moodle user
			await axios.post(`https://lms.minoslabs.com/webservice/rest/server.php?wstoken=01f04ab2f0eacb95cb7909261d5f7fb0&wsfunction=core_user_delete_users&userids[0]=${data[0].candidateID}&moodlewsrestformat=json`)
			.then(async (response) => {
				console.log(response.data); 
			})
			.catch(err => {
			   console.log(err);
			})

		 });

		 window.location.reload();

	}

	async getUser(){
		const db = firebase.firestore();
		await db.collection('ALL_USER')
		.where('candidateID', 'in', [parseInt(this.state.selected),this.state.selected])
		.get()
		.then(async (querySnapshot) => {
			const data = querySnapshot.docs.map((doc) => doc.data());
			const id = querySnapshot.docs.map((doc) => doc.id);
			this.setState({
				selectedID:id[0],
		  	});

			if(data.length<1){
				return;
			}
			await db.collection('ALL_USER').doc(id[0]).collection("education").get().then((querySnapshot) =>{
				const data = querySnapshot.docs.map((doc) => doc.data());
				for(let i =0;i<data.length;i++){
					let name = 'education' + (i +1);

					this.setState({
						[name]:data[i],	
				  });	
				}
			})
			await db.collection('ALL_USER').doc(id[0]).collection("experience").get().then((querySnapshot) =>{
				const data = querySnapshot.docs.map((doc) => doc.data());
				for(let i =0;i<data.length;i++){
					let name = 'experience' + (i +1);
					this.setState({
						[name]:data[i],	
				  });	
				}
			})
			this.setState({
				  ... data[0],
				  show:true,		
			});	
		 });
		 
	}
	
	handleEChange(info, index, type) {
		let name = '';
		if (type === 'education') {
			name = 'education' + (index + 1);
		} else {
			name = 'experience' + (index + 1);
		}
		this.setState({
			[name]: info,
		});

	}

	addEducation() {
		this.setState({
			education: this.state.education + 1,
		});
		console.log(this.state.education);
	}

	removeEducation() {
		this.setState({
			education: this.state.education - 1,
		});
		console.log(this.state.education);
	}

	displayEducation() {
		let educations = [];
		for (let i = 0; i < this.state.education; i++) {
			let name = 'education' + (i +1);
			educations.push(
				<Education initial={this.state[name]} onChange={this.handleEChange} number={i} />
			);
		}
		return educations;
	}

	addExperience() {
		this.setState({
			experience: this.state.experience + 1,
		});
	}

	removeExperience() {
		this.setState({
			experience: this.state.experience - 1,
		});
	}

	displayExperience() {
		let experiences = [];
		for (let i = 0; i < this.state.experience; i++) {
			let name = 'experience' + (i +1);
			experiences.push(
				<Experience initial={this.state[name]} onChange={this.handleEChange} number={i} />
			);
		}
		return experiences;
	}

	handleInputChange(event) {

		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});

	}



	updateUser = async (event) => {
		//uses firebase

		event.preventDefault();
		const db = firebase.firestore();
		await db.collection('ALL_USER')
		.doc(this.state.candidateID.toString())
		.update({
			first: this.state.first,
			last: this.state.last,
			email: this.state.email,
			course: this.state.course,
			hearAbout: this.state.hearAbout,			
			gender: this.state.gender,
			veteran: this.state.veteran,
			disabilities: this.state.disabilities,
			ethnicity:this.state.ethnicity,
		});


		for (let i = 1; i <= 3; i++) {
			let name = 'education' + i;
			if (JSON.stringify(this.state[name]) === '{}') {
				continue;
			}
			let education = this.state[name];
			console.log(education);
			
			db.collection("ALL_USER").doc(this.state.selectedID).collection("education").doc(name).set(
				education
			)
			.then(() => {
				console.log("Document successfully written!");
			})
			.catch((error) => {
				console.error("Error writing document: ", error);
			});
			
		}

		for (let i = 1; i <= 3; i++) {
			let name = 'experience' + i;
			if (JSON.stringify(this.state[name]) === '{}') {
				continue;
			}
			let experience = this.state[name];

			db.collection("ALL_USER").doc(this.state.selectedID).collection("experience").doc(name).set(
				experience
			)
			.then(() => {
				console.log("Document successfully written!");
			})
			.catch((error) => {
				console.error("Error writing document: ", error);
			});
			
		}
		
		//window.location.reload();

	};
	

	render() {
		//User data
		const {
			first,
			last,
			email,
			course,	
			hearAbout,	
			gender,
			veteran,
			disabilities,
			selected,
			ethnicity,
			error,
		} = this.state;
		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="View_Candidate" />

						{/*right expands when dashboard is closed*/}
						<div class="right">
							<div class="topBar">
								<div class="pageName">
									<h1>Candidates</h1>
								</div>
							</div>

							{/*code here*/}
							<div className={styles.inner}>
								<div className={styles.block}>
								<div className={styles.blockHeader}>
									<h1>Edit Candidate</h1>
									
								</div>
								<div style={{display: this.state.show ? "none":"flex"}}>								
									<input
									name="selected"
									value={selected}
									className={styles.inputField}
									onChange={this.handleInputChange}
									type="text"
									placeholder="Enter candidate ID"
									/>
									<button style={{marginTop:"0px",marginRight:"10px"}} onClick={this.getUser}>Edit User</button>
									<button style={{marginTop:"0px", backgroundColor: "#EA4335"}} onClick={this.deleteUser}>Delete User</button>
								</div>


								<div style={{display: this.state.show? "":"none"}} className={styles.formContainer}>
								<form>
									<input
									name="first"
									value={first}
									className={styles.inputField}
									onChange={this.handleInputChange}
									type="text"
									placeholder="First Name"
									/>


									<input
										name="last"
										value={last}
										className={styles.inputField}
										onChange={this.handleInputChange}
										type="text"
										placeholder="Last Name"
									/>
										<br></br>
									<input
										className={styles.email}
										name="email"
										value={email}
										onChange={this.handleInputChange}
										type="text"
										placeholder="Email address"
									/>
									<br></br>
									<select
										name="course"
										value={course}
										onChange={this.handleInputChange}
									>
										<option value="select" selected disabled hidden>
											Please Select Program
										</option>
										<option value="FullStack">
											Full-Stack Development
										</option>
										<option value="Cyber">Cybersecurity</option>
									</select>

									<select
								name="ethnicity"
								value={ethnicity}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Ethnicity
								</option>
								<option value="AmericanIndian">
									American Indian or Alaska Native
								</option>
								<option value="Asian">Asian</option>
								<option value="Black">Black or African American</option>
								<option value="Hispanic">Hispanic or Latino</option>
								<option value="NativeHawaiianPacificIslander">
									Native Hawaiian or other Pacific Islander
								</option>
								<option value="Not">Not Specified</option>
								<option value="TwoOrMore">Two or more races</option>
								<option value="White">White</option>
								<option value="NotAnswer">
									I do not wish to answer
								</option>
							</select>
							<br></br>
							<select
								name="gender"
								value={gender}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Please Select Gender
								</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
								<option value="preferNot">Prefer Not to Say</option>
							</select>

							<select
								name="veteran"
								value={veteran}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Veteran Status
								</option>
								<option value="veteran">
									I am a protected veteran
								</option>
								<option value="notVeteran">
									I am not a protected veteran
								</option>
								<option value="preferNot">Prefer Not to Say</option>
							</select>
							<br></br>
							<select
								name="disabilities"
								value={disabilities}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Disability Status
								</option>
								<option value="disabled">
									Yes, I have a disability, or have a history/record of
									having a disability
								</option>
								<option value="notDisabled">
									No, I don't have a disability, or a history/record of
									having a disability
								</option>
								<option value="preferNot">Prefer Not to Say</option>
							</select>

							
							<select
								name="hearAbout"
								value={hearAbout}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Where did you hear about us?
								</option>
								<option value="LinkedIn">LinkedIn</option>
								<option value="Instagram">Instagram</option>
								<option value="JobBoard">Job Board</option>
								<option value="Referal">Referral</option>
								<option value="other">Other</option>
							</select>

							<br></br>
							<br></br>

							<div style={{ display: 'flex' }}>
								<h1 className={styles.resumeTitle}>Education</h1>
								<i
									style={{
										display:
											this.state.education >= 3 ? 'none' : 'block',
									}}
									className={`${styles.plusButton} fa fa-plus-square`}
									onClick={this.addEducation}
								></i>
								<i
									style={{
										display:
											this.state.education > 1 ? 'block' : 'none',
									}}
									className={`${styles.minusButton} fa fa-minus-square`}
									onClick={this.removeEducation}
								></i>
							</div>
							{this.displayEducation()}



							<br></br>
							<br></br>
							<div style={{ display: 'flex' }}>
								<h1 className={styles.resumeTitle}>Experience</h1>
								<i
									style={{
										display:
											this.state.experience >= 3 ? 'none' : 'block',
									}}
									className={`${styles.plusButton} fa fa-plus-square`}
									onClick={this.addExperience}
								></i>
								<i
									style={{
										display:
											this.state.experience > 1 ? 'block' : 'none',
									}}
									className={`${styles.minusButton} fa fa-minus-square`}
									onClick={this.removeExperience}
								></i>
							</div>
							{this.displayExperience()}




							<br></br>
							<button onClick={this.updateUser}>Save Changes</button>
								</form>
								</div>
								</div>
							</div>

						</div>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default AdminContactMessages;
