import React from 'react'
import "./Student.css";
import firebase from 'firebase';
import UserContext from '../../User/User';
import { Link } from 'react-router-dom';
import { render } from '@testing-library/react';

class Student extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: this.props.name,
            email: this.props.email,
            id: this.props.id,
            remote: "0%",
            presentation: "0%",
            java: "0%",
            react: "0%",
            vue: "0%",
            angular: "0%",
            avg: "0%",
            skillsAndProgress: [],
            profileCollapse: "bottom-hide",
            isCollapsed: true,
            emailRef: "",
            profileUrl: "",
        }

        this.handleProfileCollapse = this.handleProfileCollapse.bind(this)
        this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
        this.displayProgress = this.displayProgress.bind(this)
    }

    displayProgress(){
        const { skillsAndProgress } = this.state;


        
        try {
            this.setState({
                vue: skillsAndProgress.vue+"%",
                java: skillsAndProgress.java+"%",
                react: skillsAndProgress.react+"%",
                remote: skillsAndProgress.remote+"%",
                presentation: skillsAndProgress.presentation+"%",
                angular: skillsAndProgress.angular+"%",
                avg: skillsAndProgress.avg+'%',
              });
            
          }
          catch(err) {
            
          }

       
        
    }

    static contextType = UserContext;
        
    componentDidMount() {
        const db = firebase.firestore();
        try {
            db.collection("students").doc(this.state.id).collection("skillsAndProgress").doc("progress")
            .get()
            .then(snap => {
            const data = snap.data();
            
            this.setState({
                skillsAndProgress: data
            });
            });
        }
        catch(err) {
            
          }


          var storage = firebase.storage().ref();

          const email = this.state.email+'.png';


          
            storage.child('studentPics').child(email).getDownloadURL().then((url) => {
                this.setState({
                    profileUrl: url,
                })
            }).catch(e => {
                storage.child('profilepics').child('noprofilepic.jpg').getDownloadURL().then((url2) => {
                    this.setState({
                        profileUrl: url2,
                    })
                })
            })
          
          

      }

    handleProfileCollapse(){
        const isCollapsed = this.state.isCollapsed;
        if(isCollapsed === true){
            this.setState({
                profileCollapse: "bottom-show",
                isCollapsed: false
            })
        }
        if(isCollapsed === false){
            this.setState({
                profileCollapse: "bottom-hide",
                isCollapsed: true
            })
        }
    }

    handleFavoriteClick(event){ 
        const context = this.context;
        const db = firebase.firestore();
        const userRef = db.collection("corporate").doc(context.userID);
        event.stopPropagation();
        if (this.state.favoriteClass === "fa fa-star favorite gray move-again"){
            this.setState(prevState => {
                return {
                    favoriteClass: "fa fa-star favorite gold move-again",
                }
            })
            
            userRef.update({
                savedCandidates: firebase.firestore.FieldValue.arrayUnion(this.props.id) //adds to savedCandidates array in Firebase
            });
            
        }
        if(this.state.favoriteClass === "fa fa-star favorite gold move-again"){
            this.setState(prevState => {
                return {
                    favoriteClass: "fa fa-star favorite gray move-again",
                }
            })
            
            userRef.update({
                savedCandidates: firebase.firestore.FieldValue.arrayRemove(this.props.id) //removes from savedCandidates array in Firebase
            });
            
        }
    }


    render(){
        
        this.displayProgress();
        
        const {java , vue, react,remote,presentation,angular, avg} = this.state;
        
        return(
            <React.Fragment>
                <div class="profile-container">
                    <div onClick={this.handleProfileCollapse} class="top">

                        {/*<img class="profile-pic" src={this.props.source}/>*/}
                        <img class="profile-pic" src={this.state.profileUrl}/>
                        <h2 class="profile-title">{this.props.name}</h2>

                        <div class="progress-back-avg"><p>Cumulative Average</p>
                            <div class="progress-bar" style={{backgroundColor: "#180BAD", width: avg}}></div>
                            <span class="progress-avg">{avg}</span>
                        </div>
                    </div>
                    
                    
                  
                    <div class={this.state.profileCollapse}> {/*bottom*/}
                        <div class="bottom-top">
                            <h3>Progress</h3>
                                <div class="progress-left">
                                    
                                <div class="progress-back"><p>Java</p>
                                    <div class="progress-bar" style={{backgroundColor: "#3240C3", width: java}}></div>
                                </div>
                                <div class="progress-back"><p>Vue</p>
                                    <div class="progress-bar" style={{backgroundColor: "#FD9801", width: vue}}></div>
                                </div>
                                <div class="progress-back"><p>Angular</p>
                                    <div class="progress-bar" style={{backgroundColor: "#590F94", width: angular}}></div>
                                </div>

                                </div>
                                <div class="progress-right">

                                <div class="progress-back"><p>React</p>
                                    <div class="progress-bar" style={{backgroundColor: "#CA2C2C", width: react}}></div>
                                </div>
                                <div class="progress-back"><p>Presentation</p>
                                    <div class="progress-bar" style={{backgroundColor: "#32A9C3", width: presentation}}></div>
                                </div>
                                <div class="progress-back"><p>Remote Productivity</p>
                                    <div class="progress-bar" style={{backgroundColor: "#57C03D", width: remote}}></div>
                                </div>
                                    
                                </div>
                        </div>
                        
                        {/*<Link style={{width: "100%"}}to = 
                        {{ pathname:"/candidate_profile",
                            state: { 
                                java: this.state.java,
                                id: this.state.id,
                                name: this.state.name,
                                source: this.state.source, 
                                title: this.state.title,
                                remote: this.state.remote,
                                presentation: this.state.presentation,
                                react: this.state.react,
                                vue: this.state.vue,
                                angular: this.state.angular,
                                education:this.state.education,
                                technicalSkills: this.state.technicalSkills,
                                languageSkills: this.state.languageSkills,
                                experiences: this.state.experiences,
                                mentors: this.state.mentors,
                                favoriteClass: this.state.favoriteClass,
                                profileUrl: this.state.profileUrl,
                            }
                        }}>
                        <div class ="bottomButtonWrapper">
                        <button class="bottom-button hvr-sweep">View Full Profile</button>
                        </div>
                    </Link>*/}
                        
                        
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default Student;