import React, { Component } from 'react';
import './stylesheets/Interview.css'
import './stylesheets/boot.css'
import LeaveMeetingModal from './Modals/LeaveMeetingModal';
const { LocalVideoTrack } = require('twilio-video');



class Toolbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            audio_mute: this.props.audioToggle,
            video_mute: this.props.videoToggle,
            screen_share_flag: false,
            screenTrack: null,
            audio_mute_button_value: "Mute",
            video_mute_button_value: "Turn off Camera",
            screen_share_button_value: "Share Your Screen",
            videoGreen: true,
            muteGreen: true,
            shareGreen: false
        }
        this.muteAudio = this.muteAudio.bind(this)
        this.muteCamera = this.muteCamera.bind(this)
        this.shareScreenHandler = this.shareScreenHandler.bind(this)
    }


    componentDidMount() {
        this.muteAudio()
        this.muteCamera()


    }
    muteAudio() {
        if (this.state.audio_mute == false) {
            this.props.room.localParticipant.audioTracks.forEach(publication => {
                publication.track.disable();
                this.setState({
                    audio_mute: true,
                    audio_mute_button_value: "Unmute",
                    muteGreen: false
                    

                })
            });
        }
        else {
            this.props.room.localParticipant.audioTracks.forEach(publication => {
                publication.track.enable();
                this.setState({
                    audio_mute: false,
                    audio_mute_button_value: "Mute",
                    muteGreen:true
                })
            });

        }

    }
    muteCamera() {
        if (this.state.video_mute == false) {
            this.props.room.localParticipant.videoTracks.forEach(publication => {
                if (publication.track.name != "screenShare") {
                    publication.track.disable();
                    this.setState({
                        video_mute: true,
                        video_mute_button_value: "Turn on Camera",
                        videoGreen: false
                    })
                }

            });
        }
        else {
            this.props.room.localParticipant.videoTracks.forEach(publication => {
                publication.track.enable();
                this.setState({
                    video_mute: false,
                    video_mute_button_value: "Turn off Camera",
                    videoGreen:true
                })
            });
        }

    }
    async shareScreenHandler() {
        //    const stream=await navigator.mediaDevices.getDisplayMedia();
        //    const screenTrack = new LocalVideoTrack(stream.getTracks()[0]);
        //    this.props.room.localParticipant.publishTrack(screenTrack)
        //     const screenVid = document.getElementById('mahir');
        //     console.log(screenTrack)
        // screenVid.appendChild(screenTrack)
        if (this.state.screen_share_flag == false) {
            navigator.mediaDevices.getDisplayMedia().then(stream => {

                document.getElementById("screenBanner").style.display = "block";

                const screenVid = new LocalVideoTrack(stream.getTracks()[0], { name: "screenShare" });
                //shareScreen.innerHTML = 'Stop sharing';
                screenVid.mediaStreamTrack.onended = () => { this.shareScreenHandler() };
                // screenVid.onClick(this.zoomIn(screenVid));

                this.setState({
                    screenTrack: screenVid,
                    screen_share_flag: true,
                    screen_share_button_value: "Stop Sharing Your Screen",
                    shareGreen: true
                })

                this.props.room.localParticipant.publishTrack(this.state.screenTrack);
            }).catch(() => {
                alert('Could not share the screen.');
            });
        }
        else {
            this.props.room.localParticipant.unpublishTrack(this.state.screenTrack);
            this.state.screenTrack.stop();
            this.setState({
                screenTrack: null,
                screen_share_flag: false,
                screen_share_button_value: "Share Your Screen",
                shareGreen: false
            })
            document.getElementById("screenBanner").style.display = "none";
            //this.state.screenTrack = null;
            //shareScreen.innerHTML = 'Share screen';
        }
    }

    render() {
        let video_btn_class = this.state.videoGreen ? "greenVideoButton" : "redVideoButton";
        let mute_btn_class = this.state.muteGreen ? "greenMuteButton" : "redMuteButton";
        let sShare_btn_class = this.state.shareGreen ? "greenShareButton" : "redShareButton";
        return (
            <div>
                <div className="d-flex align-items-end" id='tbar'>
                
                    
                
                    <button type='button' onClick={this.muteAudio} className={mute_btn_class}><i class='fa fa-microphone'></i></button>
                    <button type='button' onClick={this.muteCamera} className={video_btn_class}><i class='fa fa-video-camera'></i></button>
                    <button type='button' onClick={this.shareScreenHandler} className={sShare_btn_class}><i class='fa fa-desktop' ></i></button>
                    <div className='leaveMeeting'>
                    <LeaveMeetingModal id='leaveMeeting' {...this.props} leaveMeeting={this.props.leaveMeeting} userRole={this.props.userRole}></LeaveMeetingModal>
                    </div>
                   
                    {/* <button onClick={this.props.leaveMeeting} type='button' className="p-2">Leave Room</button> */}
                </div>

            </div>
        )
    }

} export default Toolbar