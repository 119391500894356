import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import NotesContext from '../notesContext';
class LeaveMeetingModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: false
        }
        this.toggle = this.toggle.bind(this);
        this.handleEndMeeting = this.handleEndMeeting.bind(this);
    }
    static contextType = NotesContext
   

    toggle = () => this.setState({
        modal: !this.state.modal
    })

    handleEndMeeting = () => {
        console.log(this.context)
        const { history } = this.props
        this.props.leaveMeeting();
        if (this.props.userRole === 1) {
            history.push({ pathname: "/feedback", state: { meetingNotes: this.context.meetingNotes, meetingInfo: this.context.meetingInfo, meetingID: this.context.meetingID } })
        }else{
            window.location.href = "/student_command_center";

        }


    }

    render() {
        return (
            <div>
                <Button color="danger" onClick={this.toggle}>Leave Meeting</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle}>Are you sure you want to leave the meeting?</ModalHeader>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleEndMeeting}>End Meeting</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}





export default LeaveMeetingModal;