
import React from 'react';
import ReactDOM from 'react-dom';
import "./ManageAccountUniv.module.scss";
import Dashboard from '../../components/DashboardUniv';
import logo_manage_acc from '../../assets/manage account/logo_manage_acc.png';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import UserContext from '../../User/User';
import add_acc_logo from '../../assets/manage account/noprofilepic.jpg';



class ManageAccountUniv extends React.Component{
    constructor(){
        super();

        this.state = {
            name: "",
            company: "",
            role: "",
            email: "",
            url: '',
            loading:true,
            helpbox: "mang-acc-help-hide",
            message: "",
        }
        this.toResetPage = this.toResetPage.bind(this);
        this.handleHelp = this.handleHelp.bind(this);
        this.submitHelp = this.submitHelp.bind(this);
        this.closeHelp = this.closeHelp.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    static contextType = UserContext;

    toResetPage(){
        window.location.href = "/reset";
    }
    componentDidMount(){
        this.displayAccInfo();
    }

    displayAccInfo(){
        const db = firebase.firestore();
        const context = this.context;

        db.collection('corporate').doc(context.userID)
        .get()
        .then(snap => {
            const data = snap.data();
            this.setState({
                name: data.first + " " + data.last,
                company: data.company,
                role: data.companyTitle,
                email: data.email,
                loading: false,
            })
        })

        var storage = firebase.storage();
        storage.ref('corporatePics').child(context.userID).getDownloadURL().then(url => {
            
            this.setState({url});
        })
        
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }


    handleHelp(){
        this.setState({
            helpbox: "mang-acc-help",
        })
    }

    submitHelp(event){
        event.preventDefault();

        const context = this.context;
        const db = firebase.firestore()

        var msg = this.state.message;

        db.collection('corporate').doc(context.userID)
        .get()
        .then(snap => {
            const data = snap.data();
            console.log(data);
            db.collection('contactUs').add({
                email: data.email,
                firstName: data.first,
                lastName: data.last,
                message: msg,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                whoAreYou: data.type,
            }).then(docRef => {
                console.log(docRef)
            });
        })

        this.setState({
            helpbox: "mang-acc-help-hide",
            message: "",
        })
    }

    closeHelp(){
        this.setState({
            helpbox: "mang-acc-help-hide",
            message: "",
        })
    }

    render(){

        //User data
        let {userID,logoutUser,setUser} = this.context;

        return(
            <React.Fragment>
            <section class = "hero-university2">
            </section>
            <section class = "candidate_process">
            <section class = "col-container"> 
                
                <Dashboard pageName="Manage_acc"/>

                {/*right expands when dashboard is closed*/}  
                <div class="right">
                {/*code here*/} 
                <div class={this.state.helpbox}>
                            <span class="close-help" onClick={this.closeHelp}>x</span>
                            <form>
                                <label>Send us a message and a Minos Labs representative will get to you as soon as possible</label>
                                <br/>
                                <br/>
                                <br/>
                                <textarea
                                rows="10" 
                                cols="60"
                                name="message"
                                type="text"
                                value={this.state.message}
                                onChange={this.handleInputChange} 
                                placeholder="Enter your message..."
                                />
                                <br/>
                                <br/>
                                <button onClick={this.submitHelp} class="mang-acc-help-send  hvr-sweep">Send</button>
                            </form>
                    </div>

                    <div style={this.state.loading? {display:'none'}:{} }class="manage-acc-container">
                        <div class="manageImgContainer">
                          <img src={this.state.url || add_acc_logo} class="logo_img"></img>
                        </div>
                          

                            <h1>My Account</h1>
                            <div class="accountContainer">  
                            <div class="accountLabelContainer">
                            <h4>Name:</h4> <p>{this.state.name}</p>
                            </div>

                            <div class="accountLabelContainer">
                            <h4>University:</h4><p>{this.state.company}</p>
                            </div>

                            <div class="accountLabelContainer">
                            <h4>Position:</h4><p>{this.state.role}</p>
                            </div>

                            <div class="accountLabelContainer">
                            <h4>Email:</h4><p>{this.state.email}</p>
                            </div>
                            

                          </div>

                          
                          <div class="wrap_button_acc0">
                                    <button type="button"  onClick={this.toResetPage} class="resetPass_button_acc hvr-sweep">Reset Password</button>
                                    <button type="button" onClick={this.handleHelp}  class="help_button_acc hvr-sweep">Help</button>
                         </div>
                         <div class="wrap_button_acc">
                                <Link to="/Edit_Manage_Account_Univ"><button type="button"  class="edit_button_acc hvr-sweep">Edit</button></Link>
                         </div>   
                    </div>

                        </div>

                    </section>
                </section>

            </React.Fragment>
        )
    }
}



export default ManageAccountUniv;
