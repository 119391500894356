import React from 'react';

//import styles from './Corporate.module.scss';
import firebase from 'firebase';
import styles from './Partner.module.scss';
import ReactDOM from 'react-dom';
import money from '../../assets/icons/money-icon.png';
import logistics from '../../assets/icons/logistics-icon.png';
import alarm from '../../assets/icons/alarm-icon.png';
import bullseye from '../../assets/icons/bullseye-icon.png';
import group from '../../assets/icons/group-icon.png';
import board from '../../assets/icons/board-icon.png';
import bag from '../../assets/icons/bag-icon.png';
import chat from '../../assets/icons/chat-icon.png';

function validate(firstName, lastName, emailId, type, info) {
	return {
		firstName: firstName.length === 0,
		lastName: lastName.length === 0,
		emailId: emailId.length === 0 || !validateEmail(emailId),
		type: type.length === 6,
		info: info.length === 0,
	};
}

function validateEmail(emailId) {
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(emailId);
}

class Corporate extends React.Component {
	constructor() {
		super();

		this.state = {
			firstName: '',
			lastName: '',
			emailId: '',
			type: 'select',
			info: '',

			everFocusedFirstName: false,
			everFocusedLastName: false,
			everFocusedEmailId: false,
			everFocusedType: false,
			everFocusedInfo: false,
      inFocus: '',
      
      isError: false,
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCloseMessage = this.handleCloseMessage.bind(this);


		let analytics = firebase.analytics();
		console.log('Visited Corporate Page')
		analytics.logEvent('visited_corporate_page')
	}

	handleFormSubmit(evt) {
    this.setState({
			isError: true,
		});

		if (!this.canBeSubmitted()) {
			evt.preventDefault();
			return;
    }
    
		var x = document.getElementById('confirmSubmit');
		ReactDOM.findDOMNode(x).style.visibility = 'visible';
		ReactDOM.findDOMNode(x).style.opacity = '1';
		ReactDOM.findDOMNode(x).style.transition =
			'opacity 0.3s 0s, visibility 0s 0s';
	}

	handleCloseMessage() {
    this.setState({
			firstName: '',
			lastName: '',
			emailId: '',
			type: 'select',
			info: '',

			isError: false,
    });
    
		var x = document.getElementById('confirmSubmit');
		ReactDOM.findDOMNode(x).style.visibility = 'hidden';
		ReactDOM.findDOMNode(x).style.opacity = '0';
		ReactDOM.findDOMNode(x).style.transition =
			'opacity 0.3s 0s, visibility 0s 0.3s';
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleSubmit = (evt) => {
		if (!this.canBeSubmitted()) {
			evt.preventDefault();
			return;
		}
	};

	canBeSubmitted() {
		const errors = validate(
			this.state.firstName,
			this.state.lastName,
			this.state.emailId,
			this.state.type,
			this.state.info
		);
		const isDisabled = Object.keys(errors).some((x) => errors[x]);
		return !isDisabled;
	}
	

	render() {
		const errors = validate(
			this.state.firstName,
			this.state.lastName,
			this.state.emailId,
			this.state.type,
			this.state.info
		);
		return (
			<React.Fragment>
				<section className={styles.heroPartner}>
					<div className={styles.container}>
						<div className={styles.fade1}>
							<h1>Corporate Partners</h1>
						</div>

						<div className={styles.fade2}>
							<p>
								At Minos Labs, we aim to develop our associates by
								involving hiring managers and experts in Cybersecurity
								and Full-Stack development in our process. We realize
								that entry level talent is crucial for Fortune 1000
								organizations to stay ahead of competitors and
								continually grow so we have built a process that drives
								transparency and efficiency within training and hiring
								for our clients. We aim to bridge the gap between high
								potential applicants and clients looking to develop
								their Workforce of Tomorrow, skilled in the latest
								advancements within Cybersecurity and Full-Stack
								Development
							</p>
						</div>
					</div>
				</section>

				<section className={styles.partnerBenefits}>
					<div className={styles.left}>
						<div className={styles.container}>
							<h2>benefits of partnering with Minos Labs</h2>
							<p>
								By including industry and client specific expertise
								into our training program, we give our clients the
								ability to vet, assess, and educate resources, prior
								to hiring them. By bridging the gap between subject
								matter expertise and entry level resources, we ensure
								a steady pipeline of value-adding associates who are{' '}
								<b>
									pre-trained, to cut down on in-house training and
									manhours required by our clients
								</b>
							</p>
						</div>
					</div>

					<div className={styles.right}>
						<div className={styles.listContainer}>
							<div className={styles.verticalCenter}>
								<div className={styles.container}>
									<img src={money} alt="portrait1" />

									<p>Cost-effective Hiring Program</p>
								</div>
								<div className={styles.container}>
									<img src={logistics} alt="portrait1" />
									<p>Speed up screening process</p>
								</div>

								<div className={styles.container}>
									<img src={alarm} alt="portrait1" />
									<div className={styles.p3}>
										<p>Increased efficiency and effectiveness</p>
									</div>
								</div>

								<div className={styles.container}>
									<img src={bullseye} alt="portrait1" />
									<p>
										Industry specific training for technology
										resources
									</p>
								</div>

								<div className={styles.container}>
									<img src={group} alt="portrait1" />
									<div className={styles.p5}>
										<p>Consistent value adding associates</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className={styles.howItWorks}>
					<h2>The Minos Labs Method</h2>

					<div className={styles.process}>
						<div className={styles.container}>
							<img src={board} alt="portrait1" />
							<h3>PRE-SCREEN</h3>
							<p>
								Hiring managers and client companies have access to
								associates’ assessments, projects, and profiles
								throughout the entire process
							</p>
						</div>
						<div className={styles.container}>
							<img src={bag} alt="portrait1" />
							<h3>EMPLOYMENT</h3>
							<p>
								At the end of the program, associates are ready to
								seamlessly integrate into client organizations
							</p>
						</div>
						<div className={styles.container}>
							<img src={chat} alt="portrait1" />
							<h3>FOLLOW UP</h3>
							<p>
								Continuous collaboration with client companies to ensure
								that our associates are trained in the most current
								industry skills to satisfy current and future demand{' '}
							</p>
						</div>
					</div>
				</section>

				<section className={styles.contactUs}>
					<div className={styles.block}>
						<div className={styles.confirmSubmit} id="confirmSubmit">
							<button className={styles.close} onClick={this.handleCloseMessage}>
								<p> &#10006;</p>
							</button>
							<div className={styles.message}>
								<h2>Thank You</h2>
								<p>
									A Minos Labs team member will get back to you as soon
									as possible!
								</p>
							</div>
						</div>
						<div className={styles.container}>
							<h2>Contact Us</h2>
							<p>
								{' '}
								Ready to build your talent pipeline? <br /> Get in touch
								with a Minos Labs team member today to discuss our full,
								end-to-end delivery model
							</p>

							<form id="contactForm" onSubmit={this.handleSubmit}>
								<input
									class={(errors.firstName && this.state.isError) ? styles.errorFirst : styles.first}
									id = "fName"
									name="firstName"
									type="text"
									placeholder="First name"
									value={this.state.firstName}
									onChange={this.handleInputChange}
								/>
								<input
									class={(errors.lastName && this.state.isError) ? styles.errorLast : styles.last}
									name="lastName"
									id = "lName"
									type="text"
									placeholder="Last name"
									value={this.state.lastName}
									onChange={this.handleInputChange}
								/>
								<input
									class={(errors.emailId && this.state.isError) ? styles.error : styles.email}
									name="emailId"
									type="text"
									placeholder="Email address"
									value={this.state.emailId}
									onChange={this.handleInputChange}
								/>
								<select
									name="type"
									class={(errors.type && this.state.isError) ? styles.errorType : styles.type}
									value={this.state.type}
									onChange={this.handleInputChange}
									id="whoAreYou"
								>
									<option value="select" selected disabled hidden>
										Are you a...?
									</option>
									<option value="Student">Student</option>
									<option value="Corporate">Corporation</option>
									<option value="University">University</option>
									<option value="Other">Other</option>
								</select>
								<textarea
									class={(errors.info && this.state.isError) ? styles.error : ''}
									id="msg"
									name="info"
									placeholder="Your Message..."
									value={this.state.info}
									onChange={this.handleInputChange}
								/>
								<div className={styles.errormsg}>{this.state.msg}</div>
								<button
									type="button"
									class="hvr-sweep"
									onClick={this.handleFormSubmit}
								>
									SUBMIT
								</button>
								
							</form>
						</div>
					</div>
				</section>
				{/*
  <section class = "partner">
    <div class = "container">
      <h1>Become A Partner</h1>
     
      <button>Contact Us</button>

    </div>
  </section>
*/}
			</React.Fragment>
		);
	}
}
export default Corporate;
