import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Examsheet } from '../../components/ExamFormat.js';
import { Question } from '../../components/ExamFormat.js';
import ReactDOM from 'react-dom';
import CodeMirror from 'react-codemirror';
import Dictionary from '../../assets/questions/DictionarySort.JPG';
import StringNumber from '../../assets/questions/StringNumber.JPG';
import Editor from '../../components/Editor.js';
import Form from '../../assets/questions/Form.JPG';
import Resize from '../../assets/questions/resize.JPG';
import firebase from 'firebase';
import UserContext from '../../User/User';
import axios from 'axios';

class BCExam extends React.Component {
	constructor() {
		super();
		this.state = {
			startTime: new Date(),
			timer: 0,
			percent: 0,
		};
		this.onValueChange = this.onValueChange.bind(this);
		this.numberOfCorrect = this.numberOfCorrect.bind(this);
		this.movePg = this.movePg.bind(this);
		this.percentfunc = this.percentfunc.bind(this);
		this.increase = this.increase.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.applicantId=0;
		this.moodleError='Email was taken';
		this.exam_Flag='pending';
	}
	static contextType = UserContext;
	componentDidMount() {
		this.percentfunc();
		if (typeof this.props.location.state !== 'undefined') {
			this.setState({
				candidateEmail: this.props.location.state.candidateEmail,
				first: this.props.location.state.first,
				last: this.props.location.state.last,
				course: this.props.location.state.course,
			});
		}
		
	}

	async createMoodleAccount(){
		let success = false;
		try{
			await axios.post(`https://lms.minoslabs.com/webservice/rest/server.php?wstoken=01f04ab2f0eacb95cb7909261d5f7fb0&wsfunction=core_user_create_users&users[0][username]=${this.props.location.state.candidateEmail}&users[0][password]=${this.props.location.state.first}${this.props.location.state.last}2!M&users[0][firstname]=${this.props.location.state.first}&users[0][lastname]=${this.props.location.state.last}&users[0][email]=${this.props.location.state.candidateEmail}&moodlewsrestformat=json`)
			.then(async (response) => {

				let data = response.data[0];
				try{
					
						this.moodleError= response.data.message
					
					console.log(response.data.message)
				}catch(err){
						this.moodleError= err
					console.log(err)
				}
				if('id' in data) {
				   success =  true;
					   this.applicantId= data.id
				} else {

				   success =  false;
				   
				}
				
			})
			.catch(err => {
			   console.log(err);
			   
			})

			
			// await axios.get(`https://us-central1-minos-labs-57c35.cloudfunctions.net/createMoodleUser?username=${this.state.email}&first=${this.state.first}&last=${this.state.last}&course=${this.state.course}&email=${this.state.email}`)
			// .then(async(response)=>{
			// 	console.log(response.data);
				
			// })
			// .catch(err =>{
			// 	console.log(err);
			// 	return false;
			// })
			

		}
		catch(e){
			console.log(e);
			
		}

		return success;
		
	}

	addUser = async (event) => {

		//event.preventDefault();
		//const templateId = this.state.courseType;

		const db = firebase.firestore();
		var storage = firebase.storage();
		if(!await this.createMoodleAccount()){
				alert(this.moodleError);
				return;
		}

		//uses firebase
		var templateParams = {
			to_name: this.state.first,
			receiver: this.state.email
			// username: this.state.email,
			
			// password: this.state.first+this.state.last+"2!M"
		};
			
		// emailjs.send('mailgun', 'template_dw8zd0u', templateParams, 'user_ry821S5CQJTzdU5eSuuAk')
		// 	.then(function(response) {
		// 		console.log('SUCCESS!', response.status, response.text);
		// 	}, function(error) {
		// 		console.log('FAILED...', error);
		// 	});
		var correctAnswers = this.numberOfCorrect();
		await db.collection('ALL_USER')
			.doc(this.applicantId.toString())
			.set({
				first: this.props.location.state.first,
				last: this.props.location.state.last,
				email: this.props.location.state.candidateEmail,
				course: this.props.location.state.course,
				// hearAbout: this.state.hearAbout,			
				// gender: this.state.gender,
				// veteran: this.state.veteran,
				// disabilities: this.state.disabilities,
				// progress: 'new',
				candidateID: this.applicantId,
				// grades:[0,0,0,0,0,0],
				// ethnicity:this.state.ethnicity,
				// timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				exam_Flag: this.exam_Flag,
				// password: this.state.first + this.state.last+"2!M",
				// role: 'PT',
				examScore: correctAnswers,
				examDuration: (new Date() - this.state.startTime) / 60000, //Total time in minutes
				examTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
			});
	}

	handleClick(event) {
		console.log("Hii from exam");
		var correctAnswers = this.numberOfCorrect();
		const db = firebase.firestore();
		
		if(correctAnswers>=19){
			this.exam_Flag = "pass";
		}
		else{
			this.exam_Flag = "fail";
		}
		this.addUser();
		const context = this.context;
		context.setExam("completed");
		console.log("examflag",this.exam_Flag)
	}
	onValueChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	}

numberOfCorrect(){//   1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24
		var answers = [2,3,4,4,3,2,2,2,4,1 , 1, 1, 1, 2, 1, 2, 1, 3, 1, 4, 4, 1, 4, 4]; //Answer key (multiple choice)
		var correct = 0;
		for (let i = 1; i <= answers.length; i++) {
			var name = 'selected' + i.toString();
			var ans = answers[i - 1];
			if (this.state[name] === ans.toString()) {
				correct += 1;
			}
		}
		console.log(correct);
		return correct;
	}

	increase() {
		var lim = 0;
		switch (this.state.curPage) {
			case '2':
				lim = 25;
				break;
			case '3':
				lim = 50;
				break;
			case '4':
				lim = 75;
				break;
		}
		this.setState({
			percent: this.state.percent + 1,
		});
		if (this.state.percent >= lim) {
			clearInterval(this.timer);
		}
	}

	percentfunc() {
		this.timer = setInterval(this.increase, 65);
	}

	movePg(event) {
		window.scroll(0,0);
		const target = event.target;
		const value = target.value;
		var start = 0;
		switch (value) {
			case '3':
				start = 25;
				break;
			case '4':
				start = 50;
				break;
		}
		var pgs = [
			document.getElementById('pg1'),
			document.getElementById('pg2'),
			document.getElementById('pg3'),
			document.getElementById('pg4'),
		];

		for (let i = 0; i < 4; i++) {
			if (Number(value) - 1 == i) {
				ReactDOM.findDOMNode(pgs[i]).style.display = 'block';
			} else {
				ReactDOM.findDOMNode(pgs[i]).style.display = 'none';
			}
		}
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		this.setState(
			{
				curPage: event.target.value,
				percent: start,
			},
			() => {
				this.percentfunc();
			}
		);
	}

	render() {
		return (
			<React.Fragment>
				{/*------------------------------------------------Page 1------------------------------------------------*/}
				<div class="pg1" id="pg1">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-0">
									<text>0%</text>
								</div>
							</div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>

							<form>
								<div class="question">
									<p>1. </p>

									<ul>
										<p>
											<b>Java OOP:</b>
											<br></br>
											Fill in the blank: In the JDK, packages are used to group similar__together.
											<br></br>
											Pick <b>ONE</b> option:
										</p>
										

										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected1"
													value="1"
													checked={this.state.selected1 === '1'}
													onChange={this.onValueChange}
												/>
												Objects
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected1"
													value="2"
													checked={this.state.selected1 === '2'}
													onChange={this.onValueChange}
												/>
												Classes
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected1"
													value="3"
													checked={this.state.selected1 === '3'}
													onChange={this.onValueChange}
												/>
												Methods
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected1"
													value="4"
													checked={this.state.selected1 === '4'}
													onChange={this.onValueChange}
												/>
												All of the Above
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>2. </p>
									<ul>
										<p>
											<b>Java OOP:</b>
											<br></br>
											Fill in the blank: An abstract class cannot be__but can be__by another class.
											<br></br>
											Pick <b>ONE</b> option:
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected2"
													value="1"
													checked={this.state.selected2 === '1'}
													onChange={this.onValueChange}
												/>
												Inherited, Instantiated
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected2"
													value="2"
													checked={this.state.selected2 === '2'}
													onChange={this.onValueChange}
												/>
												Inherited, Declared
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected2"
													value="3"
													checked={this.state.selected2 === '3'}
													onChange={this.onValueChange}
												/>
												Instantiated, Inherited
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected2"
													value="4"
													checked={this.state.selected2 === '4'}
													onChange={this.onValueChange}
												/>
												None of the Above
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>3. </p>
									<ul>
										<p>
											<b>Java OOP:</b>
											<br></br>
											Fill in the blank: To import a class, the keyword__must be used after the package statement and before the class declaration.
											<br></br>
											Pick <b>ONE</b> option:
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected3"
													value="1"
													checked={this.state.selected3 === '1'}
													onChange={this.onValueChange}
												/>
												Insert
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected3"
													value="2"
													checked={this.state.selected3 === '2'}
													onChange={this.onValueChange}
												/>
												Add
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected3"
													value="3"
													checked={this.state.selected3 === '3'}
													onChange={this.onValueChange}
												/>
												New
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected3"
													value="4"
													checked={this.state.selected3 === '4'}
													onChange={this.onValueChange}
												/>
												Import
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>4. </p>
									<ul>
										<p>
											<b>Java IO:</b>
											<br></br>
											When we "throw an exception", what happens?
											<br></br>
											Pick <b>ONE</b> option:
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected4"
													value="1"
													checked={this.state.selected4 === '1'}
													onChange={this.onValueChange}
												/>
												Skip the exception altogether
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected4"
													value="2"
													checked={this.state.selected4 === '2'}
													onChange={this.onValueChange}
												/>
												Finish runnin all code, then go back to exception
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected4"
													value="3"
													checked={this.state.selected4 === '3'}
													onChange={this.onValueChange}
												/>
												Pass the exception back to the calling method
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected4"
													value="4"
													checked={this.state.selected4 === '4'}
													onChange={this.onValueChange}
												/>
												None of the Above
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>5. </p>
									<ul>
										<p>
										<b>RESTful APIs</b>
											<br></br>
											What HTTP method is used to replace an existing item at the URL?
											<br></br>
											Pick <b>ONE</b> option:
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected5"
													value="1"
													checked={this.state.selected5 === '1'}
													onChange={this.onValueChange}
												/>
												GET
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected5"
													value="2"
													checked={this.state.selected5 === '2'}
													onChange={this.onValueChange}
												/>
												POST
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected5"
													value="3"
													checked={this.state.selected5 === '3'}
													onChange={this.onValueChange}
												/>
												PATCH
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected5"
													value="4"
													checked={this.state.selected5 === '4'}
													onChange={this.onValueChange}
												/>
												PUT
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>6. </p>
									<ul>
										<p>
										<b>Java EE:</b>
											<br></br>
											True or False: The Entity manages is the central API which is within the application but does not work with the database.
											<br></br>
											Pick <b>ONE</b> option:
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected6"
													value="1"
													checked={this.state.selected6 === '1'}
													onChange={this.onValueChange}
												/>
												True
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected6"
													value="2"
													checked={this.state.selected6 === '2'}
													onChange={this.onValueChange}
												/>
												False
											</label>
										</li>
										
									</ul>
								</div>

								
							</form>
							<div class="exam-container3">
								<button
									class="hvr-sweep"
									type="submit"
									value="2"
									onClick={this.movePg}
								>
									Next Page
								</button>
							</div>
						</div>
					</section>

					<section></section>
				</div>

				{/*------------------------------------------------Page 2------------------------------------------------*/}

				<div class="pg2" id="pg2">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-1">
									<text>{this.state.percent}%</text>
								</div>
							</div>
							<div className="loadval"></div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>
							<form>
							<div class="question">
									<p>7. </p>
									<ul>
										<p>
											<b>Java IO:</b>
											<br></br>
											Fill in the blank: The__is a list of all the method calls at that point in the execution of the program.
											<br></br>
											Pick <b>ONE</b> option:
										</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected7"
													value="1"
													checked={this.state.selected7 === '1'}
													onChange={this.onValueChange}
												/>
												Linked Stack
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected7"
													value="2"
													checked={this.state.selected7 === '2'}
													onChange={this.onValueChange}
												/>
												Call Stack
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected7"
													value="3"
													checked={this.state.selected7 === '3'}
													onChange={this.onValueChange}
												/>
												Stack
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected7"
													value="4"
													checked={this.state.selected7 === '4'}
													onChange={this.onValueChange}
												/>
												All of the Above
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>8. </p>
									<ul>
										<p>
											<b>Database:</b>
											<br></br>
											True or False: The relationships between tables must be created and defined before any data (rows) can be added.
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected8"
													value="1"
													checked={this.state.selected8 === '1'}
													onChange={this.onValueChange}
												/>
												True
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected8"
													value="2"
													checked={this.state.selected8 === '2'}
													onChange={this.onValueChange}
												/>
												False
											</label>
										</li>
										
									</ul>
								</div>
							<div class="question">
									<p>9. </p>
									<ul>
										<p>
											<b>Database:</b>
											<br></br>
											Fill in the blank: When you create a table in a relational database, you must assign a__to each column in the table.
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected9"
													value="1"
													checked={this.state.selected9 === '1'}
													onChange={this.onValueChange}
												/>
												Function
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected9"
													value="2"
													checked={this.state.selected9 === '2'}
													onChange={this.onValueChange}
												/>
												Method
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected9"
													value="3"
													checked={this.state.selected9 === '3'}
													onChange={this.onValueChange}
												/>
												Object
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected9"
													value="4"
													checked={this.state.selected9 === '4'}
													onChange={this.onValueChange}
												/>
												Data Type
											</label>
										</li>
									</ul>
								</div>
							
							
							
								<div class="question">
									<p>10. </p>
									<ul>
										<p>
											<b>SQL:</b>
											<br></br>
											What is the HTML verb for create?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected10"
													value="1"
													checked={this.state.selected10 === '1'}
													onChange={this.onValueChange}
												/>
												POST
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected10"
													value="2"
													checked={this.state.selected10 === '2'}
													onChange={this.onValueChange}
												/>
												GET
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected10"
													value="3"
													checked={this.state.selected10 === '3'}
													onChange={this.onValueChange}
												/>
												PUT/PATCH
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected10"
													value="4"
													checked={this.state.selected10 === '4'}
													onChange={this.onValueChange}
												/>
												DELETE
											</label>
										</li>
									</ul>
								</div>

								<div class="question">
									<p>11. </p>
									<ul>
										<p>
											<b>Spring Boot:</b>
											<br></br>
											JPA will recognize which annotation as the object's ID and primary key?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected11"
													value="1"
													checked={this.state.selected11 === '1'}
													onChange={this.onValueChange}
												/>
												@Id
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected11"
													value="2"
													checked={this.state.selected11 === '2'}
													onChange={this.onValueChange}
												/>
												@GeneratedValue
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected11"
													value="3"
													checked={this.state.selected11 === '3'}
													onChange={this.onValueChange}
												/>
												@Column
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected11"
													value="4"
													checked={this.state.selected11 === '4'}
													onChange={this.onValueChange}
												/>
												None of the Above
											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>12. </p>
									<ul>
										<p>
											<b>Spring Boot:</b>
											<br></br>
											In the MainController, what does @RequestMapping(“/”) do?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected12"
													value="1"
													checked={this.state.selected12 === '1'}
													onChange={this.onValueChange}
												/>
												Tells Spring to come to this method at the root URL for the application
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected12"
													value="2"
													checked={this.state.selected12 === '2'}
													onChange={this.onValueChange}
												/>
												Tells Spring to come to this method for dubbedg testing.
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected12"
													value="3"
													checked={this.state.selected12 === '3'}
													onChange={this.onValueChange}
												/>
												Tells Spring to ignore this method.
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected12"
													value="4"
													checked={this.state.selected12 === '4'}
													onChange={this.onValueChange}
												/>
												Tells Spring to come to this method on every page in the application.
											</label>
										</li>
									</ul>
								</div>
								
							</form>
							<div class="exam-container5">
								<button
									type="submit"
									class="hvr-sweep"
									value="1"
									onClick={this.movePg}
								>
									Previous Page
								</button>
								<button
									type="submit"
									class="hvr-sweep"
									value="3"
									onClick={this.movePg}
								>
									Next Page
								</button>
							</div>
						</div>
					</section>
					<section></section>
				</div>

				{/*------------------------------------------------Page 3------------------------------------------------*/}

				<div class="pg3" id="pg3">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-2">
									<text>{this.state.percent}%</text>
								</div>
							</div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>

							<form>
							<div class="question">
									<p>13. </p>
									<ul>
										<p>
											<b>Spring Boot:</b>
											<br></br>
											Which dependency can be used to connect your Spring application to a SQL database?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected13"
													value="1"
													checked={this.state.selected13 === '1'}
													onChange={this.onValueChange}
												/>
												JPA
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected13"
													value="2"
													checked={this.state.selected13 === '2'}
													onChange={this.onValueChange}
												/>
												DevTools
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected13"
													value="3"
													checked={this.state.selected13 === '3'}
													onChange={this.onValueChange}
												/>
												Web
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected13"
													value="4"
													checked={this.state.selected13 === '4'}
													onChange={this.onValueChange}
												/>
												H2
											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>14. </p>
									<ul>
										<p>
											<b>Intro to Docker:</b>
											<br></br>
											How can a new container be created?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected14"
													value="1"
													checked={this.state.selected14 === '1'}
													onChange={this.onValueChange}
												/>
												New containers are created on their own without using an existing image.
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected14"
													value="2"
													checked={this.state.selected14 === '2'}
													onChange={this.onValueChange}
												/>
												New containers are created from existing images.
											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>15. </p>
									<ul>
										<p>
											<b>Docker Services:</b>
											<br></br>
											What is the command for creating a new Docker service?.
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected15"
													value="1"
													checked={this.state.selected15 === '1'}
													onChange={this.onValueChange}
												/>
												$docker service create--name{'<'}your-service-name{'>'}
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected15"
													value="2"
													checked={this.state.selected15 === '2'}
													onChange={this.onValueChange}
												/>
												$docker create--name{'<'}your-service-name{'>'}
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected15"
													value="3"
													checked={this.state.selected15 === '3'}
													onChange={this.onValueChange}
												/>
												$docker service {'<'}your-service-name{'>'}
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected15"
													value="4"
													checked={this.state.selected15 === '4'}
													onChange={this.onValueChange}
												/>
												$docker service create--name--newservice{'<'}your-service-name{'>'}

											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>16. </p>
									<ul>
										<p>
											<b>HTML:</b>
											<br></br>
											Which would you use to open content in a new tab?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected16"
													value="1"
													checked={this.state.selected16 === '1'}
													onChange={this.onValueChange}
												/>
												target= “open”
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected16"
													value="2"
													checked={this.state.selected16 === '2'}
													onChange={this.onValueChange}
												/>
												target= “_blank”
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected16"
													value="3"
													checked={this.state.selected16 === '3'}
													onChange={this.onValueChange}
												/>
												page=_ “blank”
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected16"
													value="4"
													checked={this.state.selected16 === '4'}
													onChange={this.onValueChange}
												/>
												None of the Above
											</label>
										</li>
									</ul>
								</div>
							
							<div class="question">
									<p>17. </p>
									<ul>
										<p>
											<b>HTML:</b>
											<br></br>
											True or False: This is the correct syntax for creating a link: {'<'}a href= “YOUR_URL”{'>'}Click Here{'</a>'}
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected17"
													value="1"
													checked={this.state.selected17 === '1'}
													onChange={this.onValueChange}
												/>
												True
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected17"
													value="2"
													checked={this.state.selected17 === '2'}
													onChange={this.onValueChange}
												/>
												False
											</label>
										</li>
									</ul>
								</div>
							
							
							
								<div class="question">
									<p>18. </p>
									<ul>
										<p>
											<b>JavaScript:</b>
											<br></br>
											When addressing an array, you put the index value we want to call in __?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected18"
													value="1"
													checked={this.state.selected18 === '1'}
													onChange={this.onValueChange}
												/>
												{'{}'}
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected18"
													value="2"
													checked={this.state.selected18 === '2'}
													onChange={this.onValueChange}
												/>
												{'<>'}
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected18"
													value="3"
													checked={this.state.selected18 === '3'}
													onChange={this.onValueChange}
												/>
												[]
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected18"
													value="4"
													checked={this.state.selected18 === '4'}
													onChange={this.onValueChange}
												/>
												All of the Above
											</label>
										</li>
									</ul>
								</div>

								
							</form>
							<div class="exam-container5">
								<button
									type="submit"
									class="hvr-sweep"
									value="2"
									onClick={this.movePg}
								>
									Previous Page
								</button>
								<button
									type="submit"
									class="hvr-sweep"
									value="4"
									onClick={this.movePg}
								>
									Next Page
								</button>
								
							</div>
						</div>
					</section>
					<section></section>
				</div>

				{/*------------------------------------------------Page 4------------------------------------------------*/}

				<div class="pg4" id="pg4">
					<section class="hero-university2"></section>
					<section class="exam-container4">
						<div class="progress">
							<div class="stripes">
								<div class="prog-val prog-3">
									<text>{this.state.percent}%</text>
								</div>
							</div>
						</div>
					</section>
					<section class="exam-container">
						<div class="container">
							<h1>Minos Labs Assessment</h1>
							<form>
							<div class="question">
									<p>19. </p>
									<ul>
										<p>
											<b>JavaScript:</b>
											<br></br>
											A string can be concatenated by using which operator?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected19"
													value="1"
													checked={this.state.selected19 === '1'}
													onChange={this.onValueChange}
												/>
												“+”
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected19"
													value="2"
													checked={this.state.selected19 === '2'}
													onChange={this.onValueChange}
												/>
												“=”
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected19"
													value="3"
													checked={this.state.selected19 === '3'}
													onChange={this.onValueChange}
												/>
												“++”
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected19"
													value="4"
													checked={this.state.selected19 === '4'}
													onChange={this.onValueChange}
												/>
												“==”
											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>20. </p>
									<ul>
										<p>
											<b>JavaScript:</b>
											<br></br>
											Fill in the blank: All numbers in TypeScript are either__
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected20"
													value="1"
													checked={this.state.selected20 === '1'}
													onChange={this.onValueChange}
												/>
												Longs or BigIntegers
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected20"
													value="2"
													checked={this.state.selected20 === '2'}
													onChange={this.onValueChange}
												/>
												Strings or BigIntegers
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected20"
													value="3"
													checked={this.state.selected20 === '3'}
													onChange={this.onValueChange}
												/>
												Floating point values or integers
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected20"
													value="4"
													checked={this.state.selected20 === '4'}
													onChange={this.onValueChange}
												/>
												Floating point values or BigIntegers
											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>21. </p>
									<ul>
										<p>
											<b>AWS:</b>
											<br></br>
											How many regions does AWS have?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected21"
													value="1"
													checked={this.state.selected21 === '1'}
													onChange={this.onValueChange}
												/>
												12
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected21"
													value="2"
													checked={this.state.selected21 === '2'}
													onChange={this.onValueChange}
												/>
												16
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected21"
													value="3"
													checked={this.state.selected21 === '3'}
													onChange={this.onValueChange}
												/>
												20
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected21"
													value="4"
													checked={this.state.selected21 === '4'}
													onChange={this.onValueChange}
												/>
												24
											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>22. </p>
									<ul>
										<p>
											<b>AWS:</b>
											<br></br>
											True or False: Your company has deployed their production environment on AWS and now needs to access their EC2 instances via a bastion host using Windows Remote Desktop protocol. To achieve this, Create a bastion host in a public subnet and open the RDP port up to the bastion security group. Restrict RDP access so that only users with IP address ranges from within your office network can RDP into this bastion host
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected22"
													value="1"
													checked={this.state.selected22 === '1'}
													onChange={this.onValueChange}
												/>
												True
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected22"
													value="2"
													checked={this.state.selected22 === '2'}
													onChange={this.onValueChange}
												/>
												False
											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>23. </p>
									<ul>
										<p>
											<b>AWS:</b>
											<br></br>
											A Solutions Architect is developing a document sharing application and needs a storage layer. The storage should provide automatic support for versioning so that the user can easily roll back to a previous version or recover a deleted account. Which AWS service will meet the above requirements?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected23"
													value="1"
													checked={this.state.selected23 === '1'}
													onChange={this.onValueChange}
												/>
												Amazon EBS
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected23"
													value="2"
													checked={this.state.selected23 === '2'}
													onChange={this.onValueChange}
												/>
												Amazon EFS
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected23"
													value="3"
													checked={this.state.selected23 === '3'}
													onChange={this.onValueChange}
												/>
												Amazon Storage Gateway VTL
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected23"
													value="4"
													checked={this.state.selected23 === '4'}
													onChange={this.onValueChange}
												/>
												Amazon S3
											</label>
										</li>
									</ul>
								</div>
								<div class="question">
									<p>24. </p>
									<ul>
										<p>
											<b>Maven:</b>
											<br></br>
											Which of the following configuration elements is present in POM.xml?
											<br></br>
											Pick <b>ONE</b> option:
											</p>
										<li>
											{' '}
											<label>
												<input
													type="radio"
													name="selected24"
													value="1"
													checked={this.state.selected24 === '1'}
													onChange={this.onValueChange}
												/>
												Project  Dependencies
											</label>{' '}
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected24"
													value="2"
													checked={this.state.selected24 === '2'}
													onChange={this.onValueChange}
												/>
												Plugins
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected24"
													value="3"
													checked={this.state.selected24 === '3'}
													onChange={this.onValueChange}
												/>
												Goals
											</label>
										</li>
										<li>
											<label>
												<input
													type="radio"
													name="selected24"
													value="4"
													checked={this.state.selected24 === '4'}
													onChange={this.onValueChange}
												/>
												All of the Above
											</label>
										</li>
									</ul>
								</div>
							</form>

							<div class="exam-container5">
								<button
									class="hvr-sweep"
									type="submit"
									value="3"
									onClick={this.movePg}
								>
									Previous Page
								</button>
								<Link
									to={{
										pathname: '/exam-complete',
										state: {
											timediff:
												(new Date() - this.state.startTime) / 60000, //Total time in minutes
										},
									}}
								>
									<button
										class="hvr-sweep"
										type="submit"
										onClick={this.handleClick}
									>
										Submit
									</button>
								</Link>
							</div>
						</div>
					</section>

					<section></section>
				</div> 
			</React.Fragment>
		);
	}
}

export default BCExam;
