import React from 'react';
import styles from './AdminContactMessages.module.scss';
import Dashboard from '../../components/AdminDashboard';
import UserContext from '../../User/User';
import DataTable from '../../components/DataTable';
import firebase from 'firebase';

function convertDate(timestamp) {
	let timeStampArray = timestamp.split(' ');
	let messageMonth = timeStampArray[1].toLowerCase();
	let months = [
		'jan',
		'feb',
		'mar',
		'apr',
		'may',
		'jun',
		'jul',
		'aug',
		'sep',
		'oct',
		'nov',
		'dec',
	];

	// converting month from string to its number
	let messageMonthIndex = months.indexOf(messageMonth) + 1;

	// getting date and year from the firebase timestamp
	let messageDate = parseInt(timeStampArray[2]);
	let messageYear = parseInt(timeStampArray[3]);

	return `${messageMonthIndex}/${messageDate}/${messageYear}`;
}

function convertTime(timestamp) {
	let timeStampArray = timestamp.split(' ');
	let time = timeStampArray[0];

	//split the time and get the hours
	let hours = time.split(':');

	//check whether it's EDT or EST
	//if EDT, convert to EST by subtracting 1 from EDT
	let timezone = timeStampArray[3];
	if (timezone === 'Daylight') {
		hours[0] -= 1;
	}

	// if hours >= 12, show pm else show am
	let pmORam = hours[0] >= 12 ? 'pm' : 'am';

	//if hours > 12, subtract 12 from it
	//because we are showing pm instead
	if (hours[0] > 12) {
		hours[0] -= 12;

		// change from 8:10 to 08:10
		// because firebase shows that way
		if (hours[0] < 10) {
			hours[0] = '0' + hours[0];
		}
	}

	let messageTime = `${hours[0]}:${hours[1]} ${pmORam}`;
	return messageTime;
}

// function that takes in all the messages,
// stores them in a data array and returns the array
function dataArray(messages) {
	let rows = [];

	for (let i = 0; i < messages.length; i++) {
		let email, first, last, mess, date, time, role;
		//console.log(messages[i].timestamp.toDate().toTimeString());

		date = convertDate(messages[i].timestamp.toDate().toDateString());
		time = convertTime(messages[i].timestamp.toDate().toTimeString());

		email = messages[i].email;
		first = messages[i].firstName;
		last = messages[i].lastName;
		mess = messages[i].message;
		role = messages[i].whoAreYou;

		rows.push({
			email: email,
			first: first,
			last: last,
			mess: mess,
			date: date,
			time: time,
			role: role,
		});
		//console.log(rows[i]);
	}
	return rows;
}

class AdminContactMessages extends React.Component {
	constructor() {
		super();

		this.state = {
			messages: [],
			rows: {},
			columns: [],
			selectedRow: null,
		};
	}
	static contextType = UserContext;

	// get all the messages from 'contact us' collection
	// and store them in messages variable declared in state
	async componentDidMount() {
		const db = firebase.firestore();
		await db
			.collection('contactUs')
			.get()
			.then((querySnapshot) => {
				const data = querySnapshot.docs.map((doc) => doc.data());
				console.log(data);
				this.setState({ messages: data });
			});

		// // find users from ALL_USER collection
		// // who have failed in the exam
		// let failedApplicants;
		// await db
		// 	.collection('ALL_USER')
		// 	.where('exam_Flag', '==', 'fail')
		// 	.get()
		// 	.then((querySnapshot) => {
		// 		failedApplicants = querySnapshot.docs.map((doc) => doc.data());
		// 		console.log(failedApplicants);
		// 	});

		// // get current time in seconds
		// let currentTime = new Date().getTime() / 1000;
		// console.log(currentTime);

		// // iterate through failed applicants list and
		// // calculate the time difference in days between
		// // now and the time when they finished the exam
		// const daysDiff = failedApplicants.map((applicant) => {
		// 	let failTime = applicant.examTimestamp.seconds;
		// 	let timeDiff = currentTime - failTime;

		// 	// convert time difference into days
		// 	return [
		// 		Math.floor(timeDiff / (3600 * 24)),
		// 		applicant.first,
		// 		applicant.email,
		// 	];
		// });
		// console.log(daysDiff);

		// daysDiff.forEach((applicant) => {
		// 	if (applicant[0] >= 14) {
		// 		console.log(
		// 			'send an email to ' + applicant[1] + ' ' + applicant[2]
		// 		);
		// 	}
		// });

		let rows = dataArray(this.state.messages);

		this.setState({ rows: rows });
		this.setState({
			columns: [
				{
					label: 'Date',
					field: 'date',
				},
				{
					label: 'Time',
					field: 'time',
				},
				{
					label: 'Email ID',
					field: 'email',
				},
				{
					label: 'Message',
					field: 'mess',
				},
				{
					label: 'Role',
					field: 'role',
				},
			],
		});
	}

	render() {
		// hard coding the columns for now
		// because line 174 doesn't work for some reason
		const columns = [
			{
				title: 'Date',
				field: 'date',
			},
			{
				title: 'Time',
				field: 'time',
			},
			{
				title: 'Email ID',
				field: 'email',
			},
			{
				title: 'Message',
				field: 'mess',
			},
			{
				title: 'Role',
				field: 'role',
			},
		];

		// converting the rows to an array of objects
		// instead of an object of objects
		// because MaterialTable wants an array
		const rowsArray = Object.entries(this.state.rows);
		const rows = rowsArray.map((row) => {
			return row[1];
		});

		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="View_Candidate" />

						{/*right expands when dashboard is closed*/}
						<div class="right">
							<div class="topBar">
								<div class="pageName">
									<h1>Candidates</h1>
								</div>
							</div>

							{/*code here*/}
							<div className={styles.inner}>
								<div className={styles.message_list}>
									<div className={styles.blockHeader}>
										<h1>View Messages</h1>
										<h2>Manage Your Inbox</h2>
									</div>

									<div>
										<DataTable
											title="Contact Us Messages"
											rows={rows}
											columns={columns}
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default AdminContactMessages;
