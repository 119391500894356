/**
 * Top most component for Twilio video component,
 * handles the user driven actions of joining and leaving the room
 * Refeence: https://www.twilio.com/blog/build-a-custom-video-chat-app-with-react-and-twilio-programmable-video
 */

import React, { Component } from 'react';
import Room from './Room';
import './stylesheets/Interview.css';
import MediaAccessModal from './Modals/MediaAccessModal';
import { Container, Spinner, Row, Col } from 'reactstrap';
import UserContext from "../User/User";
const axios = require('axios');
const { connect } = require('twilio-video');




class TwilioVideo extends Component {



    constructor(props) {
        super(props)

        this.state = {
            identity: '',
            //Fetch from backend
            room_name: this.props.interviewId,
            participants: 0,
            room: null,
            cameraAccess: false,
            audioAccess: false,
            videoToggle: true,
            audioToggle: true,
            joinMeetingEnabled: true,
            userType: "",
            interviewId: this.props.interviewId
        }
        this.joinRoom = this.joinRoom.bind(this);
        this.endMeeting = this.endMeeting.bind(this);
        this.videoAudioToggle = this.videoAudioToggle.bind(this);


    }
    static contextType = UserContext;
    componentDidMount() {
        /** This checks for physical camera, microphone being present
         * before joining a meeting and sets camera/audio access to true
         * */
        navigator.mediaDevices.enumerateDevices().then(devices => {
            console.log(devices)
            const videoInput = devices.find(device => device.kind === 'videoinput');
            const audioInput = devices.find(device => device.kind === 'audioinput');

            if (videoInput !== undefined) {
                this.setState({
                    cameraAccess: true
                })
            }
            if (audioInput !== undefined) {
                this.setState({
                    audioAccess: true
                })
            }
        });

        let { userID, logoutUser, setUser, loading, userInfo, loginType } = this.context;
        console.log(loginType)

        if (loginType == "Student") {
            this.setState({
                identity: userInfo.firstname + " " + userInfo.lastname,
                userType: loginType,
            })
        }
        else {
            this.setState({
                identity: userInfo.first + " " + userInfo.last,
                userType: loginType,
            })
        }






    }

    /**
     * This is the method that will be called when the user clicks the Join Room button
     */
    async joinRoom() {
        this.setState({
            room: "waiting",
        })
        try {
            const response = await axios.get(`https://us-central1-minos-labs-57c35.cloudfunctions.net/interviewApp/getTwilioAccess?identity=${this.state.identity}`);
            const data = await response.data;
            const room = await connect(data, {
                name: this.state.room_name,
                audio: this.state.audioAccess,
                video: this.state.cameraAccess
            });
            this.setState({
                room: room,
            });
  
            var x = document.getElementsByClassName('twilio');
            for (let i = 0; i < x.length; i++) {
                x[i].style.height = "auto";
            }
           // twilio.style.height = "auto"
        } catch (e) {
            // Add a reactstrap alert instead

            //If the video/audio does not have permission
            if (e.code === 0) {
                alert("Please check your system permission for audio video access ")
            }
            else {
                alert(e)
            }
            this.setState({
                room: null,
            });

        }
    }

    /**
     * This video sets toggle video audio before joining the meeting
     * @param {bool} videoAccess 
     * @param {bool} audioAccess 
     */
    videoAudioToggle(audioToggle, videoToggle) {
        console.log(audioToggle, videoToggle)
        this.setState({
            audioToggle: audioToggle,
            videoToggle: videoToggle,
            joinMeetingEnabled: false
        })

        this.joinRoom()
    }

    /**
     * This is the method that will be called when the user clicks the Leave meeting button
     */
    endMeeting() {
        this.setState({
            room: null,
            joinMeetingEnabled: true
        });
        var x = document.getElementsByClassName('twilio');
        for (let i = 0; i < x.length; i++) {
            x[i].style.height = "100%";
        }
    }

    render() {


        if (this.state.room === null) {
            return (
                <div className="twilioVideo">
                    <div id="wrapper">
                        <div>
                            <MediaAccessModal joinRoom={this.joinRoom} videoAccess={this.state.cameraAccess} audioAccess={this.state.audioAccess} videoAudioToggle={this.videoAudioToggle} joinMeetingEnabled={this.state.joinMeetingEnabled} ></MediaAccessModal>
                        </div>
                    </div>

                </div>
            )

        }
        else if (this.state.room === "waiting") {
            return (
                <div className="twilioVideo">
                    <div id="wrapper">
                        <Container>
                            <Row className="align-items-center">
                                <Col className="d-flex justify-content-center">
                                    <Spinner color="primary"></Spinner>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>
            )
        }
        else {
            return (
                
                <div>

                                <div className="twilioVideo">
                        <Room className="room" {...this.props} leaveRoom={this.endMeeting} room={this.state.room} videoToggle={this.state.videoToggle} audioToggle={this.state.audioToggle} userType={this.state.userType}></Room>
                                </div>
                    </div>

            )
        }
    }

}
export default TwilioVideo

