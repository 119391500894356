import React from 'react';
import styles from './Article.module.scss';
import firebase from 'firebase';

import ArticleSlideShow from '../../components/ArticleSlideShow';
import {
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
} from 'react-share';

class Candidate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			date: '',
			tags: '',
			readTime: '',
			loading: true,
			loadingSlides: true,
			paragraphs: [],
			profileUrl: '',
			id: this.props.match.params.articleId.toLowerCase(),
		};

		this.displayContent = this.displayContent.bind(this);
	}

	displayContent() {
		const { paragraphs } = this.state;

		var paragraphList = [];
		var i;

		for (i = 0; i < paragraphs.length; i++) {
			let paragraph;

			try {
				paragraph = paragraphs[i];
			} catch (err) {
				continue;
			}

			paragraphList.push(<p>{paragraph}</p>);
		}

		return paragraphList;
	}

	componentDidMount() {
		const id = this.state.id;
		console.log(id);
		const db = firebase.firestore();

		db.collection('articles')
			.where('articleId', '==', id)
			.get()
			.then((querySnapshot) => {
				const data = querySnapshot.docs.map((doc) => doc.data());
				try {
					this.setState({
						title: data[0].title,
						date: data[0].date,
						tags: data[0].tags,
						paragraphs: data[0].content,
						readTime: data[0].readTime,
						loading: false,
					});
					console.log(data[0]);
				} catch (err) {
					window.location.replace('/404');
				}
			});

		var storage = firebase.storage().ref();

		const articleId = id + '.png';

		console.log(articleId);

		storage
			.child('articlePics')
			.child(articleId)
			.getDownloadURL()
			.then((url) => {
				this.setState({
					profileUrl: url,
				});
			})
			.catch((e) => {
				console.log(e);
				storage
					.child('profilepics')
					.child('noprofilepic.jpg')
					.getDownloadURL()
					.then((url2) => {
						this.setState({
							profileUrl: url2,
						});
					});
			});
	}

	render() {
		return (
			<React.Fragment>
				<section className={"hero-university2"}></section>
				<section className={styles.articleContainer}>
					<div
						style={
							this.state.loading
								? { display: 'none' }
								: { display: 'block' }
						}
						className={styles.articleBlock}
					>
						<div className={styles.imgWrapper}>
							<img
								className={styles.articleImg}
								src={this.state.profileUrl}
								alt="Article Image"
							/>
						</div>
						<div className={styles.resourceHead}>
							<div>
								<h1>{this.state.title}</h1>

								<p className={styles.resourceTags}>
									Read Time: {this.state.readTime}
								</p>
							</div>
							<p style={{ marginTop: '10px' }}>{this.state.date}</p>
						</div>

						{this.displayContent()}

						<p className={styles.resourceTags}>Tags: {this.state.tags}</p>

						<div className={styles.share}>
							<h6>Share on:</h6>
							<div className={styles.socials}>
								<FacebookShareButton
									className={'share_button'}
									url={`https://www.minoslabs.com/articles/${this.state.id}`}
									quote={this.state.title}
									hashtag={this.state.tags}
								>
									<FacebookIcon round={true} size={43} />
								</FacebookShareButton>

								<LinkedinShareButton
									className={'share_button'}
									title={this.state.title}
									url={`https://www.minoslabs.com/articles/${this.state.id}`}
								>
									<LinkedinIcon round={true} size={43} />
								</LinkedinShareButton>

								<TwitterShareButton
									className={'share_button'}
									title={this.state.title}
									via="Minos Labs"
									url={`https://www.minoslabs.com/articles/${this.state.id}`}
								>
									<TwitterIcon round={true} size={43} />
								</TwitterShareButton>
							</div>
						</div>
					</div>
				</section>
				<ArticleSlideShow id={this.state.id} />
			</React.Fragment>
		);
	}
}

export default Candidate;
