import React from "react";
import styles from "./CommandCenter.module.scss";
import Dashboard from "../../components/Dashboard";

import firebase from "firebase";
import UserContext from "../../User/User";
import add_acc_logo from "../../assets/manage account/noprofilepic.jpg";
import { Pie } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import edit_sign from "../../assets/hiring_requirement/edit_sign.png";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import HR_Select from "../../components/HRSelect";
import MonthlyNav from "../../components/DashboardCalendar.js"
import AgendaCalendar from "../../components/AgendaCalendar.js"
import axios from 'axios';

const myEventsList = [
  {
    allDay: true,
    end: new Date('2021-03-26'),
    start: new Date('2021-03-26'),
    title: 'Testing',
  },
  {
    allDay: true,
    end: new Date('December 09, 2021 11:13:00'),
    start: new Date('December 09, 2021 11:13:00'),
    title: 'All Day Event',
  },
];


// const diversity = {
//   labels: [
//     "Black/African American",
//     "White/Caucasian ",
//     "Native American",
//     "Asian/Pacific Islander",
//     "Hispanic/Latin American",
//     "2 or more races",
//     "Prefer not to identify",
//   ],
//   datasets: [
//     {
//       label: "Diversity",
//       backgroundColor: [
//         "#4285F4",
//         "#EA4335",
//         "#FBBC04",
//         "#34A853",
//         "#FF6D01",
//         "#46BDC6",
//         "#7BAAF7",
//       ],
//       hoverBackgroundColor: [
//         "#152b4f",
//         "#4f1712",
//         "#4f3b02",
//         "#194f27",
//         "#4f2200",
//         "#1c4b4f",
//         "#28374f",
//       ],
//       data: [25.7, 28.6, 0, 40, 2.9, 2.9, 0],
//     },
//   ],
// };



// const state = {
//   labels: [
//     "Not Applicable",
//     "Veteran",
//   ],
//   datasets: [
//     {
//       label: "Diversity",
//       backgroundColor: [
//         "#4285F4",
//         "#EA4335",
//         "#FBBC04",
//         "#34A853",
//         "#FF6D01",
//         "#46BDC6",
//         "#7BAAF7",
//       ],
//       hoverBackgroundColor: [
//         "#152b4f",
//         "#4f1712",
//         "#4f3b02",
//         "#194f27",
//         "#4f2200",
//         "#1c4b4f",
//         "#28374f",
//       ],
//       data: [88.6, 11.4],
//     },
//   ],
// };
const gender = {
  labels: [
    "Male",
    "Female",
  ],
  datasets: [
    {
      label: "Diversity",
      backgroundColor: [
        "#4285F4",
        "#e75480",
        "#FBBC04",
        "#34A853",
        "#FF6D01",
        "#46BDC6",
        "#7BAAF7",
      ],
      hoverBackgroundColor: [
        "#152b4f",
        "#4f1712",
        "#4f3b02",
        "#194f27",
        "#4f2200",
        "#1c4b4f",
        "#28374f",
      ],
      data: [88.6, 11.4],
    },
  ],
};





class CommandCenter extends React.Component {
  constructor() {
    super();

    this.state = {
      first: "",
      last: "",
      company: "",
      role: "",
      email: "",
      phoneNumber: "",
      url: "",
      linkedin: "",
      industry: "",

      HRfirstName: "",
      HRlastName: "",
      HRemailId: "",
      HRpassword: "",
      HRlinkedin: "",
      HRconfirm: "",
      HRrole: "",

      subUsers: [],
      skills: [],
      skill: "",
      skillError: "",
      view: "overview",
      loading: true,
      textDisplay: "edit_req",
      inputDisplay: "edit_input_hide",
      editButton: "edit_button",
      setButton: "set_button_hide",
      calendarEvents: [],
      
      courses: {},
      gender:{},
      veteran:{},
      show:"diversity",
      diversity: {}
    };
    this.showChart1 = this.showChart1.bind(this);
		this.showChart2 = this.showChart2.bind(this);
		this.showChart3 = this.showChart3.bind(this);
  }
  showChart1() {
		this.setState({
			show: "diversity",
		});
		console.log(this.state.show);
	}
  showChart2() {
		this.setState({
			show: "veteran",
		});
		console.log(this.state.show);
	}
  showChart3() {
		this.setState({
			show: "gender",
		});
		console.log(this.state.show);
  }

  static contextType = UserContext;
  async componentDidMount() {
    this.displayAccInfo();
    const db = firebase.firestore();
    let fullstack = 0;
    let cyber = 0;
    let vet_status = 0;
    let nap = 0
    let americanIndian = 0;
    let asian = 0;
    let black = 0;
    let hispanic = 0;
    let nativeHawi = 0;
    let na = 0;
    let twoOrMore = 0;
    let white = 0;
    let female = 0;
    let male = 0;

    

    await db
    .collection('ALL_USER')
    .get()
    .then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data());
      console.log(data);
      for(let index = 0;index<data.length;index++){
        if(data[index].course==="FullStack"){
          fullstack++;
        }else{
          cyber++;
        }
  
        //grabbing veteran statistics
        if (data[index].veteran === "veteran"){
          vet_status++;
        }
        else{
          nap++;
        }
        //this is grabbing the information for ethnicity
        if(data[index].ethnicity === 'AmericanIndian'){
          americanIndian++;
        } else if(data[index].ethnicity === 'Asian'){
          asian++;
        } else if(data[index].ethnicity ==='Black'){
          black++;
        } else if(data[index].ethnicity === 'Hispanic'){
          hispanic++;
        } else if(data[index].ethnicity === 'NativeHawaiianPacificIslander'){
          nativeHawi++;
        } else if(data[index].ethnicity === 'NotAnswer' || data[index].ethnicity === 'Not'){
          na++;
        } else if(data[index].ethnicity === 'TwoOrMore'){
          twoOrMore++;
        } else{
          white++;
        }

        //this is grabbing gender information
        if(data[index].gender === 'female'){
          female++;
        }else{
          male++;
        }


      }


    });
    
    
  
    const courses = {
      labels: [
        "Full-Stack",
        "Cyber",
      ],
      datasets: [
        {
          label: "Course",
          backgroundColor: [
            "#4285F4",
            "#EA4335",
            "#FBBC04",
            "#34A853",
            "#FF6D01",
            "#46BDC6",
            "#7BAAF7",
          ],
          hoverBackgroundColor: [
            "#152b4f",
            "#4f1712",
            "#4f3b02",
            "#194f27",
            "#4f2200",
            "#1c4b4f",
            "#28374f",
          ],
          data: [fullstack, cyber],
        },
      ],
    };
    const diversity = {
      labels: [
        "Black/African American",
        "White/Caucasian ",
        "Native American",
        "Asian",
        "Hispanic/Latin American",
        "Native Hawaiian/Pacific Islander",
        "2 or more races",
        "Prefer not to identify",
      ],
      datasets: [
        {
          label: "Diversity",
          backgroundColor: [
            "#4285F4",
            "#EA4335",
            "#FBBC04",
            "#34A853",
            "#FF6D01",
            "#46BDC6",
            "#7BAAF7",
          ],
          hoverBackgroundColor: [
            "#152b4f",
            "#4f1712",
            "#4f3b02",
            "#194f27",
            "#4f2200",
            "#1c4b4f",
            "#28374f",
          ],
          data: [black, white, americanIndian, asian, hispanic, nativeHawi, twoOrMore, na],
        },
      ],
    };

    

    const veteran = {
      labels: [
        "Not Applicable",
        "Veteran",
      ],
      datasets: [
        {
          label: "Veteran",
          backgroundColor: [
            "#4285F4",
            "#EA4335",
            "#FBBC04",
            "#34A853",
            "#FF6D01",
            "#46BDC6",
            "#7BAAF7",
          ],
          hoverBackgroundColor: [
            "#152b4f",
            "#4f1712",
            "#4f3b02",
            "#194f27",
            "#4f2200",
            "#1c4b4f",
            "#28374f",
          ],
          data: [nap, vet_status],
        },
      ],
    };
    const gender = {
      labels: [
        "Female",
        "Male",
      ],
      datasets: [
        {
          label: "Gender",
          backgroundColor: [
            "#4285F4",
            "#EA4335",
            "#FBBC04",
            "#34A853",
            "#FF6D01",
            "#46BDC6",
            "#7BAAF7",
          ],
          hoverBackgroundColor: [
            "#152b4f",
            "#4f1712",
            "#4f3b02",
            "#194f27",
            "#4f2200",
            "#1c4b4f",
            "#28374f",
          ],
          data: [female, male]
        },
      ],
    };
    //get nylas events
    var calendarStart= new Date();
    var calendarEnd= new Date();
    calendarStart.setDate(new Date().getDate()-31);
    calendarEnd.setDate(new Date().getDate()+31);
    var unixStart = Math.floor(calendarStart.getTime() / 1000);
    var unixEnd = Math.floor(calendarEnd.getTime() / 1000);


    let token = "Bearer" + " " + this.context.userInfo.nylasAccessToken
    let nylasEvents = [];
    await axios({
			url: `https://api.nylas.com/events?starts_after=${unixStart}&ends_before=${unixEnd}`,
			method: 'get',
			headers: {"Content-Type": "application/json", "Authorization": token}, 
		})
		.then(res => {
			nylasEvents = res.data;
		});
    let calendarEvents =[];
    for(let i =0;i<nylasEvents.length;i++){
      let calendarEvent ={}
      if(nylasEvents[i].when.end_time==undefined){
        calendarEvent = {
          allDay: true,
          end: nylasEvents[i].when.date,
          start: nylasEvents[i].when.date,
          title: nylasEvents[i].title,
        }
      }else{

        let interviewId = nylasEvents[i].location.replace('https://www.minoslabs.com/interview/','');

        try{
          
          var interviewRef = db.collection("interviews").doc(interviewId);
          let docFound = false;
          await interviewRef.get().then((doc) => {
              if (doc.exists) {
                docFound= true;
            }
          }).catch((error) => {
              console.log("Error getting document:", error);
          });

          if(docFound){
            interviewRef.update({
              start_time: new Date(nylasEvents[i].when.start_time * 1000)
            })
          }

  
        }catch(err){
          
        }


        calendarEvent = {
          //allDay: true,
          end: new Date(nylasEvents[i].when.end_time *1000),
          start: new Date(nylasEvents[i].when.start_time * 1000),
          title: nylasEvents[i].title,
        }
      }

      calendarEvents.push(calendarEvent);
      
    }
    console.log("calendar",calendarEvents)
    this.setState({
      calendarEvents: calendarEvents,
      courses:courses,
      veteran:veteran,
      diversity:diversity,
      gender: gender
    });

  }

  displayAccInfo() {
    const db = firebase.firestore();
    const context = this.context;

    this.setState({
      first: context.userInfo.first,
      last: context.userInfo.last,
      skills: context.userInfo.skills,
      company: context.userInfo.company,
      role: context.userInfo.companyTitle,
      email: context.userInfo.email,
      linkedin: context.userInfo.linkedin,
      phoneNumber: context.userInfo.phoneNumber,
      industry: context.userInfo.industry
    });

    db.collection("corporate")
      .where("managerID", "==", context.userID)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        this.setState({
          subUsers: data,
        });
      });

    var storage = firebase.storage();
    storage
      .ref("corporatePics")
      .child(context.userID)
      .getDownloadURL()
      .then((url) => {
        this.setState({ url });
      })
      .catch((e) => {});
  }

  render() {
    //User data
    let { userID, logoutUser, setUser, loading, userInfo } = this.context;

    let dataSet = this.state.diversity;
    if(this.state.show==="veteran"){
      dataSet = this.state.veteran;
    }else if(this.state.show==="gender"){
      dataSet = this.state.gender;
    
    }
    return (
      <div className={styles.borderbox}>
        <div className="hero-university2"></div>
        <div className="candidate_process">
          <div className="col-container">
            <Dashboard pageName="Dashboard" />

            {/*right expands when dashboard is closed*/}
            <div className="right">
            <div class="topBar">
								<div class="pageName">
									<h1>Dashboard</h1>
								</div>
							</div>
              
              <div className={styles.container}>
                <div className={styles.inner}>
                  <div className={styles.calendar}>
                    <div className={styles.calendarHeader}>
                      <h1>Calendar</h1>
                      <h2>Manage Your Upcoming Events</h2>
                      <div>
                      <MonthlyNav events={this.state.calendarEvents} />
                      </div>
                      <br></br>
                      <div>
                        <AgendaCalendar events={this.state.calendarEvents} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.block}>
                      <div className={styles.blockInner}>
                        <div className={styles.blockHeader}>
                          <h1>Cohort Enrollment</h1>
                          
                        </div>
                        <Pie
                          data={this.state.courses}
                          options={{
                            title: {
                              display: false,
                              text: "Diversity in the Minos Labs Cohort",
                              fontSize: 20,

                            },
                            tooltips: {
                              callbacks: {
                                label: function(tooltipItem, data) {
                                  //get the concerned dataset
                                  var dataset = data.datasets[tooltipItem.datasetIndex];
                                  //calculate the total of this data set
                                  var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                    return previousValue + currentValue;
                                  });
                                  //get the current items value
                                  var currentValue = dataset.data[tooltipItem.index];
                                  //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                                  var percentage = Math.floor(((currentValue/total) * 100)+0.5);
                            
                                  return percentage + "%";
                                }
                              }
                            },
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                boxWidth: 13,
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.block}>

                      <div className={styles.blockInner}>
                        <div className={styles.blockHeader}>
                           <button className={this.state.show==="diversity" ? styles.chartButton : styles.chartButtonSelected}onClick={this.showChart1} >Diversity</button>
                          <button className={this.state.show==="veteran" ? styles.chartButton : styles.chartButtonSelected} onClick={this.showChart2}>Veteran Status</button>  
                          <button className={this.state.show==="gender" ? styles.chartButton : styles.chartButtonSelected} onClick={this.showChart3}>Gender</button>
                          
                        </div>
                        <div >
                        <Pie
                          data={dataSet}
                          options={{
                            title: {
                              display: false,
                              text: "Diversity in the Minos Labs Cohort",
                              fontSize: 20,
                            },
                            tooltips: {
                              callbacks: {
                                label: function(tooltipItem, data) {
                                  //get the concerned dataset
                                  var dataset = data.datasets[tooltipItem.datasetIndex];
                                  //calculate the total of this data set
                                  var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                    return previousValue + currentValue;
                                  });
                                  //get the current items value
                                  var currentValue = dataset.data[tooltipItem.index];
                                  //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                                  var percentage = Math.floor(((currentValue/total) * 100)+0.5);
                            
                                  return percentage + "%";
                                }
                              }
                            },
                            legend: {
                              display: true,
                              position: "right",
                              labels: {
                                boxWidth: 13,
                              },
                            },
                          }}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommandCenter;
