import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
// import "bootstrap/dist/css/bootstrap.min.css";
import Header from './components/Header';
import Footer from './components/Footer';
import ExamStartRoute from './components/ExamStartRoute';
import ExamCompleteRoute from './components/ExamCompleteRoute';
import BCExamStartRoute from './components/BCExamStartRoute';
import ProtectedRoute from './components/ProtectedRoute';
import CorporateRoute from './components/CorporateRoute';
import AdminRoute from './components/AdminRoute';
import StudentRoute from './components/StudentRoute';
import UniversityRoute from './components/UniversityRoute';
import HRRoute from './components/HRRoute';
import Home from './pages/Home';
import About from './pages/About';
import Veterans from './pages/Veterans'
import Privacy_Policy from './pages/PrivacyPolicy';
import Privacy_Policy_Exam from './pages/PrivacyPolicyExam';
import University from './pages/University';
import Corporate from './pages/Corporate';

import PaymentCard from './pages/PaymentCard';
import Application from './pages/Application';
import BCReferralProgram from './pages/BCReferralProgram';
import BCExamStart from './pages/BCExamStart';
import BCExam from './pages/BCExam';
import Exam_start from './pages/ExamStart';
import Exam_pt1 from './pages/ExamJava';
import FutureCohorts from './pages/FutureCohorts';

import Cyber_pt1 from './pages/CyberExam';
import Exam_complete from './pages/ExamComplete';

import ScrollToTop from './components/ScrollToTop';
import Reset from './pages/Reset';

import CorporateInfo from './pages/CorporateInfo';
import Candidate_Profile from './pages/CandidateProfile';
import Manage_Account from './pages/ManageAccount';
import View_Candidates from './pages/ViewCandidates';
import Saved_Candidates from './pages/SavedCandidates';
import Hiring_Requirements from './pages/HiringRequirements';
import Hire_Candidates_Now from './pages/HireCandidatesNow';
import Edit_Manage_Account from './pages/EditManageAccount'
import Command_Center from './pages/CommandCenter'
import InterviewSchedule from './pages/InterviewScheduling/InterviewSchedule';

import HRInfo from './pages/HRInfo';
import HrDocUpload from './pages/HrDocUpload'
import HRCommandCenter from './pages/HRCommandCenter'
import StudentInfo from './pages/StudentInfo';
import Student_Command_Center from './pages/StudentCommandCenter'
import StudentInterviewSchedule from './pages/StudentInterviewScheduling/StudentInterviewSchedule'

import Student_Progress from './pages/StudentProgress'
import Class_Progress from './pages/ClassProgress'
import Manage_Account_Univ from './pages/ManageAccountUniv'
import Edit_Manage_Account_Univ from './pages/EditManageAccountUniv'
import studentManageAccount from './pages/StudentManageAccount'

import UserContext from './User/User.js';
import Resources from './components/Resources'
import Article from './pages/Article'
import Careers from './pages/Careers'
import Growth from './pages/GrowthStrategyVirtualInternship'
import FullStack from './pages/FullStackDevelopmentVirtualInternship'
import PageNotFound from './pages/PageNotFound'
import Loading from './pages/Loading'
import firebase from 'firebase';
import moodle_client from "moodle-client";
import Interview from './Interview/Interview';
import Feedback from './Interview/Feedback';
import SamplePayment from './pages/Payments/SamplePayment';
import PaymentStatusFailed from './pages/Payments/PaymentStatusFailed';
import PaymentStatusSuccess from './pages/Payments/PaymentStatusSuccess';

//admin pages
import AdminCommandCenter from './pages/AdminCommandCenter'
import AdminContactMessages from './pages/AdminContactMessages'
import AdminViewCandidates from './pages/AdminViewCandidates'
import ViewPartners from './pages/ViewPartners'
import AddUser from './pages/AddUser'
import EditUser from './pages/EditUser'
import AddPartner from './pages/AddPartner/AddPartner'
import 'semantic-ui-css/semantic.min.css'

// import student from "../functions/index.js";
import axios from 'axios';
import "./App.scss"
import "./circle.css"

class App extends Component {
  constructor() {
    super();
    this.state = {
      userID: localStorage.getItem("userID"),
      userInfo: {},
      loginType: localStorage.getItem("loginType"),
	  setExam: this.setExam,
      logoutUser: this.logoutUser,
      setUser: this.setUser,
      setloginType: this.setloginType,
      addSavedCand:this.addSavedCand,
      removeSavedCand:this.removeSavedCand,
      navDash: localStorage.getItem('navDash'),
      loginNav: localStorage.getItem('loginNav'),
      lastActive: localStorage.getItem('lastActive'),
	  candidates: [],
	  examStatus: "",
	  moodleCandidates:[],
      savedCands: [],
      requirements: [],
      reqDocID: [],
      loading: true,
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
	this.setExam = this.setExam .bind(this);
    this.setloginType = this.setloginType.bind(this);
    this.addSavedCand = this.addSavedCand.bind(this);
    this.removeSavedCand = this.removeSavedCand.bind(this);
    this.setloginType = this.setloginType.bind(this);
	}

	async componentDidMount() {
		const db = firebase.firestore();
		var storage = firebase.storage().ref();


		const current = new Date().getTime() / 1000;
		const idleTime = current - localStorage.getItem('lastActive');
		//log out user if they were offline for one hour
		if ((this.state.userID !== '') & (idleTime > 3600)) {
			console.log('Time since last active: ' + idleTime);
			this.logoutUser();
			window.location.reload();
		}

		//Loads in the candidate data for a corporate or HR user
		if (
			(this.state.userID !== '') &
			(this.state.loginType == 'Corporate' || this.state.loginType == 'HR')
		) {

			//Get info of corporate user
			await db.collection('corporate')
			.where('userID', '==', this.state.userID)
			.get()
			.then((querySnapshot) => {
				const data = querySnapshot.docs.map((doc) => doc.data());
				this.setState({
          			userInfo: data[0],		
				});	
			 });

			let corporateID = "";
			if(this.state.loginType=="Corporate"){
				corporateID = this.state.userID;
			}else{
				corporateID = this.state.userInfo.managerID;
		
			}
	  
				await db
				.collection('ALL_USER').where("role", "==", "CAS")
				.get()
				.then((querySnapshot) => {
					const data = querySnapshot.docs.map((doc) => doc.data());
					var index = 0;
					console.log(data);

					querySnapshot.forEach((document) => {
						document.ref
							.collection('experience')
							.orderBy('start', 'desc')
							.get()
							.then((querySnapshot) => {
								const experience = querySnapshot.docs.map((doc) =>
									doc.data()
								);
								data[index].experience = experience;
							});
						document.ref
							.collection('education')
							.get()
							.then((querySnapshot) => {
								const education = querySnapshot.docs.map((doc) =>
									doc.data()
								);
								data[index].education = education;
							});
						document.ref
							.collection('mentor')
							.get()
							.then((querySnapshot) => {
								const mentor = querySnapshot.docs.map((doc) =>
									doc.data()
								);
								data[index].mentor = mentor;
							});
						document.ref
							.collection('skillsAndProgress')
							.get()
							.then((querySnapshot) => {
								const skillsAndProgress = querySnapshot.docs.map(
									(doc) => doc.data()
								);
								data[index].skillsAndProgress = skillsAndProgress;
								index++;
							});
						data[index].skills = document.data().skills


						//Pull Moodle Data to get PFP URL
							//Update the user data. pfp URL with that info

						
						//console.log(data[index].skills)
					});

					this.setState({
						moodleCandidates: data,
					});

				});

				


			//loads saved candidate ids
			if(this.state.loginType=="Corporate"){
				this.setState({
					savedCands: this.state.userInfo.savedCandidates,
				});
			}else{
				await db.collection('corporate')
				.where('userID', '==', corporateID)
				.get()
				.then((querySnapshot) => {
					const data = querySnapshot.docs.map((doc) => doc.data());
					this.setState({
						savedCands: data[0].savedCandidates
					});	
				 });
				 
			}

			//loads hiring requirements
			await db
				.collection('corporate')
				.doc(corporateID)
				.collection('hiringReqs')
				.get()
				.then((snap) => {
					const temp = [];
					const tempID = [];
					snap.forEach((doc) => {
						temp.push(doc.data());
						tempID.push(doc.id);
					});
					this.setState({
						requirements: temp,
						reqDocID: tempID,
					});
				});

			this.setState({
				loading: false,
			});

			//load profile image
			let profile ={}
			await storage
			.child("corporatePics")
			.child(this.state.userID)
			.getDownloadURL()
			.then((url) => {
			  profile = {profileUrl: url}
			});
			
			
			this.setState({
				userInfo: { ...this.state.userInfo, ...profile},		
			});	
			//loads candidate images
			const { candidates } = this.state;
			var x;
			for (x in candidates) {
				const profileUrl = candidates[x].email + '.jpg';
				await storage
					.child('profilepics')
					.child(profileUrl)
					.getDownloadURL()
					.then((url) => {
						candidates[x].profileUrl = url;
					});
			}
			this.setState({
				candidates: candidates,
			});


		} else if (
			(this.state.userID !== '') &
			(this.state.loginType == 'University')
		) {
			//load data for a university user then set loading to false
			//load data here

			this.setState({
				loading: false,
			});
		}else if (
			(this.state.userID !== '') &
			(this.state.loginType == 'Student')
		) {
			//load data for a student user then set loading to false
			


			//load data from firebase
			console.log(this.state.userID)
			let firstTimeUser = true;
			await db.collection('ALL_USER')
			.where('candidateID', '==', this.state.userID)
			.get()
			.then((querySnapshot) => {
				const data = querySnapshot.docs.map((doc) => doc.data());
				if(data.length>0){
					this.setState({
						userInfo: data[0],		
				  });	
				  firstTimeUser = false;
				}
			 });

			 if(firstTimeUser==true){ //if first time login create firestore document for student
				console.log("First time user");

				//Pull new user data from moodle
				await moodle_client.init({
					wwwroot: "http://lms.minoslabs.com/",
					token: "01f04ab2f0eacb95cb7909261d5f7fb0"
				
				}).then( async (client) => {
					
					//get profile info
					let profile ={};
					await client.call({
					wsfunction: "core_user_get_users_by_field",
					args: {
						field:"id",
						values:[this.state.userID]
					}
				
					}).then((info) => {
						profile.name = info[0].fullname;
						profile.first = info[0].firstname;
						profile.last = info[0].lastname;
						profile.email = info[0].email;
						profile.profileUrl = info[0].profileimageurl;
						this.setState({
							userInfo: { ...this.state.userInfo, ...profile},
						});	
						
					
					});



					// get course enrolled in
					let enrolled ={};
					// await client.call({
					// 	wsfunction: "core_enrol_get_users_courses",
					// 	args: {
					// 	userid:this.state.userID,
					// 	}
					
					// }).then((info) => {
					// 	// if(info[0].displayname){
					// 	// 	if(info[0].displayname==="Cybersecurity"){
					// 	// 		enrolled.course ="Cyber";
					// 	// 	}else{
					// 	// 		enrolled.course ="FullStack";
					// 	// 	}
					// 	// }
					// 	// else{
					// 	// 	enrolled.course='pt'
					// 	// }
					// 	enrolled.week =info[0].progress;
					// 	this.setState({
					// 		userInfo: { ...this.state.userInfo, ...enrolled},		
					// 	});	
					// });



					//Update student with moodle data
					await db.collection('ALL_USER').where("email","==",this.state.userInfo.email)
					.get()
					.then((snapshot) => {
					snapshot.forEach((doc) => {
						console.log(doc.id, ' => ', doc.data());
						const docData = doc.data();
						const docID = doc.id;
						if (docData) {
						db.collection('ALL_USER')
							.doc(docID)
							.update({
								candidateID: this.state.userID,
								profileUrl: this.state.userInfo.profileUrl,
								grades:[0,0,0,0,0,0],
								languages: [],
								technical: [],
							});
						}
					});
					});


				}).catch((err) =>{
					console.log("Unable to initialize the client: " + err);
					this.setState({
						loading: false,
					});
				});	
			}

			 this.setState({
				loading: false,
			});
		
		}else if (
			(this.state.userID !== '') &
			(this.state.loginType == 'Admin')
		) {

			

			this.setState({
				loading: false,
			});
		}
		
	}

	handleOnAction(event) {
		const current = new Date().getTime() / 1000;
		const idleTime = current - localStorage.getItem('lastActive');
		if ((this.state.userID !== '') & (idleTime > 3600)) {
			alert('Session timeout due to inactivity');

			this.logoutUser();
			window.location.reload();
		}

		this.setState({
			lastActive: current,
		});

		localStorage.setItem('lastActive', current);
	}

	handleOnActive(event) {
		//  console.log('user is active', event)
		//console.log('time remaining', this.idleTimer.getRemainingTime())
	}

	handleOnIdle(event) {
		//console.log('user is idle', event)
		// console.log('last active', this.idleTimer.getLastActiveTime())
		/*
    if(this.state.userID!==""){
      this.logoutUser();
      window.location.reload();
    }
    */
    }

    logoutUser = () => {
        this.setState({
            userID: '',
            loginType: '',
        });
        localStorage.setItem('userID', '');
        localStorage.setItem('loginType', '');
    };

    setUser = (data) => {
        this.setState({
            userID: data,
        });
        localStorage.setItem('userID', data);
    };

	setExam = (data) => {
		this.setState({
            examStatus: data,
        });
	}

    setloginType = (data) => {
        this.setState({
            loginType: data,
        });
        localStorage.setItem('loginType', data);
    };

    addSavedCand = (candidate) => {
        var { savedCands } = this.state;
        savedCands.push(candidate);
        this.setState({
            savedCands: savedCands,
        });
    };

    removeSavedCand = (candidate) => {
        var { savedCands } = this.state;
        savedCands = savedCands.filter((e) => e !== candidate);
        this.setState({
            savedCands: savedCands,
        });
    };

    render() {
        return (
            <Router>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    timeout={1000 * 10}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                <div className="App">
                    <div className="mainContainer">
                        <ScrollToTop />
                        <UserContext.Provider value={this.state}>
                            <Header />
                            {(this.state.userID !== '') & this.state.loading ? (
                                <Loading />
                            ) : (
                                <Switch>
                                    <Route exact path="/" component={Home} />
                                    <Route path="/about" component={About} />
									<Route path="/veterans" component={Veterans} />
                                    <Route path="/university" component={University} />
                                    <Route path="/corporate" component={Corporate} />
                                    <Route path="/application" component={Application} />
									<Route path="/byp" component={Application} />
									<Route path="/bc-referral-program" component={BCReferralProgram}/>
									<Route path="/byp-bootcamp" component={BCReferralProgram}/>
                                    <Route
                                        path="/privacy-policy"
                                        component={Privacy_Policy}
                                    />
									<Route
                                        path="/Privacy_Policy"
                                        component={Privacy_Policy_Exam}
                                    />
									<Route path="/future-cohorts" component={FutureCohorts}/>
                                    <Route path="/exam-start" component={Exam_start} />
									<Route path='/bc-exam-start' component={BCExamStart}/>
									<BCExamStartRoute path="/bc-exam" component={BCExam}/> 
                                    <ExamStartRoute path="/exam-pt1" component={Exam_pt1} />
                                    <ExamStartRoute path="/cyber-pt1" component={Cyber_pt1} />
                                    <ExamCompleteRoute
                                        path="/exam-complete"
                                        component={Exam_complete}
                                    />

                                    <ProtectedRoute path="/reset" component={Reset} />
                                    <Route path="/resources" component={Resources} />
                                    <Route path="/careers" component={Careers} />
                                    <Route
                                        path="/Growth-Strategy-Virtual-Internship"
                                        component={Growth}
                                    />
                                    <Route
                                        path="/Full_Stack_Development_Virtual_Internship"
                                        component={FullStack}
                                    />
                                    <Route
                                        path="/articles/:articleId"
                                        component={Article}
                                    />

                                    <Route
                                        path="/payment"
                                        component={PaymentCard}
                                    />

                                    <CorporateRoute
                                        path="/corporate_info"
                                        component={CorporateInfo}
                                    />

                                    <CorporateRoute
                                        path="/candidate_profile"
                                        component={Candidate_Profile}
                                    />
                                    <ProtectedRoute
                                        path="/manage_account"
                                        component={Manage_Account}
                                    />
                                    <ProtectedRoute
                                        path="/edit_manage_account"
                                        component={Edit_Manage_Account}
                                    />
                                    {this.state.userInfo.nylasAccessToken == undefined?
										<CorporateRoute
											path="/command_center"
											component={CorporateInfo}
                                    	/>
									:
										<CorporateRoute
											path="/command_center"
											component={Command_Center}
										/>
									}
                                    <CorporateRoute
                                        path="/View_Candidates"
                                        component={View_Candidates}
                                    />
                                    <CorporateRoute
                                        path="/Saved_Candidates"
                                        component={Saved_Candidates}
                                    />
                                    <ProtectedRoute

                                        path="/Hiring_Requirements"
                                        component={Hiring_Requirements}
                                    />

                                    <CorporateRoute
                                        path="/scheduledinterviews"
                                        component={InterviewSchedule}
                                    />

                                    <CorporateRoute
                                        path="/Hire_Candidates_Now"
                                        component={Hire_Candidates_Now}
                                    />
                                    <HRRoute
                                        path="/hr_doc_upload"
                                        component={HrDocUpload}
                                    />
                                    <HRRoute
                                        path="/hr_command_center"
                                        component={HRCommandCenter}
                                    />
                                    <HRRoute
                                        path="/hr_info"
                                        component={HRInfo}
                                    />
                                    <StudentRoute
                                        path="/student_command_center"
                                        component={Student_Command_Center}
                                    />
                                    <StudentRoute
                                        path="/student_info"
                                        component={StudentInfo}
                                    />
                                    <StudentRoute
                                        path="/student_scheduled_interviews"
                                        component={StudentInterviewSchedule}
                                    />
                                    <StudentRoute
                                        path="/student_manage_account"
                                        component={studentManageAccount}
                                    />




                                    <UniversityRoute
                                        path="/Student_Progress"
                                        component={Student_Progress}
                                    />
                                    <UniversityRoute
                                        path="/Class_Progress"
                                        component={Class_Progress}
                                    />
                                    <UniversityRoute
                                        path="/Manage_Account_Univ"
                                        component={Manage_Account_Univ}
                                    />
                                    <UniversityRoute
                                        path="/Edit_Manage_Account_Univ"
                                        component={Edit_Manage_Account_Univ}
                                    />

									<AdminRoute
                                        path="/admin_command_center"
                                        component={AdminCommandCenter}
                                    />
									<AdminRoute
                                        path="/admin_view_candidates"
                                        component={AdminViewCandidates}
                                    />
									<AdminRoute
                                        path="/view_partners"
                                        component={ViewPartners}
                                    />
									<AdminRoute
                                        path="/contact_messages"
                                        component={AdminContactMessages}
                                    />
									<AdminRoute
                                        path="/add_user"
                                        component={AddUser}
                                    />
									<AdminRoute
                                        path="/edit_user"
                                        component={EditUser}
                                    />

									<AdminRoute
                                        path="/add_partner"
                                        component={AddPartner}
                                    />

                                    <ProtectedRoute path="/interview/:interviewId" component={Interview} />
                                    <Route path="/feedback" component={Feedback}></Route>
                                    <Route path="/purchase/:purchaseId" component={SamplePayment}></Route>
                                    <Route path="/paymentstatusfailed" component={PaymentStatusFailed}></Route>
                                    <Route path="/paymentstatussuccess" component={PaymentStatusSuccess}></Route>
                                    <Route component={PageNotFound} />

                                </Switch>
                            )}

                            <Footer />
                        </UserContext.Provider>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
