import React, { Component } from 'react';
import styles from './StudentInterviewSchedule.module.scss';
import UserContext from '../../User/User';
import firebase from 'firebase';
import ActionButton from './ActionButtons';
import Dashboard from '../../components/Dashboard';
import StudentDashboard from "../../components/DashboardStudent";

export class StudentInterviewSchedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			studentInterviewList: [],
		};
		this.handleOnDelete = this.handleOnDelete.bind(this);
	}

	async componentDidMount() {
		const db = firebase.firestore();
		const interviewRef = db.collection('interviews');
		const interviewData = await interviewRef
			.where('candidateID', '==', this.context.userID)
			.get();

		const corporateDetail = db.collection('corporate');
		const corporateDetailData = await corporateDetail.get();

		var corporateNames = {};

		corporateDetailData.forEach((corp) => {
			corporateNames[corp.id] = `${corp.data().first} ${corp.data().last}`;
		});

		var studentInterviewList = [];
		interviewData.forEach((studentInterviewDoc) => {
			var studentInterviewObj = studentInterviewDoc.data();
			studentInterviewObj.meetingId = studentInterviewDoc.id;
			studentInterviewObj.name =
				corporateNames[studentInterviewObj.corporateUserID];
			studentInterviewList.push(studentInterviewObj);
		});

		this.setState({
			studentInterviewList: studentInterviewList,
		});
	}

	static contextType = UserContext;

	handleOnDelete(id) {
		var currStudentInterviews = [...this.state.studentInterviewList];

		var updatedStudentInterviewList = currStudentInterviews.filter(
			(interview) => {
				return interview.meetingId !== id;
			}
		);
		console.log('updated', updatedStudentInterviewList);
		this.setState(
			{
				studentInterviewList: updatedStudentInterviewList,
			},
			async () => {
				const db = firebase.firestore();
				const reqRef = await db.collection('interviews').doc(id);
				await reqRef.delete();
			}
		);
	}

	render() {
		return (
			<div className={styles.borderbox}>
				<div className="hero-university2"></div>
				<div className="candidate_process">
					<div className="col-container">
						<StudentDashboard />

						<div className="right">
							<div class="topBar">
								<div class="pageName">
									<h1>Scheduled Interviews</h1>
								</div>
							</div>
							<div className={styles.container}>
								<div className={styles.inner}>
									<div className={styles.calendar}>
										<div className={styles.calendarHeader}>
											<h1>Upcoming Interviews</h1>
											<h2>Manage Your Interviews</h2>
										</div>
										<div>
											<table className="table table-hover">
												<thead>
													<tr>
														<th
															className={styles.hrreqTh}
															scope="col"
														>
															Name
														</th>
														<th
															className={styles.hrreqTh}
															scope="col"
														>
															Position
														</th>
														<th
															className={styles.hrreqTh}
															scope="col"
														>
															Job ID
														</th>
														<th
															className={styles.hrreqTh}
															scope="col"
														>
															Interview Date
														</th>
														<th
															className={styles.hrreqTh}
															scope="col"
														>
															Actions
														</th>
													</tr>
												</thead>

												<tbody>
													{this.state.studentInterviewList &&
														this.state.studentInterviewList.map(
															(interview) => (
																<tr key={interview.meetingId}>
																	<ActionButton
																		handleOnDelete={
																			this.handleOnDelete
																		}
																		interviewDetails={
																			interview
																		}
																	></ActionButton>
																</tr>
															)
														)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default StudentInterviewSchedule;
