import React from 'react'
import { Link } from 'react-router-dom';
//import "./positions.css";
/*import { withFirebase } from '../Firebase';*/
import firebase from 'firebase';
/*import firestore from "../Firebase/firestore.js"*/

const INITIAL_STATE = {
  first: '',
  last: '',
  email: '',
  course: 'select',
  hearAbout: 'select',
  error: null,
};

class Growth extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
        ...INITIAL_STATE,
        filename: "Upload Resume",
        resume: [],
        submitted: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.addUser = this.addUser.bind(this);
  }

  componentDidMount() {

 }

  handleInputChange(event) {

    this.onChange(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }

  validResume = true;
  validateResume(){
    var name = this.state.filename;
    var len = name.length;
    try{
      var form1 = name.substring(len-4,len);
      var form2 = name.substring(len-3,len);
      if(form1.toLowerCase() === "docx" || form2.toLowerCase() === "doc" || form2.toLowerCase() === "pdf"){
        return true;
      }
      else{
        this.validResume = false;
        return false;
      }
    }
    catch(err){
      console.log(err);
      this.validResume = false;
      return false;
    }
    
  }

  validateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.state.email);
  }
 
  canSubmit(){
    try{
      this.validEmail = true; 
      this.validResume = true;
      if(this.state.first && this.state.last && 
        this.state.email){
          if(!this.validateEmail())
            {
                this.validEmail = false;
                return false;
            }
          else if(!this.validateResume()){
            this.validResume = false;
              return false;
          }
          
      }
      else{
        return false;
      }
  
    }
    catch(err){
      return false;
    }
  }

  validEmail = true;
  handleClick(event){
    console.log('out');
    if(!this.canSubmit()){
      if(!(this.state.first && this.state.last && 
        this.state.email)){
          this.setState({
            msg: "Please complete all fields and upload your resume."
          });
          event.preventDefault();
        }
      else if(!this.validEmail){
        this.setState({
          msg: "Please enter valid email."
        });
        event.preventDefault();
      }
      else if(!this.validResume){
        this.setState({
          msg: "Please upload a resume in a valid format."
        });
        event.preventDefault();
      }
    }
    else{
         //this.addUser(event);    
         
         //var blob = new Blob([this.state.resume], { type: "pdf" });
         /*
         var selectedFile = document.getElementById("upload").files[0]; 
         var selectedFilename  = selectedFile.name;
         console.log(selectedFile);
         console.log(selectedFilename);*/
         
    }
   
  }

  uploadFile(event) {
    let file = event.target.files[0];    
    if (file) {
      this.setState({
        resume: file,
        filename: `${file.name}`
      }, () => {
        console.log(this.state.resume);
        console.log(this.state.filename);
      });
      
    }

}
  addUser = event => { //uses firebase
    event.preventDefault();
    var storage = firebase.storage();
    const db = firebase.firestore();
    db.collection("recruits").add({
      first: this.state.first,
      last: this.state.last,
      email: this.state.email,
      position: "Full_Stack_Development_Virtual_Internship",
      hearAbout: this.state.hearAbout,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    });

    const {resume} = this.state;
    storage.ref(`recruitResumes/${this.state.first}_${this.state.last}_Full_Stack`).put(resume).then((snapshot) =>{
      console.log('Resume uploaded');
      this.setState({ submitted: true });
    });   
    
  
  }

  onChange = event => {
      this.setState({ [event.target.name]: event.target.value });
  };


render() {

  const {
    first,
    last,
    email,
    course,
    error,
    hearAbout,
  } = this.state;

  return (
    
    <React.Fragment>

  <section class = "hero-university2">
    
  </section>

  <section class = "app-container">
    
    <div class = "positionContainer"style={{textAlign:'left'}}>
    <div class="positionHeader">
    <h1 >Full-Stack Development Virtual Internship</h1>

    </div>
    <div>Minos
    Labs is focusing on building out an Educational-Technology Platform to
    bridge the gap between college graduates and organizations to
    effectively onboard the top tier technology talent. We are looking for
    virtual interns to join our firm that have recently finished a coding
    bootcamp, students currently in college, individuals that have an
    entrepreneurial mindset, and those that are solution driven. The perfect
    candidate has an innovative mindset, so if you like solve problems this
    is the best place for you. We aim to build a diverse team of
    individuals from different backgrounds and with different perspectives.
    We are a interested in candidates that are seeking to refine their technology skills within an Ed-Tech Start Up environment
    </div>

    <h1>Details:</h1>
        <ul>
          <li>This is a virtual internship, so you get to work from home and be involved from the ground level of this start up.</li>
          <li>Primarily for Computer Science, Computer Engineering, or STEM related majors</li>
          <li>This internship is meant to improve your skills by taking part in
educational exercises and gaining exposure to real world projects</li>
          <li>Expected workload is 20 hours per week</li>
         
        </ul>
    
    <h1>Benefits:</h1>
        <ul>
          <li>We work with universities to offer course credit and write excellent letters of recommendation</li>
          <li> The intern will build valuable skills in Development concepts, Software
 Development Life Cycle, analytics by contributing to group discussions
and educational exercises</li>
          <li>Working directly with the founders of a startup will provide unique insight into the agile nature of working within technology</li>
          <li>Stimulating group roundtable discussions are held on a broad variety of
 topics from the latest in new frameworks, to discussing interesting
companies within technology</li>
        </ul>

        <h1>Potential Duties:</h1>
        <ul>
          <li>Developing front end website architecture.</li>
          <li>Designing user interactions on web pages.</li>
          <li> Developing back end website applications.</li>
          <li>Creating servers and databases for functionality.</li>
          <li> Ensuring cross-platform optimization for mobile phones.</li>
          <li>Ensuring responsiveness of applications.</li>
          <li>Working alongside graphic designers for web design features.</li>
          <li>Seeing through a project from conception to finished product.</li>
          <li>Designing and developing APIs.</li>
          <li>Meeting both technical and consumer needs.</li>
          <li>Staying abreast of developments in web applications and programming languages.</li>
        </ul>

        <h1>Preferred:</h1>
        <ul>
          <li>Experience in Java, Javascript, React.JS, Python, HTML, CSS, Node.JS, React, Scala, MySQL</li>
        </ul>

<p>BONUS: If
 you happen to have expertise on the social media / content / meme
landscape in any of the following niches, please briefly describe this
in your application.</p>
            

<form style={{marginBottom:'0px'},{ display: this.state.submitted ? 'none' : 'block'}} onSubmit={this.addUser}>
            <input
            name="first"
            value={first}
            onChange={this.handleInputChange} 
            type="text"
            placeholder="First Name"
            />
            <div class = "inputgap1"/>
            <input
            name="last"
            value={last}
            onChange={this.handleInputChange} 
            type="text"
            placeholder="Last Name"
            />
            <div class = "inputgap2"/>
            <input
            class ="email"
            name="email"
            value={email}
            onChange={this.handleInputChange} 
            type="text"
            placeholder="Email address"
            />
            <div class = "inputgap3"/>

            <select
								name="hearAbout"
								value={hearAbout}
								onChange={this.handleInputChange}
							>
								<option value="select" selected disabled hidden>
									Where did you hear about us
								</option>
								<option value="LinkedIn">
								     LinkedIn
								</option>
								<option value="Instagram">Instagram</option>
								<option value="JobBoard">Job Board</option>
								<option value="Referal">Referal</option>
								<option value="Instagram">Other</option>
							</select>

              <div class = "inputgap3"/>

        <label for = "upload" class = "app-button hvr-sweep">
        <input id = "upload" type = "file" accept = ".pdf, .doc,.docx" onChange = {this.uploadFile}/>
        {this.state.filename}

        </label> 
            <button type="submit" name="msg" class="hvr-sweep" value="T" onClick = {this.handleClick}>Submit Application</button>
            
            <div class = "errormsg" style={{textAlign:'center',paddingBottom:'0px'}}>{this.state.msg}</div>
        </form>

        <div style={{marginTop:'30px', display: this.state.submitted ? 'block' : 'none'}}>
          <h1 style={{lineHeight:"30px", textAlign:'center',marginTop: '40px'}}>Application Submitted</h1>
          Thank you for your interest in Minos Labs. We have received your application for the position. 
          Our hiring team will reach out if you are a good fit
          <Link to="/careers">
          <button class="hvr-sweep" >Return to Careers Page</button>
          </Link>
        </div>
        
        
        
    </div>
    
  </section>
    </React.Fragment>
  )
}
}

export default Growth;