import { Equalizer } from '@material-ui/icons';
import React from 'react';
import './candidateVideo.scss';


class CandidateVideo extends React.Component{
    constructor(props){
        super();
        this.state = {
            slide:0,
        }
        this.refresh = this.refresh.bind(this)
        this.nextSlide = this.nextSlide.bind(this)
        this.previousSlide = this.previousSlide.bind(this)
        this.slides = []
        this.keys = []
    }
 // This is an important functions that listens for propchange (initial empty) to update slideshow
    componentDidUpdate(prevProps){
        if(prevProps.experience != this.props.experience){
            this.refresh();
        }
    }

    //random key generator for slides (To be implemented)
    generateRandomKey(){
        let key = 0
        while(this.keys.find(key) >= 0){
            key = Math.ceil(Math.random()*100)
        }
        this.keys.push(key)
        return(key)
    }

    generateSlides(){

        if(this.props.experience.length > 0){
            this.slides.push(this.ProfileSlide())
            for(let i = 0; i < this.props.education.length; i++){
                this.slides.push(
                    this.EducationSlide(this.props.education[i])
                )
            }
            for(let i = 0; i < this.props.experience.length; i++){
                this.slides.push(
                    this.ExperienceSlide(this.props.experience[i])
                )
            }
        }
    }

    //Component Template for education element
    EducationSlide(item){

        let degreeCourse = (item.degree || item.courseOfStudy) || ""

        return(
            <div className='videoContainer' key='1'>
                <div className="introductionBullets">
                    <h1 className="resumeItem">{item.institute}</h1>
                    <h1 className="resumeItem size15">{degreeCourse}</h1>
                </div>
            </div>
        )
    }

    //Component Template for experience element
    ExperienceSlide(item){
        
        let head = ""
        let resumePoints = []
       
        head = item.title
        if(item.accomplishments != undefined){
           
        let splitResume = item.accomplishments.split('*')
        let count = 0
        for(let i = 0; i<splitResume.length; i++){
            
            if(count > 2){
                break;
            }
            resumePoints.push(
                <h1 className="resumeItem size15">- {splitResume[i]}</h1>
            )
            count += 1
        }
        }else{
            resumePoints.push(<h4>- No accomplishments</h4>)
        }
        
        return(
            <div className='videoContainer' key='1'>
                <div className="introductionBullets">
                    <h1 className="resumeItem">{head}</h1>
                    {resumePoints}
                </div>
            </div>
        )   
    }

    ProfileSlide(){
        return(
            <div className='videoContainer' key='2'>
                <div className="profileImage">
                    <img src={this.props.profile}></img>
                </div>
                <div className="introducitonBullets">
                    <h1 className="resumeItem">{this.props.name}</h1>
                    <h1 className="resumeItem size30">{this.props.title}</h1>
                </div>
            </div>
        )   
    }

    //refresh function that sets slideshow to slide 0
    refresh(){
        this.setState((state, props) => ({
            slide:0
        }))
    }

    nextSlide(){
        let next = this.slides.length - 1
        if(this.state.slide < (this.slides.length -1)){
            next = this.state.slide + 1
        }
        this.setState({
            slide: next
        })
    }

    previousSlide(){
        let previous = 0;
        if(this.state.slide > 0){
            previous = this.state.slide - 1
        }
        this.setState({
            slide: previous
        })
    }

    render(){
        this.slides = []
        this.generateSlides();
        if(this.props.show){
            return(
                <div>
                    {this.slides[this.state.slide]}
                    <div className="controlBar">
                        <h3 className='minosViewerLogo'>Minos Viewer</h3>
                        <div className="left-right">
                            <button className='controlButton reload' onClick={this.refresh}>&#8635;</button>
                            <button className='controlButton' onClick={this.previousSlide}>&#x2190;</button>
                            <button className='controlButton' onClick={this.nextSlide}>&#x2192;</button>
                        </div>
                    </div>
                </div>
                
            );
        }
        else{
            return(<div></div>);
        }
    }
}

export default CandidateVideo;