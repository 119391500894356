import React from 'react';
import firebase from 'firebase';
import styles from './Experience.module.scss';
import add_acc_logo from '../../assets/manage account/noprofilepic.jpg';
import RichTextEditor from 'react-rte';

class Candidate_Select extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			number: this.props.number,
			title: "",
			company: "",
			accomplishments:"",
			bp1:"",
			bp2:"",
			bp3:"",
			start: "",
			end: "",
		};
		this.handleChangeInput = this.handleChangeInput.bind(this);
	}


	componentWillReceiveProps(newProps) {
		this.setState({
			...newProps.initial,
		  })
	}
	
	handleChangeInput(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
	
		this.setState({
			[name]: value,
		  },function () {
		  let info ={
			  title: this.state.title,
			  company: this.state.company,
			  bp1:this.state.bp1,
			  bp2:this.state.bp2,
			  bp3:this.state.bp3,
			  accomplishments:"*"+this.state.bp1+"*"+this.state.bp2+"*"+this.state.bp3,
			  start: this.state.start,
			  end: this.state.end,
		  }
		  this.props.onChange(info,this.state.number,"experience");
		  }.bind(this));
		  
  
		
	
	  }


	render() {

		return (
			<React.Fragment>
							<form className={styles.educationForm}>
								<h3>Experience {this.state.number + 1}</h3>
								<input
									name="title"
									className={styles.educationInput}
									type="text"
									placeholder= "Title"
									value = {this.state.title}
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>
								<input
									name="company"
									className={styles.educationInput}
									type="text"
									placeholder= "Company"
									value = {this.state.company}
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>
								<br></br>
								<p style={{fontSize:'16px'}}>Enter your experience without special characters or hyphenation in the beginning</p>
								<input
									name="bp1"
									className={styles.educationInput}
									type="text"
									value = {this.state.bp1}
									placeholder= "Enter your first bullet point"
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>
								<input
									name="bp2"
									className={styles.educationInput}
									type="text"
									value = {this.state.bp2}
									placeholder= "Enter your second bullet point"
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>
								<input
									name="bp3"
									className={styles.educationInput}
									type="text"
									value = {this.state.bp3}
									placeholder= "Enter your third bullet point"
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>
								<label className={styles.educationLabelL}>Start: </label>
								<label className={styles.educationLabelR}>End: </label>
								<input
									name="start"
									className={styles.educationInputL}
									type="date"
									value = {this.state.start}
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>
								<input
									name="end"
									className={styles.educationInputR}
									type="date"
									value = {this.state.end}
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>


							</form>

			</React.Fragment>
		);
	}
}

export default Candidate_Select;
