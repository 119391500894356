import React from 'react';
import ReactDOM from 'react-dom';
import styles from './HrDocUpload.module.scss';
import Dashboard from '../../components/Dashboard';
import Candidate_Select from '../../components/CandidateSelect';
import firebase from 'firebase';
import UserContext from '../../User/User';
import { Link } from 'react-router-dom';

class HrDocUpload extends React.Component {
	constructor() {
		super();

		this.state = {
			
			calendarSection: false,
			selections: 0,
			savedCands: [], //unique IDs of user's savedCandidates
			candidates: [], //Phase-out (pull candidates from moodle)
			moodleCandidates: [],
			selectCands:[],
			document: [],
			filename: "Upload Document",
			submitted: false,
		};

		this.handleSelectedCandidate = this.handleSelectedCandidate.bind(this);
		this.handleCalendar = this.handleCalendar.bind(this);

		this.handleClick = this.handleClick.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
	}
	static contextType = UserContext;

	uploadFile(event) {
		let file = event.target.files[0];    
		if (file) {
		  this.setState({
			document: file,
			filename: `${file.name}`
		  }, () => {
			console.log(this.state.document);
			console.log(this.state.filename);
		  });
		  
		}
	
	}

	validDoc = true;
	validateDoc() {
		
		var name = this.state.filename;
		var len = name.length;
		try {
			var form1 = name.substring(len - 4, len);
			var form2 = name.substring(len - 3, len);
			console.log(form2);
			if (
				form1.toLowerCase() === 'docx' ||
				form2.toLowerCase() === 'doc' ||
				form2.toLowerCase() === 'pdf'
			) {
				
				return true;
			} else {
				
				this.validDoc = false;
				return false;
			}
		} catch (err) {
			console.log(err);
			this.validDoc = false;
			return false;
		}
	}

	displayCandidates() { //Phase-out (pull candidates from moodle)
		const db = firebase.firestore();
		var candidateSelect = [];
		const savedCands = this.state.savedCands;
		const candidates = this.state.candidates;
		var i;

		for (i = 0; i < candidates.length; i++) {
			let savedRef;
			let saved;
			let name;
			let title;
			let profileUrl;
			let id;
			let email;
			let selected = false;
			try {
				saved = savedCands;
				id = candidates[i].candidateID;
				email = candidates[i].email;
				profileUrl = candidates[i].profileUrl;
				if (!saved.includes(id)) {
					continue;
				}
				name = candidates[i].first + ' ' + candidates[i].last;
				if(email === this.state.email){
					selected = true;
				}
				if (candidates[i].course === 'FullStack') {
					title = 'Full-Stack Developer';
				} else if (candidates[i].course === 'Cyber') {
					title = 'Cybersecurity';
				}
			} catch (err) {
				continue;
			}
			candidateSelect.push(
				<Candidate_Select
					name={name}
					email={email}
					title={title}
					id={id}
					profileUrl={profileUrl}
					isSelected={this.handleSelectedCandidate}
					deSelect = {selected}
				/>
			);
		}
		return candidateSelect;
	}

	displayMoodleCandidates() {
		const { moodleCandidates } = this.state;
		const { savedCands } = this.state;
		var candidateSelect = [];
		var i;

		for (i = 0; i < moodleCandidates.length; i++) {
			let savedRef;
			let saved;
			let name;
			let title;
			let profileUrl;
			let id;
			let email;
			let selected = false;

			try {
				saved = savedCands;
				name = moodleCandidates[i].first + ' ' + moodleCandidates[i].last;
				email = moodleCandidates[i].email;
				id = moodleCandidates[i].candidateID;
				if (!saved.includes(id)) {
					continue;
				}

				profileUrl = moodleCandidates[i].profileUrl;
				if(email === this.state.email){
					selected = true;
				}
				if (moodleCandidates[i].course === 'FullStack') {
					title = 'Full-Stack Developer';
				} else {
					title = 'Cybersecurity';
				}

			} catch (err) {
				continue;
			}
			candidateSelect.push(
				<Candidate_Select
					name={name}
					email={email}
					title={title}
					id={id}
					profileUrl={profileUrl}
					isSelected={this.handleSelectedCandidate}
					deSelect = {selected}
				/>
			);
		}

		return candidateSelect;
	}
	
	componentDidMount() {
		
		const context = this.context;

		this.setState({
			candidates: context.candidates, //Phase-out (pull candidates from moodle)
			moodleCandidates: context.moodleCandidates,
			savedCands: context.savedCands,
		});
	}

	
	handleSelectedCandidate(selected,id,email,name,title) {
		if (selected === false) {
			this.setState(
				{
					selections: 1,
					email: email,
					name: name,
					title: title,
					id:id,
				},
				() => {
					this.handleCalendar();
				}
			);
		} else {
			this.setState(
				{
					selections: this.state.selections - 1,
					email: "",
					name: "",
					title: "",
					id:"",
				},
				() => {
					this.handleCalendar();
				}
			);
		}
	}

	handleCalendar() {
		if (this.state.selections > 0) {
			this.setState({
				calendarSection: true,
			});
		} else {
			this.setState({
				calendarSection: false,
			});
		}
	}

	handleClick(event) {
		const context = this.context;
		
		event.preventDefault();
		if (!this.canSubmit()) {
			
			if (!this.validDoc) {
				console.log("invalid");
				this.setState({
					msg: 'Please upload a document in a valid format.',
				});
				
			} 
		} else {



			const {document} = this.state;
			var storage = firebase.storage();
			const db = firebase.firestore();

			storage.ref(`hrDocuments/${context.userID}`).put(document).then((snapshot) =>{
			 console.log('Document uploaded');
			 this.setState({ submitted: true });
		   }); 

			var hrRef = db.collection('corporate').doc(context.userID)
			hrRef.update({
				selectedCandidates: this.state.selectCands
			})
			.then(function() {
				console.log("Candidates Selected");
			})
			.catch(function(error) {
				
				console.error("Error Selecting candidates: ", error);
			});


		}
	}

	canSubmit() {
		//console.log(this.state.acceptTerms);
		try {
			this.validDoc = true;
			if (!this.validateDoc()) {
				this.validDoc = false;
				return false;
			}else{
				return true;
			}
			
		} catch (err) {
			return false;
		}
	}

	render() {
		//User data
		let { userID, logoutUser, setUser } = this.context;

		//names of days and months
		const months = this.state.monthNames;
		const days = this.state.dayNames;
		return (
			<React.Fragment>
				<section class="hero-university2"></section>
				<section class="candidate_process">
					<section class="col-container">
						<Dashboard pageName="Hire_Candidate" />

						{/*right expands when dashboard is closed*/}
						<div className="right">
							{/*code here*/}
							<div class="topBar">
								<div class="pageName">
									<h1>Candidates</h1>
								</div>
							</div>
							<div id="HiringMessage" className={styles.HiringMessage} style={{display: this.state.submitted ? 'flex' : 'none'}}>
								<div className={styles.modalContent}>
									<p>
										Thank you for submitting a Hiring Inquiry. A Minos
										Labs team member will confirm your interview
										slots.
									</p>
									<Link
										to="/View_Candidates"
										style={{
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<button class="hvr-sweep">Confirm</button>
									</Link>
								</div>
							</div>

							<div className={styles.hireCandidates}>
								<div className={styles.hireCandidatesTop}>
									<h1>Hire Candidates</h1>
									<p>
										Choose the candidates you’re interested in
										onboarding onto your team
									</p>
									<br></br>
									<br></br>
									<div className={styles.candidateSelect}>
										{this.displayCandidates()}
										{this.displayMoodleCandidates()}
									</div>
									<br></br>
											
											<br></br>
									{this.state.calendarSection && (
										
										<div>
										<label for="upload" className={`${styles.appButton} hvr-sweep`}>
										<input style={{display:'none'}}
											id="upload"
											type="file"
											accept=".pdf, .doc,.docx"
											onChange={this.uploadFile}
										/>
										{this.state.filename}
									</label>
											<br></br>
											
											<br></br>
											<button style={{maxWidth:'400px',height:'45px'}}
												className={`${styles.candidateSubmit} hvr-sweep`}
												onClick={this.handleClick}
											>
												Submit Hiring Inquiry
											</button>
											<br></br>
											
											<br></br>
											<div className={styles.errormsg} style={{color:"white"}}>{this.state.msg}</div>
										</div>
									)}

								</div>
							</div>
						</div>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default HrDocUpload;
