import React from 'react';


const NotesContext = React.createContext();

const NotesProvider = NotesContext.Provider;
const NotesConsumer = NotesContext.Consumer;


export { NotesProvider, NotesConsumer };
export default NotesContext;