import axios from 'axios';
import React, { Component } from 'react'
import { Jumbotron, Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
class Feedback extends Component {

    constructor(props) {
        super(props)

        this.state = {
            commSkills: 0,
            techDepth: 0,
            techBreadth: 0,
            problemSolving: 0,
            fundamentals: 0,
            finalDecision: "",
            hrForOnboarding: "",
            optionalComments: "",
            rescheduleMeeting: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    //Fixes the header to the top when on this component 
    componentDidMount() {
        var headerDom = document.getElementById("myHeader")
        headerDom.style.position = "absolute"
    }
    //Undos the above action when leaving the component 
    componentWillUnmount() {

        var headerDom = document.getElementById("myHeader")
        headerDom.style.position = "fixed"
    }

    /**
     * Hndles form input changes
     * @param {form input event} event
     */
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    /**
     * Handles submit on click for the feedback form 
     * @param {form input event} event 
     */
    async handleSubmit(event) {
        event.preventDefault()

        console.log("feedback submit clicked");
        console.log(this.props.location.state.meetingNotes)
        const meetingInfo = this.props.location.state.meetingInfo
        const meetingNotes = String(this.props.location.state.meetingNotes)
        const meetingID = this.props.location.state.meetingID

        const meetingObj = {
            candidateID: meetingInfo.candidateID,
            userID: meetingInfo.corporateUserID,
            flag: false,
            notes: meetingNotes,
            communicationSkills: this.state.commSkills,
            technicalDepth: this.state.techDepth,
            technicalBreadth: this.state.techBreadth,
            problemSolvingSkills: this.state.problemSolving,
            fundamentalConcepts: this.state.fundamentals,
            optionalComments: this.state.optionalComments,
            finalDecision: this.state.finalDecision,
            hrRep: this.state.hrForOnboarding,
            newMeeting: ""
        }

        console.log(meetingObj)

        try {
            var config = {
                method: 'put',
                url: `https://us-central1-minos-labs-57c35.cloudfunctions.net/interviewApp/interviewUpdate?id=${meetingID}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(meetingObj)
            };

            await axios(config)

            console.log("Put request sent");

            window.location.href = "/command_center";

        } catch (e) {
            console.log(e);
        }




        //handle redirect to a different page here

        //Below statement has the access to props redirect state


    }

    render() {
        return (
            <div id="interviewComponent">
                <React.Fragment>

                    <section class="hero-university2"></section>
            <Container fluid >
                <Row >
                    <Col sm="12">
                        <Jumbotron fluid className="feedbackJumbotron">
                            <h1 className="display-3">Student Feedback</h1>
                            <p className="lead">Please review the student based on the interview</p>
                        </Jumbotron>
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col md="4" sm="3" xs="2"></Col>
                    <Col md="4" sm="6" xs="8">
                                <Form>
                            <h4>Rate student out of 5 for the following skills:</h4>
                            <FormGroup>
                                <Label for="communicationRange">Communication Skills - {this.state.commSkills}</Label>
                                        <Input type="range" name="commSkills" id="communicationRange" min="0" max="5" defaultValue="0" onInput={this.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="technicalDepthRange">Technical Depth - {this.state.techDepth}</Label>
                                        <Input type="range" name="techDepth" id="technicalDepthRange" min="0" max="5" defaultValue="0" onInput={this.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="technicalBreadthRange">Technical Breadth - {this.state.techBreadth}</Label>
                                        <Input type="range" name="techBreadth" id="technicalBreadthRange" min="0" max="5" defaultValue="0" onInput={this.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="problemSolvingRange">Problem Solving Skills - {this.state.problemSolving}</Label>
                                        <Input type="range" name="problemSolving" id="problemSolvingRange" min="0" max="5" defaultValue="0" onInput={this.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="fundamentalRange">Fundamental Concepts - {this.state.fundamentals}</Label>
                                        <Input type="range" name="fundamentals" id="fundamentalRange" min="0" max="5" defaultValue="0" onInput={this.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="finalDecision">Final Decision</Label>
                                        <Input type="select" name="finalDecision" id="finalDecision" onInput={this.handleChange} defaultValue={this.state.finalDecision}
                                    placeholder="Select your decision">
                                            <option >Choose...</option>
                                    <option>Accepted</option>
                                    <option>Pending</option>
                                    <option>Rejected</option>
                                </Input>
                            </FormGroup>

                            {this.state.finalDecision === "Pending" &&
                                <FormGroup>
                                    <Label for="rescheduleMeeting">Schedule a new meeting</Label>
                                    <Input
                                        type="date"
                                        name="date"
                                        id="rescheduleMeeting"
                                        placeholder="datetime placeholder"
                                    />
                                </FormGroup>
                            }

                            {this.state.finalDecision === "Accepted" &&
                                <FormGroup>
                                    <Label for="selectHr">Select HR manager to onboard student</Label>
                                        <Input type="select" name="hrForOnboarding" id="selectHr" defaultValue={this.state.hrForOnboarding} onInput={this.handleChange}
                                        placeholder="Select your decision">
                                            <option>Choose...</option>
                                        <option>HR 1</option>
                                        <option>HR 2</option>
                                        <option>HR 3</option>
                                        <option>HR 4</option>
                                    </Input>
                                </FormGroup>
                            }

                            <FormGroup>
                                <Label for="optionalComments">Optional Comments</Label>
                                        <Input type="textarea" name="optionalComments" id="optionalComments"
                                            onChange={this.handleChange} />
                            </FormGroup>



                                    <Button type="submit" onClick={this.handleSubmit} color="primary">Submit</Button>
                        </Form>
                    </Col>
                    <Col md="4" sm="6" xs="2"></Col>
                </Row>

                    </Container>
                </React.Fragment>
            </div>




        )
    }
}

export default Feedback
