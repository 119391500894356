import React from 'react';
import firebase from 'firebase';
import styles from './Education.module.scss';
import add_acc_logo from '../../assets/manage account/noprofilepic.jpg';

class Candidate_Select extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			
			number: this.props.number,
			instituation: "",
			degreeLevel: "",
			degree:"",
			start: "",
			end: ""
		};
		this.handleChangeInput = this.handleChangeInput.bind(this);
	}

	handleChangeInput(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		
		this.setState({
		  [name]: value,
		},function () {
		let info ={
			instituation: this.state.instituation,
			degreeLevel: this.state.degreeLevel,
			degree:this.state.degree,
			start: this.state.start,
			end: this.state.end,
		}
		this.props.onChange(info,this.state.number,"education");
		}.bind(this));
		

	  }

	componentWillReceiveProps(newProps) {
		this.setState({
			...newProps.initial,
		  })
	}



	render() {
		return (
			<React.Fragment>
							<form className={styles.educationForm}>
								<h3>Education {this.state.number + 1}</h3>
								<input
									name="instituation"
									className={styles.educationInput}
									type="text"
									placeholder= "Institution"
									value = {this.state.instituation}
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>
								<select
									name="degreeLevel"
									value={this.state.degreeLevel}
									onChange={(e) => this.handleChangeInput(e)}
									className={styles.educationInputL}
								>
									<option value="degreeLevel" selected disabled hidden>
										Select degree level
									</option>
									<option value="Associate">Associate</option>
									<option value="Bacholers">Bacholers</option>
									<option value="Masters">Masters</option>
									<option value="Doctoral">Doctoral</option>
								</select>
								<input
									name="degree"
									className={styles.educationInputR}
									type="text"
									placeholder= "Degree or certificate"
									value = {this.state.degree}
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>
								<label className={styles.educationLabelL}>Start: </label>
								<label className={styles.educationLabelR}>End: </label>
								<input
									name="start"
									className={styles.educationInputL}
									type="date"
									value = {this.state.start}
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>
								<input
									name="end"
									className={styles.educationInputR}
									type="date"
									value = {this.state.end}
									onChange={(e) => this.handleChangeInput(e)}
									required
								/>

							</form>

			</React.Fragment>
		);
	}
}

export default Candidate_Select;
