import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import firebase from "firebase";
import { Spinner } from "reactstrap";
import styles from "./SamplePayment.module.scss";
import secure from "../../assets/icons/progressBar/secure implementation.png";
import logoBig2 from "../../assets/payment/Logo3.png";
import check from "../../assets/payment/check-circle.png";
import exclamation from "../../assets/payment/exclamation-circle.png";
import unlink from "../../assets/payment/unlink.png";
const axios = require("axios");
const stripePromise = loadStripe(
    "pk_live_51ITEHdDQ5HoMjRpVs8jNOwqXcSFHVqlbTkxSW9nvTSsuSkddxomWLSLmtKAeOjvfmLhFz5wI82Ez8ZXO2rxtaDyE00IGICwdDU"
);

export class SamplePayment extends Component {
    state = {
        message: "",
        purchaseId: this.props.match.params.purchaseId,
        valid: "loading",
        priceId: "",
        customer_email: "",
        course: "",
        sessionID: "",
    };

    async componentDidMount() {
        const db = firebase.firestore();
        const purchaseRef = await db
            .collection("stripeLink")
            .doc(this.state.purchaseId)
            .get();
        let purchaseData = purchaseRef.data();

        if (purchaseData === undefined) {
            this.setState({
                valid: false,
            });
        } else {
            this.setState(
                {
                    priceId: purchaseData.priceId,
                    customer_email: purchaseData.email,
                    course: purchaseData.course,
                },
                () => {
                    console.log("new price id: ", this.state.priceId);
                }
            );

            var data = JSON.stringify({
                priceId: purchaseData.priceId,
                customer_email: purchaseData.email,
            });

            var config = {
                method: "post",
                url:
                    "https://us-central1-minos-labs-57c35.cloudfunctions.net/stripeApp/create-checkout-session",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            try {
                const response = await axios(config);
                const session = response.data.sessionId;
                this.setState({
                    valid: purchaseData.valid,
                    sessionID: session,
                });
            } catch (e) {
                console.log(e);
            }
        }
    }

    handleCheckout = async (event) => {
        const stripe = await stripePromise;

        try {
            const db = firebase.firestore();
            // let purchaseRef =
            await db
                .collection("stripeLink")
                .doc(this.state.purchaseId)
                .update({ SessionID: this.state.sessionID });
            // let oldStripeObj = purchaseRef.data();
            // oldStripeObj["SessionID"] = this.state.sessionID;

            // purchaseRef = db
            //     .collection("stripeLink")
            //     .doc(this.state.purchaseId);
            // await purchaseRef.update(oldStripeObj);

            // When the customer clicks on the button, redirect them to Checkout.

            console.log(this.state.sessionID);
            const result = await stripe.redirectToCheckout({
                sessionId: this.state.sessionID,
            });
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    render() {
        if (this.state.valid == "loading") {
            return (
                <React.Fragment>
                    <section class="hero-university2"></section>
                    <div className={styles.payment_process}>
                        <div className={styles.topBar}>
                            <div className={styles.pageName}>
                                <h1>Checkout</h1>
                            </div>
                        </div>
                        <div className={styles.paymentBlock}>

                            <Spinner></Spinner>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (this.state.valid) {
            return (
                <React.Fragment>
                    <section class="hero-university2"></section>
                    <div className={styles.payment_process}>
                        <div className={styles.topBar}>
                            <div className={styles.pageName}>
                                <h1>Checkout</h1>
                            </div>
                        </div>

                        <div className={styles.paymentBlock}>
                            <div className={styles.blockHeader}>
                                <h1>Checkout</h1>
                                <h2>Confirm Your Purchases</h2>
                            </div>
                            <div className={styles.imgWrapper}>
                                <img
                                    className={styles.courseImage}
                                    src={logoBig2}
                                    alt="Course img"
                                />
                            </div>
                            <br></br>
                            <br></br>
                            <div className={styles.courseInfo}>

							<p style={{display: this.state.course == "Cyber" ? "none" : "block"}}>
                            The Full-Stack Development Career Acceleration Course is a 6-week, accelerated program designed to enhance your technical & professional skills and prepare you to work within a Fortune 1000 company!
							</p>
                            <p style={{display: this.state.course == "Cyber" ? "block" : "none"}}>
                            The Cybersecurity Career Acceleration Course is a 6-week, accelerated program designed to enhance your technical & professional skills and prepare you to work within a Fortune 1000 company!
                            </p>
							<h4>What’s Included</h4>
							<ul>
								<li>
                                6-weeks of live instruction by industry professionals working in the field

								</li>
								<li>
                                Twice weekly executive coaching sessions by ICF coaches who work with CEOs, Politicians, and Executives
								</li>
								<li>Your profile and achievements, listed on the exclusive Minos Labs hiring portal, consisting of Fortune 1000 hiring managers always on the lookout for top technology talent
</li>
								<li>
                                Weekly, round-table advisor webinars with CIOs, CISOs, & CTOs at companies like Amazon, Peloton, BlackRock, and more!

								</li>
								<li>Access to our community slack channel where you will be able to collaborate with your peers and instructors while building connections that will last a lifetime
</li>
								<li>
                                Resume & LinkedIn optimization by our in-house team of professional career coaches
								</li>
                                <li>
                                One on one mock interviews and practice tailored to you
                                </li>
                                <li>
                                A library of technical and professional content that apply regardless of where you are in your career
                                </li>
                                <li>
                                An award winning, client simulation project to add to your portfolio
                                </li>
                                <li>
                                A certificate of completion, solidifying your credibility as a full-stack developer in the field
                                </li>
							</ul>
                            <p>
                            If you have any questions, please don’t hesitate to reach out to Himanshu (Head of Operations) or book time directly on his calendar!
                            </p>
                            <p>
                            Thank you for choosing Minos Labs, we look forward to helping you accelerate your career!
                            </p>
                            </div>
                            <div className={styles.paymentInfo}>
                                <div className={styles.paymentInfoL}>
                                    {this.state.course == "Cyber" ? (
                                        <h4>Cybersecurity Course </h4>
                                    ) : (
                                        <h4>Full-Stack Course </h4>
                                    )}
                                    <h4>Total: </h4>
                                </div>
                                <div className={styles.paymentInfoR}>
                                    <h4>x1</h4>
                                    <h4>$3997.00</h4>
                                </div>
                            </div>
                            <div className={styles.buttonWrapper}>
                                <button
                                    className={styles.checkoutButton}
                                    onClick={this.handleCheckout}
                                >
                                    CHECKOUT
                                </button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (!this.state.valid) {
            return (
                <React.Fragment>
                    <section class="hero-university2"></section>
                    <div className={styles.payment_process}>
                        <div className={styles.topBar}>
                            <div className={styles.pageName}>
                                <h1>Checkout</h1>
                            </div>
                        </div>

                        <div className={styles.paymentBlock}>
                            <h1 className={styles.paymentHeader}>
                                Linked Expired
                            </h1>
                            <div className={styles.imgWrapper}>
                                <img
                                    className={styles.paymentImage}
                                    src={unlink}
                                    alt="Course img"
                                />
                            </div>

                            <div>
                                <h1 className={styles.paymentMessage}>
                                    Your requested link has expired. Please try
                                    again or contact support.
                                </h1>
                            </div>
                            <div className={styles.buttonWrapper2}>
                                <button className={styles.checkoutButton}>
                                    CONTACT SUPPORT
                                </button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default SamplePayment;
