import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyAzqVI_3KpJmJJiu8Mum8zAe4MFufWD0R0",
    authDomain: "minos-labs-57c35.firebaseapp.com",
    databaseURL: "https://minos-labs-57c35.firebaseio.com",
    projectId: "minos-labs-57c35",
    storageBucket: "minos-labs-57c35.appspot.com",
    messagingSenderId: "36310363624",
    appId: "1:36310363624:web:379c5d854319a75e26885d",
    measurementId: "G-78D8YJD4EV"
  }; 

  class Firebase{
      constructor(){
          app.initializeApp(firebaseConfig);

          /* Helper */

          this.fieldValue = app.firestore.FieldValue;
          
          /* Firebase APIs */
          
          this.auth = app.auth();
          this.db = app.firestore();
          this.analytics = app.analytics(); 
      }

      // *** Auth API ***

      doCreateUserWithEmailAndPassword = (email, password) => 
        this.auth.createUserWithEmailAndPassword(email, password);

      doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

      doSignOut = () => this.auth.signOut();

      doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

      doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);



      onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
          if (authUser) {
            this.user(authUser.uid)
              .get()
              .then(snapshot => {
                const dbUser = snapshot.data();
     
                // default empty roles
                if (!dbUser.roles) {
                  dbUser.roles = {};
                }
     
                // merge auth and db user
                authUser = {
                  uid: authUser.uid,
                  email: authUser.email,
                  emailVerified: authUser.emailVerified,
                  providerData: authUser.providerData,
                  ...dbUser,
                };
     
                next(authUser);
              });
          } else {
            fallback();
          }
        });
     
     /* // *** User API ***
     
      user = uid => this.db.doc(`users/${uid}`);
     
      users = () => this.db.collection('users');
     
      // *** Message API ***
     
      message = uid => this.db.doc(`messages/${uid}`);
     
      messages = () => this.db.collection('messages');*/
  }

  export default Firebase;