/**
 * This file renders each row for the requirement table
 *
 * If user presses the edit button it renders set of inputs in the same row
 * to let user edit the row
 */
import React, { Component } from 'react'
import styles from "./HiringRequirements.module.scss";
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import edit_sign from '../../assets/hiring_requirement/edit4.png';
import { Button, ButtonToolbar } from 'react-bootstrap';
import usState from './stateList';
import close_sign from '../../assets/hiring_requirement/trash1.png';
import UserContext from '../../User/User';
import clock_sign from '../../assets/hiring_requirement/clock.png';
import user_sign from '../../assets/hiring_requirement/user.png';
import { Multiselect } from "multiselect-react-dropdown";
export class ActionButtons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: false,
            plainArray: this.props.skillList,
            location: this.props.requirements[0]["location"],
            position: this.props.requirements[0]["field"],
            urgency: this.props.requirements[0]["timeline"],
            hires: this.props.requirements[0]["resources"],
            selectedSkills:this.props.requirements[0]["skills"],
            errorMessage: "",
            usStates: usState(),
            addModalShow: false,
        };
        // This style is for the Multiselect dropdown box
        // This is the CSS customization
        this.style = {
            multiselectContainer: { // To change css for multiselect (Width,height,etc..)
            margin:"0px 11px",
            fontStyle:"bold",
            fontWeight:"300px"
            
              },
              searchBox: { // To change search box element look
                border: "1px solid #4bace9",
                width:"246px",
                padding:"0px",
                borderRadius:"10px"
              },
              inputField: { // To change input field position or margin
              },
              chips: { // To change css chips(Selected options)
                // borderRadius:"10px",
                // padding:"0px 0px",
                marginLeft:"5px",
                marginBottom:"5px",
               
              },
              optionContainer: { // To change css for option container 
                border: "1px solid",
              },
              option: { // To change css for dropdown options
                fontsize:"5px",
                lineHeight:"3px"
              },
            };
        this.handleOnEdit = this.handleOnEdit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnSet = this.handleOnSet.bind(this);
        this.handleOnDelete = this.handleOnDelete.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemove =this.onRemove.bind(this);
    }
    static contextType = UserContext;
    /**
     * Handles the edit state to render the edit form in the same row
     */
    handleOnEdit() {
        this.setState({
            edit: true
        })

    }

    /**
     * Handles deletion of an entire row represented by this component
     */
    handleOnDelete() {

        this.props.handleOnDelete(this.props.requirements[1])
    }

    /**
     * Updates the current requirementrepresented by this row in the firebase
     * as well as the state
     * @returns Null
     */
    async handleOnSet() {
        if (this.state.position === "Select" ||
            this.state.location === "" ||
            this.state.urgency === "") {

            this.setState({
                errorMessage: "Please make sure you entered all valid inputs"
            })
            return
        }
        else {
            this.props.handleOnEdit(this.props.requirements[1], {
                field: this.state.position,
                location: this.state.location,
                resources: this.state.hires,
                timeline: this.state.urgency,
                skills: [...this.state.selectedSkills],
                
            })
            this.setState({
                edit: false
            })
        }
    }
    /**
     * Manages the state update for each input component
     * @param {event} event
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    async onSelect(selectedList, selectedItem){
        console.log("getselected",selectedItem)
        console.log("selected list",selectedList)
        // let prevSelectedSkill = [...this.state.selectedSkills]
        // prevSelectedSkill.push(selectedItem)
        // this.setState({selectedSkills:prevSelectedSkill,skills: selectedList})
        this.setState({selectedSkills:selectedList})
    }
 async onRemove(selectedList, removedItem){
     console.log("removed item",removedItem)
    this.setState({selectedSkills:selectedList})
 }

    render() {
        const { plainArray} = this.state;
        let { userID, logoutUser, setUser, loginType } = this.context;
        let addModalClose = () => this.setState({addModalShow:false});
        //If the user has pressed the edit row button
        if (this.state.edit) {
            return (
                <React.Fragment className={styles.hrreqToolbar}>

                    <td>
                        <select
                            name="position"
                            value={this.state.position}
                            onChange={this.handleInputChange}
                            className={styles.toolbarInput1}
                        >
                            <option value="Select">
                                Select Field
													</option>
                            <option value="Full-Stack">
                                Full-Stack
													</option>
                            <option value="Cybersecurity">
                                Cybersecurity
													</option>
                        </select>
                        <div>{this.state.errorMessage}</div>
                    </td>
                    <td>


                        <input
                            autocomplete="on"
                            name="location"
                            onChange={this.handleInputChange}
                            type="text"
                            value={this.state.location}
                            placeholder="Location"
                            list="states"
                            className={styles.toolbarInput1}

                        />


                        <datalist id="states">
                            {this.state.usStates.map(currState => (
                                <option key={currState} value={currState}></option>
                            ))}
                        </datalist>
                    </td>
                    <td>
                        <select
                            name="urgency"
                            value={this.state.urgency}
                            onChange={this.handleInputChange}
                            className={styles.toolbarInput1}
                        >
                            <option value="Immediately">
                                Immediately
													</option>
                            <option value="4 Weeks">4 Weeks</option>
                            <option value="8 Weeks">8 Weeks</option>
                            <option value="12 Weeks">
                                12 Weeks
													</option>
                        </select>
                    </td>
                    <td>
                        <input
                            type="number"
                            name="hires"
                            min="1"
                            value={this.state.hires}
                            placeholder="1"
                            onChange={this.handleInputChange}
                            className={styles.toolbarInput1}
                        ></input>
                    </td>
                    <td>
                    <Multiselect 
                                            style={this.style}
                                            placeholder="Skills" showArrow options={plainArray} isObject={false} 
                                            onSelect={this.onSelect} selectedValues={this.state.selectedSkills}
                                            onRemove={this.onRemove} hidePlaceholder={true}
                                            />
                    </td>
                    <td>
                        <button className={styles.toolbarInput2} onClick={this.handleOnSet}>Set</button>
                    </td>

                </React.Fragment>
            )
        }

        //Renders the requirements and the two action buttons (Edit and Delete)
        else {

            return (
                <React.Fragment>


                    <td className={styles.hrreqPoistion}>
                        <a onClick={() => { this.props.handlePosSelect(this.props.requirements[1]) }}>{this.props.requirements[0]["field"]}</a> 
                    </td>
                    <td className={styles.hrreqLocation}>{this.props.requirements[0]["location"]}
                    </td>
                    <td>
                        <div style={{ display: "flex" }}>
                            {/* <img className={styles.hrreqClock} src={clock_sign}></img> */}
                            <div className={styles.hrreqDuration}>
                                {this.props.requirements[0]["timeline"]}
                            </div>
                        </div>
                    </td>

                    <td>
                        <div style={{ display: "flex" }}>
                            {/* <img className={styles.hrreqUserIcon} src={user_sign} alt="user icon"></img> */}
                            <div className={styles.hrreqPAvailable}>
                                {this.props.requirements[0]["resources"]}
                            </div>
                        </div>
                    </td>

                    <td> 
                        <div style={{ display: "flex" }}>
                            {/* <img className={styles.hrreqUserIcon} src={user_sign} alt="user icon"></img> */}
                            <div className={styles.hrreqDuration}>
                                {this.props.requirements[0]["skills"].join(", ")}
                            </div>
                        </div>
                    </td>

                    <td style={{display: loginType==='HR' ? 'none' : 'flex'}}>
                        <div>
                            <img src={edit_sign} alt="edit requirement" onClick={this.handleOnEdit} />
                        </div>
                        <div style={{marginLeft: '20px'}}>
                            {/* <ButtonToolbar> */}
                                {/* <Button> */}
                                <img src={close_sign} alt="delete requirement" onClick={()=>this.setState({addModalShow:true})}/>
                                <DeleteConfirmationModal handleOnDelete={this.handleOnDelete} show = {this.state.addModalShow} onHide={addModalClose}></DeleteConfirmationModal> 
                                {/* </Button> */}
                           
                            
                            {/* </ButtonToolbar> */}
                            
                        </div>
                    </td>

                </React.Fragment>




            )
        }
    }
}

export default ActionButtons
