import React from 'react'
import { Link } from 'react-router-dom';
import styles from "./PageNotFound.module.scss";

function PageNotFound() {
  
  return (
    <React.Fragment>
      <section class = "hero-university2">
      </section>

      <section className = {styles.PageNotFoundContainer}>
    <div className = {styles.blockPageNotFound}>
    <h1>404</h1>
    <h3>We couldn't find that page!</h3>
    
    </div>




  </section>

    </React.Fragment>
  )
}

export default PageNotFound;
