import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import UserContext from '../../User/User';
import firebase, { firestore } from 'firebase';
import styles from './StudentCommandCenterPage.module.scss';
import StudentDashboard from '../../components/DashboardStudent';
import calendarIcon from '../../assets/studentDashboard/calendarIcon.png';
import facebook from '../../assets/studentDashboard/facebook.png';
import google from '../../assets/studentDashboard/google.png';
// import moodle from "./studentDashboard/moodle.png";
import MLIcon from '../../assets/MLIcon.png';
// import "bootstrap/dist/css/bootstrap.min.css";
import MonthlyNav from '../../components/DashboardCalendar.js';

class StudentCommandCenterPage extends Component {
	state = {
		profile: {
			name: '',
			image: '',
			school: 'City Institution of Arizona',
			degree: 'BS',
			major: 'Computer Science',
			graduation: 'May 2022',
		},
		skills: {
			JavaScript: true,
			HTML: true,
			CSS: true,
			Bootstrap: true,
			Python: true,
		},
		interviews: {
			company: 'Facebook',
			position: 'Marketing Intern',
			nextStep: 'Technical Interview',
			currentStage: 3,
			interviewDate: '2021-03-25',
		},
		applications: {
			company: 'Google',
			position: 'Cloud Engineer Intern',
			nextStep: 'Submit coding challenge',
			currentStage: 3,
			totalStages: 4,
		},
		events: {
			date: '2021-03-25',
			company: 'Google',
			title: 'Tips on Interviewing with FAANG',
			favorited: true,
		},
		studentInterviewList: [],
	};
	static contextType = UserContext;

	async componentDidMount() {
		const context = this.context;
		const userInfo = context.userInfo;
		console.log(userInfo);
		const { profile } = this.state;
		profile.name = userInfo.name;
		profile.image = userInfo.profileUrl;
		this.setState({
			profile: profile,
		});

		const db = firebase.firestore();

	
		const interviewRef = db.collection('interviews');
		const interviewData = await interviewRef
			.where('candidateID', '==', this.context.userID)
			.get();

		const corporateDetail = db.collection('corporate');
		const corporateDetailData = await corporateDetail.get();

		var corporateNames = {};

		corporateDetailData.forEach((corp) => {
			corporateNames[corp.id] = `${corp.data().first} ${corp.data().last}`;
		});

		var studentInterviewList = [];
		interviewData.forEach((studentInterviewDoc) => {
			var studentInterviewObj = studentInterviewDoc.data();

			let interviewTime = 'unconfirmed';
			let start_time = studentInterviewObj.start_time;
			if (start_time != undefined) {
				interviewTime = new Date(start_time.seconds * 1000);

				studentInterviewObj.allDay = true;
				studentInterviewObj.meetingId = studentInterviewDoc.id;
				studentInterviewObj.title = `Interview with ${
					corporateNames[studentInterviewObj.corporateUserID]
				}`;
				studentInterviewObj.start = interviewTime;
				studentInterviewObj.end = interviewTime;
				studentInterviewList.push(studentInterviewObj);
			}
		});

		this.setState({
			studentInterviewList: studentInterviewList,
		});
	}

	render() {
		// let checkBox = (
		//   <div className={styles.checkbox}>
		//     <label className="container">
		//       <input type="checkbox" checked="checked" />
		//       <span className="checkmark"></span>
		//     </label>
		//   </div>
		// );

		return (
			<div className="student-command-center-page">
				<section className="hero-university2"></section>
				<section className="candidate_process">
					<section className="col-container">
						<StudentDashboard />

						{/*right expands when dashboard is closed*/}
						<div className="right">
							<div class="topBar">
								<div class="pageName">
									<h1>Dashboard</h1>
								</div>
							</div>
              <div className={styles.container}>
                <div className={styles.inner}>
                  <div className={styles.calendar}>
                    <div className={styles.calendarHeader}>
					<h1>Upcoming Interviews</h1>
                      <h2>Manage Your Upcoming Interviews</h2>
                    </div>
						<div>
						<MonthlyNav
							events={
								this.state.studentInterviewList
							}
						/>
					</div>
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.block}>
                      <div className={styles.blockInner}>
                        <div className={styles.blockHeader}>
						<h1>Events </h1>
						
                     	 <h2>Upcoming Events to Accelerate Your Career</h2>
                          
                        </div>
						<p>Cohort Events are unavailable until May 17, 2021!</p>

                      </div>
                    </div>
                    <div className={styles.block}>




                    <div className={styles.blockInner}>
                        <div className={styles.blockHeader}>
							<h1>Application Tracker</h1>
							
							<h2>Manage Your Applications</h2>
                        </div>




                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    );
  }
}

export default StudentCommandCenterPage;
