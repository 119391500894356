import React from 'react';
import firebase from 'firebase';
import styles from './HRSelect.module.scss';
import add_acc_logo from '../../assets/manage account/noprofilepic.jpg';

class HRSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.id,
		};
	}


	componentDidMount() {
		var storage = firebase.storage().ref();

		if(this.state.profileUrl==undefined){
            storage
			.child('corporatePics')
			.child(this.state.id)
			.getDownloadURL()
			.then((url) => {
				this.setState({
					profileUrl: url,
				});
			})
			.catch((e) => {

			});

		}
		
	}

	render() {
		return (
			<React.Fragment>
				<div className={styles.hrContainer}>
					<img className={styles.hrProfilePic} src={this.state.profileUrl||add_acc_logo} />
					<p className={styles.hrInfo}>
						<span className={styles.hrName}>{this.props.name}</span>
						<br />
						<span className={styles.hrTitle}>{this.props.title}</span>
					</p>
				</div>
			</React.Fragment>
		);
	}
}

export default HRSelect;
