import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import styles from './Dashboard.module.scss';
import firebase from "firebase";

import add_acc_logo from "../../assets/manage account/noprofilepic.jpg";
import chalkboard from '../../assets/icons/sideMenu/chalkboard.png';
import clipboard from '../../assets/icons/sideMenu/clipboard-list.png';
import cog from '../../assets/icons/sideMenu/cog.png';
import search from '../../assets/icons/sideMenu/search.png';
import signOut from '../../assets/icons/sideMenu/sign-out-alt.png';
import userTie from '../../assets/icons/sideMenu/user-tie.png';
import bars from '../../assets/icons/sideMenu/bars.png'
import interview from "../../assets/icons/marketing-icon.png"

import UserContext from '../../User/User';
import { InvertColors } from '@material-ui/icons';

class Candidate_Profile extends React.Component {
	constructor() {
		super();
		this.state = {
			expand: true,
			url: "",
		};
		this.handleCollapseClick = this.handleCollapseClick.bind(this);
		this.logoutHandler = this.logoutHandler.bind(this);
	}

	static contextType = UserContext;

	componentDidMount() {
		this.logoutHandler();
		const db = firebase.firestore();
		const context = this.context;
		this.setState({
			url: context.userInfo.profileUrl,		
		});	

		var storage = firebase.storage();
		storage
		  .ref("corporatePics")
		  .child(context.userID)
		  .getDownloadURL()
		  .then((url) => {
			this.setState({ url });
		  })
		  .catch((e) => {});
	}

	logoutHandler() {
		var x = document.getElementById('logoutSection');
		var x1 = ReactDOM.findDOMNode(x).style.display;
		var y = document.getElementById('logoutBox');
		var y1 = ReactDOM.findDOMNode(y).style.display;

		if (x1 === 'none') {
			ReactDOM.findDOMNode(x).style.display = 'flex';
			ReactDOM.findDOMNode(y).style.display = 'flex';
		} else {
			ReactDOM.findDOMNode(x).style.display = 'none';
			ReactDOM.findDOMNode(y).style.display = 'none';
		}
	}

	signOutUser() {
		const context = this.context;
		context.logoutUser();
	}

	handleCollapseClick() {
		this.setState({
			expand: !this.state.expand,
		});
	}

	render() {
		//user data
		let {
			userID,
			loginType,
			logoutUser,
			setUser,
			setloginType,
			userInfo
		} = this.context;
		let fullname = userInfo.first + " " + userInfo.last;
		let title = userInfo.companyTitle;
		return (
			<React.Fragment>
				<div
					className={styles.logoutSection}
					id="logoutSection"
					onClick={this.logoutHandler}
				>
					<div
						className={styles.logoutBox}
						id="logoutBox"
						onClick={this.logoutHandler}
					>
						<h4> Are you sure you want to logout?</h4>
						<div className={styles.logoutButtons}>
							<button class="hvr-sweep" onClick={this.logoutHandler}>
								Cancel
							</button>
								<button
									class="hvr-sweep"
									onClick={() => {
										this.signOutUser();
										this.logoutHandler();
										window.location.reload();
									}}
								>
									Logout
								</button>
						</div>
					</div>
				</div>

				<nav className={this.state.expand ? styles.left : styles.leftCollapse}>
					<div onClick={this.handleCollapseClick} className={this.state.expand ? styles.collapseButton : styles.hide}><i class="fa fa-times"></i></div>
				<div className={this.state.expand ? styles.headerContainer : styles.hide}>
					<div className={styles.imgWrapper}>
					<img
                        src={this.state.url || add_acc_logo}
                        className={styles.header_img}
                      ></img>
					</div>

					  <h4 className={styles.headerName}>{fullname}</h4>
					  <h6 className={styles.headerPosition}>{title}</h6>
                    </div>
					<ul className={styles.navbarNav}>
						<li
							onClick={this.handleCollapseClick}
							className={this.state.expand ? styles.hide : styles.navItem }
							style={loginType === 'HR' ? { display: 'none' } : {}}
						>
							<div className={styles.navLink}>
								<img
									src={bars}
									alt="View Candidates Icon"
								/>
							</div>
						</li>
						<li
							className={styles.navItem}
							style={loginType === 'HR' ? { display: 'none' } : {}}
						>
							
							<Link to="/command_center" className={styles.navLink}>
								<img
									src={chalkboard}
									alt="View Candidates Icon"
								/>
								<p className={this.state.expand ? styles.linkText : styles.hide}>Dashboard</p>
							</Link>
						</li>
						<li
							className={styles.navItem}
							style={loginType === 'HR' ? { } : {display: 'none'}}
						>
							
							<Link to="/hr_command_center" className={styles.navLink}>
								<img
									src={chalkboard}
									alt="View Candidates Icon"
								/>
								<p className={this.state.expand ? styles.linkText : styles.hide}>Dashboard</p>
							</Link>
						</li>
						<li
							className={styles.navItem}
							style={loginType === 'HR' ? { display: 'none' } : {}}
						>
							<Link to="/View_Candidates" className={styles.navLink}>
								<img
									src={search}
									alt="View Candidates Icon"
								/>
								<span className={this.state.expand ? styles.linkText : styles.hide}>Candidates</span>
							</Link>
						</li>
						{/*}
						<li
							className={styles.navItem}
							style={loginType === 'HR' ? { display: 'none' } : {}}
						>
							<Link to="/Saved_Candidates" className={styles.navLink}>
								<img
									src={search}
									alt="Saved Candidates Icon"
								/>
								<span className={styles.linkText}>Saved</span>
							</Link>
						</li>
								*/}
						<li className={styles.navItem}>
							<Link to="/Hiring_Requirements" className={styles.navLink}>
								<img
									src={clipboard}
									alt="Hiring Requirements Icon"
								/>
								<span className={this.state.expand ? styles.linkText : styles.hide}>Requirements</span>
							</Link>
						</li>

						<li className={styles.navItem}>
							<Link to="/scheduledinterviews" className={styles.navLink}>
								<img src={interview} alt="Schedule Interview" style={{filter: "grayscale(1)", transform: "scale(1.5)"}}/>
								<span className={this.state.expand ? styles.linkText : styles.hide}>Interviews</span>
							</Link>
						</li>

						<li
							className={styles.navItem}
							style={loginType === 'HR' ? { display: 'none' } : {}}
						>
							
							<Link to="/Hire_Candidates_Now" className={styles.navLink}>
								<img
									src={userTie}
									alt="Hire Candidates Now Icon"
								/>
								<span className={this.state.expand ? styles.linkText : styles.hide}>Hire</span>
							</Link>
						</li>
						<li
							className={styles.navItem}
							style={
								loginType === 'HR'
									? { display: 'inline-block' }
									: { display: 'none' }
							}
						>
							<Link to="/hr_doc_upload" className={styles.navLink}>
								<img src={userTie} alt="Upload Doc" />
								<span className={this.state.expand ? styles.linkText : styles.hide}>Documents</span>
							</Link>
						</li>
						<li className={styles.navItem}>
							<Link to="/Edit_Manage_Account" className={styles.navLink}>
								<img src={cog} alt="Manage Account Icon" />
								<span className={this.state.expand ? styles.linkText : styles.hide}>Account</span>
							</Link>
						</li>
						
						<li className={styles.navItem}>
							<button
								className={styles.navLink}
								onClick={this.logoutHandler}
							>
								<img src={signOut} alt="Log Out Icon" />
								<span className={this.state.expand ? styles.linkText : styles.hide}>Logout</span>
							</button>
						</li>
					</ul>
					{/*<div class="sticky">
							<nav className="rectangle">
								<Link to="View_Candidates">
									<button
										className="dash_button"
										style={
											this.props.pageName === 'View_Candidate'
												? { color: '#4BACE9' }
												: { color: '#FFFFFF' }
										}
									>
										<img
											src={
												this.props.pageName === 'View_Candidate'
													? b_ViewCandidates
													: ViewCandidates
											}
											alt="View Candidates Icon"
										/>
										<h3>View Candidates</h3>
									</button>
								</Link>
								<Link to="Saved_Candidates" style={
											loginType == 'HR'
												? { display: 'none' }
												: { display: 'block' }
										}>
									<button
										className="dash_button"
										style={
											this.props.pageName === 'Save_Candidate'
												? { color: '#4BACE9' }
												: { color: '#FFFFFF' }
										}
									>
										<img
											src={
												this.props.pageName === 'Save_Candidate'
													? b_SavedCandidates
													: SavedCandidates
											}
											alt="Saved Candidates Icon"
										/>
										<h3>Saved Candidates</h3>
									</button>
								</Link>
								<Link to="Hiring_Requirements" >
									<button
										className="dash_button"
										style={
											this.props.pageName === 'Hire_Req'
												? { color: '#4BACE9' }
												: { color: '#FFFFFF' }
										}
									>
										<img
											src={
												this.props.pageName === 'Hire_Req'
													? b_HiringReqs
													: HiringReqs
											}
											alt="Hiring Requirements Icon"
										/>
										<h3>Hiring Requirements</h3>
									</button>
								</Link>
								<Link to="Hire_Candidates_Now" style={
											loginType == 'HR'
												? { display: 'none' }
												: { display: 'block' }
										}>
									<button
										className="dash_button"
										style={
											this.props.pageName === 'Hire_Candidate'
												? { color: '#4BACE9' }
												: { color: '#FFFFFF' }
										}
									>
										<img
											src={
												this.props.pageName === 'Hire_Candidate'
													? b_HireCandidatesNow
													: HireCandidatesNow
											}
											alt="Hire Candidates Now Icon"
										/>
										<h3>
											Hire <br /> Candidates Now
										</h3>
									</button>
								</Link>
								<div style={{ height: '16px' }}></div>
								<Link to="/hr_doc_upload" style={
											loginType == 'HR'
												? { display: 'block' }
												: { display: 'none' }
										}>
									<button
										className="dash_button"
										style={
											this.props.pageName == 'UploadDoc'
												? { color: '#4BACE9' }
												: { color: '#FFFFFF' }
										}
									>
										<img
											src={
												this.props.pageName == 'UploadDoc'
													? b_Group
													: Group
											}
										/>
										<h3>
											Upload Document
										</h3>
									</button>
								</Link>
								
								<Link to="Manage_Account" >
									<button
										className="dash_button"
										style={
											this.props.pageName === 'Manage_acc'
												? { color: '#4BACE9' }
												: { color: '#FFFFFF' }
										}
									>
										<img
											src={
												this.props.pageName === 'Manage_acc'
													? b_ManageAccount
													: ManageAccount
											}
											alt="Manage Account Icon"
										/>
										<h3>Manage Account</h3>
									</button>
								</Link>
								<button
									className="dash_button logout"
									onClick={this.logoutHandler}
									style={
										this.props.pageName === 'Logout_id'
											? { color: '#4BACE9' }
											: { color: '#FFFFFF' }
									}
								>
									<img
										style={{ marginTop: '-19px' }}
										src={
											this.props.pageName === 'Logout_id'
												? b_Logout
												: Logout
										}
										alt="Log Out Icon"
									/>
									<h3>Log Out</h3>
								</button>
								<button
									onClick={this.handleCollapseClick}
									className="dash_collapse_button"
								>
									<img
										className={this.state.arrowDirection}
										src={arrowleft}
										alt="A Left Arrow"
									/>
								</button>
							</nav>
						</div>*/}
				</nav>
			</React.Fragment>
		);
	}
}

export default Candidate_Profile;